// sampleReducer.js
import { createReducer, createActions } from "reduxsauce";
import {
    ProfessionalTypes,
    IProfessionalState,
    IProfessionalActionsCreators,
} from "./types";

export const { Creators, Types } = createActions<
    Record<ProfessionalTypes, ProfessionalTypes>,
    IProfessionalActionsCreators
>({
    getProfessionalByIdRequest: ["payload"],
    getProfessionalByIdSuccess: ["payload"],
    getProfessionalByIdFailure: [],

    createOrEditProfessionalRequest: ["payload"],
    createOrEditProfessionalSuccess: ["payload"],
    createOrEditProfessionalFailure: [],

    clearProfessional: [],
});

const INITIAL_STATE: IProfessionalState = {
    error: false,
    loading: false,
};

const request = (state = INITIAL_STATE, _: any) => {
    return { ...state, error: false, loading: true };
};

const failure = (state = INITIAL_STATE) => {
    return { ...state, error: true, loading: false };
};

const setState = (state = INITIAL_STATE, action: any) => ({
    ...state,
    loading: false,
    error: false,
    ...action.payload,
});

const clear = () => INITIAL_STATE;

const HANDLERS = {
    [Types.GET_PROFESSIONAL_BY_ID_REQUEST]: request,
    [Types.GET_PROFESSIONAL_BY_ID_SUCCESS]: setState,
    [Types.GET_PROFESSIONAL_BY_ID_FAILURE]: failure,

    //

    [Types.CREATE_OR_EDIT_PROFESSIONAL_REQUEST]: request,
    [Types.CREATE_OR_EDIT_PROFESSIONAL_SUCCESS]: setState,
    [Types.CREATE_OR_EDIT_PROFESSIONAL_FAILURE]: failure,

    //

    [Types.CLEAR_PROFESSIONAL]: clear,
};

export default createReducer(INITIAL_STATE, HANDLERS);
