import { InputType } from "utils/enums/InputType";

export const addressFields = (pathName = "person") => [
    {
        name: `${pathName}.address.zip`,
        type: InputType.CEP,
        grid: {
            col: 3,
        },
    },
    {
        type: InputType.Space,
        grid: {
            col: 8,
        },
    },

    {
        name: `${pathName}.address.street`,
        type: InputType.Text,
        grid: {
            col: 6,
        },
    },
    {
        name: `${pathName}.address.number`,
        type: InputType.Text,
        grid: {
            col: 3,
        },
    },
    {
        name: `${pathName}.address.complement`,
        type: InputType.Text,
        grid: {
            col: 3,
        },
    },

    {
        name: `${pathName}.address.neighbourhood`,
        type: InputType.Text,
        grid: {
            col: 3,
        },
    },

    {
        name: `${pathName}.address.city`,
        type: InputType.Text,
        grid: {
            col: 3,
        },
    },
    {
        name: `${pathName}.address.state`,
        type: InputType.Text,
        grid: {
            col: 3,
        },
    },
];
