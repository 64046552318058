import React, { memo, useCallback, useEffect } from "react";
import { useTitle } from "../../hooks/useTitle";
import Button from "../Button";
import CustomText from "../Text";
import * as S from "./styles";
import history from "../../services/history";
import { useSelector } from "react-redux";
import { IReduxStore } from "../../store/ducks";
import HeaderDropdown from "./dropdown";
import useIsMobile from "../../hooks/useIsMobile";
import { MainContainer } from "../../pages/Main/styles";
import Breadcrumb from "components/Breadcrumb";
import { IRoute } from "utils/interfaces/IRoute";
import { useHeaderTitle } from "hooks/useHeaderTitle";

interface IProps {
    title?: string;
    canCreate?: boolean;
    createButtonLabel?: string;
    path: string;
    showTotal?: boolean;
    breadcrumbs: IRoute[];
    showEditButton?: boolean;
    paramId?: number;
    hideTitle?: boolean;
}

const Header = memo(
    ({
        title = "",
        canCreate = false,
        createButtonLabel = "Criar novo",
        path,
        showTotal = false,
        breadcrumbs = [],
        showEditButton = false,
        paramId,
        hideTitle = false,
    }: IProps) => {
        const { headerTitle, setTitle } = useHeaderTitle();

        useEffect(() => {
            setTitle(title);
        }, [setTitle, title]);

        useTitle(headerTitle);

        const isMobile = useIsMobile();

        const totalItems = useSelector<IReduxStore, number>(
            ({ crud }) => crud?.pagination?.totalItems || 0
        );

        const handleClickCreate = useCallback(
            () => history.push(`/app${path}/novo`),
            [path]
        );

        const handleClickEdit = useCallback(
            () =>
                history.push(
                    `/app${path
                        .replace("/ver", "/editar")
                        .replace(":id", "")}${paramId}`,
                    {}
                ),
            [paramId, path]
        );

        return (
            <>
                <S.HeaderContainer>
                    <MainContainer
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <Breadcrumb routes={breadcrumbs} />

                        {!isMobile && <HeaderDropdown />}
                    </MainContainer>
                </S.HeaderContainer>

                {!hideTitle && (
                    <S.HeaderBreadcrumbContainer>
                        <CustomText
                            color="black"
                            size={22}
                            style={{ flex: 1 }}
                            bottom="8px"
                        >
                            {headerTitle}
                        </CustomText>
                        {showEditButton && (
                            <Button
                                style={{ marginLeft: "auto" }}
                                variant="success"
                                onClick={handleClickEdit}
                            >
                                Editar
                            </Button>
                        )}
                    </S.HeaderBreadcrumbContainer>
                )}

                <S.SubHeaderContainer>
                    {showTotal && (
                        <CustomText color="base" size={16}>
                            {totalItems} no total
                        </CustomText>
                    )}

                    {canCreate && (
                        <Button
                            style={{ marginLeft: "auto" }}
                            variant="success"
                            onClick={handleClickCreate}
                        >
                            {createButtonLabel}
                        </Button>
                    )}
                </S.SubHeaderContainer>
            </>
        );
    }
);

export default Header;
