import styled from "styled-components/macro";
import { Card } from "../Card";

export const ListDataContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    > ${Card} {
        padding: 0;
    }

    .table-enter {
        opacity: 0;
    }
    .table-enter-active {
        opacity: 1;
        transition: opacity 300ms, transform 300ms;
    }
    .table-exit {
        opacity: 1;
    }
    .table-exit-active {
        opacity: 0;
        transition: opacity 300ms, transform 300ms;
    }
`;

export const ListDataHeaderContainer = styled.header`
    padding: 20px;
    display: flex;
    align-items: center;
`;
