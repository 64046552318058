import { put, takeLatest, call } from "redux-saga/effects";
import graphql from "../../services/graphql";
import { ISagaAction } from "../../utils/interfaces/ISagaAction";
import { Creators, Types } from ".";
import { Creators as ModalActions } from "../modal";
import { toast } from "react-toastify";
import history from "../../services/history";
import { crudFormatBody } from "./utils";
import { ICrudCreatePayload } from "./types";
import _get from "lodash/get";
import _set from "lodash/set";
import isDevelopment from "../../helpers/isDevelopment";
import { IYield } from "utils/interfaces/IYield";

function* getAll(
    action: ISagaAction<{
        query: string;
        page: number;
        search?: any;
        contentId?: number | string;
        orderBy?: any;
    }>
) {
    try {
        if (!action?.payload?.query) {
            throw new Error();
        }

        const { page = 1, query, search, contentId, orderBy } = action.payload;

        const itemsPerPage = 10;

        if (isDevelopment()) {
            console.group("CRUD SAGA");
            console.log(query);
            console.log({ search });
            console.groupEnd();
        }

        const variables = {
            limit: itemsPerPage,
            offset: (page >= 1 ? page - 1 : 0) * itemsPerPage,
            orderBy,
            ...(!!search && { where: search }),
        };

        const { data } = yield call(graphql, query, variables, `crudGetAll`);

        if (!data?.items) {
            throw new Error();
        }

        const totalItems = data?.quantity?.aggregate?.count ?? 0;

        const pagination = {
            page,
            totalPages: Math.ceil(totalItems / itemsPerPage),
            totalItems,
        };

        const response = {
            items: data.items,
            pagination,
            contentId,
        };

        yield put(Creators.getAllSuccess(response));
    } catch (error) {
        console.log("error", error);

        yield call(toast.error, "Desculpe, tivemos um problema.");

        yield put(
            Creators.getAllFailure({ contentId: action?.payload?.contentId })
        );
    }
}

function setMainColumnsVariables(mainColumns: string[], item: any) {
    var tmpObject: any = {};

    for (var i = 0; i < mainColumns.length; ++i) {
        const key = mainColumns[i];

        _set(tmpObject, key, _get(item, key));
    }

    return tmpObject;
}

function* deleteById(
    action: ISagaAction<{
        item: any;
        query: string;
        mainColumn?: string;
        contentId?: number | string;
    }>
) {
    try {
        if (!action?.payload?.query) {
            throw new Error();
        }

        yield put(ModalActions.closeModal());

        const { item, query, mainColumn, contentId } = action.payload;

        const params = Array.isArray(mainColumn)
            ? setMainColumnsVariables(mainColumn, item)
            : !!mainColumn
            ? { [mainColumn]: item[mainColumn] }
            : { id: item.id };

        console.log({
            item,
            query,
            params,
            contentId,
        });

        const { data } = yield call(graphql, query, params);

        if (!data?.item) {
            throw new Error();
        }

        yield put(
            Creators.deleteByIdSuccess({
                item,
                mainColumn,
                contentId,
            })
        );

        yield call(toast.success, "Registro removido.");
    } catch (error) {
        console.log("error", error);

        yield call(toast.error, "Desculpe, tivemos um problema.");

        yield put(
            Creators.deleteByIdFailure({ contentId: action.payload?.contentId })
        );
    }
}

function* createOrEdit(action: ISagaAction<ICrudCreatePayload>): IYield {
    try {
        if (!action?.payload?.query) {
            throw new Error();
        }

        const {
            id,
            query,
            body,
            onCreate,
            formatFunction = crudFormatBody,
        } = action.payload;

        const formatBody: any = yield call(formatFunction, body, id);

        if (isDevelopment()) {
            console.log(query);
            console.log(body);
        }

        const { data } = yield call(graphql, query, formatBody);

        if (!data?.item) {
            throw new Error();
        }

        yield put(Creators.createOrEditSuccess(data.item));

        yield call(toast.success, "Registro salvo!");

        if (onCreate) {
            return onCreate(data.item);
        }

        const location = `${window.location.pathname}`.split(
            id ? "/editar" : "/novo"
        )[0];

        yield call(history.push, { pathname: location });
    } catch (error) {
        console.log("error", error);

        yield call(toast.error, "Desculpe, tivemos um problema.");
        yield put(Creators.createOrEditFailure());
    }
}

function* getById(
    action: ISagaAction<{
        id: number;
        query: string;
    }>
) {
    try {
        if (!action?.payload?.query || !action?.payload?.id) {
            throw new Error();
        }

        const { id, query } = action.payload;

        const { data } = yield call(graphql, query, { id });

        if (!data?.item) {
            throw new Error();
        }

        yield put(Creators.getByIdSuccess(data.item));
    } catch (error) {
        console.log("error", error);

        yield call(toast.error, "Desculpe, tivemos um problema.");

        yield put(Creators.getByIdFailure());

        const location = `${window.location.pathname}`.split("/editar")[0];

        yield call(history.push, { pathname: location });
    }
}

export default [
    takeLatest(Types.GET_ALL_REQUEST, getAll),
    takeLatest(Types.DELETE_BY_ID_REQUEST, deleteById),
    takeLatest(Types.CREATE_OR_EDIT_REQUEST, createOrEdit),
    takeLatest(Types.GET_BY_ID_REQUEST, getById),
];
