import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { theme } from "config/theme";

const colors = theme.colors.components.breadcrumb;

export const BreadcrumbContainer = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
`;

export const BreadcrumbLink = styled(NavLink)`
    margin-right: 10px;
    color: ${colors.color};
    text-decoration: none;

    :hover {
        color: ${colors.hoverColor};
    }
`;
