import React, { memo, useEffect } from "react";
import Form from "..";
import { VerticalDiv } from "../../../assets/globalStyles";
import { InputType } from "../../../utils/enums/InputType";
import { IInputField } from "../../../utils/interfaces/IInputField";
import { ColorsVariants } from "../../../utils/types/Variants";
import Button from "../../Button";
import { Col, Row } from "../../Grid";
import Spinner from "../../Spinner";
import InputFactory from "../InputFactory";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import _get from "lodash/get";

export interface IOnChangeWatchers {
    name: string;
    value: any;
    setValue(name: string, value: any): void;
}

export interface IFormFactoryProps {
    onSubmit(data: any): void;
    fields: IInputField[];
    schema?: any;
    initialData?: any;
    submitButtonLabel?: string | React.ReactNode;
    submitButtonVariant?: ColorsVariants;
    isLoading?: boolean;
    children?: any;
    watchers?: string[];
    onChangeWatchers?(data: IOnChangeWatchers[]): void;
}

const FormFactory = ({
    onSubmit,
    fields,
    schema,
    initialData,
    submitButtonLabel = "Salvar",
    submitButtonVariant = "main",
    isLoading,
    children,
    watchers,
    onChangeWatchers,
}: IFormFactoryProps) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        control,
        watch,
        setValue,
    } = useForm({
        ...(!!initialData && { defaultValues: initialData }),
        ...(!!schema && {
            resolver: yupResolver(schema),
        }),
    });

    useEffect(() => {
        return () => {
            reset();
        };
    }, [reset]);

    const itemsToWatch = watchers?.map((name) => ({
        name,
        value: watch(name),
        setValue,
    }));

    useEffect(() => {
        if (!itemsToWatch?.length || !onChangeWatchers) {
            return;
        }

        onChangeWatchers(itemsToWatch);
    }, [itemsToWatch, onChangeWatchers]);

    const getError = (fieldName: string) =>
        _get(errors, `${fieldName}.message`);

    return (
        <VerticalDiv style={{ position: "relative" }}>
            {isLoading && <Spinner fullScreen />}

            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    {fields.map(({ name, type, grid, ...rest }, index) => {
                        return (
                            <Col
                                key={`${name}_${index}`}
                                hidden={type === InputType.Hidden}
                                col={grid?.col ?? 12}
                                {...grid}
                            >
                                <InputFactory
                                    key={`field_${index}`}
                                    name={name}
                                    type={type}
                                    register={register}
                                    control={control}
                                    errorMessage={getError(name)}
                                    {...rest}
                                />
                            </Col>
                        );
                    })}
                </Row>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Button
                        type="submit"
                        variant={submitButtonVariant}
                        style={{ alignSelf: "baseline" }}
                    >
                        {submitButtonLabel}
                    </Button>
                    {children}
                </div>
            </Form>
        </VerticalDiv>
    );
};

export default memo(FormFactory);
