import { dictionary } from "config/dictionary";
import { ProfessionalPosition } from "utils/enums/ProfessionalPosition";
import { personSchema } from "utils/schemas/person";
import { multipleSelectSchema, selectSchema } from "utils/schemas/select";
import { object, string } from "yup";

export const professionalSchema = (position?: ProfessionalPosition) =>
    object().shape({
        position: selectSchema().required(dictionary.validations.required),
        ...(position === ProfessionalPosition.Teacher && {
            documentProfessional: string()
                .min(1, dictionary.validations.invalid)
                .typeError(dictionary.validations.invalid)
                .required(dictionary.validations.required),
        }),
        ...(position === ProfessionalPosition.Coordinator && {
            projects: multipleSelectSchema(),
        }),
        person: personSchema({ withAdress: true, withUser: true }),
    });
