import React, { useMemo, useState } from "react";
import Header from "../../components/Header";
import Navbar from "../../components/Navbar";
import {
    MainContainer,
    MainWrap,
    MainContent,
    MainContentScroll,
} from "./styles";

import { Route, Switch } from "react-router";
import { privateRoutes } from "../../routes/privateRoutes";
import useAuthenticatedRoute from "../../hooks/useAuthenticatedRoute";
import { HeaderTitleContext } from "hooks/useHeaderTitle";

const Main = () => {
    useAuthenticatedRoute();

    const [title, setTitle] = useState("");

    const headerTitle = useMemo(() => ({ title, setTitle }), [title]);

    return (
        <MainWrap>
            <Navbar />

            <Switch>
                {privateRoutes.map(
                    (
                        {
                            path,
                            component: Component,
                            exact,
                            showTotal,
                            hideTitle = false,
                            ...rest
                        },
                        index
                    ) => (
                        <Route
                            path={`/app${path}`}
                            exact={exact}
                            key={`route_${index}`}
                            render={(props) => {
                                const breadcrumbs = privateRoutes
                                    // Get all routes that contain the current one.
                                    .filter(({ path }) =>
                                        props.match.path.includes(path)
                                    )
                                    // Swap out any dynamic routes with their param values.
                                    // E.g. "/pizza/:pizzaId" will become "/pizza/1"
                                    .map(({ path, ...rest }) => ({
                                        path: Object.keys(props.match.params)
                                            .length
                                            ? Object.keys(
                                                  props.match.params
                                              ).reduce(
                                                  (path, param) =>
                                                      path.replace(
                                                          `:${param}`,
                                                          props.match.params[
                                                              param
                                                          ]
                                                      ),
                                                  path
                                              )
                                            : path,
                                        ...rest,
                                    }));

                                return (
                                    <HeaderTitleContext.Provider
                                        value={headerTitle}
                                    >
                                        <MainContent>
                                            <Header
                                                title={rest?.title}
                                                canCreate={!!rest?.canCreate}
                                                path={path}
                                                showTotal={!!showTotal}
                                                breadcrumbs={breadcrumbs}
                                                showEditButton={
                                                    !!rest?.showEditButton
                                                }
                                                paramId={
                                                    props?.match?.params?.id
                                                }
                                                hideTitle={hideTitle}
                                            />
                                            <MainContainer>
                                                <MainContentScroll>
                                                    <Component
                                                        {...props}
                                                        {...rest}
                                                        path={path}
                                                    />
                                                </MainContentScroll>
                                            </MainContainer>
                                        </MainContent>
                                    </HeaderTitleContext.Provider>
                                );
                            }}
                        >
                            {/* {(props) => (
                                <MainContent>
                                    <Header
                                        title={rest?.title}
                                        canCreate={!!rest?.canCreate}
                                        path={path}
                                        showTotal={!!showTotal}
                                    />
                                    <MainContainer>
                                        <MainContentScroll>
                                            <Component
                                                {...props}
                                                {...rest}
                                                path={path}
                                            />
                                        </MainContentScroll>
                                    </MainContainer>
                                </MainContent>
                            )} */}
                        </Route>
                    )
                )}
            </Switch>
        </MainWrap>
    );
};

export default Main;
