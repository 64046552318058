import Button from "components/Button";
import CustomText from "components/Text";
import React from "react";
import { ListDataHeaderContainer } from "./styles";

// import { Container } from './styles';

interface IProps {
    title: string;
    showTotal?: boolean;
    totalItems?: number;
    canCreate?: boolean;
    handleClickCreate?(): void;
    createButtonLabel?: string;
}

const ListDataHeader = ({
    title,
    showTotal,
    totalItems,
    canCreate,
    handleClickCreate,
    createButtonLabel,
}: IProps) => {
    return (
        <ListDataHeaderContainer>
            <div style={{ flex: 1 }}>
                <CustomText
                    color="black"
                    weight="SemiBold"
                    size={17}
                    right="auto"
                >
                    {title}
                </CustomText>
                {showTotal && (
                    <CustomText
                        color="gray"
                        weight="SemiBold"
                        size={12}
                        top="8px"
                    >
                        {totalItems} no total
                    </CustomText>
                )}
            </div>

            {!!canCreate && (
                <Button variant="main" onClick={handleClickCreate}>
                    {createButtonLabel}
                </Button>
            )}
        </ListDataHeaderContainer>
    );
};

export default ListDataHeader;
