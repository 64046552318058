import React, { useCallback, useEffect, useRef, useState } from "react";
import Spinner from "../Spinner";
import { GoogleMapsContainer, GoogleMapsLoading } from "./styles";
import Script from "react-load-script";
import formatAddressComponents from "../../helpers/formatAddressComponents";
import isValid from "../../helpers/isValid";

interface IProps {
    latitude?: string;
    longitude?: string;
    onChange?(data: any): void;
    center?: { latitude?: string; longitude?: string };
    locations?: {
        name: string;
        latitude?: string;
        longitude?: string;
        totalStudents?: number;
        totalClasses?: number;
    }[];
    zoom?: number;
    id?: string;
    place?: string;
}

const GoogleMaps = ({
    latitude,
    longitude,
    onChange,
    locations,
    center,
    zoom = 13,
    id = "map",
}: IProps) => {
    const [loading, setLoading] = useState(true);

    const marker = useRef<any>();
    const google = useRef<any>();
    const geocoder = useRef<any>();
    const map = useRef<any>();

    useEffect(() => {
        if (!isValid(latitude) || !isValid(longitude)) {
            return;
        }

        const ltlng = {
            lat: Number(latitude),
            lng: Number(longitude),
        };

        marker.current?.setPosition(ltlng);

        map.current?.setCenter(ltlng);
    }, [latitude, longitude]);

    const toggleBounce = useCallback((result: any) => {
        if (marker.current.getAnimation() !== null) {
            marker.current.setAnimation(null);
        } else {
            marker.current.setAnimation(google.current.maps.Animation.BOUNCE);
        }
    }, []);

    const geocodePosition = useCallback(
        (pos: any) => {
            geocoder.current.geocode(
                {
                    latLng: pos,
                },
                function (responses: any) {
                    if (!!responses.length) {
                        const address = formatAddressComponents(
                            responses[0]?.address_components ?? []
                        );

                        if (onChange) {
                            onChange({
                                ...address,
                                name: responses[0]?.formatted_address,
                            });
                        }

                        marker.current.formatted_address =
                            responses[0].formatted_address;
                    } else {
                        marker.current.formatted_address =
                            "Cannot determine address at this location.";
                    }
                }
            );
        },
        [onChange]
    );

    const drag = useCallback(
        (result: any) => {
            geocodePosition(marker.current.getPosition());
        },
        [geocodePosition]
    );

    const handleLoadMapWithSingleLocation = useCallback(() => {
        console.log("handleLoadMaps LOADING");
        setTimeout(() => {
            setLoading(false);
        }, 1000);

        google.current = (window as any).google;

        geocoder.current = new google.current.maps.Geocoder();

        map.current = new google.current.maps.Map(
            document.querySelector(`#${id}`),
            {
                zoom,
                center: { lat: Number(latitude), lng: Number(longitude) },
            }
        );

        marker.current = new google.current.maps.Marker({
            map: map.current,
            draggable: !!onChange,
            animation: google.current.maps.Animation.DROP,
            position: { lat: Number(latitude), lng: Number(longitude) },
        });

        marker.current.addListener("click", toggleBounce);
        marker.current.addListener("dragend", drag);
    }, [drag, latitude, longitude, onChange, toggleBounce, zoom, id]);

    const handleLoadArrayMap = useCallback(() => {
        if (!locations?.length) {
            return;
        }

        setTimeout(() => {
            setLoading(false);
        }, 1000);

        google.current = (window as any).google;

        const [firstLocation] = locations;

        const centeredLocation = center ?? firstLocation;

        var map = new google.current.maps.Map(
            document.querySelector(`#${id}`),
            {
                zoom: 10,
                center: new google.current.maps.LatLng(
                    centeredLocation.latitude,
                    centeredLocation.longitude
                ),
                mapTypeId: google.current.maps.MapTypeId.ROADMAP,
            }
        );

        var markers: any[] = [];

        locations.forEach((location, index) => {
            markers[index] = new google.current.maps.Marker({
                position: new google.current.maps.LatLng(
                    location.latitude,
                    location.longitude
                ),
                map: map,
                icon: "./logo-small.png",
            });

            const marker = markers[index];

            var content = `<p><strong>${location?.name}</strong></p>`;

            if (location?.totalStudents !== undefined) {
                content += `<p>Alunos: ${~~location?.totalStudents}</p>`;
            }

            if (location?.totalClasses !== undefined) {
                content += `<p>Turmas: ${~~location?.totalClasses}</p>`;
            }

            var bubbleOptionsDeKoning = {
                content,
            };

            const bubbleDeKoning = new google.current.maps.InfoWindow(
                bubbleOptionsDeKoning
            );

            // eslint-disable-next-line
            google.current.maps.event.addListener(marker, "click", () => {
                bubbleDeKoning.open(map, marker);
            });
        });
    }, [center, locations, id]);

    const onLoadMap = useCallback(() => {
        if (isValid(latitude) || isValid(longitude)) {
            handleLoadMapWithSingleLocation();
            return;
        }

        if (locations?.length) {
            handleLoadArrayMap();
        }
    }, [
        handleLoadArrayMap,
        handleLoadMapWithSingleLocation,
        latitude,
        locations,
        longitude,
    ]);

    if ((!latitude || !longitude) && !locations?.length) {
        return null;
    }

    return (
        <>
            <Script
                url={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&libraries=&region=BR&language=pt-br&v=weekly`}
                onLoad={onLoadMap}
            />
            <GoogleMapsContainer>
                {loading && (
                    <GoogleMapsLoading>
                        <Spinner />
                    </GoogleMapsLoading>
                )}
                <div className="map" id={id}></div>
            </GoogleMapsContainer>
        </>
    );
};

export default GoogleMaps;
