import { Card } from "components/Card";
import GoogleMaps from "components/GoogleMaps";
import CustomText from "components/Text";
import { formatAddressName } from "helpers/formatAddressName";
import { useProject } from "hooks/useProject";
import React from "react";

const ProjectDetailsTab: React.FC = () => {
    const { state } = useProject();
    const { address, region, modalities = [] } = state || {};

    if (!address?.latitude) {
        return null;
    }

    return (
        <Card bottom="35px">
            <CustomText color="base" size={18} bottom="20px">
                <strong>Modalidades:</strong>{" "}
                {modalities?.map(({ name }) => name)?.join(", ")}
            </CustomText>

            <CustomText color="base" size={18} bottom="20px">
                <strong>Região:</strong> {region?.name}
            </CustomText>
            <CustomText color="base" size={18} bottom="20px">
                {formatAddressName(address)}
            </CustomText>
            <GoogleMaps
                latitude={address?.latitude}
                longitude={address?.longitude}
                place={address?.name}
                zoom={15}
            />
        </Card>
    );
};

export default ProjectDetailsTab;
