import { all } from "redux-saga/effects";

import crud from "./crud/sagas";
import auth from "./auth/sagas";

import projectClass from "./projectClass/sagas";
import projectClassDay from "./projectClassDay/sagas";
import project from "./project/sagas";
import student from "./student/sagas";
import user from "./user/sagas";
import professional from "./professional/sagas";
import provider from "./provider/sagas";

export default function* rootSaga() {
    try {
        yield all([
            ...auth,
            ...crud,
            ...projectClass,
            ...projectClassDay,
            ...student,
            ...project,
            ...professional,
            ...user,
            ...provider,
        ]) as any;
    } catch (error) {
        console.log(error);
    }
}
