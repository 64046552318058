import { store } from "../store";

import { Creators as AuthActions } from "../store/auth";
import _get from "lodash/get";

const fakePromise = () => {
    return new Promise((resolve) => {
        resolve({ data: { items: {}, item: {}, id: 1 } });
    });
};

const logout = () => {
    store.dispatch(AuthActions.logout());
    return fakePromise();
};

const graphql = async (query: string, variables = {}, name = "") => {
    const token = (window as any).token;

    if (!token) {
        return logout();
    }

    const response = await fetch(
        `${process.env.REACT_APP_GRAPHQL_API_URL as string}${
            !!name ? `?${name}` : ""
        }`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "x-hasura-admin-secret":
                    "ZUOvts7Rlq2lwH7HCGYN994L6UNEeMY6Z2YLDMVNwu4fymPIlJ9d6HnrS6gyFNZ1",
            },
            body: JSON.stringify({ query, variables }),
        }
    );

    const bodyResponse: any = await response.json();

    const errorCode = _get(bodyResponse, "errors[0].extensions.code");

    // WHEN HASURA RETURNS THAT THE TOKEN IS INVALID
    if (errorCode === "access-denied") {
        return logout();
    }

    return bodyResponse;
};

export default graphql;
