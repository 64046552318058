import ShuffleIcon from "components/Icons/shuffle";
import { useAppSelector } from "hooks/store";
import { useModal } from "hooks/useModal";
import React, { useCallback } from "react";
import { IModalPayload } from "store/modal/types";
import { ModalElements } from "utils/enums/ModalElements";
import { NavbarCompanyContainer } from "./styles";

interface IProps {
    onCloseNav?(): void;
}

const NavbarCompany = ({ onCloseNav }: IProps) => {
    const { company, hasManyCompanies } = useAppSelector(({ auth }) => ({
        company: auth?.currentCompany,
        hasManyCompanies: (auth?.availableCompanies?.length || 0) > 1,
    }));

    const { open: openModal } = useModal();

    const handleClick = useCallback(() => {
        const payload: IModalPayload = {
            element: ModalElements.SelectCompany,
            width: "450px",
            title: "Selecione uma empresa",
        };

        openModal(payload);

        if (onCloseNav) {
            onCloseNav();
        }
    }, [openModal, onCloseNav]);

    return (
        <NavbarCompanyContainer>
            <p>Navegando como:</p>
            <button
                type="button"
                title="Clique aqui para trocar de empresa."
                onClick={hasManyCompanies ? handleClick : undefined}
            >
                <strong>{company?.name}</strong>
                {hasManyCompanies && <ShuffleIcon width={17} height={17} />}
            </button>
        </NavbarCompanyContainer>
    );
};

export default NavbarCompany;
