import styled from "styled-components/macro";
import { theme } from "../../config/theme";
import { FormElementContainer } from "../Form/styles";
import { PaginationContainer } from "../Pagination/styles";
import { SpinnerContainer } from "../Spinner";

const colors = theme.colors.components.table;

export const TableScroll = styled.div`
    /* overflow-x: auto;
    max-width: 100%;

    @media only screen and (min-width: 850px) {
        overflow-x: unset;
    } */

    display: block;
    width: 100%;
    overflow-x: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
`;

export const TableContainer = styled.table`
    position: relative;
    color: ${colors.color};
    height: 100%;
    max-width: 100%;
    width: 100%;

    ${FormElementContainer} {
        margin-bottom: 0;
    }

    ${PaginationContainer} {
        border-top: 1px solid ${theme.colors.mainLight};
    }

    .row {
        width: 100%;
        margin-right: unset;
        margin-left: unset;
    }

    div[class*="col-"] {
        display: flex;
    }

    .item-exit {
        opacity: 1;
    }
    .item-exit-active {
        opacity: 0;
        transform: scale(0.9);
        transition: opacity 300ms, transform 300ms;
    }
`;

export const TableHeader = styled.thead`
    background: ${colors.header.background};
    padding: 15px 10px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
`;

export const TableBody = styled.tbody`
    padding: 10px;
    position: relative;
    min-height: 80px;

    height: 100%;
    flex: 1;

    ${SpinnerContainer} {
        border-radius: 8px;

        :before {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }
    }
`;

export const TableItem = styled.td`
    border-bottom: 1px solid ${theme.colors.mainLight};
    padding: 15px 10px;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
`;

export const TableActions = styled.td`
    border-bottom: 1px solid ${theme.colors.mainLight};
    width: 150px;

    > div {
        align-items: center;
        display: flex;
        justify-content: flex-start;
    }

    button {
        width: 35px;
        padding: 10px 0;

        :hover {
            background: ${theme.colors.mainLight};
        }
    }
`;

export const TableEmptyContainer = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding: 35px 0;
`;
