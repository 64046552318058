import React, { useCallback } from "react";
import ArrowLeftIcon from "../Icons/arrowLeft";
import ArrowRightIcon from "../Icons/arrowRight";

import * as S from "./styles";

interface IProps {
    totalPages: number;
    totalItems: number;
    page: number;
    onChangePage(page: number): void;
    totalItemsPerPage: number;
}

const Pagination = ({
    totalPages,
    page,
    onChangePage,
    totalItems,
    totalItemsPerPage,
}: IProps) => {
    const prevPage = page - 1;
    const nextPage = page + 1;

    const isValidNumber = useCallback(
        (pageNumber: number) => {
            return pageNumber > 0 && pageNumber <= totalPages;
        },
        [totalPages]
    );

    const baseButtonsArray = Array.from(new Array(2));

    return (
        <S.PaginationContainer>
            <S.PaginationButton
                direction="left"
                disabled={prevPage <= 0}
                onClick={() => onChangePage(prevPage)}
            >
                <ArrowLeftIcon width={16} height={14} />
                <span>Anterior</span>
            </S.PaginationButton>

            <S.PaginationNumbersContainer>
                {/* <span>
                    Mostrando {totalItemsPerPage} de {totalItems}
                </span> */}

                <S.PaginationNumbersContainer>
                    {baseButtonsArray
                        .map((_, index) => {
                            const pageNumber = page - (index + 1);

                            if (!isValidNumber(pageNumber)) {
                                return null;
                            }

                            return (
                                <S.PaginationNumberButton
                                    active={false}
                                    onClick={() => onChangePage(pageNumber)}
                                >
                                    {pageNumber}
                                </S.PaginationNumberButton>
                            );
                        })
                        .reverse()}

                    <S.PaginationNumberButton active={true}>
                        {page}
                    </S.PaginationNumberButton>

                    {baseButtonsArray.map((_, index) => {
                        const pageNumber = page + (index + 1);

                        if (!isValidNumber(pageNumber)) {
                            return null;
                        }

                        return (
                            <S.PaginationNumberButton
                                active={false}
                                onClick={() => onChangePage(pageNumber)}
                                key={index}
                            >
                                {pageNumber}
                            </S.PaginationNumberButton>
                        );
                    })}
                </S.PaginationNumbersContainer>
            </S.PaginationNumbersContainer>

            <S.PaginationButton
                direction="right"
                disabled={nextPage > totalPages}
                onClick={() => onChangePage(nextPage)}
            >
                <span>Próximo</span>
                <ArrowRightIcon width={16} height={14} />
            </S.PaginationButton>
        </S.PaginationContainer>
    );
};

export default Pagination;
