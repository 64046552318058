import { Card } from "components/Card";
import Error from "components/Error";
import GoogleMaps from "components/GoogleMaps";
import ListCards, { IListCardsConfig } from "components/ListCards";
import Spinner from "components/Spinner";
import CustomText from "components/Text";
import { formatAddressName } from "helpers/formatAddressName";
import useGraphQL from "hooks/useGraphQL";
import { useProjectClass } from "hooks/useProjectClass";
import React, { useCallback, useEffect, useMemo } from "react";
import { InputType } from "utils/enums/InputType";
import { IGoogleMapsAddress } from "utils/interfaces/IGoogleMapsAddress";

interface IReturn {
    id: number;
    project?: {
        id: number;
        name: string;
        project_address?: {
            address?: IGoogleMapsAddress;
        };
    };
}

const ProjectClassDetailsTab: React.FC = () => {
    const { state } = useProjectClass();
    const { id } = state || {};

    const { error, loading, request, response } = useGraphQL<IReturn>();

    const requestDetails = useCallback(() => {
        if (!id) {
            return;
        }

        request(
            `query ($id: Int!) {
            item: project_class_by_pk(id: $id) {
              id
              name
              project {
                name
                id
                project_address {
                  address {
                    street
                    city
                    neighbourhood
                    state
                    zip
                    number
                    latitude
                    longitude
                  }
                }
              }
            }
          }
          `,
            { id },
            "item"
        );
    }, [id, request]);

    useEffect(() => {
        requestDetails();
    }, [requestDetails]);

    const { project } = response || {};
    const address = project?.project_address?.address;

    const listCardsConfig = useMemo<IListCardsConfig>(
        () => ({
            cards: [
                {
                    name: "week_days",
                    type: InputType.WeekDays,
                    grid: {
                        col: "auto",
                    },
                },
                {
                    name: "start_time",
                    type: InputType.Time,
                    grid: {
                        col: "auto",
                    },
                },
                {
                    name: "end_time",
                    type: InputType.Time,
                    grid: {
                        col: "auto",
                    },
                },
            ],
            graphQL: {
                tableName: "project_class",
                returnPath: "item",
                primaryKey: id,
            },
        }),
        [id]
    );

    if (loading || state?.loading) {
        return <Spinner />;
    }

    if (error) {
        return <Error description={<></>} />;
    }

    return (
        <>
            <ListCards {...listCardsConfig} />

            <Card bottom="35px">
                <CustomText
                    color="base"
                    weight="SemiBold"
                    size={22}
                    right="auto"
                    bottom="8px"
                >
                    {project?.name}
                </CustomText>

                {!!address && (
                    <>
                        <CustomText color="base" size={18} bottom="20px">
                            {formatAddressName(address)}
                        </CustomText>
                        <GoogleMaps
                            latitude={address?.latitude}
                            longitude={address?.longitude}
                            place={address?.name}
                        />
                    </>
                )}
            </Card>
        </>
    );
};

export default ProjectClassDetailsTab;
