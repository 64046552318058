export const getProjectClassDayByIdQuery = `
query GetProjectClassDayById($classDayId: Int!) {
    item: project_class_day_by_pk(id: $classDayId) {
      id
      day
      end_time
      start_time
      movements {
        longitude
        latitude
      }
      project_class {
        number_of_students
        name
      }
      project_class_day_presences {
        project_class_day_presence_students_aggregate(where: {is_present: {_eq: True}}) {
            aggregate {
            count
            }
        }
      }
    }
  }
`;
