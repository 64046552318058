import React, { InputHTMLAttributes, forwardRef } from "react";

import { FormElementContainer, FormError, FormLabel } from "../styles";
import { InputType } from "../../../utils/enums/InputType";
import { ToggleSwitchElement } from "../ToggleSwitch/styles";

export interface InputSwitchProps {
    type?: InputType | string;
    placeholder?: string;
    name: string;
    required?: boolean;
    showError?: boolean;
    label?: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    value?: boolean;
    error?: string;
}

const InputSwitch = forwardRef<
    HTMLInputElement,
    InputHTMLAttributes<HTMLInputElement> & InputSwitchProps
>(
    (
        {
            type = InputType.Switch,
            name,
            required = false,
            showError = true,
            label,
            value = false,
            error,
            onChange,
            ...props
        },
        ref: any
    ) => {
        return (
            <FormElementContainer hidden={type === "hidden"}>
                {!!label && (
                    <FormLabel htmlFor={name}>
                        {label}
                        {required && ` *`}
                    </FormLabel>
                )}
                <ToggleSwitchElement>
                    <input
                        {...props}
                        ref={ref}
                        type="checkbox"
                        checked={value}
                        onChange={onChange}
                    />
                    <span className="slider round"></span>
                </ToggleSwitchElement>
                {!!error && showError && <FormError>{error}</FormError>}
            </FormElementContainer>
        );
    }
);

export default InputSwitch;
