import React, { Fragment } from "react";

// Assets
import Error from "../Error";

interface IProps {
    children: React.ReactNode;
    onError?: (error: Error, errorInfo: React.ErrorInfo) => void;
}

interface IState {
    hasError: boolean;
}

class ErrorBoundary extends React.Component<IProps, IState> {
    public state: IState = {
        hasError: false,
    };

    public componentDidCatch = (error: Error, errorInfo: React.ErrorInfo) => {
        const { onError } = this.props;

        console.log(error);
        console.log(errorInfo);

        if (onError) {
            return onError(error, errorInfo);
        }

        this.setState({ hasError: true });
    };

    public render() {
        if (this.state?.hasError) {
            return <Error />;
        }

        return this.props?.children || <Fragment />;
    }
}

export default ErrorBoundary;
