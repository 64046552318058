import React, { useCallback } from "react";
import { HorizontalDiv, VerticalDiv } from "../../../../assets/globalStyles";
import Button from "../../../../components/Button";
import Empty from "../../../../components/Empty";
import ToggleSwitch from "../../../../components/Form/ToggleSwitch";
import DeleteIcon from "../../../../components/Icons/delete";
import PlusIcon from "../../../../components/Icons/plus";
import { IListDataProps } from "../../../../components/ListData";
import CustomText from "../../../../components/Text";
import { theme } from "../../../../config/theme";
import pluralize from "../../../../helpers/pluralize";
import { useModal } from "../../../../hooks/useModal";
import { projectClassColumns } from "../../../../routes/entities/projectClasses";
import { IStudentSubscription } from "../../../../store/student/types";
import { ModalElements } from "../../../../utils/enums/ModalElements";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IReduxStore } from "../../../../store/ducks";
import { Creators as StudentActions } from "../../../../store/student";
import * as S from "./styles";
import { IStudentActionsCreators } from "../../../../store/student/types";
import { dictionary } from "../../../../config/dictionary";
interface IProps {
    subscriptions: Array<IStudentSubscription>;
    onSubmit(): void;
    studentActions: IStudentActionsCreators;
    isEdit: boolean;
    projectId?: number;
}

const StudentSubscription: React.FC<IProps> = ({
    subscriptions,
    onSubmit,
    studentActions,
    isEdit,
    projectId,
}) => {
    const modal = useModal();

    const handleClickRemove = useCallback(
        (item: IStudentSubscription) => {
            if (isEdit) {
                modal.open({
                    element: ModalElements.ConfirmAction,
                    title: undefined,
                    width: "500px",
                    action: {
                        message: dictionary.phrases.confirmDelete,
                        onConfirm: () =>
                            studentActions.deleteStudentSubscriptionRequest(
                                item
                            ),
                    },
                    center: true,
                });

                return;
            }

            studentActions.deleteStudentSubscriptionRequest(item);
        },
        [isEdit, modal, studentActions]
    );

    const handleAdd = useCallback(
        (subscription: IStudentSubscription) => {
            const idProjectClass =
                subscription?.id_project_class ??
                subscription?.project_class?.id_project_class;

            const isSelected = subscriptions?.some((subscriptionItem) => {
                if (!!subscription?.id_subscription) {
                    return (
                        subscriptionItem.id_subscription !==
                        subscription.id_subscription
                    );
                }

                const projectClass =
                    subscriptionItem?.project_class?.id_project_class ||
                    subscriptionItem?.id_project_class;

                return projectClass === idProjectClass;
            });

            if (isSelected) {
                handleClickRemove(subscription);
            } else {
                studentActions.createOrEditStudentSubscriptionRequest({
                    project_class: {
                        id_project_class: idProjectClass || 0,
                        name: subscription?.project_class?.name || "",
                    },
                    is_active: true,
                    id_project_class: idProjectClass,
                });
            }
        },
        [handleClickRemove, studentActions, subscriptions]
    );

    const handleClickSearch = useCallback(() => {
        const action: IListDataProps = {
            contentId: `project_class_search`,
            columns: projectClassColumns,
            graphql: {
                tableName: "project_class",
            },
            mainColumn: "id_project_class",
            customActions: [
                {
                    icon: (item) => {
                        const isSelected = subscriptions?.some(
                            ({ id_project_class, project_class }) =>
                                id_project_class === item.id_project_class ||
                                project_class?.id_project_class ===
                                    item.id_project_class
                        );

                        return isSelected ? (
                            <DeleteIcon
                                width={12}
                                height={12}
                                fill={theme.colors.danger}
                            />
                        ) : (
                            <PlusIcon
                                width={12}
                                height={12}
                                fill={theme.colors.success}
                            />
                        );
                    },
                    onClick: (project_class) =>
                        handleAdd({ project_class } as any),
                },
            ],
            ...(!!projectId && {
                defaultSearch: {
                    id_project: {
                        _eq: projectId,
                    },
                },
            }),
        };

        modal.open({
            element: ModalElements.List,
            action,
            title: `Buscar núcleo`,
        });
    }, [handleAdd, modal, projectId, subscriptions]);

    const handleClickAdd = useCallback(() => {
        handleClickSearch();
    }, [handleClickSearch]);

    const handleToggleActive = useCallback(
        (is_active: boolean, subscription: IStudentSubscription) => {
            studentActions.createOrEditStudentSubscriptionRequest({
                ...subscription,
                is_active,
            });
        },
        [studentActions]
    );

    return (
        <VerticalDiv>
            {!subscriptions?.length ? (
                <Empty
                    text="Nenhum núcleo adicionado ainda"
                    onClick={handleClickAdd}
                    padding={{ all: "20px" }}
                />
            ) : (
                <>
                    <HorizontalDiv bottom="20px">
                        <CustomText color="black" size={16} right="15px">
                            {subscriptions?.length}{" "}
                            {pluralize(
                                subscriptions?.length,
                                "selecionado",
                                "selecionados"
                            )}
                        </CustomText>

                        <Button
                            variant="main"
                            type="button"
                            onClick={handleClickAdd}
                            padding={{ all: "8px" }}
                        >
                            <PlusIcon fill="#FFF" width={12} height={12} />
                        </Button>
                    </HorizontalDiv>

                    <S.StudentSubscriptionListContainer>
                        {subscriptions?.map((subscription, index) => (
                            <S.StudentSubscriptionItem
                                key={`subscription_${index}`}
                            >
                                <CustomText
                                    color="black"
                                    size={15}
                                    right="15px"
                                    weight="Bold"
                                >
                                    {subscription?.project_class?.name}
                                </CustomText>

                                <ToggleSwitch
                                    checked={!!subscription?.is_active}
                                    onChange={(active) =>
                                        handleToggleActive(active, subscription)
                                    }
                                />

                                <Button
                                    variant="light"
                                    type="button"
                                    onClick={() =>
                                        handleClickRemove(subscription)
                                    }
                                    padding={{ all: "0px" }}
                                >
                                    <DeleteIcon
                                        fill={theme.colors.danger}
                                        width={12}
                                        height={12}
                                    />
                                </Button>
                            </S.StudentSubscriptionItem>
                        ))}
                    </S.StudentSubscriptionListContainer>

                    {!isEdit && (
                        <Button
                            variant="main"
                            type="button"
                            onClick={onSubmit}
                            right="auto"
                        >
                            Finalizar
                        </Button>
                    )}
                </>
            )}
        </VerticalDiv>
    );
};

const mapStateToProps = ({ student }: IReduxStore) => ({
    subscriptions: student?.subscriptions || [],
    isEdit: !!student?.id,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    studentActions: bindActionCreators(StudentActions, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StudentSubscription);
