import { useEffect, useCallback } from "react";

// default key is 27 (ESC)
export default function useKeyDown(callBack: () => void, key: number = 27) {
    const onKeyPress = useCallback(
        (event) => {
            if (event.keyCode === key) {
                callBack();
            }
        },
        [callBack, key]
    );

    useEffect(() => {
        document.addEventListener("keydown", onKeyPress);

        return () => {
            document.removeEventListener("keydown", onKeyPress);
        };
    }, [onKeyPress]);
}
