import { ITableColumn } from "../utils/interfaces/ITable";
import { TableColumnType } from "../utils/enums/TableColumnType";

/**
 *  Dear maintainer:
 *
 *  Once you are done trying to 'optimize' this routine,
 *  and have realized what a terrible mistake that was,
 *  please increment the following counter as a warning
 *  to the next guy:
 *
 *  total_hours_wasted_here = 7
 *
 *
 * @param columns
 * @param tableName
 */
export default function generateGetAllQuery(
    columns: ITableColumn[],
    tableName?: string,
    alternativeQuery?: any,
    mainColumn?: string | Array<string>
) {
    if (!tableName || alternativeQuery) {
        return alternativeQuery;
    }

    const formatfields = columns
        .map(({ name, type }) => {
            const format = name.split(".");

            if (type === TableColumnType.Link) {
                return `${name} {\nid\ntitle}`;
            }

            // if (name === "person") {
            //     return `person {\nname\nfile {\nfilename\npath\n}\nuser {\nemail\n}\n}\n\n`;
            // }

            if (type === TableColumnType.Person) {
                return `${format.join(" {\n")}\n
                {\n name\nfile {\nfilename\npath\n}\nuser {\nemail\n}\n}\n
                ${Array.from(
                    new Array(format.length >= 1 ? format.length - 1 : 0)
                )
                    .map(() => `}`)
                    .join("\n")}`;
            }

            if (type === TableColumnType.AsyncSelect) {
                return `${name} {\nid\nname\n}`;
            }

            return `${format.join(" {\n")}\n${Array.from(
                new Array(format.length >= 1 ? format.length - 1 : 0)
            )
                .map(() => `}`)
                .join("\n")}`;
        })
        .join("\n");

    const formatTableName = tableName;

    const mainColumns = Array.isArray(mainColumn)
        ? mainColumn?.join("\n")
        : mainColumn;

    return `query ($orderBy: [${formatTableName}_order_by!], $where: ${formatTableName}_bool_exp, $limit: Int, $offset: Int) {
          items: ${formatTableName}(where: $where, order_by: $orderBy, limit: $limit, offset: $offset) {
            ${mainColumns || ""}
            ${formatfields}
          }
          quantity: ${formatTableName}_aggregate(where: $where) {
            aggregate {
              count
            }
          }
        }`;
}
