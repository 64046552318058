import isDevelopment from "helpers/isDevelopment";

const originalConsole = console.log;

// Disable console in a production env
const logger = isDevelopment() ? originalConsole : (_: any) => null;

export const rewriteConsole = () => {
    console.log = logger;
};
