import React, { memo } from "react";
import { theme } from "../../config/theme";
import { IIconProps } from "../../utils/interfaces/IIconProps";

const DeleteIcon = memo(
    ({ fill = theme.colors.black, ...props }: IIconProps) => {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                {...props}
                viewBox="0 0 16.667 16.667"
            >
                <defs>
                    <clipPath id="clip-path">
                        <path
                            d="M12.5,16.667H4.167a2.5,2.5,0,0,1-2.5-2.5V5H.833a.834.834,0,0,1,0-1.667H5V1.94A2.018,2.018,0,0,1,7.083,0h2.5a2.018,2.018,0,0,1,2.083,1.94V3.333h4.166a.834.834,0,0,1,0,1.667H15v9.166A2.5,2.5,0,0,1,12.5,16.667ZM3.333,5v9.166A.835.835,0,0,0,4.167,15H12.5a.834.834,0,0,0,.833-.833V5Zm3.75-3.334c-.238,0-.416.145-.416.274V3.333H10V1.94c0-.129-.178-.274-.416-.274ZM10.833,12.5A.834.834,0,0,1,10,11.667V8.333a.834.834,0,0,1,1.667,0v3.334A.835.835,0,0,1,10.833,12.5Zm-5,0A.834.834,0,0,1,5,11.667V8.333a.833.833,0,0,1,1.667,0v3.334A.834.834,0,0,1,5.833,12.5Z"
                            transform="translate(0 0)"
                            fill={fill}
                        />
                    </clipPath>
                </defs>
                <path
                    d="M12.5,16.667H4.167a2.5,2.5,0,0,1-2.5-2.5V5H.833a.834.834,0,0,1,0-1.667H5V1.94A2.018,2.018,0,0,1,7.083,0h2.5a2.018,2.018,0,0,1,2.083,1.94V3.333h4.166a.834.834,0,0,1,0,1.667H15v9.166A2.5,2.5,0,0,1,12.5,16.667ZM3.333,5v9.166A.835.835,0,0,0,4.167,15H12.5a.834.834,0,0,0,.833-.833V5Zm3.75-3.334c-.238,0-.416.145-.416.274V3.333H10V1.94c0-.129-.178-.274-.416-.274ZM10.833,12.5A.834.834,0,0,1,10,11.667V8.333a.834.834,0,0,1,1.667,0v3.334A.835.835,0,0,1,10.833,12.5Zm-5,0A.834.834,0,0,1,5,11.667V8.333a.833.833,0,0,1,1.667,0v3.334A.834.834,0,0,1,5.833,12.5Z"
                    transform="translate(0 0)"
                    fill={fill}
                />
            </svg>
        );
    }
);

export default DeleteIcon;
