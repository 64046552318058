import { Card } from "components/Card";
import EyeIcon from "components/Icons/eye";
import { ListDataContainer } from "components/ListData/styles";
import Table from "components/Table";
import { theme } from "config/theme";
import { useAppSelector } from "hooks/store";
import useApi, { IRequestPayload } from "hooks/useApi";
import React, { useCallback, useEffect } from "react";
import { TableColumnType } from "utils/enums/TableColumnType";
import ListDataHeader from "components/ListData/header";
import { useModal } from "hooks/useModal";
import { IListDataProps } from "components/ListData";
import { ModalElements } from "utils/enums/ModalElements";
import { Status } from "utils/enums/Status";

const StudentSchoolClasses = () => {
    const { id } = useAppSelector(({ student }) => student!);
    const { request, response = [], error, loading } = useApi<any[]>();
    const { open: openModal } = useModal();

    const requestClasses = useCallback(() => {
        const payload: IRequestPayload = {
            method: "get",
            endpoint: `/admin/students/${id}/school-classes`,
        };

        request(payload);
    }, [request, id]);

    useEffect(() => {
        requestClasses();
    }, [requestClasses]);

    const handleClickClassDetails = useCallback(
        (projectClass: { id: number; name: string }) => {
            const action: IListDataProps = {
                contentId: "student_presence",
                columns: [
                    {
                        name: "project_class_day_presence.project_class_day.day",
                        label: "Data",
                        type: TableColumnType.Date,
                        col: "auto",
                    },
                    {
                        name: "is_present",
                        type: TableColumnType.Boolean,
                        col: 3,
                    },
                    {
                        name: "grade",
                        type: TableColumnType.Text,
                        col: 1,
                    },
                ],
                graphql: {
                    tableName: "project_class_day_presence_student",
                    orderBy: {
                        project_class_day_presence: {
                            project_class_day: {
                                day: "asc",
                            },
                        },
                    },
                },
                mainColumn: "id",
                canCreate: false,
                canDelete: false,
                canEdit: false,
                canSee: false,
                defaultSearch: {
                    student_id: { _eq: id },
                    project_class_day_presence: {
                        project_class_day: {
                            project_class_id: { _eq: projectClass.id },
                            status: { _eq: Status.Active },
                        },
                    },
                },
            };

            openModal({
                element: ModalElements.List,
                action,
                width: undefined,
                title: `Frequência na turma ${projectClass.name}`,
            });
        },
        [id, openModal]
    );

    return (
        <ListDataContainer>
            <Card column>
                <ListDataHeader
                    totalItems={response?.length}
                    showTotal={!loading}
                    title="Turmas"
                />

                <Table
                    isEmpty={!loading && !response?.length}
                    isLoading={loading}
                    columns={[
                        {
                            col: 6,
                            name: "class.name",
                            type: TableColumnType.Text,
                            label: "Nome",
                            removeSearch: true,
                        },
                        {
                            col: 6,
                            name: "class.numberOfFinalizedClasses",
                            type: TableColumnType.Number,
                            label: "Aulas finalizadas",
                            removeSearch: true,
                        },
                        {
                            col: 6,
                            name: "numberOfPresences",
                            type: TableColumnType.Number,
                            label: "Presenças",
                            removeSearch: true,
                        },
                    ]}
                    actions={[
                        {
                            icon: () => (
                                <EyeIcon
                                    width={16}
                                    height={16}
                                    fill={theme.colors.placeholder}
                                />
                            ),
                            onClick: ({ class: projectClass }: any) =>
                                handleClickClassDetails({
                                    id: projectClass.id,
                                    name: projectClass.name,
                                }),
                        },
                    ]}
                    data={response}
                    mainColumn="id"
                    error={error}
                />
            </Card>
        </ListDataContainer>
    );
};

export default StudentSchoolClasses;
