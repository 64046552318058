import { formatDate } from "../../helpers/formatDate";
import { dictionary } from "../../config/dictionary";
import { InputType } from "../../utils/enums/InputType";
import { convertWeekDaysToString } from "helpers/convertWeekDaysToString";
import { isValidDate } from "helpers/isValidDate";
import { useMemo } from "react";

interface IProps {
    value: any;
    type: InputType;
    enumValue?: any;
}

const ListCardsItem = ({ value, type, enumValue }: IProps) => {
    const formattedValue = useMemo(() => {
        switch (type) {
            case InputType.Date:
                return formatDate(value) || "-";

            case InputType.DateWithHour:
                return formatDate(value, "ff") || "-";

            case InputType.Enum:
                const label = !!enumValue[value]
                    ? (dictionary as any)[enumValue[value]] || enumValue[value]
                    : value;

                return label || "-";

            case InputType.WeekDays:
                return convertWeekDaysToString(value, dictionary.shortWeekDays);

            case InputType.Number:
                return ~~value;

            case InputType.Time:
                const isDate = isValidDate(value);

                return isDate
                    ? formatDate(value, "HH:mm")
                    : value?.substr(0, 5);

            default:
                return value || "-";
        }
    }, [enumValue, type, value]);

    return formattedValue === undefined ? "-" : formattedValue;
};

export default ListCardsItem;
