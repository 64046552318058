import React from "react";
import { Card } from "../../components/Card";

import { IBaseRouteProps } from "../../utils/interfaces/IBaseRouteProps";
import ProjectClassForm from "./form";

const ProjectClass = ({ match: { params } }: IBaseRouteProps) => {
    const { id } = params;

    return (
        <Card column>
            <ProjectClassForm id={id} />
        </Card>
    );
};

export default ProjectClass;
