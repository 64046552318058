import React, { useCallback, useEffect, useRef, useState } from "react";
import formatInitialDataFormValues from "../../helpers/formatFormValues";
import Button from "../../components/Button";
import isValid from "../../helpers/isValid";
import Input from "../../components/Form/Input";

import useDebounce from "../../hooks/useDebounce";
import formatAddressComponents from "../../helpers/formatAddressComponents";
import AddressForm from "../../components/AddressForm";
import _pick from "lodash/pick";
import _uniq from "lodash/uniq";
import _set from "lodash/set";
import Form from "../../components/Form";
import { Col, Row } from "../../components/Grid";
import { InputType } from "../../utils/enums/InputType";

import { useProject } from "hooks/useProject";
import { projectFields, projectSchema } from "routes/entities/projects";
import { ICreateProjectPayload } from "store/project/types";
import Spinner from "components/Spinner";
import getYupErrors from "helpers/getYupErrors";
import { formatAddressName } from "helpers/formatAddressName";
import AsyncSelect from "components/Form/AsyncSelect";
import generateSearchQuery from "helpers/generateSearchQuery";
import {
    searchAssistantQuery,
    searchProfessionalsQuery,
} from "queries/professionals/search";

interface IProps {
    id?: number | string;
    onCreate?(data: any): void;
}

const ProjectForm = ({ id, onCreate }: IProps) => {
    const [formState, setFormState] = useState<any>({
        address: undefined,
    });
    const [formErrors, setFormErrors] = useState<any>();

    const isEdit = !!id;

    const { getById, state, createOrEdit, clear } = useProject();
    const { loading = false } = state || {};

    const formRef = useRef<any>(null);

    useEffect(() => {
        console.log("useEffect 1");

        return () => {
            console.log("useEffect 1 unmount");

            formRef.current = null;
            clear();
        };
    }, [clear]);

    const request = useCallback(() => {
        if (!id) {
            return;
        }

        getById({ id });
    }, [getById, id]);

    useEffect(() => {
        request();
    }, [request]);

    const showFormAddress = useCallback(() => {
        const element = document.getElementById("form-address");

        if (!element) {
            return;
        }

        element.style.display = "block";
    }, []);

    const formatInitialData = useCallback(() => {
        console.log("formatInitialData");

        const fields = projectFields;

        const tmpState = { ...state };

        const { address } = state || {};

        const adressName = [
            address?.street,
            `${address?.number || ""}${
                (!!address?.neighbourhood && ` - ${address?.neighbourhood}`) ||
                ""
            }`,
            address?.state,
            address?.zip,
            address?.country || "Brasil",
        ]
            .filter(Boolean)
            .join(", ");

        if (isEdit) {
            _set(tmpState, "address.name", adressName);
        }

        const tempInitialData: any = _pick(
            tmpState,
            _uniq([
                ...fields.map(({ name }) => name),
                ...fields.map(({ name }) => name.replace("id_", "")),
            ])
        );

        console.log("tempInitialData", { tempInitialData });

        const formatData = formatInitialDataFormValues(tempInitialData, fields);

        console.log("formatData", { formatData });

        if (isValid(formatData?.address?.name)) {
            showFormAddress();
        }

        setFormState(formatData);
    }, [isEdit, showFormAddress, state]);

    useEffect(() => {
        formatInitialData();
    }, [formatInitialData]);

    const requestCreateOrEdit = useCallback(() => {
        const payload: ICreateProjectPayload = {
            id,
            body: {
                ...formState,
            },
            onCreate,
        };

        createOrEdit(payload);
    }, [createOrEdit, formState, id, onCreate]);

    const handleSubmit = useCallback(
        async (event: any) => {
            try {
                event.preventDefault();

                await projectSchema.validate(formState, { abortEarly: false });
                requestCreateOrEdit();
            } catch (error) {
                const errors = getYupErrors(error as any);

                console.log(errors, formState);

                if (errors) {
                    setFormErrors(errors);
                }
            }
        },
        [formState, requestCreateOrEdit]
    );

    const handleChangeInputValue = useCallback(
        (inputName: string, value: any) => {
            console.log("handleChangeInputValue");

            setFormState((old: any) => ({ ...old, [inputName]: value }));
        },
        []
    );

    const shouldUpdateGoogle = useRef(true);
    const [tmpAddressText, setTmpAddressText] = useState("");

    const debounceAddressText = useDebounce(tmpAddressText, 1000);

    const requestGoogleMapsAddress = useCallback(
        async (debouncedText: string) => {
            console.log("requestGoogleMapsAddress", { debouncedText });

            try {
                const response = await fetch(
                    `https://maps.googleapis.com/maps/api/geocode/json?sensor=true&address=${debouncedText}&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`
                );

                const results = await response
                    .json()
                    .then((data) => data.results[0]);

                setFormState((old: any) => ({
                    ...old,
                    address: {
                        ...old.address,
                        latitude: results.geometry.location.lat,
                        longitude: results.geometry.location.lng,
                        name: results.formatted_address,
                        ...formatAddressComponents(
                            results.address_components ?? []
                        ),
                    },
                }));
            } catch (error) {
                console.log(error);
            }
        },
        []
    );

    useEffect(() => {
        console.log("useEffect requestGoogleMapsAddress");

        if (!debounceAddressText || !shouldUpdateGoogle.current) {
            console.log("useEffect !debounceAddressText");

            shouldUpdateGoogle.current = true;

            return;
        }

        console.log("useEffect requestGoogleMapsAddress [PASS]");

        requestGoogleMapsAddress(debounceAddressText);
    }, [debounceAddressText, requestGoogleMapsAddress]);

    const getAddressByInputValues = useCallback((address: any) => {
        console.log("getAddressByInputValues", address);

        if (!address) {
            return;
        }

        shouldUpdateGoogle.current = false;

        const keys = [
            "city",
            "state",
            "neighbourhood",
            "street",
            "zip",
            "number",
        ];

        const values: any = _pick(address, keys);

        const text = formatAddressName(values);

        console.log("setTmpAddressText", { text });

        setFormState((old: any) => ({
            ...old,
            address: {
                ...old.address,
                name: text,
            },
        }));

        setTmpAddressText(text);
    }, []);

    const onSelectAddress = (data: any) => {
        console.log("onSelectAddress", data);

        setFormState((old: any) => ({
            ...old,
            address: {
                ...old.address,
                ...data,
            },
        }));

        showFormAddress();
    };

    const onChangeAdress = useCallback(
        (data: any) => {
            console.log("onChangeAdress", data);

            setFormState((old: any) => {
                console.log("updated info", {
                    ...old,
                    address: {
                        ...old.address,
                        ...data,
                    },
                });

                return {
                    ...old,
                    address: {
                        ...old.address,
                        ...data,
                    },
                };
            });
            getAddressByInputValues({
                ...formState.address,
                ...data,
            });
        },
        [formState.address, getAddressByInputValues]
    );

    const onChangeInputAddressName = useCallback((data: any) => {
        console.log("onChangeInputAddressName");

        setFormState((old: any) => ({
            ...old,
            address: {
                ...old.address,
                ...data,
            },
        }));
    }, []);

    const onChangeAddressMap = useCallback((data: any) => {
        console.log("onChangeAddressMap");

        setFormState((old: any) => ({
            ...old,
            address: {
                ...old.address,
                ...data,
            },
        }));
    }, []);

    console.log("render", {
        formState,
    });

    return (
        <>
            {loading && <Spinner fullScreen />}
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col md={4}>
                        <Input
                            label="Nome"
                            name="name"
                            type={InputType.Text}
                            value={formState?.name}
                            onChange={(e) =>
                                handleChangeInputValue("name", e.target.value)
                            }
                            error={formErrors?.name}
                        />
                    </Col>

                    <Col md={4}>
                        <AsyncSelect
                            name="region"
                            label="Região"
                            required
                            isClearable
                            query={generateSearchQuery({ tableName: "region" })}
                            isMulti={false}
                            onChange={(value) =>
                                handleChangeInputValue("region", value)
                            }
                            error={formErrors?.region}
                            value={formState?.region}
                        />
                    </Col>
                    <Col md={4}>
                        <AsyncSelect
                            name="professional"
                            label="Profissional"
                            required
                            isClearable
                            query={searchProfessionalsQuery}
                            isMulti={false}
                            onChange={(value) =>
                                handleChangeInputValue("professional", value)
                            }
                            error={formErrors?.professional}
                            value={formState?.professional}
                        />
                    </Col>
                    <Col md={4}>
                        <AsyncSelect
                            name="professionals"
                            label="Assistentes"
                            required
                            isClearable
                            isMulti
                            query={searchAssistantQuery}
                            onChange={(value) =>
                                handleChangeInputValue("professionals", value)
                            }
                            error={formErrors?.professionals}
                            value={formState?.professionals}
                        />
                    </Col>

                    <Col md={4}>
                        <AsyncSelect
                            name="modalities"
                            label="Modalidades"
                            required
                            isClearable
                            isMulti
                            query={generateSearchQuery({
                                tableName: "modality",
                            })}
                            onChange={(value) =>
                                handleChangeInputValue("modalities", value)
                            }
                            error={formErrors?.modalities}
                            value={formState?.modalities}
                        />
                    </Col>
                </Row>

                <AddressForm
                    address={formState?.address}
                    errors={formErrors?.address}
                    onChange={onChangeAdress}
                    onChangeInputName={onChangeInputAddressName}
                    onChangeMap={onChangeAddressMap}
                    onSelectAddress={onSelectAddress}
                />

                <Button
                    type="submit"
                    variant="success"
                    style={{ alignSelf: "baseline" }}
                >
                    Salvar
                </Button>
            </Form>
        </>
    );
};

export default ProjectForm;
