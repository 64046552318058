import useApi from "hooks/useApi";

const useDownload = () => {
    const { request, response, loading, error } = useApi<{
        pdf: string;
    }>();

    return { request, loading, error, pdf: response?.pdf };
};

export default useDownload;
