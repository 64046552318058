import React, { memo } from "react";
import { theme } from "../../config/theme";
import { IIconProps } from "../../utils/interfaces/IIconProps";

const UserIcon = memo(({ fill = theme.colors.black, ...props }: IIconProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            viewBox="0 0 11.667 15"
        >
            <path
                d="M10.833,15A.834.834,0,0,1,10,14.167a4.167,4.167,0,1,0-8.333,0,.833.833,0,1,1-1.666,0,5.833,5.833,0,0,1,11.667,0A.835.835,0,0,1,10.833,15Zm-5-8.333A3.333,3.333,0,1,1,9.167,3.333,3.338,3.338,0,0,1,5.833,6.667Zm0-5A1.667,1.667,0,1,0,7.5,3.333,1.668,1.668,0,0,0,5.833,1.666Z"
                fill={fill}
            />
        </svg>
    );
});

export default UserIcon;
