import { object } from "yup";
import { dictionary } from "../../config/dictionary";
import { createResponsibleQuery } from "../../queries/responsibles/create";
import { editResponsibleQuery } from "../../queries/responsibles/edit";
import { getResponsibleByIdQuery } from "../../queries/responsibles/getById";

import CrudCreateOrEdit from "../../pages/CRUD/CreateOrEdit";

import CrudList from "../../pages/CRUD/List";
import { InputType } from "../../utils/enums/InputType";
import { TableColumnType } from "../../utils/enums/TableColumnType";
import {
    createPersonFields,
    editPersonFields,
} from "../../utils/fields/person";
import { IRoute } from "../../utils/interfaces/IRoute";
import { personSchema } from "../../utils/schemas/person";
import { searchProjectsQuery } from "../../queries/projects/selectSearch";
import { ITableColumn } from "../../utils/interfaces/ITable";
import { selectSchema } from "utils/schemas/select";

import { crudFormatBody } from "store/crud/utils";

const schema = object().shape({
    person: personSchema(),
    project_id: selectSchema().required(dictionary.validations.required),
});

export const responsiblesColumns: ITableColumn[] = [
    {
        col: 4,
        name: "person",
        type: TableColumnType.Person,
        label: "Pessoa",
    },
    // {
    //     col: 4,
    //     name: "project.name",
    //     type: TableColumnType.Text,
    // },
];

const responsibleFields: any = (isEdit = false) => [
    ...(isEdit ? editPersonFields() : createPersonFields()),
    {
        name: "project_id",
        type: InputType.AsyncSelect,
        label: "Núcleo",
        required: true,
        grid: {
            col: 4,
        },
        graphQL: {
            searchQuery: searchProjectsQuery,
        },
    },
];

const formatFunction = (data: any, isEdit = false) => {
    const values = {
        ...data,
        project_id: data?.project_id?.value,
        person: {
            ...data?.person,
            gender: data?.person?.gender?.value,
        },
    };

    const formatToGraphQL = crudFormatBody(values);

    if (isEdit) {
        const tmp = { ...formatToGraphQL };
        delete tmp.body.person;

        return {
            ...tmp,
            id: values.id,
            person: values.person,
        };
    }

    return formatToGraphQL;
};

export const responsiblesRoutes: IRoute[] = [
    {
        title: "Responsáveis",
        path: "/responsaveis",
        graphql: {
            tableName: "responsible",
        },
        mainColumn: "id",
        component: CrudList,
        exact: true,
        canEdit: true,
        canDelete: true,
        canCreate: true,
        columns: responsiblesColumns,
        showTotal: true,
    },
    {
        title: "Novo Responsável",
        path: "/responsaveis/novo",
        graphql: {
            tableName: "responsible",
            create: createResponsibleQuery,
            formatFunction,
        },
        component: CrudCreateOrEdit,
        exact: true,
        schema,
        fields: responsibleFields(),
    },

    {
        title: "Editar Responsável",
        path: "/responsaveis/editar/:id",
        graphql: {
            tableName: "responsible",
            edit: editResponsibleQuery,
            getById: getResponsibleByIdQuery,
            formatFunction: (data) => formatFunction(data, true),
        },
        mainColumn: "id",
        component: CrudCreateOrEdit,
        exact: true,
        schema,
        fields: responsibleFields(true),
    },
];
