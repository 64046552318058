import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import ListTabData, { IListTabDataConfig } from "../../components/ListTabData";

import { useCrud } from "../../hooks/useCrud";

import { TableColumnType } from "../../utils/enums/TableColumnType";
import { IBaseRouteProps } from "../../utils/interfaces/IBaseRouteProps";
import { IReduxStore } from "../../store/ducks";
import { ProjectsContainer } from "./styles";
import { Creators as ProjectActions } from "../../store/project";
import { IProjectActions, IProjectState } from "../../store/project/types";
import { InputType } from "../../utils/enums/InputType";

import ProjectCreateStudentModal from "./createStudentModal";
import { IStudent } from "../../store/student/types";
import ListCards, { IListCardsConfig } from "components/ListCards";
import Spinner from "components/Spinner";
import { ModalElements } from "utils/enums/ModalElements";
import ProjectClassForm from "pages/ProjectClass/form";
import { useModal } from "hooks/useModal";
import ProjectDetailsTab from "./tabs/details";
import { dictionary } from "config/dictionary";
import { useHeaderTitle } from "hooks/useHeaderTitle";

interface IProps extends IBaseRouteProps {
    projectActions: IProjectActions;
    project?: IProjectState;
}

const Projects = ({ match: { params }, projectActions, project }: IProps) => {
    const { id = "" } = params;
    const { open: openModal, close: closeModal } = useModal();
    const { addListItem } = useCrud();
    const [isActiveCreateStudentModal, setIsActiveCreateStudentModal] =
        useState(false);

    const { loading, id: id_project = 0, name } = project || {};

    useHeaderTitle(name);

    useEffect(() => {
        return () => {
            projectActions.clearProject();
        };
    }, [projectActions]);

    const request = useCallback(() => {
        projectActions.getProjectByIdRequest({ id: ~~id });
    }, [id, projectActions]);

    useEffect(() => {
        request();
    }, [request]);

    const handleClickCreateProjectClass = useCallback(() => {
        openModal({
            element: ModalElements.Custom,
            action: (
                <ProjectClassForm
                    project={{ value: ~~id, label: name || "-" }}
                    onCreate={(item: any) => {
                        addListItem({ item, contentId: "project_class" });
                        closeModal();
                    }}
                />
            ),
            title: "Criar turma",
        });
    }, [addListItem, closeModal, id, name, openModal]);

    const handleClickCreateStudent = useCallback(() => {
        setIsActiveCreateStudentModal(true);
    }, []);

    const listDataConfig = useMemo<IListTabDataConfig[]>(
        () => [
            {
                title: "Detalhes",
                component: <ProjectDetailsTab />,
                columns: [],
            },
            {
                title: "Alunos",
                canCreate: false,
                canDelete: false,
                canEdit: false,
                canSee: true,
                contentId: "student",
                path: "/alunos",
                columns: [
                    {
                        col: "auto",
                        name: "person",
                        type: TableColumnType.Person,
                    },
                    {
                        col: 4,
                        name: "person.birthday",
                        type: TableColumnType.Date,
                    },
                    {
                        col: 4,
                        name: "responsible.responsible.person",
                        type: TableColumnType.Person,
                        label: "Responsável",
                    },
                ],
                graphql: {
                    tableName: "student",
                    orderBy: {
                        person: {
                            name: "asc",
                        },
                    },
                },
                mainColumn: "id",
                defaultSearch: {
                    project_id: {
                        _eq: id_project,
                    },
                },
                onClickCreate: handleClickCreateStudent,
            },
            {
                title: "Turmas",
                canCreate: false,
                canDelete: true,
                canEdit: true,
                canSee: true,
                contentId: "project_class",
                path: "/turmas",
                columns: [
                    {
                        col: 5,
                        name: "name",
                        type: TableColumnType.Text,
                    },
                    {
                        col: "auto",
                        name: "week_days",
                        type: TableColumnType.WeekDays,
                    },
                ],
                graphql: {
                    tableName: "project_class",
                },
                mainColumn: "id",
                defaultSearch: {
                    project_id: {
                        _eq: id_project,
                    },
                },
                onClickCreate: handleClickCreateProjectClass,
            },
            {
                title: "Assistentes",
                canCreate: false,
                canDelete: false,
                canEdit: false,
                canSee: true,
                contentId: "professionals",
                showDeleted: true,
                columns: [
                    {
                        col: 7,
                        name: "professional.person",
                        type: TableColumnType.Person,
                        label: dictionary["professional.name"],
                    },
                ],
                graphql: {
                    tableName: "project_professional",
                    orderBy: {
                        professional: {
                            person: {
                                name: "asc",
                            },
                        },
                    },
                },
                mainColumn: "professional_id",
                defaultSearch: {
                    project_id: {
                        _eq: id_project,
                    },
                },
                path: "/profissionais",
            },
        ],
        [handleClickCreateProjectClass, handleClickCreateStudent, id_project]
    );

    const handleAddStudent = useCallback(
        (item: IStudent) => {
            addListItem({
                item,
                contentId: "student",
            });
        },
        [addListItem]
    );

    const listCardsConfig = useMemo<IListCardsConfig>(
        () => ({
            cards: [
                {
                    name: "professional.person.name",
                    type: InputType.Text,
                    grid: {
                        md: 4,
                    },
                    label: dictionary["professional.name"],
                },
                {
                    name: "classes_aggregate.aggregate.count",
                    type: InputType.Number,
                    grid: {
                        md: 4,
                    },
                    label: "Turmas",
                },
                {
                    name: "students_aggregate.aggregate.count",
                    type: InputType.Number,
                    grid: {
                        md: 4,
                    },
                    label: "Alunos",
                },
            ],
            graphQL: {
                query: `query ($id: Int!) {
                    item: project_by_pk(id: $id) {
                      students_aggregate(where: {status: {_eq: 1}}) {
                        aggregate {
                          count
                        }
                      }
                      classes_aggregate(where: {status: {_eq: 1}}) {
                        aggregate {
                          count
                        }
                      }
                      professional {
                       person {
                        name
                       }
                      }
                    }
                  }
                  
                  `,
                returnPath: "item",
                primaryKey: id,
            },
        }),
        [id]
    );

    return (
        <>
            {loading && <Spinner fullScreen />}
            <ProjectCreateStudentModal
                isActive={isActiveCreateStudentModal}
                onClose={() => setIsActiveCreateStudentModal(false)}
                onSubmit={handleAddStudent}
                project={{ value: project?.id, label: name || "-" }}
            />
            <ProjectsContainer>
                <ListCards {...listCardsConfig} />

                {!!id_project && <ListTabData configs={listDataConfig} />}
            </ProjectsContainer>
        </>
    );
};

const mapStateToProps = ({ project }: IReduxStore) => ({
    project,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    projectActions: bindActionCreators(ProjectActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
