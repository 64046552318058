import { Card } from "components/Card";
import React from "react";
import { connect } from "react-redux";
import { IReduxStore } from "store/ducks";
import { IProjectClassDayState } from "store/projectClassDay/types";
import { ProjectClassMovementsContainer } from "./style";
import CustomText from "components/Text";
import { theme } from "config/theme";
import { formatDate } from "helpers/formatDate";
import GoogleMaps from "components/GoogleMaps";

type ProjectClassMovementsTabProps = {
    projectClassId?: number;
    projectClassDay?: IProjectClassDayState;
    projectClass: any;
    project: any;
};

const ProjectClassMovementsTab = ({
    projectClassDay,
    projectClass,
    project,
}: ProjectClassMovementsTabProps) => {
    console.log("projectClass: ", projectClass);
    console.log("PROJECT: ", project);
    return (
        <ProjectClassMovementsContainer>
            {projectClassDay?.movements?.map((item, index) => (
                <Card key={index}>
                    <div className="d-flex justify-content-between">
                        <div className="mb-4">
                            <CustomText
                                size={16}
                                className="mb-2"
                                weight="Bold"
                                color={theme.colors.components.listCards.color}
                            >
                                {formatDate(projectClassDay.start_time, "ff") ||
                                    "-"}
                            </CustomText>
                            <CustomText
                                size={14}
                                color={theme.colors.components.listCards.color}
                            >
                                Horário (ínicio)
                            </CustomText>
                        </div>
                        <div className="mb-5">
                            <CustomText
                                size={16}
                                className="mb-2"
                                color={theme.colors.components.listCards.color}
                                weight="Bold"
                            >
                                {formatDate(projectClassDay.end_time, "ff") ||
                                    "-"}
                            </CustomText>
                            <CustomText
                                size={14}
                                color={theme.colors.components.listCards.color}
                            >
                                Horário (fim)
                            </CustomText>
                        </div>
                    </div>

                    {(!item.latitude || !item.longitude) && (
                        <CustomText
                            size={14}
                            color={theme.colors.components.listCards.color}
                        >
                            Sem localização definida.
                        </CustomText>
                    )}
                    {Boolean(item.latitude) && Boolean(item.longitude) && (
                        <GoogleMaps
                            key={index}
                            id={`movements_${index}`}
                            latitude={item.latitude}
                            longitude={item.longitude}
                        />
                    )}
                </Card>
            ))}
        </ProjectClassMovementsContainer>
    );
};

const mapStateToProps = ({
    projectClassDay,
    projectClass,
    project,
}: IReduxStore) => ({
    projectClassDay,
    projectClass,
    project,
});

export default connect(mapStateToProps)(ProjectClassMovementsTab);
