import React from "react";
import { formatDate } from "../../helpers/formatDate";
import { TableColumnType } from "../../utils/enums/TableColumnType";
import Badge from "../Badge";
import Person from "../Person";
import CustomText from "../Text";
import { Status } from "../../utils/enums/Status";
import { dictionary } from "../../config/dictionary";
import Availation from "../Availation";
import { Link } from "react-router-dom";
import Avatar from "../Avatar";
import ClockIcon from "components/Icons/clock";
import { convertWeekDaysToString } from "helpers/convertWeekDaysToString";
import { isValidDate } from "helpers/isValidDate";

interface IProps {
    value: any;
    type: TableColumnType;
    enumValue?: any;
    path?: string;
    component?: any;
}

const TableItem = ({ value, type, enumValue, path, component }: IProps) => {
    switch (type) {
        case TableColumnType.Badge:
            return <Badge variant="main">{value}</Badge>;
        case TableColumnType.Text:
        case TableColumnType.Number:
            return (
                <CustomText color="components.table.color" size={15}>
                    {value}
                </CustomText>
            );
        case TableColumnType.Date:
            return (
                <CustomText color="components.table.color" size={15}>
                    {formatDate(value)}
                </CustomText>
            );

        case TableColumnType.DateWithTime:
            return (
                <CustomText color="components.table.color" size={15}>
                    {formatDate(value, "dd LLL yyyy, HH:mm")}
                </CustomText>
            );

        case TableColumnType.Status:
            const badgesVariant: any = {
                [Status.Active]: "success",
                [Status.Inactive]: "warning",
                [Status.Deleted]: "danger",
            };

            return (
                <Badge variant={badgesVariant[value] || "info"}>
                    {(dictionary as any)[Status[value]] || value}
                </Badge>
            );

        case TableColumnType.Avatar:
            return <Avatar image={value} size="20px" />;

        case TableColumnType.Person:
            if (!value?.name) {
                return <div style={{ minWidth: 129 }} />;
            }

            return (
                <Person
                    email={value?.user?.email}
                    name={value?.name}
                    file={value?.file}
                />
            );

        case TableColumnType.Enum:
            if (!value) {
                return null;
            }

            const variant: any = {
                Genre: "info",
                TypePersonality: "success",
                Active: "info",
                FinalizedWaitingForMusic: "primary",
                FinalizedWithMusic: "success",
                FinalizedWithoutMusic: "main",

                // Product Order
                Requested: "info",
                Sent: "main",
                Denied: "warning",
                Concluded: "success",
                Received: "primary",
                Canceled: "danger",

                // ProfessionalPosition
                Teacher: "info",
                Assistant: "main",
                Coordinator: "success",
            };

            const label = !!enumValue[value]
                ? (dictionary as any)[enumValue[value]] || enumValue[value]
                : value;

            return (
                <Badge
                    variant={variant[enumValue[value]] || "info"}
                    style={{ display: "inline" }}
                >
                    {label}
                </Badge>
            );

        case TableColumnType.Availation:
            return <Availation vote={value} />;

        case TableColumnType.Link:
            const url = path?.replace(":id", value?.id) || "#";

            return (
                <Link to={url} style={{ textDecoration: "none" }}>
                    <CustomText
                        color="info"
                        size={16}
                        style={{ textDecoration: "underline" }}
                    >
                        {value?.title ?? value?.name}
                    </CustomText>
                </Link>
            );

        case TableColumnType.WeekDays:
            const days = convertWeekDaysToString(value);

            return (
                <CustomText color="components.table.color" size={15}>
                    {days}
                </CustomText>
            );

        case TableColumnType.Time:
            const isDate = isValidDate(value);

            const text = isDate
                ? formatDate(value, "HH:mm")
                : value?.substr(0, 5);

            return (
                <CustomText
                    color="components.table.color"
                    size={15}
                    style={{ display: "flex", alignItems: "center" }}
                >
                    <ClockIcon
                        width={14}
                        height={14}
                        style={{ marginRight: 3 }}
                    />
                    {text}
                </CustomText>
            );

        case TableColumnType.Custom:
            return component;

        case TableColumnType.Boolean:
            return !!value ? (
                <Badge variant="success">Sim</Badge>
            ) : (
                <Badge variant="danger">Não</Badge>
            );

        case TableColumnType.AsyncSelect:
            return value?.name || value?.title;

        default:
            return null;
    }
};

export default TableItem;
