export const getProjectByIdQuery = `query ($id: Int!) {
    item: project_by_pk(id_project: $id) {
      created_at
      company {
        id: id_company
        name
      }
      id_project
      name
    }
  }`;
