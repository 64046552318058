import React from "react";
import ProjectClassTab from "./tabs/projectClass";
import ProjectClassMovementsTab from "./tabs/movements";
import { TableColumnType } from "utils/enums/TableColumnType";
import { IListTabDataConfig } from "components/ListTabData";
import { InputType } from "utils/enums/InputType";
import { IListCardsConfig } from "components/ListCards";
import { IProjectClassDayState } from "store/projectClassDay/types";

export const listConfigProjectClassDay = (
    classDayId: number
): Array<IListTabDataConfig> => {
    if (!classDayId) {
        return [];
    }

    return [
        {
            title: "Alunos",
            columns: [
                {
                    col: "auto",
                    name: "student.person",
                    type: TableColumnType.Person,
                    label: "Aluno",
                },
                {
                    col: "auto",
                    name: "grade",
                    type: TableColumnType.Custom,
                    label: "Nota",
                    component(data) {
                        return data?.grade || "-";
                    },
                },
                {
                    col: 4,
                    name: "is_present",
                    type: TableColumnType.Boolean,
                    label: "Presente",
                },
            ],
            graphql: {
                tableName: "project_class_day_presence_student",
            },
            mainColumn: "id",
            defaultSearch: {
                project_class_day_presence: {
                    project_class_day_id: {
                        _eq: +classDayId,
                    },
                },
            },
        },
        {
            title: "Mídias",
            columns: [
                {
                    col: "auto",
                    name: "file.original_name",
                    type: TableColumnType.Text,
                    label: "Nome do arquivo",
                },
            ],
            graphql: {
                tableName: "project_class_day_file",
            },
            mainColumn: "id",
            defaultSearch: {
                project_class_day: {
                    id: {
                        _eq: +classDayId,
                    },
                },
            },
        },
        {
            title: "Turma",
            component: <ProjectClassTab />,
            columns: [],
        },
        {
            title: "Movimentações",
            component: <ProjectClassMovementsTab />,
            columns: [],
            // graphql: {
            //     tableName: "project_class_day_movement",
            // },
        },
    ];
};

export const listCardsConfigProjectClassDay = (
    projectClassDay: IProjectClassDayState,
    classDayId: number
): IListCardsConfig => ({
    cards: [
        {
            name: "day",
            type: InputType.Date,
            grid: {
                md: 4,
            },
            label: "Data",
        },
        {
            name: "project_class_day_presences.project_class_day_presence_students_aggregate.aggregate.count",
            type: InputType.Number,
            grid: {
                md: 4,
            },
            valueCallback: (value: any) => {
                const newValue =
                    value?.project_class_day_presences?.[0]
                        ?.project_class_day_presence_students_aggregate
                        ?.aggregate?.count;

                return newValue;
            },
            label: "Alunos",
        },
        {
            name: "project_class_day_presences.project_class_day_presence_students_aggregate.aggregate.count",
            customKey: "project_class_day_presences_students_total",
            type: InputType.Number,
            valueCallback() {
                const newValue =
                    projectClassDay?.project_class_day_presences?.[0]
                        ?.project_class_day_presence_students_aggregate
                        ?.aggregate?.count;

                return newValue;
            },

            grid: {
                md: 4,
            },
            label: "Presença",
        },
        {
            name: "start_time",
            type: InputType.DateWithHour,
            grid: {
                md: 4,
            },
            label: "Horário (Início)",
        },
        {
            name: "end_time",
            type: InputType.DateWithHour,
            grid: {
                md: 4,
            },
            label: "Horário (Fim)",
        },
    ],
    graphQL: {
        tableName: "project_class_day",
        returnPath: "item",
        mainColumn: "id",
        primaryKey: classDayId,
    },
});
