import React, { memo, CSSProperties } from "react";
import styled, { css } from "styled-components";
import { theme } from "../../config/theme";
import { generateSpacingStyles } from "../../helpers/generateSpacingStyles";
import { FontWeight } from "../../utils/enums/FontWeight";
import { ISpacing } from "../../utils/interfaces/ISpacing";
import { FontWeightType } from "../../utils/types/FontWeight";
import _get from "lodash/get";
interface IProps extends ISpacing {
    children: any;
    color: string;
    size: number;
    uppercase?: boolean;
    center?: boolean;
    font?: any;
    style?: CSSProperties;
    className?: any;
    weight?: FontWeightType;
    ellipsis?: boolean;
    italic?: boolean;
    pre?: boolean;
    lineHeight?: string;
}

export const TextElement = styled.p<IProps>`
    font-size: ${({ size }) => size}px;
    line-height: ${({ lineHeight, size }) => lineHeight ?? "normal"};
    color: ${({ color }) => _get(theme.colors, color, color)};
    text-transform: ${({ uppercase }) => (!!uppercase ? "uppercase" : "none")};

    ${(props) => generateSpacingStyles(props)};

    ${({ pre }) =>
        !!pre &&
        css`
            white-space: pre;
        `};

    ${({ center }) =>
        !!center &&
        css`
            text-align: center;
        `};

    font-weight: ${({ weight }) =>
        !!weight ? FontWeight[weight] : FontWeight.Regular};

    ${({ ellipsis }) =>
        !!ellipsis &&
        css`
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        `};

    ${({ italic }) =>
        !!italic &&
        css`
            font-style: italic;
        `};
`;

const CustomText = memo(({ children, ...rest }: IProps) => {
    return <TextElement {...rest}>{children}</TextElement>;
});

export default CustomText;
