import styled, { css } from "styled-components";
import ReactSelect from "react-select";
import { theme } from "../../../config/theme";
import { generateSizeStyles } from "../../../helpers/generateSizeStyles";
import { generateSpacingStyles } from "../../../helpers/generateSpacingStyles";
import { ISelectProps } from ".";
import { BaseFormElementStyle } from "../styles";

const colors = theme.colors.components.form.select;

export const SelectElementStyles = css<ISelectProps>`
    ${(props) => generateSpacingStyles(props)};

    > * {
        outline: none;
    }

    > .react-select__control--is-focused {
        ${BaseFormElementStyle};
        border-color: ${theme.colors.placeholder} !important;
        box-shadow: none;
        background-color: ${colors.backgroundFocused};
    }

    .react-select__option,
    .react-select__option--is-selected {
        color: ${colors.color} !important;

        :hover {
            color: ${colors.color} !important;
        }
    }

    .react-select__option--is-selected {
        background: ${colors.backgroundSelected} !important;
        color: ${colors.colorSelected} !important;

        :hover {
            color: ${colors.colorSelected} !important;
        }
    }

    > .react-select__control {
        border-color: ${colors.borderColor};
        box-shadow: none;
        width: ${({ width }) => width ?? "191px"};

        ${(props) => generateSizeStyles(props)};

        :hover {
            border: 1px solid ${theme.colors.secondary};
        }

        .react-select__value-container {
            font-size: 13px;
            padding: 8px;
        }

        ${BaseFormElementStyle};

        .react-select__placeholder {
            color: ${theme.colors.placeholder};
        }

        > .react-select__indicators {
            .react-select__indicator-separator {
                background-color: ${theme.colors.mainLight};
            }
        }
    }
`;

export const SelectElement = styled(ReactSelect)<ISelectProps>`
    ${SelectElementStyles};
`;
