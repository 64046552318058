import styled from "styled-components";
import { TableHeader } from "../Table/styles";

export const ListTabDataContainer = styled.div`
    display: flex;
    flex-direction: column;

    ${TableHeader} {
        border-top-right-radius: unset;
        border-top-left-radius: unset;
    }
`;
