import { put, takeLatest, call, all } from "redux-saga/effects";
import { ISagaAction } from "../../utils/interfaces/ISagaAction";
import { Creators, Types } from ".";
import { toast } from "react-toastify";
import history from "../../services/history";
import {
    ICreateOrEditProfessionalPayload,
    IGetProfessionalByIdPayload,
} from "./types";
import api from "services/api";
import { formatDate } from "helpers/formatDate";
import _pick from "lodash/pick";
import convertEnumToOptions from "helpers/convertEnumToOptions";
import { Genre } from "utils/enums/Genre";
import { IYield } from "utils/interfaces/IYield";

function* getDetails(id: number): IYield {
    const [{ data: details }, { data: stats }] = yield all([
        call(api.get, `/admin/professionals/${id}`),
        call(api.get, `/admin/professionals/${id}/stats`),
    ]);

    console.log("stats", stats);

    if (!details?.id) {
        throw new Error();
    }

    const formatBody = {
        ...details,
        stats,
    };

    yield put(Creators.getProfessionalByIdSuccess(formatBody));
}

function* getByIdForm(id: number) {
    const { data } = yield call(api.get, `/admin/professionals/${id}`);

    if (!data) {
        throw new Error();
    }

    const formatResponse = {
        ...data,
        person: {
            ...data.person,
            gender: convertEnumToOptions(Genre).find(
                ({ value }) => value === data.person.gender
            ),
            birthday: formatDate(data.person.birthday, "yyyy-LL-dd"),
        },
        position: !!data?.position
            ? {
                  value: data?.position?.id,
                  label: data?.position?.name,
              }
            : null,
        projects:
            data?.projects?.map(({ id, name }: any) => ({
                value: id,
                label: name,
            })) || [],
    };

    yield put(Creators.getProfessionalByIdSuccess(formatResponse));
}

function* getById(action: ISagaAction<IGetProfessionalByIdPayload>) {
    try {
        const { id, isDetailScreen = false } = action.payload;

        if (!id) {
            throw new Error();
        }

        const resolver = isDetailScreen ? getDetails : getByIdForm;

        yield resolver(id);
    } catch (error) {
        console.log("error", error);

        yield call(toast.error, "Desculpe, tivemos um problema.");

        yield put(Creators.getProfessionalByIdFailure());

        yield call(history.push, { pathname: "/app/profissionais" });
    }
}

function* createOrEdit(action: ISagaAction<ICreateOrEditProfessionalPayload>) {
    try {
        const { id, body } = action.payload;

        const method = !!id ? api.patch : api.post;
        const endpoint = !!id
            ? `/admin/professionals/${id}`
            : "/admin/professionals";

        const formatBody = {
            ...body,
            company: 1,
            person: {
                ...body.person,
                gender: (body as any).person!.gender.value,
                birthday: formatDate(body.person?.birthday!),
            },
            position: body?.position?.value,
            projects: body?.projects?.map(({ value }) => value),
        };

        const acceptedFields = [
            "company",
            "person.name",
            "person.gender",
            "person.phone",
            "person.document",
            "person.birthday",
            "person.user.email",
            "person.address.complement",
            "person.address.city",
            "person.address.state",
            "person.address.latitude",
            "person.address.longitude",
            "person.address.neighbourhood",
            "person.address.number",
            "person.address.street",
            "person.address.zip",
            "documentProfessional",
            "professional_position_id",
            "position",
            "projects",
        ];

        console.log("body", _pick(formatBody, acceptedFields));

        const { data } = yield call(
            method,
            endpoint,
            _pick(formatBody, acceptedFields)
        );

        if (!data) {
            throw new Error();
        }

        yield put(Creators.createOrEditProfessionalSuccess(data));

        yield call(history.push, { pathname: "/app/profissionais" });
    } catch (error) {
        const msgs = (error as any).response?.data?.msgs;

        if (!!msgs?.length) {
            msgs.forEach((msg: string) => toast.error(msg));
        } else {
            yield call(toast.error, "Desculpe, tivemos um problema.");
        }

        yield put(Creators.createOrEditProfessionalFailure());
    }
}

export default [
    takeLatest(Types.GET_PROFESSIONAL_BY_ID_REQUEST, getById),
    takeLatest(Types.CREATE_OR_EDIT_PROFESSIONAL_REQUEST, createOrEdit),
];
