export const editStudentQuery = `mutation ($body: student_set_input, $id: Int!, $person: person_insert_input!) {
    item: update_student_by_pk(pk_columns: {id_student: $id}, _set: $body) {
      id_student
      id_project
    }
    person: insert_person_one(object: $person, on_conflict: {constraint: person_pkey, update_columns: [name, phone, email, document, birthday, gender, updated_at]}) {
      id_person
      document
      email
      name
      birthday
      gender
    }
  }`;
