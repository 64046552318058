import styled, { css } from "styled-components";
import { theme } from "../../config/theme";
import { generateSizeStyles } from "../../helpers/generateSizeStyles";
import { generateSpacingStyles } from "../../helpers/generateSpacingStyles";
import { ISizing } from "../../utils/interfaces/ISizing";
import { ISpacing } from "../../utils/interfaces/ISpacing";
import { ColorsVariants } from "../../utils/types/Variants";

export interface IBadgeElementProps extends ISpacing, ISizing {
    variant: ColorsVariants;
    iconDirection?: "left" | "right";
    textAlign?: "left" | "right" | "center";
    padding?: ISpacing;
}

const generateColors = (variant: ColorsVariants) => {
    let color = variant === "light" ? "#000" : "#FFF";
    let background =
        variant === "light"
            ? "transparent"
            : (theme as any).colors[variant] || "transparent";

    return css`
        color: ${color};
        background: ${background};
        border: none;
    `;
};

export const BadgeElement = styled.span<IBadgeElementProps>`
    ${({ variant }) => generateColors(variant)};
    border-radius: 8px;
    padding: 5px 10px;
    display: inline;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s, transform 0.3s;
    text-align: ${({ textAlign }) => textAlign};

    ${({ padding }) => generateSpacingStyles(padding, "padding")}
    ${(props) => generateSpacingStyles(props)};
    ${(props) => generateSizeStyles(props)};

    ${({ iconDirection }) =>
        iconDirection === "left" &&
        css`
            flex-direction: row-reverse;
        `};

    > .badge-icon {
        ${({ iconDirection }) =>
            iconDirection === "left"
                ? css`
                      margin-right: 10px;
                  `
                : css`
                      margin-left: 10px;
                  `};

        display: flex;
        align-items: center;
    }
`;

export const BadgeGroup = styled.div<ISizing & { column?: boolean }>`
    display: flex;

    ${({ column }) =>
        column
            ? css`
                  flex-direction: column;

                  ${BadgeElement} {
                      :not(:last-child) {
                          margin-bottom: 10px;
                      }
                  }
              `
            : css`
                  ${BadgeElement} {
                      :not(:last-child) {
                          margin-right: 10px;
                      }
                  }
              `};

    ${(props) => generateSizeStyles(props)};
`;
