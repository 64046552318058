import React from "react";
import { ModalElements } from "../../utils/enums/ModalElements";
import { IBaseModalElement } from "../../utils/interfaces/IBaseModalElement";
import GenericForm from "./GenericForm";
import ConfirmAction from "./ConfirmAction";
import ModalList from "./List";
import ModalActions from "./Actions";
import CreateProjectClassDayModal from "./CreateProjectClassDay";
import SelectCompany from "./SelectCompany";

interface IProps extends IBaseModalElement {
    element: ModalElements;
}

const ModalElement = ({ element, ...props }: IProps) => {
    const { action } = props;

    const elements: { [key: string]: JSX.Element } = {
        [ModalElements.ConfirmAction]: <ConfirmAction {...props} />,
        [ModalElements.GenericForm]: <GenericForm {...props} />,
        [ModalElements.List]: <ModalList {...props} />,
        [ModalElements.Actions]: <ModalActions actions={action ?? []} />,
        [ModalElements.CreateProjectClassDay]: (
            <CreateProjectClassDayModal action={action} />
        ),
        [ModalElements.Custom]: action || null,
        [ModalElements.SelectCompany]: <SelectCompany />,
    };

    return elements[element] || null;
};

export default ModalElement;
