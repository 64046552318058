import React from "react";
import CheckIcon from "../../../components/Icons/check";
import CloseIcon from "../../../components/Icons/close";
import CustomText from "../../../components/Text";

import * as S from "./styles";

export interface IWizardStep {
    title: string;
    active: boolean;
    done: boolean;
    component: React.ReactNode;
    onClick?(): void;
    error?: boolean;
}

interface IProps {
    steps: IWizardStep[];
}

const StudentWizard: React.FC<IProps> = ({ steps }) => {
    return (
        <S.StudentWizardContainer>
            {steps.map(
                (
                    { done, active, component, title, onClick, error = false },
                    index
                ) => (
                    <S.StudentWizardContent key={`wizard_${index}`}>
                        <S.StudentWizardTimeLine
                            done={done}
                            active={active}
                            error={error}
                        />
                        <S.StudentWizardBull
                            active={active}
                            done={done}
                            error={error}
                        >
                            {error ? (
                                <CloseIcon fill="#FFF" width={12} height={12} />
                            ) : done ? (
                                <CheckIcon fill="#FFF" width={12} height={12} />
                            ) : (
                                index + 1
                            )}
                        </S.StudentWizardBull>

                        <S.StudentWizardCard active={active}>
                            <S.StudentWizardContentHeader onClick={onClick}>
                                <CustomText
                                    color="black"
                                    size={18}
                                    weight="SemiBold"
                                >
                                    {title}
                                </CustomText>
                            </S.StudentWizardContentHeader>

                            {active && (
                                <S.StudentWizardCardContent>
                                    {component}
                                </S.StudentWizardCardContent>
                            )}
                        </S.StudentWizardCard>
                    </S.StudentWizardContent>
                )
            )}
        </S.StudentWizardContainer>
    );
};

export default StudentWizard;
