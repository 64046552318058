import styled from "styled-components";
import { ButtonElement } from "../../Button/styles";

export const ModalActionsButtonGroup = styled.div`
    display: flex;
    align-items: center;

    ${ButtonElement} {
        padding: 15px;
        display: flex;
        flex-direction: column;
        flex: 1;

        :not(:last-child) {
            margin-right: 15px;
        }

        svg {
            margin-bottom: 10px;
        }
    }
`;
