import { Card } from "components/Card";
import React from "react";
import { IBaseRouteProps } from "utils/interfaces/IBaseRouteProps";
import ProfessionalForm from "./form";

const ProfessionalCreateOrEdit = ({ match: { params } }: IBaseRouteProps) => {
    const { id } = params || {};

    return (
        <Card column bottom="50px">
            <ProfessionalForm id={~~id} />
        </Card>
    );
};

export default ProfessionalCreateOrEdit;
