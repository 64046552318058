import getNestedObjects from "../../helpers/getNestedObjects";
import removeNestedObjects from "../../helpers/removeNestedObjects";
import removeObjectEmptyValues from "../../helpers/removeObjectEmptyValues";
import _set from "lodash/set";
import isDevelopment from "../../helpers/isDevelopment";

export const crudFormatBody = (body: object, id?: number | string) => {
    const isCreate = !id;

    const nestedObjects = getNestedObjects(removeObjectEmptyValues(body));

    if (isDevelopment()) {
        console.log(
            "removeObjectEmptyValues(body)",
            removeObjectEmptyValues(body)
        );
        console.log("nestedObjects", nestedObjects);
    }

    if (!nestedObjects.length) {
        return {
            ...(!isCreate && { id }),
            body,
        };
    }

    const formatNestedObjects = nestedObjects.reduce(
        (accumulator: any, currentValue) => {
            const values = removeObjectEmptyValues((body as any)[currentValue]);

            // let key: any;

            // switch (true) {
            //     case value:

            //         break;

            //     default:
            //         key = isCreate ? `${currentValue}.data` : currentValue
            //         break;
            // }

            const key = isCreate ? `${currentValue}.data` : currentValue;

            console.log("key", key);
            console.log("isCreate", isCreate);

            _set(accumulator, key, values);

            return accumulator;
        },
        {}
    );

    return isCreate
        ? {
              body: {
                  ...removeNestedObjects(body),
                  ...formatNestedObjects,
              },
          }
        : {
              id,
              body: removeNestedObjects(body),
              ...formatNestedObjects,
          };
};
