import styled from "styled-components/macro";
import { Card } from "../../Card";

export const ModalListContainer = styled.div`
    margin: -19px;

    ${Card} {
        border: none;
    }
`;
