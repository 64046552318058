// sampleReducer.js
import { createReducer, createActions } from "reduxsauce";
import {
    ProviderTypes,
    IProviderState,
    IProviderActionsCreators,
} from "./types";

export const { Creators, Types } = createActions<
    Record<ProviderTypes, ProviderTypes>,
    IProviderActionsCreators
>({
    getProviderByIdRequest: ["payload"],
    getProviderByIdSuccess: ["payload"],
    getProviderByIdFailure: [],

    createOrEditProviderRequest: ["payload"],
    createOrEditProviderSuccess: ["payload"],
    createOrEditProviderFailure: [],

    clearProvider: [],
});

const INITIAL_STATE: IProviderState = {
    error: false,
    loading: false,
    id: 0,
    juridicDocument: "",
    juridicName: "",
    name: "",
};

const request = (state = INITIAL_STATE, _: any) => {
    return { ...state, error: false, loading: true };
};

const failure = (state = INITIAL_STATE) => {
    return { ...state, error: true, loading: false };
};

const setState = (state = INITIAL_STATE, action: any) => ({
    ...state,
    loading: false,
    error: false,
    ...action.payload,
});

const clear = () => INITIAL_STATE;

const HANDLERS = {
    [Types.GET_PROVIDER_BY_ID_REQUEST]: request,
    [Types.GET_PROVIDER_BY_ID_SUCCESS]: setState,
    [Types.GET_PROVIDER_BY_ID_FAILURE]: failure,

    [Types.CREATE_OR_EDIT_PROVIDER_REQUEST]: request,
    [Types.CREATE_OR_EDIT_PROVIDER_SUCCESS]: setState,
    [Types.CREATE_OR_EDIT_PROVIDER_FAILURE]: failure,

    [Types.CLEAR_PROVIDER]: clear,
};

export default createReducer(INITIAL_STATE, HANDLERS);
