import ListCards, { IListCardsConfig } from "components/ListCards";
import ProjectClassDetailsTab from "pages/ProjectClass/tabs/details";
import { listCardsConfigProjectClass } from "pages/ProjectClass/utils";
import React, { useMemo } from "react";
import { connect } from "react-redux";
import { IReduxStore } from "store/ducks";
import { IProjectClassState } from "store/projectClass/types";

type ProjectClassTabProps = {
    projectClassId?: number;
    projectClass?: IProjectClassState;
};

const ProjectClassTab = ({ projectClass }: ProjectClassTabProps) => {
    const listCardsConfig = useMemo<IListCardsConfig>(
        () => listCardsConfigProjectClass(projectClass?.id!),
        [projectClass]
    );

    return (
        <>
            <ListCards {...listCardsConfig} />
            <ProjectClassDetailsTab />
        </>
    );
};

const mapStateToProps = ({ projectClass }: IReduxStore) => ({
    projectClass,
});

export default connect(mapStateToProps)(ProjectClassTab);
