/* eslint-disable */
export const isValidCPF = (cpf: string) => {
    if (typeof cpf !== "string") return false;
    cpf = cpf.replace(/[^\d]+/g, "");
    if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false;
    cpf = (cpf as any).split("").map((el: any) => +el) as any;
    const rest: any = (count: any) =>
        (((cpf as any)
            .slice(0, count - 12)
            .reduce(
                (soma: any, el: any, index: any) => soma + el * (count - index),
                0
            ) *
            10) %
            11) %
        10;
    return rest(10) === cpf[9] && rest(11) === cpf[10];
};
