import { IInputField } from "../utils/interfaces/IInputField";
import { InputType } from "../utils/enums/InputType";

export default function generateGetByIdQuery(
    tableName: string,
    fields: IInputField[],
    mainColumn = "id"
) {
    const generateFields = fields
        ?.map(({ name, type, options }) => {
            switch (type) {
                case InputType.Select:
                case InputType.AsyncSelect:
                    return !!options?.length
                        ? name
                        : `${name}: ${name?.replace("_id", "")} {
                        id
                        name
                    }`;

                default:
                    const format = name.split(".");

                    return `${format.join(" {\n")}\n${Array.from(
                        new Array(format.length >= 1 ? format.length - 1 : 0)
                    )
                        .map(() => `}`)
                        .join("\n")}`;
            }
        })
        ?.filter(Boolean)
        ?.join("\n");

    return `query ($id: Int!) {
        item: ${tableName}_by_pk(${mainColumn}: $id) {
            ${mainColumn}
          ${generateFields}
        }
      }`;
}
