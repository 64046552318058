import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Creators } from "../store/project";
import { ICreateProjectPayload, IProjectState } from "../store/project/types";
import { IReduxStore } from "../store/ducks";

export function useProject() {
    const dispatch = useDispatch();

    const getById = useCallback(
        (payload: { id: number | string }) =>
            dispatch(Creators.getProjectByIdRequest(payload)),
        [dispatch]
    );

    const createOrEdit = useCallback(
        (payload: ICreateProjectPayload) =>
            dispatch(Creators.createOrEditProjectRequest(payload)),
        [dispatch]
    );

    const clear = useCallback(
        () => dispatch(Creators.clearProject()),
        [dispatch]
    );

    const state = useSelector<IReduxStore, IProjectState | undefined>(
        (state) => state?.project
    );

    return { createOrEdit, getById, state, clear };
}
