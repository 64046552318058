import React, { useCallback } from "react";

import DateService from "../../../services/date";
import { InputType } from "../../../utils/enums/InputType";
import { IProjectClassDay } from "../../../utils/interfaces/IProjectClassDay";
import Button from "../../Button";
import Form from "../../Form";
import Input from "../../Form/Input";
import { Col, Row } from "../../Grid";
import { toast } from "react-toastify";
import { useCrud } from "../../../hooks/useCrud";
import { useModal } from "../../../hooks/useModal";

interface IProps {
    action: {
        onSubmit?(data: IProjectClassDay): void;
        id_project_class: number;
    };
}

const CreateProjectClassDayModal: React.FC<IProps> = ({ action }) => {
    const { onSubmit, id_project_class } = action;

    const { createOrEdit, addListItem } = useCrud();
    const { close } = useModal();

    const handleSubmit = useCallback(
        (data: any) => {
            const date = DateService.fromISO(data.day).set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
            });

            const [startHour, startMinute] = data.start_time.split(":");

            const start_time = DateService.fromJSDate(
                date
                    .set({
                        hour: startHour,
                        minute: startMinute,
                    })
                    .toJSDate()
            );

            const [endHour, endMinute] = data.end_time.split(":");

            const end_time = DateService.fromJSDate(
                date
                    .set({
                        hour: endHour,
                        minute: endMinute,
                    })
                    .toJSDate()
            );

            if (end_time <= start_time) {
                return toast.error(
                    "Horário de fim não pode ser menor que o Horário de início."
                );
            }

            if (!onSubmit) {
                console.log("!onSubmit");
                return;
            }

            createOrEdit({
                query: `mutation ($body: project_class_day_insert_input!) {
                    item: insert_project_class_day_one(object: $body) {
                      day
                      end_time
                      start_time
                      id_project_class
                      id_project_class_day
                    }
                  }`,
                body: {
                    start_time: start_time.toJSON(),
                    end_time: end_time.toJSON(),
                    day: data.day,
                    id_project_class,
                },
                onCreate: (item: any) => {
                    addListItem({
                        item,
                        contentId: "classDay",
                    });
                    close();
                },
            });
        },
        [addListItem, close, createOrEdit, id_project_class, onSubmit]
    );

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Col md={4}>
                    <Input
                        type={InputType.Date}
                        name="day"
                        required
                        label="Dia"
                    />
                </Col>

                <Col md={4}>
                    <Input
                        type={InputType.Time}
                        name="start_time"
                        required
                        label="Horário de início"
                    />
                </Col>

                <Col md={4}>
                    <Input
                        type={InputType.Time}
                        name="end_time"
                        required
                        label="Horário de fim"
                    />
                </Col>
            </Row>

            <Button type="submit" variant="main" right="auto">
                Salvar
            </Button>
        </Form>
    );
};

export default CreateProjectClassDayModal;
