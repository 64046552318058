import Button from "components/Button";
import ArrowRightIcon from "components/Icons/arrowRight";
import React, { useCallback } from "react";
import { Creators as AuthActions } from "store/auth";

import * as S from "./styles";
import { ICompany } from "utils/interfaces/ICompany";
import { useAppDispatch, useAppSelector } from "hooks/store";

interface IProps {
    onCancel(): void;
}

const SelectCompany = ({ onCancel }: IProps) => {
    const dispatch = useAppDispatch();
    const availableCompanies = useAppSelector(
        ({ auth }) => auth?.availableCompanies ?? []
    );

    const handleClick = useCallback(
        (payload: ICompany) => {
            dispatch(AuthActions.setCurrentCompany(payload));
        },
        [dispatch]
    );

    return (
        <S.SelectCompanyContainer>
            {availableCompanies?.map(({ id, name }) => {
                return (
                    <Button
                        type="button"
                        variant="main"
                        key={`company_${id}`}
                        onClick={() => handleClick({ id, name })}
                    >
                        <span>{name}</span>
                        <ArrowRightIcon fill="#fff" width={16} height={16} />
                    </Button>
                );
            })}

            <Button
                type="button"
                variant="light"
                style={{ marginTop: 15 }}
                onClick={onCancel}
            >
                Cancelar
            </Button>
        </S.SelectCompanyContainer>
    );
};

export default SelectCompany;
