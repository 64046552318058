import React, { memo } from "react";
import { theme } from "../../config/theme";
import { IIconProps } from "../../utils/interfaces/IIconProps";

const HamburgerIcon = memo(
    ({ fill = theme.colors.black, ...props }: IIconProps) => {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                {...props}
                viewBox="0 0 13.334 14.167"
                style={{ transform: "rotate(90deg)" }}
            >
                <path
                    d="M11.667,13.333V7.5a.833.833,0,1,1,1.667,0v5.833a.833.833,0,1,1-1.667,0Zm-5.833,0V.833A.833.833,0,0,1,7.5.833v12.5a.833.833,0,1,1-1.667,0ZM0,13.333V4.167a.833.833,0,1,1,1.667,0v9.167a.833.833,0,1,1-1.667,0Z"
                    fill={fill}
                />
            </svg>
        );
    }
);

export default HamburgerIcon;
