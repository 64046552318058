import React, { Fragment } from "react";

import { BreadcrumbContainer, BreadcrumbLink } from "./styles";
import HomeIcon from "components/Icons/home";
import CustomText from "components/Text";
import { useHeaderTitle } from "hooks/useHeaderTitle";

export interface IBreadcrumbRoute {
    title?: string;
    path: string;
}

interface IProps {
    routes: IBreadcrumbRoute[];
}

const Divider = () => (
    <CustomText color="gray" size={20} right="10px">
        /
    </CustomText>
);

const Breadcrumb = ({ routes }: IProps) => {
    const { headerTitle } = useHeaderTitle();
    const [, ...routesToShow] = routes || [];

    if (!routesToShow?.length) {
        return <div style={{ flex: 1 }} />;
    }

    return (
        <BreadcrumbContainer>
            <BreadcrumbLink to="/">
                <HomeIcon width={18} height={18} />
            </BreadcrumbLink>
            {!!routesToShow?.length && <Divider />}
            {routesToShow?.map(({ path, title }, index) => {
                const isLast = index === ~~(routesToShow?.length - 1);

                const correctPath =
                    path === "*" || !path ? "/app" : `/app${path}`;

                return (
                    <Fragment key={path}>
                        <BreadcrumbLink to={correctPath}>
                            {isLast ? headerTitle ?? title : title}
                        </BreadcrumbLink>
                        {!isLast && <Divider />}
                    </Fragment>
                );
            })}
        </BreadcrumbContainer>
    );
};

export default Breadcrumb;
