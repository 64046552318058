import { IGoogleMapsAddress } from "utils/interfaces/IGoogleMapsAddress";

export const formatAddressName = (address?: IGoogleMapsAddress) => {
    return [
        address?.street,
        [address?.number, address?.neighbourhood].filter(Boolean).join(" - "),
        [address?.city, address?.state].filter(Boolean).join(" - "),
    ]
        .filter(Boolean)
        .join(", ");
};
