import styled from "styled-components";
import { theme } from "../../config/theme";

export const ErrorContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${theme.colors.background};

    > svg,
    > img {
        margin-bottom: 20px;
    }

    > img {
        border-radius: 5px;
        max-width: 100%;
    }

    a {
        text-decoration: underline;
        color: ${theme.colors.info};
    }
`;
