import { put, takeLatest, call, select } from "redux-saga/effects";
import { ISagaAction } from "../../utils/interfaces/ISagaAction";
import { Creators, Types } from ".";
import { Creators as ModalActions } from "../modal";
import { toast } from "react-toastify";
import history from "../../services/history";
import _set from "lodash/set";
import _get from "lodash/get";
import api from "../../services/api";
import { AxiosResponse } from "axios";
import { IAuth, ILoginRequestPayload } from "./types";
import graphql from "../../services/graphql";
import { checkIfUserIsAdminByEmail } from "../../queries/auth/checkIfUserIsAdminByEmail";
import { ICompany } from "utils/interfaces/ICompany";
import { IModalPayload } from "store/modal/types";
import { ModalElements } from "utils/enums/ModalElements";
import { IReduxStore } from "store/ducks";
import { IYield } from "utils/interfaces/IYield";

function* login(action: ISagaAction<ILoginRequestPayload>) {
    try {
        if (api.defaults.headers.common.Authorization) {
            delete api.defaults.headers.common.Authorization;
        }

        // Request login
        const { data }: AxiosResponse<IAuth> = yield call(
            api.post,
            "/tokens",
            action.payload
        );

        if (!data?.token) {
            throw new Error();
        }

        (window as any).token = data?.token;

        // check if user has the admin profile
        const {
            data: checkData,
        }: AxiosResponse<{ item: { email: string }[] }> = yield call(
            graphql,
            checkIfUserIsAdminByEmail,
            { email: action?.payload?.username }
        );

        if (!_get(checkData, "item[0].email", undefined)) {
            throw new Error();
        }

        api.defaults.headers.common.Authorization = `Bearer ${data.token}`;

        // save only the profile image url
        _set(
            data,
            "credentials.profileImage",
            _get(data, "credentials.profileImage.url", undefined)
        );

        const availableCompanies = _get(data, "credentials.companies", []);

        if (!availableCompanies?.length) {
            throw new Error();
        }

        yield put(
            Creators.loginSuccess({
                ...data,
                availableCompanies,
            })
        );

        // IF THE USER HAS A SINGLE COMPANY, SET THE COMPANY
        const hasASingleCompany = availableCompanies?.length === 1;

        if (hasASingleCompany) {
            yield put(Creators.setCurrentCompany(availableCompanies[0]));
        } else {
            const payloadModal: IModalPayload = {
                element: ModalElements.SelectCompany,
                width: "450px",
                title: "Selecione uma empresa",
                removeCloseButton: true,
            };

            yield put(ModalActions.openModal(payloadModal));
        }
    } catch (error) {
        console.log("error", error);

        yield call(toast.error, "Login ou senha inválidos, tente novamente.");

        yield put(Creators.loginFailure());
    }
}

function* logout() {
    if (api.defaults.headers.common.Authorization) {
        delete api.defaults.headers.common.Authorization;
    }

    if ((window as any).company) {
        (window as any).company = undefined;
    }

    if (api.defaults.headers.common["x-company"]) {
        delete api.defaults.headers.common["x-company"];
    }

    yield call(history.push, { pathname: "/" });
    yield call(toast.info, "Você foi deslogado.");
}

function* setCurrentCompany(action: ISagaAction<ICompany>) {
    const { payload } = action;

    api.defaults.headers.common["x-company"] = payload.id;

    (window as any).company = payload.id;

    yield put(ModalActions.closeModal());

    yield call(history.push, { pathname: "/app" });
    yield call(toast.info, `Você está navegando como ${payload?.name}.`);
}

function* getAvailableCompaniesRequest(): IYield {
    try {
        const token = yield select(({ auth }: IReduxStore) => auth?.token);

        if (!token) {
            return;
        }

        const { data }: AxiosResponse<ICompany[]> = yield call(
            api.get,
            "/admin/users/companies"
        );

        if (!data?.length) {
            throw new Error();
        }

        yield put(Creators.getAvailableCompaniesSuccess(data));

        // IF THE USER HAS A SINGLE COMPANY, SET THE COMPANY
        const hasASingleCompany = data?.length === 1;

        if (hasASingleCompany) {
            yield put(Creators.setCurrentCompany(data[0]));
        } else {
            const payloadModal: IModalPayload = {
                element: ModalElements.SelectCompany,
                width: "450px",
                title: "Selecione uma empresa",
                removeCloseButton: true,
            };

            yield put(ModalActions.openModal(payloadModal));
        }
    } catch (error) {
        console.log("error", error);

        yield call(toast.error, "Login ou senha inválidos, tente novamente.");

        yield put(Creators.getAvailableCompaniesFailure());
        yield put(Creators.logout());
    }
}

export default [
    takeLatest(Types.LOGIN_REQUEST, login),
    takeLatest(Types.LOGOUT, logout),
    takeLatest(Types.SET_CURRENT_COMPANY, setCurrentCompany),
    takeLatest(
        Types.GET_AVAILABLE_COMPANIES_REQUEST,
        getAvailableCompaniesRequest
    ),
];
