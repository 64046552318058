import { object, string } from "yup";
import CrudList from "../../pages/CRUD/List";
import { TableColumnType } from "../../utils/enums/TableColumnType";
import { createPersonFields } from "../../utils/fields/person";
import { IInputField } from "../../utils/interfaces/IInputField";
import { IRoute } from "../../utils/interfaces/IRoute";
import { personSchema } from "../../utils/schemas/person";
import { InputType } from "utils/enums/InputType";
import { dictionary } from "config/dictionary";
import ProviderCreateOrEdit from "pages/Provider/createOrEdit";
import Provider from "pages/Provider";

export const providersSchema = object().shape({
    name: string()
        .min(1, dictionary.validations.invalid)
        .typeError(dictionary.validations.invalid)
        .required(dictionary.validations.required),
    juridicName: string()
        .min(1, dictionary.validations.invalid)
        .typeError(dictionary.validations.invalid)
        .required(dictionary.validations.required),
    juridicDocument: string()
        .min(1, dictionary.validations.invalid)
        .typeError(dictionary.validations.invalid)
        .required(dictionary.validations.required),
    responsible: personSchema({ withAdress: false, withUser: true }),
});

export const providersFields: IInputField[] = [
    {
        name: "name",
        type: InputType.Text,
        required: true,
        grid: {
            col: 4,
        },
    },
    {
        name: "juridicName",
        type: InputType.Text,
        required: true,
        grid: {
            col: 4,
        },
    },
    {
        name: "juridicDocument",
        type: InputType.CPNJ,
        required: true,
        grid: {
            col: 4,
        },
    },

    ...createPersonFields({
        withUser: true,
        withAdress: true,
        pathName: "responsible",
    }),
];

export const providersRoutes: IRoute[] = [
    {
        title: "Fornecedores",
        path: "/fornecedores",
        graphql: {
            companyPath: "company_id",
            tableName: "provider",
            orderBy: {
                name: "asc",
                person: {
                    name: "asc",
                },
            },
        },
        mainColumn: "id",
        component: CrudList,
        exact: true,
        canEdit: true,
        canSee: true,
        canDelete: true,
        canCreate: true,
        columns: [
            {
                col: "auto",
                name: "name",
                type: TableColumnType.Text,
            },
            {
                col: "auto",
                name: "juridic_document",
                type: TableColumnType.Text,
            },
            {
                col: "auto",
                name: "juridic_name",
                type: TableColumnType.Text,
            },
            {
                col: "auto",
                name: "person",
                type: TableColumnType.Person,
            },
        ],
        showTotal: true,
    },
    {
        title: "Novo fornecedor",
        path: "/fornecedores/novo",
        exact: true,
        component: ProviderCreateOrEdit,
    },
    {
        title: "Editar fornecedor",
        path: "/fornecedores/editar/:id",
        exact: true,
        component: ProviderCreateOrEdit,
        mainColumn: "id",
    },

    {
        title: "Visualizar fornecedor",
        path: "/fornecedores/ver/:id",
        exact: true,
        component: Provider,
    },
];
