import { put, takeLatest, call, all } from "redux-saga/effects";
import { ISagaAction } from "../../utils/interfaces/ISagaAction";
import { Creators, Types } from ".";
import { toast } from "react-toastify";
import history from "../../services/history";
import { ICreateOrEditProviderPayload, IGetProviderByIdPayload } from "./types";
import api from "services/api";
import { formatDate } from "helpers/formatDate";
import _pick from "lodash/pick";
import convertEnumToOptions from "helpers/convertEnumToOptions";
import { Genre } from "utils/enums/Genre";
import { IYield } from "utils/interfaces/IYield";

function* getDetails(id: number): IYield {
    const [{ data: details }, { data: stats }] = yield all([
        call(api.get, `/admin/providers/${id}`),
        // call(api.get, `/admin/providers/${id}/stats`),
    ]);

    console.log("stats", stats);

    if (!details?.id) {
        throw new Error();
    }

    const formatBody = {
        ...details,
        stats,
    };

    yield put(Creators.getProviderByIdSuccess(formatBody));
}

function* getByIdForm(id: number) {
    const { data } = yield call(api.get, `/admin/providers/${id}`);

    if (!data) {
        throw new Error();
    }

    const formatResponse = {
        ...data,
        responsible: {
            ...data.responsible,
            gender: convertEnumToOptions(Genre).find(
                ({ value }) => value === data?.responsible?.gender
            ),
            birthday: formatDate(data?.responsible?.birthday, "yyyy-LL-dd"),
            address: data.address,
        },
    };

    yield put(Creators.getProviderByIdSuccess(formatResponse));
}

function* getById(action: ISagaAction<IGetProviderByIdPayload>) {
    try {
        const { id, isDetailScreen = false } = action.payload;

        if (!id) {
            throw new Error();
        }

        const resolver = isDetailScreen ? getDetails : getByIdForm;

        yield resolver(id);
    } catch (error) {
        console.log("error", error);

        yield call(toast.error, "Desculpe, tivemos um problema.");

        yield put(Creators.getProviderByIdFailure());

        yield call(history.push, { pathname: "/app/fornecedores" });
    }
}

function* createOrEdit(action: ISagaAction<ICreateOrEditProviderPayload>) {
    try {
        const { id, body } = action.payload;

        const method = !!id ? api.patch : api.post;
        const endpoint = !!id ? `/admin/providers/${id}` : "/admin/providers";

        const formatBody = {
            ...body,
            company: 1,
            responsible: {
                ...body.responsible,
                gender: (body as any).responsible!.gender.value,
                birthday: formatDate(body.responsible?.birthday!),
            },
            address: body.responsible.address,
        };

        delete formatBody.responsible.address;

        console.log("formatBody", formatBody);

        const acceptedFields = [
            "name",
            "juridicName",
            "juridicDocument",
            "company",
            "responsible.name",
            "responsible.gender",
            "responsible.document",
            "responsible.birthday",
            "responsible.phone",
            "responsible.user.email",
            "address.zip",
            "address.street",
            "address.number",
            "address.complement",
            "address.neighbourhood",
            "address.city",
            "address.state",
            "address.latitude",
            "address.longitude",
        ];

        const { data } = yield call(
            method,
            endpoint,
            _pick(formatBody, acceptedFields)
        );

        if (!data) {
            throw new Error();
        }

        yield put(Creators.createOrEditProviderSuccess(data));

        yield call(history.push, { pathname: "/app/fornecedores" });
    } catch (error) {
        const msgs = (error as any).response?.data?.msgs;

        if (!!msgs?.length) {
            msgs.forEach((msg: string) => toast.error(msg));
        } else {
            yield call(toast.error, "Desculpe, tivemos um problema.");
        }

        yield put(Creators.createOrEditProviderFailure());
    }
}

export default [
    takeLatest(Types.GET_PROVIDER_BY_ID_REQUEST, getById),
    takeLatest(Types.CREATE_OR_EDIT_PROVIDER_REQUEST, createOrEdit),
];
