import React from "react";

import { ISpacing } from "../../utils/interfaces/ISpacing";

import { FormElement } from "./styles";

export interface IFormProps extends ISpacing {
    children: React.ReactNode;
    onSubmit(data: any): any;
    column?: any;
}

const Form = (props: IFormProps) => {
    const { children, onSubmit, column = true, ...rest } = props;

    return (
        <FormElement onSubmit={onSubmit} column={column.toString()} {...rest}>
            {children}
        </FormElement>
    );
};

export default Form;
