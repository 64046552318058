import React from "react";
import Portal from "../../../components/Portal";
import CreateStudentForm from "../../Student/form";

import ModalComponent from "../../../components/Modal/component";
import { theme } from "../../../config/theme";
import { IStudent } from "../../../store/student/types";
import { ISelectOption } from "../../../utils/interfaces/ISelectOption";

interface IProps {
    onClose(): void;
    onSubmit(student: IStudent): void;
    isActive: boolean;
    project: ISelectOption;
}

const ProjectCreateStudentModal: React.FC<IProps> = ({
    onClose,
    onSubmit,
    isActive,
    project,
}) => {
    return (
        <Portal portalId="create-student">
            {isActive ? (
                <ModalComponent
                    isOpen={isActive}
                    onClose={onClose}
                    title="Criar aluno"
                    contentBackground={theme.colors.background}
                >
                    <CreateStudentForm
                        projectId={project}
                        defaultValues={{
                            project: {
                                id: project?.value,
                                name: project?.label || "-",
                            },
                        }}
                        onSubmit={console.log}
                    />
                </ModalComponent>
            ) : null}
        </Portal>
    );
};

export default ProjectCreateStudentModal;
