import { Card } from "components/Card";
import { Col, Row } from "components/Grid";
import CustomText from "components/Text";
import { dictionary } from "config/dictionary";
import { formatAddressName } from "helpers/formatAddressName";
import { formatDate } from "helpers/formatDate";
import { useAppSelector } from "hooks/store";
import React from "react";
import { Genre } from "utils/enums/Genre";
import { ISelectOption } from "utils/interfaces/ISelectOption";

const ProfessionalDetails: React.FC = () => {
    const state = useAppSelector(({ professional }) => professional);
    const { person, documentProfessional, position } = state || {};

    const details: ISelectOption[][] = [
        [
            {
                label: "Nome",
                value: person?.name,
            },

            {
                label: "E-mail",
                value: person?.user?.email,
            },
            {
                label: "CRE",
                value: documentProfessional,
            },
            {
                label: "CPF",
                value: person?.document,
            },
            {
                label: "Nascimento",
                value: formatDate(person?.birthday),
            },
            {
                label: "Gênero",
                value: (dictionary as any)[Genre[person?.gender as any]],
            },
        ],
        [
            {
                label: "Cargo",
                value: position?.name,
            },
            {
                label: "Telefone",
                value: person?.phone,
            },

            {
                label: "Endereço",
                value: formatAddressName(person?.address),
            },
        ],
    ];

    return (
        <>
            <Card>
                <Row>
                    {details.map((items) => (
                        <Col md={6}>
                            {items.map(({ label, value }) => (
                                <CustomText
                                    color="base"
                                    size={18}
                                    bottom="10px"
                                >
                                    <strong>{label}</strong>: {value}
                                </CustomText>
                            ))}
                        </Col>
                    ))}
                </Row>
            </Card>
        </>
    );
};

export default ProfessionalDetails;
