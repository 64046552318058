export const createStudentSubscriptionQuery = `mutation ($body: subscription_insert_input!) {
    item: insert_subscription_one(object: $body) {
      id_subscription
      is_active
      created_at
      project_class {
        id_project_class
        name
      }
    }
  }`;
