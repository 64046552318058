import React, { forwardRef } from "react";
import { Props as SelectProps, components } from "react-select";

import { FormElementContainer, FormError, FormLabel } from "../styles";
import { theme } from "../../../config/theme";

import { ISelectOption } from "../../../utils/interfaces/ISelectOption";
import { ISizing } from "../../../utils/interfaces/ISizing";
import CrossIcon from "../../Icons/cross";
import { SelectElement } from "./styles";

export interface ISelectProps<T = any>
    extends SelectProps<ISelectOption>,
        ISizing {
    name: string;
    showError?: boolean;
    options: ISelectOption<T>[];
    label?: string;
    required?: boolean;
    isClearable?: boolean;
    error?: string;
}

const DropdownIndicator = (props: any) => {
    return (
        <components.DropdownIndicator {...props}>
            <CrossIcon width={12} height={12} fill={theme.colors.gray} />
        </components.DropdownIndicator>
    );
};

const Select = forwardRef(
    (
        {
            name,
            showError = true,
            required = false,
            label,
            isClearable = false,
            placeholder = "Selecione...",
            disabled,
            components,
            error,
            ...rest
        }: ISelectProps,
        ref
    ) => {
        return (
            <FormElementContainer>
                {!!label && (
                    <FormLabel htmlFor={name}>
                        {label}
                        {required && ` *`}
                    </FormLabel>
                )}
                <SelectElement
                    // defaultValue={defaultValue}
                    ref={ref}
                    classNamePrefix="react-select"
                    components={{ DropdownIndicator, ...components }}
                    isClearable={isClearable}
                    // onFocus={clearError}
                    error={!!error}
                    placeholder={placeholder}
                    isDisabled={!!disabled}
                    {...rest}
                />
                {!!error && showError && <FormError>{error}</FormError>}
            </FormElementContainer>
        );
    }
);

export default Select;
