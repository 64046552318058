import { Card } from "components/Card";
import { Col, Row } from "components/Grid";
import CustomText from "components/Text";
import { formatAddressName } from "helpers/formatAddressName";
import { useAppSelector } from "hooks/store";
import React from "react";

const ProviderTabDetails = () => {
    const { responsible, address, name, juridicName, juridicDocument } =
        useAppSelector(({ provider }) => provider!);

    return (
        <>
            <Card>
                <Row>
                    <Col md={6}>
                        <CustomText
                            color="base"
                            size={18}
                            right="auto"
                            bottom="8px"
                        >
                            <strong>Nome:</strong> {name}
                        </CustomText>

                        <CustomText
                            color="base"
                            size={18}
                            right="auto"
                            bottom="8px"
                        >
                            <strong>Razão Social:</strong> {juridicName}
                        </CustomText>

                        <CustomText
                            color="base"
                            size={18}
                            right="auto"
                            bottom="20px"
                        >
                            <strong>CNPJ:</strong> {juridicDocument}
                        </CustomText>

                        <CustomText
                            color="base"
                            weight="SemiBold"
                            size={22}
                            right="auto"
                            bottom="12px"
                        >
                            Responsável
                        </CustomText>

                        <CustomText
                            color="base"
                            size={18}
                            right="auto"
                            bottom="8px"
                        >
                            <strong>Nome:</strong> {responsible?.name}
                        </CustomText>
                        <CustomText
                            color="base"
                            size={18}
                            right="auto"
                            bottom="8px"
                        >
                            <strong>CPF:</strong> {responsible?.document}
                        </CustomText>

                        <CustomText
                            color="base"
                            size={18}
                            right="auto"
                            bottom="8px"
                        >
                            <strong>Telefone:</strong> {responsible?.phone}
                        </CustomText>
                    </Col>
                    <Col md={6}>
                        <CustomText
                            color="base"
                            weight="SemiBold"
                            size={22}
                            right="auto"
                            bottom="12px"
                        >
                            Endereço do fornecedor
                        </CustomText>

                        {!!address && (
                            <>
                                <CustomText
                                    color="base"
                                    size={18}
                                    bottom="20px"
                                >
                                    {formatAddressName({
                                        ...address,
                                        name,
                                    } as any)}
                                </CustomText>
                            </>
                        )}
                    </Col>
                </Row>
            </Card>
        </>
    );
};

export default ProviderTabDetails;
