import { put, takeLatest, call, select, all } from "redux-saga/effects";
import graphql from "../../services/graphql";
import { ISagaAction } from "../../utils/interfaces/ISagaAction";
import { Creators, Types } from ".";
import { toast } from "react-toastify";
import history from "../../services/history";
import { createStudentQuery } from "../../queries/students/create";
import {
    IStudent,
    IStudentBasicInformation,
    IStudentResponsible,
    IStudentSubscription,
} from "./types";
import { IReduxStore } from "../ducks";
import { Creators as ModalActions } from "../modal";
import { editStudentQuery } from "../../queries/students/edit";
import _omit from "lodash/omit";
import { createStudentSubscriptionQuery } from "../../queries/students/createSubscription";
import { editStudentSubscriptionQuery } from "../../queries/students/editSubscription";
import api from "services/api";

function* getStudentId() {
    return yield* select((state: IReduxStore) => state?.student?.id) as any;
}

function* createOrEdit(
    action: ISagaAction<{
        id?: number | string;
        body: any;
        onSubmit?(student: IStudent): void;
    }>
) {
    try {
        const { id, onSubmit } = action.payload;

        const { responsibles = [], subscriptions = [] } = yield* select(
            ({ student }: IReduxStore) => ({
                responsibles:
                    student?.responsibles?.map(
                        ({
                            id_responsible,
                            id_responsible_student,
                            responsible,
                        }) => ({
                            id_responsible:
                                id_responsible || responsible?.id_responsible,
                            id_responsible_student,
                        })
                    ) || [],
                subscriptions: student?.subscriptions?.map(
                    ({
                        id_subscription,
                        id_project_class,
                        is_active,
                        project_class,
                    }) => ({
                        id_subscription,
                        id_project_class:
                            id_project_class || project_class?.id_project_class,
                        is_active,
                    })
                ),
            })
        ) as any;

        const formatBody = yield* select(action.payload?.body, id) as any;

        const body = {
            ...formatBody?.body,
            responsibles: {
                data: responsibles,
            },
            subscriptions: {
                data: subscriptions,
            },
            id_student:
                formatBody?.body?.id_student >= 1
                    ? formatBody?.body?.id_student
                    : undefined,
        };

        const { data } = yield call(graphql, !!id ? "" : createStudentQuery, {
            body,
        });

        if (!data?.item) {
            throw new Error();
        }

        yield put(Creators.createOrEditStudentSuccess(data.item));

        if (onSubmit) {
            onSubmit(data.item);
        } else {
            yield call(toast.success, "Registro salvo!");
            yield call(history.push, { pathname: "/app/alunos" });
        }
    } catch (error) {
        console.log("error", error);

        yield call(toast.error, "Desculpe, tivemos um problema.");
        yield put(Creators.createOrEditStudentFailure());
    }
}

function* getFormDetails(id: number) {
    const { data } = yield call(
        graphql,
        `query ($id: Int!) {
            item: student_by_pk(id: $id) {
              id
              person {
                name
                document
                birthday
                phone
                file {
                    path
                    filename
                }
              }
            }
          }
        `,
        { id }
    );

    if (!data?.item?.id) {
        throw new Error();
    }

    yield put(Creators.getStudentByIdSuccess(data.item));
}

function* getDetails(id: number) {
    const [{ data: details }, { data: stats }] = yield all([
        call(
            graphql,
            `query ($id: Int!) {
                item: student_by_pk(id: $id) {
                  id
                  project {
                    name
                  }
                  person {
                    name
                    document
                    birthday
                    phone
                    file {
                      path
                      filename
                    }
                  }
                  responsible {
                    responsible {
                      person {
                        name
                        document
                        phone
                      }
                    }
                  }
                }
              }
              `,
            { id }
        ),
        call(api.get, `/admin/students/${id}/stats`),
    ]);

    console.log("stats", stats);

    if (!details?.item?.id) {
        throw new Error();
    }

    const formatBody = {
        ...details?.item,
        stats,
    };

    yield put(Creators.getStudentByIdSuccess(formatBody));
}

function* getById(
    action: ISagaAction<{
        id: number | string;
        isDetailScreen?: boolean;
    }>
) {
    try {
        const { id, isDetailScreen = false } = action.payload || {};

        if (!id) {
            throw new Error();
        }

        const resolver = isDetailScreen ? getDetails : getFormDetails;

        yield resolver(~~id);
    } catch (error) {
        console.log("error", error);

        yield call(toast.error, "Desculpe, tivemos um problema.");

        yield put(Creators.getStudentByIdFailure());

        yield call(history.push, { pathname: "/app/alunos" });
    }
}

function* createResponsible(action: ISagaAction<IStudentResponsible>) {
    try {
        if (!action?.payload) {
            throw new Error();
        }

        const studentId = yield* getStudentId();

        if (!studentId) {
            yield put(ModalActions.closeModal());

            yield* put(
                Creators.createStudentResponsibleSuccess(action?.payload)
            ) as any;

            return;
        }

        const { data } = yield call(
            graphql,
            `mutation ($body: responsible_student_insert_input!) {
                item: insert_responsible_student_one(object: $body) {
                  id_responsible
                  id_responsible_student
                  responsible {
                    id_responsible
                    person {
                      id_person
                      name
                      user {
                        id_user
                        email
                      }
                    }
                  }
                }
              }
              `,
            {
                body: {
                    id_student: studentId,
                    id_responsible:
                        action?.payload?.id_responsible ??
                        action?.payload?.responsible?.id_responsible,
                },
            }
        );

        if (!data?.item) {
            throw new Error();
        }

        yield put(Creators.createStudentResponsibleSuccess(data?.item));

        yield call(toast.success, "Responsável adicionado com sucesso.");
        yield put(ModalActions.closeModal());
    } catch (error) {
        console.log("error", error);

        yield call(toast.error, "Desculpe, tivemos um problema.");

        yield put(Creators.createStudentResponsibleFailure());
    }
}

function* deleteResponsible(action: ISagaAction<IStudentResponsible>) {
    try {
        const { responsible, id_responsible } = action?.payload;

        const studentId = yield* getStudentId();

        if (!studentId) {
            yield put(ModalActions.closeModal());

            yield put(
                Creators.deleteStudentResponsibleSuccess(action?.payload)
            );

            return;
        }

        const { data } = yield call(
            graphql,
            `mutation ($idStudent: Int!, $idResponsible: Int!) {
                item: delete_responsible_student(where: {id_student: {_eq: $idStudent}, id_responsible: {_eq: $idResponsible}}) {
                  affected_rows
                }
              }`,
            {
                idStudent: studentId,
                idResponsible: id_responsible || responsible?.id_responsible,
            }
        );

        if (!data?.item?.affected_rows) {
            throw new Error();
        }

        yield put(Creators.deleteStudentResponsibleSuccess(action?.payload));

        yield call(toast.success, "Responsável excluído com sucesso.");

        yield put(ModalActions.closeModal());
    } catch (error) {
        console.log("error", error);

        yield call(toast.error, "Desculpe, tivemos um problema.");

        yield put(Creators.deleteStudentResponsibleFailure());
    }
}

function* setBasicInformation(action: ISagaAction<IStudentBasicInformation>) {
    try {
        const studentId = yield* getStudentId();

        if (!studentId) {
            yield put(
                Creators.setStudentBasicInformationSuccess(action.payload)
            );

            return;
        }

        const tempBody = { ...action?.payload };

        const person: any = {
            ...tempBody?.person,
            birthday: tempBody?.person?.birthday || null,
        };

        const student = {
            id_project: tempBody.project?.id_project ?? tempBody.id_project,
            id_person: person?.id_person,
            ..._omit(tempBody, ["project", "person"]),
        };

        const { data } = yield call(graphql, editStudentQuery, {
            id: studentId,
            body: student,
            person,
        });

        if (!data?.item) {
            throw new Error();
        }

        yield put(
            Creators.setStudentBasicInformationSuccess({
                ...data?.item,
                person: data?.person,
            })
        );

        yield call(toast.success, "Informações básicas salvas com sucesso.");
    } catch (error) {
        console.log("error", error);

        yield call(toast.error, "Desculpe, tivemos um problema.");

        yield put(Creators.setStudentBasicInformationFailure());
    }
}

function* createOrEditSubscription(action: ISagaAction<IStudentSubscription>) {
    try {
        const studentId = yield* getStudentId();

        if (!studentId) {
            yield put(ModalActions.closeModal());

            yield put(
                Creators.createOrEditStudentSubscriptionSuccess(action.payload)
            );

            return;
        }

        const idProjectClass =
            action?.payload?.id_project_class ||
            action?.payload?.project_class?.id_project_class;

        const idSubscription = action?.payload?.id_subscription;

        const { data } = yield call(
            graphql,
            !!idSubscription
                ? editStudentSubscriptionQuery
                : createStudentSubscriptionQuery,
            {
                ...(!!idSubscription && { id: idSubscription }),
                body: {
                    id_subscription: idSubscription,
                    id_project_class: idProjectClass,
                    is_active: !!action?.payload?.is_active,
                    id_student: studentId,
                },
            }
        );

        if (!data?.item) {
            throw new Error();
        }

        yield put(
            Creators.createOrEditStudentSubscriptionSuccess({
                ...data?.item,
                person: data?.person,
            })
        );

        yield call(toast.success, "Aluno salvo com sucesso.");

        yield put(ModalActions.closeModal());
    } catch (error) {
        console.log("error", error);

        yield call(toast.error, "Desculpe, tivemos um problema.");

        yield put(Creators.createOrEditStudentSubscriptionFailure());
    }
}

function* deleteSubscription(action: ISagaAction<IStudentSubscription>) {
    try {
        const { project_class, id_project_class } = action?.payload;

        const studentId = yield* getStudentId();

        if (!studentId) {
            yield put(ModalActions.closeModal());

            yield put(
                Creators.deleteStudentSubscriptionSuccess(action?.payload)
            );

            return;
        }

        const idProjectClass =
            project_class?.id_project_class || id_project_class;

        const { data } = yield call(
            graphql,
            `mutation ($idStudent: Int!, $idProjectClass: Int!) {
                item: delete_subscription(where: {id_student: {_eq: $idStudent}, id_project_class: {_eq: $idProjectClass}}) {
                  affected_rows
                }
              }
              `,
            {
                idStudent: studentId,
                idProjectClass: idProjectClass,
            }
        );

        if (!data?.item?.affected_rows) {
            throw new Error();
        }

        yield put(Creators.deleteStudentSubscriptionSuccess(action?.payload));

        yield call(toast.success, "Responsável excluído com sucesso.");

        yield put(ModalActions.closeModal());
    } catch (error) {
        console.log("error", error);

        yield call(toast.error, "Desculpe, tivemos um problema.");

        yield put(Creators.deleteStudentSubscriptionFailure());
    }
}

export default [
    takeLatest(Types.CREATE_OR_EDIT_STUDENT_REQUEST, createOrEdit),
    takeLatest(Types.GET_STUDENT_BY_ID_REQUEST, getById),
    takeLatest(Types.CREATE_STUDENT_RESPONSIBLE_REQUEST, createResponsible),
    takeLatest(Types.DELETE_STUDENT_RESPONSIBLE_REQUEST, deleteResponsible),
    takeLatest(
        Types.SET_STUDENT_BASIC_INFORMATION_REQUEST,
        setBasicInformation
    ),

    takeLatest(
        Types.CREATE_OR_EDIT_STUDENT_SUBSCRIPTION_REQUEST,
        createOrEditSubscription
    ),

    takeLatest(Types.DELETE_STUDENT_SUBSCRIPTION_REQUEST, deleteSubscription),
];
