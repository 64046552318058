import React, { useCallback, useEffect, useMemo } from "react";

import ListTabData, { IListTabDataConfig } from "../../components/ListTabData";
import { TableColumnType } from "../../utils/enums/TableColumnType";
import { IBaseRouteProps } from "../../utils/interfaces/IBaseRouteProps";
import { InputType } from "../../utils/enums/InputType";
import ListCards, { IListCardsConfig } from "components/ListCards";
import ProviderTabDetails from "./tabs/details";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { Creators } from "store/provider";
import Spinner from "components/Spinner";
import { useHeaderTitle } from "hooks/useHeaderTitle";
import ProviderOrders from "./tabs/orders";

interface IProps extends IBaseRouteProps {}

const ProviderDetails = ({ match: { params } }: IProps) => {
    const { id } = params;

    const {
        id: providerId,
        loading,
        name,
    } = useAppSelector(({ provider }) => provider!);
    const dispatch = useAppDispatch();

    useHeaderTitle(name);

    useEffect(() => {
        return () => {
            dispatch(Creators.clearProvider());
        };
    }, [dispatch]);

    const request = useCallback(async () => {
        if (!id) {
            return;
        }

        dispatch(
            Creators.getProviderByIdRequest({ id, isDetailScreen: false })
        );
    }, [id, dispatch]);

    useEffect(() => {
        request();
    }, [request]);

    const listConfig = useMemo<Array<IListTabDataConfig>>(() => {
        if (!providerId) {
            return [];
        }

        return [
            {
                title: "Detalhes",
                component: <ProviderTabDetails />,
                columns: [],
            },
            {
                contentId: "product_order",
                title: "Pedidos",
                canCreate: false,
                canDelete: false,
                columns: [],
                component: <ProviderOrders />,
            },
            {
                contentId: "person",
                title: "Usuários",
                canCreate: false,
                canDelete: false,
                canSee: false,
                showDeleted: true,
                columns: [
                    {
                        type: TableColumnType.Text,
                        name: "name",
                        col: "auto",
                    },
                    {
                        type: TableColumnType.Text,
                        name: "user.email",
                        col: "auto",
                        label: "E-mail",
                    },
                    {
                        type: TableColumnType.Text,
                        name: "document",
                        col: "auto",
                    },
                    {
                        type: TableColumnType.Status,
                        name: "status",
                        col: 2,
                    },
                ],
                graphql: {
                    tableName: "person",
                },
                mainColumn: "id",
                defaultSearch: {
                    provider_id: {
                        _eq: providerId,
                    },
                },
            },
        ];
    }, [providerId]);

    const listCardsConfig = useMemo<IListCardsConfig>(
        () => ({
            cards: [
                {
                    name: "name",
                    type: InputType.Text,
                    grid: {
                        md: 4,
                    },
                },
                {
                    name: "juridic_name",
                    type: InputType.Text,
                    grid: {
                        md: 4,
                    },
                },
                {
                    name: "juridic_document",
                    type: InputType.Text,
                    grid: {
                        md: 4,
                    },
                },
            ],
            graphQL: {
                tableName: "provider",
                returnPath: "item",
                primaryKey: id,
            },
        }),
        [id]
    );

    return (
        <>
            {loading && <Spinner fullScreen />}
            <ListCards {...listCardsConfig} />

            {!!providerId && <ListTabData configs={listConfig} />}
        </>
    );
};

export default ProviderDetails;
