// sampleReducer.js
import { createReducer, createActions } from "reduxsauce";
import {
    StudentTypes,
    IStudentState,
    IStudentActionsCreators,
    IStudentSubscription,
    IStudentResponsible,
} from "./types";

export const { Creators, Types } = createActions<
    Record<StudentTypes, StudentTypes>,
    IStudentActionsCreators
>({
    createOrEditStudentRequest: ["payload"],
    createOrEditStudentSuccess: ["payload"],
    createOrEditStudentFailure: [],

    getStudentByIdRequest: ["payload"],
    getStudentByIdSuccess: ["payload"],
    getStudentByIdFailure: [],

    setStudentBasicInformationRequest: ["payload"],
    setStudentBasicInformationSuccess: ["payload"],
    setStudentBasicInformationFailure: ["payload"],

    createStudentResponsibleRequest: ["payload"],
    createStudentResponsibleSuccess: ["payload"],
    createStudentResponsibleFailure: ["payload"],

    deleteStudentResponsibleRequest: ["payload"],
    deleteStudentResponsibleSuccess: ["payload"],
    deleteStudentResponsibleFailure: ["payload"],

    createOrEditStudentSubscriptionRequest: ["payload"],
    createOrEditStudentSubscriptionSuccess: ["payload"],
    createOrEditStudentSubscriptionFailure: ["payload"],

    deleteStudentSubscriptionRequest: ["payload"],
    deleteStudentSubscriptionSuccess: ["payload"],
    deleteStudentSubscriptionFailure: ["payload"],

    setStudentDefaultValues: ["payload"],

    clearStudent: [],
});

const INITIAL_STATE: IStudentState = {
    error: false,
    loading: false,
    id: 0,
    responsibles: [],
};

const request = (state = INITIAL_STATE, _: any) => {
    return { ...state, error: false, loading: true };
};

const failure = (state = INITIAL_STATE) => {
    return { ...state, error: true, loading: false };
};

const setState = (state = INITIAL_STATE, action: any) => ({
    ...state,
    loading: false,
    error: false,
    ...action.payload,
});

const clear = () => INITIAL_STATE;

const createResponsible = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        loading: false,
        error: false,
        responsibles: [...(state?.responsibles || []), action?.payload],
    };
};

const deleteResponsible = (state = INITIAL_STATE, action: any) => {
    const payload = action?.payload as IStudentResponsible | undefined;
    const idStudentResponsible = payload?.id_responsible_student;
    const idResponsible =
        payload?.id_responsible || payload?.responsible?.id_responsible;

    const updateResponsibles =
        state?.responsibles?.filter((item) => {
            if (!!idStudentResponsible) {
                return item.id_responsible_student !== idStudentResponsible;
            }

            const responsible =
                item?.id_responsible || item?.responsible?.id_responsible;

            return responsible !== idResponsible;
        }) || [];

    return {
        ...state,
        loading: false,
        error: false,
        responsibles: updateResponsibles,
    };
};

const stopLoading = (state = INITIAL_STATE) => {
    return { ...state, loading: false };
};

const createOrEditSubscription = (state = INITIAL_STATE, action: any) => {
    const payload = action?.payload as IStudentSubscription | undefined;

    const idProjectClass =
        payload?.id_project_class ?? payload?.project_class?.id_project_class;

    const haveThisSubscription = !!state?.subscriptions?.some(
        (item) =>
            item.id_project_class === idProjectClass ||
            item.project_class?.id_project_class === idProjectClass
    );

    const updateSubscriptions = haveThisSubscription
        ? state.subscriptions?.map((item) =>
              item.id_project_class === idProjectClass ||
              item.project_class?.id_project_class === idProjectClass
                  ? action?.payload
                  : item
          )
        : [...(state?.subscriptions || []), action?.payload];

    return {
        ...state,
        loading: false,
        error: false,
        subscriptions: updateSubscriptions,
    };
};

const deleteSubscription = (state = INITIAL_STATE, action: any) => {
    const payload = action?.payload as IStudentSubscription | undefined;

    const idSubscription = action?.payload?.id_subscription;
    const idProjectClass =
        payload?.id_project_class ?? payload?.project_class?.id_project_class;

    const updateSubscriptions =
        state?.subscriptions?.filter((item) => {
            if (!!idSubscription) {
                return item?.id_subscription !== idSubscription;
            }

            const projectClass =
                item?.id_project_class || item?.project_class?.id_project_class;

            return projectClass !== idProjectClass;
        }) || [];

    return {
        ...state,
        loading: false,
        error: false,
        subscriptions: updateSubscriptions,
    };
};

const setDefaultValues = (state = INITIAL_STATE, action: any) => {
    return { ...state, loading: false, ...action.payload };
};

const HANDLERS = {
    [Types.CREATE_OR_EDIT_STUDENT_REQUEST]: request,
    [Types.CREATE_OR_EDIT_STUDENT_SUCCESS]: setState,
    [Types.CREATE_OR_EDIT_STUDENT_FAILURE]: failure,

    [Types.GET_STUDENT_BY_ID_REQUEST]: request,
    [Types.GET_STUDENT_BY_ID_SUCCESS]: setState,
    [Types.GET_STUDENT_BY_ID_FAILURE]: failure,

    [Types.CLEAR_STUDENT]: clear,

    [Types.CREATE_STUDENT_RESPONSIBLE_REQUEST]: request,
    [Types.CREATE_STUDENT_RESPONSIBLE_SUCCESS]: createResponsible,
    [Types.CREATE_STUDENT_RESPONSIBLE_FAILURE]: stopLoading,

    [Types.DELETE_STUDENT_RESPONSIBLE_REQUEST]: request,
    [Types.DELETE_STUDENT_RESPONSIBLE_SUCCESS]: deleteResponsible,
    [Types.DELETE_STUDENT_RESPONSIBLE_FAILURE]: stopLoading,

    [Types.SET_STUDENT_BASIC_INFORMATION_REQUEST]: request,
    [Types.SET_STUDENT_BASIC_INFORMATION_SUCCESS]: setState,
    [Types.SET_STUDENT_BASIC_INFORMATION_FAILURE]: stopLoading,

    [Types.CREATE_OR_EDIT_STUDENT_SUBSCRIPTION_REQUEST]: request,
    [Types.CREATE_OR_EDIT_STUDENT_SUBSCRIPTION_SUCCESS]:
        createOrEditSubscription,
    [Types.CREATE_OR_EDIT_STUDENT_SUBSCRIPTION_FAILURE]: stopLoading,

    [Types.DELETE_STUDENT_SUBSCRIPTION_REQUEST]: request,
    [Types.DELETE_STUDENT_SUBSCRIPTION_SUCCESS]: deleteSubscription,
    [Types.DELETE_STUDENT_SUBSCRIPTION_FAILURE]: stopLoading,

    [Types.SET_STUDENT_DEFAULT_VALUES]: setDefaultValues,
};

export default createReducer(INITIAL_STATE, HANDLERS);
