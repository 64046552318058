import React, { memo } from "react";
import { theme } from "../../config/theme";
import { IIconProps } from "../../utils/interfaces/IIconProps";

const UsersIcon = memo(
    ({ fill = theme.colors.black, ...props }: IIconProps) => {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                {...props}
                viewBox="0 0 13.333 12"
            >
                <path
                    d="M8.666,12A.667.667,0,0,1,8,11.333a3.333,3.333,0,1,0-6.667,0,.667.667,0,1,1-1.333,0,4.664,4.664,0,0,1,7.96-3.3,3.333,3.333,0,0,1,5.373,2.636.667.667,0,0,1-1.333,0A2,2,0,0,0,8.762,9.1a4.669,4.669,0,0,1,.571,2.234A.668.668,0,0,1,8.666,12ZM10,6.667a2,2,0,1,1,2-2A2,2,0,0,1,10,6.667ZM10,4a.667.667,0,1,0,.667.667A.667.667,0,0,0,10,4ZM4.667,5.333A2.667,2.667,0,1,1,7.333,2.666,2.67,2.67,0,0,1,4.667,5.333Zm0-4A1.334,1.334,0,1,0,6,2.666,1.335,1.335,0,0,0,4.667,1.333Z"
                    fill={fill}
                />
            </svg>
        );
    }
);

export default UsersIcon;
