import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { toast } from "react-toastify";
import {
    IStudent,
    IStudentActionsCreators,
    IStudentState,
} from "../../store/student/types";
import { IReduxStore } from "../../store/ducks";
import StudentBasicInfos from "./steps/BasicInfos";
import Responsible from "./steps/Responsible";
import StudentSubscription from "./steps/Subscription";

import { Creators as StudentActions } from "../../store/student";

import * as S from "./styles";
import Wizard from "./wizard";
import Spinner from "../../components/Spinner";
import _omit from "lodash/omit";
import _merge from "lodash/merge";
import { ISelectOption } from "../../utils/interfaces/ISelectOption";

enum Steps {
    Responsible,
    BasicInfos,
    Subscriptions,
}

interface IProps {
    studentActions: IStudentActionsCreators;
    student?: IStudentState;
    match?: {
        params?: {
            id?: string;
        };
    };
    defaultValues?: IStudent;
    onSubmit?(student: IStudent): void;
    projectId?: ISelectOption;
}

const CreateStudentForm: React.FC<IProps> = ({
    studentActions,
    student,
    match,
    defaultValues,
    onSubmit,
    projectId,
}) => {
    const [errorResponsible, setErrorResponsible] = useState(false);
    const [errorBasicInfos, setErrorBasicInfos] = useState(false);
    const [activeTab, setActiveTab] = useState<Steps>(Steps.Responsible);

    const { id = undefined } = match?.params || {};
    const { responsibles = [], subscriptions = [] } = student || {};

    const basicInfos = useMemo(() => {
        const tempStudent = { ...student };

        return _omit(tempStudent, [
            "responsibles",
            "error",
            "loading",
            "subscriptions",
        ]);
    }, [student]);

    useEffect(() => {
        if (!!defaultValues) {
            studentActions.setStudentDefaultValues(defaultValues);
        }
    }, [defaultValues, studentActions]);

    useEffect(() => {
        return () => {
            studentActions.clearStudent();
        };
    }, [studentActions]);

    const requestStudent = useCallback(() => {
        if (!id) {
            return;
        }

        studentActions.getStudentByIdRequest({ id });
    }, [id, studentActions]);

    useEffect(() => requestStudent(), [requestStudent]);

    const handleSubmitBasicInfos = useCallback(
        (data: any) => {
            if (!student?.id) {
                setActiveTab(Steps.Subscriptions);
            }

            const tempBasicInfos = { ...basicInfos };

            const body = _merge(tempBasicInfos, data);

            studentActions.setStudentBasicInformationRequest(body);
        },
        [basicInfos, student, studentActions]
    );

    const handleSubmit = useCallback(() => {
        let error = false;

        if (!responsibles?.length) {
            error = true;

            toast.error("Insira pelo menos um responsável.");
        }

        if (!basicInfos) {
            error = true;
            toast.error("Informações básicas do aluno faltando.");
        }

        setErrorResponsible(!responsibles?.length);
        setErrorBasicInfos(!basicInfos);

        if (error) {
            return;
        }

        studentActions.createOrEditStudentRequest({
            ...(!!id && { id }),
            body: basicInfos,
            onSubmit,
        });
    }, [basicInfos, id, onSubmit, responsibles, studentActions]);

    const handleClickWizard = useCallback((step: Steps) => {
        // if (!student?.id_student) {
        //     return;
        // }

        setActiveTab(step);
    }, []);

    return (
        <>
            {!!student?.loading && <Spinner fullScreen={true} />}

            <S.StudentContainer>
                <Wizard
                    steps={[
                        {
                            active:
                                activeTab === Steps.Responsible ||
                                !!student?.id,
                            title: "Responsáveis",
                            component: (
                                <Responsible
                                    onSubmit={() =>
                                        setActiveTab(Steps.BasicInfos)
                                    }
                                    projectId={projectId?.value}
                                />
                            ),
                            done: !!responsibles?.length,
                            onClick: () => handleClickWizard(Steps.Responsible),
                            error: errorResponsible,
                        },

                        {
                            active:
                                activeTab === Steps.BasicInfos || !!student?.id,
                            title: "Informações Básicas",
                            component: (
                                <StudentBasicInfos
                                    onSubmit={handleSubmitBasicInfos}
                                    initialData={basicInfos}
                                    isEdit={!!student?.id}
                                    projectId={projectId}
                                />
                            ),
                            done: !!student?.person,
                            onClick: () => handleClickWizard(Steps.BasicInfos),
                            error: errorBasicInfos,
                        },
                        {
                            active:
                                activeTab === Steps.Subscriptions ||
                                !!student?.id ||
                                !!subscriptions?.length,
                            title: "Núcleo(s)",
                            component: (
                                <StudentSubscription
                                    onSubmit={handleSubmit}
                                    projectId={projectId?.value}
                                />
                            ),
                            done: !!subscriptions?.length,
                            onClick: () =>
                                handleClickWizard(Steps.Subscriptions),
                        },
                    ]}
                />
            </S.StudentContainer>
        </>
    );
};

const mapStateToProps = ({ student }: IReduxStore) => ({
    student,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    studentActions: bindActionCreators(StudentActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateStudentForm);
