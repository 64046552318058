import styled from "styled-components/macro";
import { ListDataContainer } from "../../components/ListData/styles";

export const StudentContainer = styled.div`
    display: flex;
`;

export const StudentTabContainer = styled.div`
    display: flex;
    width: 100%;
    max-width: 100%;
    position: relative;

    .content-enter {
        opacity: 0;
    }
    .content-enter-active {
        opacity: 1;
    }
    .content-exit {
        opacity: 0;
    }
    .content-exit-active {
        opacity: 0;
    }
`;

export const StudentStepContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    ${ListDataContainer} {
        margin-bottom: 20px;
    }
`;
