import React, { useCallback } from "react";
import { number, object } from "yup";
import { HorizontalDiv, VerticalDiv } from "../../../../assets/globalStyles";
import Button from "../../../../components/Button";
import Empty from "../../../../components/Empty";
import DeleteIcon from "../../../../components/Icons/delete";
import PlusIcon from "../../../../components/Icons/plus";
import SearchIcon from "../../../../components/Icons/search";
import { IListDataProps } from "../../../../components/ListData";
import Person from "../../../../components/Person";
import CustomText from "../../../../components/Text";
import { dictionary } from "../../../../config/dictionary";
import { theme } from "../../../../config/theme";
import pluralize from "../../../../helpers/pluralize";
import { useCrud } from "../../../../hooks/useCrud";
import { useModal } from "../../../../hooks/useModal";
import { searchProjectsQuery } from "../../../../queries/projects/selectSearch";
import { createResponsibleQuery } from "../../../../queries/responsibles/create";
import { responsiblesColumns } from "../../../../routes/entities/responsibles";
import {
    IStudentActionsCreators,
    IStudentResponsible,
} from "../../../../store/student/types";
import { InputType } from "../../../../utils/enums/InputType";
import { ModalElements } from "../../../../utils/enums/ModalElements";
import { createPersonFields } from "../../../../utils/fields/person";
import { IResponsible } from "../../../../utils/interfaces/IResponsible";
import { personSchema } from "../../../../utils/schemas/person";
import * as S from "./styles";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IReduxStore } from "../../../../store/ducks";
import { Creators as StudentActions } from "../../../../store/student";

interface IProps {
    responsibles: Array<IStudentResponsible>;
    studentActions: IStudentActionsCreators;
    isEdit: boolean;
    onSubmit(): void;
    projectId?: number;
}

const StudentResponsible: React.FC<IProps> = ({
    responsibles,
    studentActions,
    isEdit,
    onSubmit,
    projectId,
}) => {
    const modal = useModal();
    const crud = useCrud();

    const handleClickRemove = useCallback(
        (responsible: IStudentResponsible) => {
            if (isEdit) {
                modal.open({
                    element: ModalElements.ConfirmAction,
                    title: undefined,
                    width: "500px",
                    action: {
                        message: dictionary.phrases.confirmDelete,
                        onConfirm: () =>
                            studentActions.deleteStudentResponsibleRequest(
                                responsible
                            ),
                    },
                    center: true,
                });

                return;
            }

            studentActions.deleteStudentResponsibleRequest(responsible);
        },
        [isEdit, modal, studentActions]
    );

    const handleAdd = useCallback(
        (responsible: IResponsible) => {
            const isSelected = responsibles?.some(
                (responsibleItem) =>
                    responsibleItem.id_responsible ===
                        responsible.id_responsible ||
                    responsibleItem?.responsible?.id_responsible ===
                        responsible.id_responsible
            );

            if (isSelected) {
                handleClickRemove({ responsible });
            } else {
                studentActions.createStudentResponsibleRequest({
                    responsible,
                });
            }
        },
        [handleClickRemove, responsibles, studentActions]
    );

    const handleClickCreate = useCallback(() => {
        modal.open({
            element: ModalElements.GenericForm,
            title: "Criar responsável",
            width: undefined,

            action: {
                fields: [
                    ...createPersonFields(),
                    {
                        name: "id_project",
                        type: InputType.AsyncSelect,
                        label: "Núcleo",
                        required: true,
                        grid: {
                            col: 4,
                        },
                        graphQL: {
                            searchQuery: searchProjectsQuery,
                        },
                    },
                ],
                onSubmit: (data: any) =>
                    crud.createOrEdit({
                        body: data,
                        query: createResponsibleQuery,
                        onCreate: handleAdd,
                    }),
                schema: object().shape({
                    person: personSchema(),
                    id_project: number()
                        .typeError(dictionary.validations.required)
                        .required(dictionary.validations.required),
                }),
            },
        });
    }, [crud, handleAdd, modal]);

    const handleClickSearch = useCallback(() => {
        const action: IListDataProps = {
            contentId: `responsible_search`,
            columns: responsiblesColumns,
            graphql: {
                tableName: "responsible",
            },
            mainColumn: "id_responsible",
            customActions: [
                {
                    icon: ({ id_responsible }) => {
                        const isSelected = responsibles?.some(
                            (item) =>
                                item.id_responsible === id_responsible ||
                                item.responsible?.id_responsible ===
                                    id_responsible
                        );

                        return isSelected ? (
                            <DeleteIcon
                                width={12}
                                height={12}
                                fill={theme.colors.danger}
                            />
                        ) : (
                            <PlusIcon
                                width={12}
                                height={12}
                                fill={theme.colors.success}
                            />
                        );
                    },
                    onClick: (item) => handleAdd(item),
                },
            ],

            ...(!!projectId && {
                defaultSearch: {
                    id_project: {
                        _eq: projectId,
                    },
                },
            }),
        };

        console.log("action", action);

        modal.open({
            element: ModalElements.List,
            action,
            width: undefined,
            title: `Buscar responsável`,
        });
    }, [handleAdd, modal, projectId, responsibles]);

    const handleClickAdd = useCallback(() => {
        modal.open({
            title: "Adicionar responsável",
            element: ModalElements.Actions,
            action: [
                {
                    text: "Buscar",
                    icon: <SearchIcon width={12} height={12} fill="#FFF" />,
                    onClick: handleClickSearch,
                },
                {
                    text: "Criar",
                    icon: <PlusIcon width={12} height={12} fill="#FFF" />,
                    onClick: handleClickCreate,
                    variant: "success",
                },
            ],
            width: "450px",
        });
    }, [handleClickCreate, handleClickSearch, modal]);

    return (
        <VerticalDiv>
            {!responsibles?.length ? (
                <Empty
                    text="Nenhum responsável adicionado ainda"
                    onClick={handleClickAdd}
                    padding={{ all: "20px" }}
                />
            ) : (
                <>
                    <S.StudentResponsibleList>
                        <HorizontalDiv bottom="20px">
                            <CustomText color="black" size={16} right="15px">
                                {responsibles.length}{" "}
                                {pluralize(
                                    responsibles?.length,
                                    "selecionado",
                                    "selecionados"
                                )}
                            </CustomText>

                            <Button
                                variant="main"
                                type="button"
                                onClick={handleClickAdd}
                                padding={{ all: "8px" }}
                            >
                                <PlusIcon fill="#FFF" width={12} height={12} />
                            </Button>
                        </HorizontalDiv>

                        {responsibles.map((item) => (
                            <Person
                                key={item.id_responsible}
                                email={item?.responsible?.person?.user?.email}
                                name={item?.responsible?.person?.name}
                                onClickRemove={() => handleClickRemove(item)}
                            />
                        ))}
                    </S.StudentResponsibleList>

                    {!isEdit && (
                        <Button
                            variant="main"
                            type="button"
                            onClick={onSubmit}
                            right="auto"
                        >
                            Próximo
                        </Button>
                    )}
                </>
            )}
        </VerticalDiv>
    );
};

const mapStateToProps = ({ student }: IReduxStore) => ({
    responsibles: student?.responsibles || [],
    isEdit: !!student?.id,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    studentActions: bindActionCreators(StudentActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentResponsible);
