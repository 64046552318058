import { ButtonElement } from "components/Button/styles";
import styled from "styled-components";

export const SelectCompanyContainer = styled.div`
    display: flex;
    flex-direction: column;

    ${ButtonElement} {
        padding: 15px 20px;

        > span {
            flex: 1;
            margin-right: 5px;
        }

        :not(:last-child) {
            margin-bottom: 10px;
        }
    }
`;
