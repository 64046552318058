import ListCards, { IListCardsConfig } from "components/ListCards";
import ListData, { IListDataProps } from "components/ListData";
import { useAppSelector } from "hooks/store";
import React, { useMemo } from "react";
import { InputType } from "utils/enums/InputType";
import { ProductOrderStatus } from "utils/enums/ProductOrderStatus";
import { TableColumnType } from "utils/enums/TableColumnType";

const ProviderOrders = () => {
    const { id } = useAppSelector(({ provider }) => provider!);

    const listCardsConfig = useMemo<IListCardsConfig>(
        () => ({
            cards: [
                {
                    name: "product_orders_aggregate.aggregate.count",
                    type: InputType.Number,
                    grid: {
                        md: 3,
                    },
                    label: "Pedidos",
                },
                {
                    name: "sentOrders.aggregate.count",
                    type: InputType.Number,
                    grid: {
                        md: 3,
                    },
                    label: "Pedidos enviados",
                },
                {
                    name: "receivedOrders.aggregate.count",
                    type: InputType.Number,
                    grid: {
                        md: 3,
                    },
                    label: "Pedidos recebidos",
                },
                {
                    name: "finishedOrders.aggregate.count",
                    type: InputType.Number,
                    grid: {
                        md: 3,
                    },
                    label: "Pedidos finalizados",
                },
            ],
            graphQL: {
                tableName: "provider",
                returnPath: "item",
                primaryKey: id,
                query: `query ($id: Int!) {
                    item: provider_by_pk(id: $id) {
                      product_orders_aggregate {
                        aggregate {
                          count
                        }
                      }
                      finishedOrders: product_orders_aggregate(where: {product_order_status_id: {_eq: ${ProductOrderStatus.Concluded}}}) {
                        aggregate {
                          count
                        }
                      }
                      sentOrders: product_orders_aggregate(where: {product_order_status_id: {_eq: ${ProductOrderStatus.Sent}}}) {
                        aggregate {
                          count
                        }
                      }
                      receivedOrders: product_orders_aggregate(where: {product_order_status_id: {_eq: ${ProductOrderStatus.Received}}}) {
                        aggregate {
                          count
                        }
                      }
                    }
                  }
                  `,
            },
        }),
        [id]
    );

    const listDataConfig = useMemo<IListDataProps>(
        () => ({
            contentId: "product_order",
            title: "Pedidos",
            canCreate: false,
            canDelete: false,
            canSee: true,
            columns: [
                {
                    col: "auto",
                    name: "number",
                    type: TableColumnType.Text,
                    label: "Código",
                },
                {
                    col: "auto",
                    name: "professional.person",
                    type: TableColumnType.Person,
                    label: "Profissional",
                },
                {
                    col: "auto",
                    name: "project.name",
                    type: TableColumnType.Text,
                },
                {
                    col: 2,
                    name: "product_order_status_id",
                    type: TableColumnType.Enum,
                    enumValue: ProductOrderStatus,
                    label: "Status",
                },
                {
                    col: "auto",
                    name: "created_at",
                    type: TableColumnType.Date,
                },
            ],
            graphql: {
                tableName: "product_order",
            },
            mainColumn: "id",
            defaultSearch: {
                provider_id: {
                    _eq: id,
                },
            },
            path: "/pedidos",
        }),
        [id]
    );

    if (!id) {
        return null;
    }

    return (
        <>
            <ListCards {...listCardsConfig} />

            <ListData {...listDataConfig} />
        </>
    );
};

export default ProviderOrders;
