export enum BreakPoints {
    /** 320px */
    smallX = 320,
    /** 600px */
    small = 600,
    /** 768px */
    tablet = 768,
    /** 1024px */
    medium = 1024,
    /** 1200px */
    wide = 1200,
    /** 1400px */
    huge = 1440,
}
