import React, { memo, useState, useMemo, Fragment } from "react";
import CSSTransition from "react-transition-group/CSSTransition";
import { INavbarItem } from "..";
import { theme } from "../../../config/theme";
import HamburgerIcon from "../../Icons/hamburger";
import LogoSmallIcon from "../../Icons/logoSmall";
import Logo from "../../Logo";
import NavbarCompany from "../company";
import * as S from "./styles";

interface IProps {
    items: INavbarItem[];
    onClickHome(): void;
}

const NavbarDesktop = ({ items, onClickHome }: IProps) => {
    const [activeNavbarBig, setActiveNavbarBig] = useState(true);

    const iconColor = theme.colors.components.navbar.small.iconColor;

    const memoNavbarSmall = useMemo(
        () => (
            <S.NavbarSmall>
                <S.NavbarSmallLogo onClick={onClickHome}>
                    <LogoSmallIcon width={38} height={22} />
                </S.NavbarSmallLogo>

                <S.NavbarSmallItems>
                    {/* <S.NavbarSmallItemButton>
            <SearchIcon width={18} height={18} fill={iconColor} />
        </S.NavbarSmallItemButton>

        <S.NavbarSmallItemButton>
            <UserIcon width={18} height={18} fill={iconColor} />
        </S.NavbarSmallItemButton>

        <S.NavbarSmallItemButton style={{ marginBottom: "auto" }}>
            <FileIcon width={18} height={18} fill={iconColor} />
        </S.NavbarSmallItemButton>*/}

                    {/* <S.NavbarSmallItemButton>
                        <GearIcon width={18} height={18} fill={iconColor} />
                    </S.NavbarSmallItemButton> */}

                    {/* <S.NavbarSmallItemButton>
                        <Avatar size="18px" image={profileImage} />
                    </S.NavbarSmallItemButton> */}

                    <S.NavbarSmallItemButton
                        onClick={() => setActiveNavbarBig((old) => !old)}
                    >
                        <HamburgerIcon
                            width={18}
                            height={18}
                            fill={iconColor}
                        />
                    </S.NavbarSmallItemButton>
                </S.NavbarSmallItems>
            </S.NavbarSmall>
        ),
        [iconColor, onClickHome]
    );

    return (
        <>
            {memoNavbarSmall}
            <CSSTransition
                in={activeNavbarBig}
                timeout={300}
                classNames="navbarBig"
                unmountOnExit
            >
                <S.NavbarBig>
                    <S.NavbarBigProjectName onClick={onClickHome}>
                        <Logo width={120} height={22} />
                    </S.NavbarBigProjectName>

                    <NavbarCompany />

                    <S.NavbarListItems>
                        {items.map(
                            ({ path, label, Icon, items: subItems }, index) => {
                                const Component: any = !!subItems?.length
                                    ? S.NavbarItem
                                    : S.NavbarItemLink;

                                return (
                                    <Fragment key={`menu_${index}`}>
                                        <Component
                                            activeClassName="active"
                                            to={path}
                                            exact={path === "/app"}
                                        >
                                            <Icon width={14} height={14} />
                                            <span>{label}</span>
                                        </Component>
                                        {!!subItems?.length && (
                                            <S.NavbarSubItemContainer>
                                                <span className="line"></span>

                                                <div className="wrap">
                                                    {subItems?.map(
                                                        (item, ix) => (
                                                            <S.NavbarSubItem
                                                                key={`subitem_${ix}`}
                                                                to={item.path}
                                                                activeClassName="active"
                                                            >
                                                                <span>
                                                                    {item.label}
                                                                </span>
                                                            </S.NavbarSubItem>
                                                        )
                                                    )}
                                                </div>
                                            </S.NavbarSubItemContainer>
                                        )}
                                    </Fragment>
                                );
                            }
                        )}
                    </S.NavbarListItems>
                </S.NavbarBig>
            </CSSTransition>
        </>
    );
};

export default memo(NavbarDesktop);
