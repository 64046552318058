import api from "../../services/api";
import { Creators as AuthActions } from "store/auth";
import { Creators as ModalActions } from "store/modal";
import { IModalPayload } from "store/modal/types";
import { ModalElements } from "utils/enums/ModalElements";

export default (store: any) => (next: any) => (action: any) => {
    const result = next(action);
    const state = store.getState();

    if (action.type === "persist/REHYDRATE") {
        const {
            token,
            currentCompany,
            availableCompanies = [],
        } = state.auth || {};

        if (!token) {
            return result;
        }

        api.defaults.headers.common.Authorization = `Bearer ${token}`;

        (window as any).token = token;

        if (!!currentCompany?.id) {
            api.defaults.headers.common["x-company"] = currentCompany.id;
            (window as any).company = currentCompany.id;
        }

        // SHOWS THE MODAL TO SELECT A COMPANY
        if (!!token && !currentCompany?.id) {
            const hasASingleCompany = availableCompanies?.length === 1;

            if (hasASingleCompany) {
                store.dispatch(
                    AuthActions.setCurrentCompany(availableCompanies[0])
                );
            } else {
                const payload: IModalPayload = {
                    element: ModalElements.SelectCompany,
                    width: "450px",
                    title: "Selecione uma empresa",
                    removeCloseButton: true,
                };

                store.dispatch(ModalActions.openModal(payload));
            }
        }
    }

    return result;
};
