import React from "react";
import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components/macro";

const Styles = css`
    background: none;
    border: none;
    outline: none;
    text-decoration: none;

    :not(:last-child) {
        margin-bottom: 10px;
    }
`;

const DropdownLinkRoute = styled(NavLink)`
    ${Styles}
`;
const DropdownLinkButton = styled.button`
    text-align: left;
    ${Styles}
`;

interface IProps {
    to?: string;
    onClick?(): void;
}

const DropdownLink: React.FC<IProps> = ({ to, onClick, children }) => {
    if (!!to) {
        return (
            <DropdownLinkRoute to={to} onClick={onClick}>
                {children}
            </DropdownLinkRoute>
        );
    }

    return (
        <DropdownLinkButton onClick={onClick}>{children}</DropdownLinkButton>
    );
};

export default DropdownLink;
