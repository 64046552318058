import React, { memo } from "react";
import { IIconProps } from "../../utils/interfaces/IIconProps";

const LogoFullIcon = memo(({ ...props }: IIconProps) => {
    return (
        <svg
            viewBox="0 0 135 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M31.5539 0H6.32954C2.81313 0 0 2.79661 0 6.29237V15.7076C0 19.1568 2.81313 22 6.32954 22H31.5539C35.0234 22 37.8835 19.2034 37.8835 15.7076V6.29237C37.8366 2.79661 35.0234 0 31.5539 0Z"
                fill="#96AECB"
            />
            <path
                d="M34.1796 7.55081H31.3195C31.3195 7.41098 31.3195 7.31776 31.3195 7.22454C31.2727 6.38555 31.0382 5.82623 30.7569 5.59318C30.4287 5.36013 29.6786 5.26691 28.5064 5.26691C27.1467 5.26691 26.209 5.40674 25.7871 5.63979C25.3651 5.87284 25.1307 6.43216 25.1307 7.22454C25.1307 8.20335 25.3182 8.76267 25.6464 8.94911C25.9746 9.13555 27.1467 9.27539 29.069 9.41522C31.3664 9.55505 32.8199 9.88132 33.5232 10.394C34.1796 10.9067 34.5546 12.0254 34.5546 13.6567C34.5546 15.7076 34.1796 17.0127 33.3825 17.6186C32.5855 18.2245 30.8507 18.5042 28.1782 18.5042C25.7402 18.5042 24.1461 18.2245 23.349 17.6186C22.552 17.0127 22.1769 15.8474 22.1769 14.0762V13.5169H25.0369V13.8432C25.0369 14.9152 25.2244 15.5678 25.5995 15.8008C25.9746 16.0339 27.0061 16.1737 28.694 16.1737C30.0068 16.1737 30.8507 16.0339 31.1789 15.7542C31.554 15.4745 31.7415 14.822 31.7415 13.8432C31.7415 13.0974 31.6009 12.6313 31.3195 12.3517C31.0382 12.1186 30.4756 11.9788 29.5379 11.9322L27.9438 11.8389C25.5057 11.6991 23.9585 11.3728 23.2552 10.8135C22.5988 10.3008 22.2238 9.13555 22.2238 7.41098C22.2238 5.63979 22.6457 4.42793 23.4428 3.82199C24.2398 3.21606 25.8808 2.9364 28.3189 2.9364C30.6163 2.9364 32.2104 3.21606 33.0074 3.77538C33.8045 4.33471 34.2264 5.40674 34.2264 6.99149V7.55081H34.1796Z"
                fill="#1A173F"
            />
            <path
                d="M15.2378 7.55083H12.3778C12.3778 7.411 12.3778 7.31778 12.3778 7.22456C12.3309 6.38557 12.0965 5.82625 11.8151 5.5932C11.4869 5.36015 10.7368 5.26693 9.56465 5.26693C8.20497 5.26693 7.26726 5.40676 6.84529 5.63981C6.42332 5.87286 6.18889 6.43218 6.18889 7.22456C6.18889 8.20337 6.37643 8.76269 6.70463 8.94913C7.03283 9.13558 8.20497 9.27541 10.1273 9.41524C12.4247 9.55507 13.8781 9.88134 14.5814 10.394C15.2378 10.9068 15.6129 12.0254 15.6129 13.6568C15.6129 15.7076 15.2378 17.0127 14.4407 17.6186C13.6437 18.2246 11.9089 18.5042 9.23645 18.5042C6.7984 18.5042 5.2043 18.2246 4.40724 17.6186C3.61019 17.0127 3.23511 15.8474 3.23511 14.0763V13.5169H6.09512V13.8432C6.09512 14.9152 6.28266 15.5678 6.65775 15.8008C7.03283 16.0339 8.06431 16.1737 9.75219 16.1737C11.065 16.1737 11.9089 16.0339 12.2371 15.7542C12.6122 15.4746 12.7997 14.822 12.7997 13.8432C12.7997 13.0974 12.6591 12.6313 12.3778 12.3517C12.0965 12.1186 11.5338 11.9788 10.5961 11.9322L9.00202 11.839C6.56398 11.6991 5.01675 11.3729 4.31347 10.8135C3.61019 10.3008 3.28199 9.18218 3.28199 7.411C3.28199 5.63981 3.70396 4.42795 4.50102 3.82202C5.29807 3.26269 6.93906 2.98303 9.3771 2.98303C11.6745 2.98303 13.2686 3.26269 14.0656 3.82202C14.8627 4.38134 15.2847 5.45337 15.2847 7.03812V7.55083H15.2378Z"
                fill="#1A173F"
            />
            <path
                d="M23.3021 18.8771L22.3175 15.9406H16.3631L15.4253 18.8771H11.3463L16.7381 2.61011H21.8018L27.3343 18.8304H23.3021V18.8771ZM21.3329 12.8643L19.3168 6.85163L17.3477 12.8643H21.3329Z"
                fill="#1A173F"
            />
            <path
                d="M21.4736 3.07625L26.6779 18.3644H23.6303L22.6457 15.4279H16.0349L15.0972 18.3644H12.0027L17.0664 3.07625H21.4736ZM16.6913 13.3305H21.9893L19.3169 5.36015L16.6913 13.3305ZM22.13 2.14404H21.4736H17.0664H16.41L16.1756 2.79659L11.065 18.0847L10.6431 19.2966H11.9559H15.0503H15.7536L15.9411 18.644L16.6913 16.3601H21.9893L22.7395 18.644L22.9739 19.2966H23.6303H26.6779H27.9907L27.5687 18.0847L22.3644 2.79659L22.13 2.14404ZM18.0041 12.3983L19.3169 8.3432L20.6766 12.3983H18.0041Z"
                fill="#96AECB"
            />
            <path
                d="M47.7417 4.20215H46.3828C46.3757 4.13411 46.3721 4.08398 46.3721 4.05176C46.3398 3.63997 46.2485 3.38216 46.0981 3.27832C45.9478 3.1709 45.5933 3.11719 45.0347 3.11719C44.3758 3.11719 43.9443 3.17806 43.7402 3.2998C43.5397 3.42155 43.4395 3.67757 43.4395 4.06787C43.4395 4.52979 43.5218 4.80729 43.6865 4.90039C43.8512 4.99349 44.3955 5.0651 45.3193 5.11523C46.4115 5.17611 47.1169 5.33187 47.4355 5.58252C47.7578 5.83317 47.9189 6.35417 47.9189 7.14551C47.9189 8.11947 47.731 8.74967 47.355 9.03613C46.979 9.31901 46.1501 9.46045 44.8682 9.46045C43.7152 9.46045 42.9489 9.3208 42.5693 9.0415C42.1934 8.76221 42.0054 8.19645 42.0054 7.34424L42 7.07568H43.3535L43.3589 7.23145C43.3589 7.74349 43.4484 8.0568 43.6274 8.17139C43.8065 8.28597 44.2952 8.34326 45.0938 8.34326C45.7168 8.34326 46.1143 8.27702 46.2861 8.14453C46.458 8.01204 46.5439 7.70589 46.5439 7.22607C46.5439 6.87158 46.4777 6.63704 46.3452 6.52246C46.2163 6.4043 45.9352 6.33268 45.502 6.30762L44.7339 6.25928C43.5737 6.19124 42.8325 6.03011 42.5103 5.77588C42.188 5.52165 42.0269 4.97559 42.0269 4.1377C42.0269 3.2819 42.2202 2.71257 42.6069 2.42969C42.9972 2.14323 43.7743 2 44.938 2C46.0409 2 46.7839 2.1307 47.167 2.39209C47.5501 2.65348 47.7417 3.16374 47.7417 3.92285V4.20215Z"
                fill="#96AECB"
            />
            <path
                d="M48.8857 9.396V2.06445H52.2051C53.1969 2.06445 53.8647 2.23812 54.2085 2.58545C54.5558 2.9292 54.7295 3.59521 54.7295 4.5835C54.7295 5.56462 54.5648 6.21631 54.2354 6.53857C53.9059 6.86084 53.2399 7.02197 52.2373 7.02197L51.915 7.02734H50.2769V9.396H48.8857ZM50.2769 5.85645H51.8613C52.4915 5.85645 52.8908 5.78483 53.0591 5.6416C53.231 5.49479 53.3169 5.15104 53.3169 4.61035C53.3169 3.99089 53.2453 3.60775 53.1021 3.46094C52.9624 3.31055 52.5954 3.23535 52.001 3.23535H50.2769V5.85645Z"
                fill="#96AECB"
            />
            <path
                d="M58.6665 2C59.9985 2 60.8561 2.22201 61.2393 2.66602C61.6224 3.10645 61.814 4.09473 61.814 5.63086C61.814 7.31022 61.6206 8.36833 61.2339 8.80518C60.8507 9.24202 59.9198 9.46045 58.4409 9.46045C57.1089 9.46045 56.2477 9.24561 55.8574 8.81592C55.4671 8.38265 55.272 7.43197 55.272 5.96387C55.272 4.21647 55.4635 3.11898 55.8467 2.67139C56.2298 2.2238 57.1698 2 58.6665 2ZM58.5591 3.19238C57.6245 3.19238 57.0785 3.31234 56.9209 3.55225C56.7633 3.79215 56.6846 4.62288 56.6846 6.04443C56.6846 7.09717 56.7759 7.73096 56.9585 7.9458C57.1447 8.16064 57.689 8.26807 58.5913 8.26807C59.4543 8.26807 59.9717 8.14811 60.1436 7.9082C60.3154 7.66471 60.4014 6.93066 60.4014 5.70605C60.4014 4.47786 60.319 3.75277 60.1543 3.53076C59.9932 3.30518 59.4614 3.19238 58.5591 3.19238Z"
                fill="#96AECB"
            />
            <path
                d="M62.7539 9.396V2.06445H66.3687C67.2674 2.06445 67.8869 2.22021 68.2271 2.53174C68.5672 2.84326 68.7373 3.40723 68.7373 4.22363C68.7373 4.96484 68.6514 5.47152 68.4795 5.74365C68.3112 6.01579 67.9639 6.20378 67.4375 6.30762V6.35596C68.2503 6.40609 68.6567 6.88053 68.6567 7.7793V9.396H67.2656V8.05859C67.2656 7.30306 66.8932 6.92529 66.1484 6.92529H64.145V9.396H62.7539ZM64.145 5.75439H66.1431C66.6193 5.75439 66.9344 5.67025 67.0884 5.50195C67.2459 5.33008 67.3247 4.98812 67.3247 4.47607C67.3247 3.95329 67.2567 3.6167 67.1206 3.46631C66.9881 3.31234 66.6945 3.23535 66.2397 3.23535H64.145V5.75439Z"
                fill="#96AECB"
            />
            <path
                d="M72.7656 3.31055V9.396H71.3745V3.31055H69.2583V2.06445H74.957V3.31055H72.7656Z"
                fill="#96AECB"
            />
            <path
                d="M81.1069 4.20215H79.748C79.7409 4.13411 79.7373 4.08398 79.7373 4.05176C79.7051 3.63997 79.6138 3.38216 79.4634 3.27832C79.313 3.1709 78.9585 3.11719 78.3999 3.11719C77.741 3.11719 77.3096 3.17806 77.1055 3.2998C76.9049 3.42155 76.8047 3.67757 76.8047 4.06787C76.8047 4.52979 76.887 4.80729 77.0518 4.90039C77.2165 4.99349 77.7607 5.0651 78.6846 5.11523C79.7767 5.17611 80.4821 5.33187 80.8008 5.58252C81.123 5.83317 81.2842 6.35417 81.2842 7.14551C81.2842 8.11947 81.0962 8.74967 80.7202 9.03613C80.3442 9.31901 79.5153 9.46045 78.2334 9.46045C77.0804 9.46045 76.3141 9.3208 75.9346 9.0415C75.5586 8.76221 75.3706 8.19645 75.3706 7.34424L75.3652 7.07568H76.7188L76.7241 7.23145C76.7241 7.74349 76.8136 8.0568 76.9927 8.17139C77.1717 8.28597 77.6605 8.34326 78.459 8.34326C79.082 8.34326 79.4795 8.27702 79.6514 8.14453C79.8232 8.01204 79.9092 7.70589 79.9092 7.22607C79.9092 6.87158 79.8429 6.63704 79.7104 6.52246C79.5815 6.4043 79.3005 6.33268 78.8672 6.30762L78.0991 6.25928C76.939 6.19124 76.1978 6.03011 75.8755 5.77588C75.5532 5.52165 75.3921 4.97559 75.3921 4.1377C75.3921 3.2819 75.5854 2.71257 75.9722 2.42969C76.3625 2.14323 77.1395 2 78.3032 2C79.4061 2 80.1491 2.1307 80.5322 2.39209C80.9154 2.65348 81.1069 3.16374 81.1069 3.92285V4.20215Z"
                fill="#96AECB"
            />
            <path
                d="M88.5918 7.98877H85.4282L84.9717 9.396H83.5L85.9385 2.06445H88.0278L90.5039 9.396H89.0591L88.5918 7.98877ZM88.2695 6.96289L87.002 3.14404L85.7559 6.96289H88.2695Z"
                fill="#96AECB"
            />
            <path
                d="M95.6655 6.78027H97.062V7.03271C97.062 8.05322 96.8758 8.71387 96.5034 9.01465C96.131 9.31185 95.3075 9.46045 94.0327 9.46045C92.5861 9.46045 91.6963 9.22412 91.3633 8.75146C91.0303 8.27881 90.8638 7.01481 90.8638 4.95947C90.8638 3.74919 91.0894 2.95426 91.5405 2.57471C91.9917 2.19157 92.9352 2 94.3711 2C95.4167 2 96.1149 2.15755 96.4658 2.47266C96.8167 2.78418 96.9922 3.40544 96.9922 4.33643L96.9976 4.50293H95.6011V4.31494C95.6011 3.83512 95.5098 3.52897 95.3271 3.39648C95.1481 3.26042 94.7363 3.19238 94.0918 3.19238C93.2288 3.19238 92.7096 3.29801 92.5342 3.50928C92.3623 3.72054 92.2764 4.34717 92.2764 5.38916C92.2764 6.79281 92.3534 7.62354 92.5073 7.88135C92.6649 8.13916 93.1698 8.26807 94.022 8.26807C94.7131 8.26807 95.1606 8.19645 95.3647 8.05322C95.5724 7.90999 95.6763 7.59489 95.6763 7.10791L95.6655 6.78027Z"
                fill="#96AECB"
            />
            <path
                d="M102.374 7.98877H99.2104L98.7539 9.396H97.2822L99.7207 2.06445H101.81L104.286 9.396H102.841L102.374 7.98877ZM102.052 6.96289L100.784 3.14404L99.5381 6.96289H102.052Z"
                fill="#96AECB"
            />
            <path
                d="M104.92 9.396V2.06445H108.524C109.548 2.06445 110.264 2.28825 110.672 2.73584C111.084 3.18343 111.29 3.96761 111.29 5.08838C111.29 6.91813 111.125 8.0944 110.796 8.61719C110.466 9.13639 109.723 9.396 108.567 9.396H104.92ZM106.311 8.2251H108.293C108.959 8.2251 109.389 8.07292 109.582 7.76855C109.779 7.46061 109.877 6.78385 109.877 5.73828C109.877 4.6569 109.79 3.9694 109.614 3.67578C109.439 3.38216 109.027 3.23535 108.379 3.23535H106.311V8.2251Z"
                fill="#96AECB"
            />
            <path
                d="M113.557 3.23535V5.12598H116.994V6.15186H113.557V8.2251H117.214V9.396H112.166V2.06445H117.182V3.23535H113.557Z"
                fill="#96AECB"
            />
            <path
                d="M126.823 2.06445V9.396H125.432V5.3999C125.432 5.08122 125.441 4.71956 125.459 4.31494L125.513 3.22461H125.47L125.303 3.73486L125.142 4.24512C124.992 4.70345 124.875 5.04183 124.793 5.26025L123.182 9.396H121.914L120.287 5.29248C120.197 5.06689 120.079 4.72852 119.932 4.27734L119.766 3.76709L119.599 3.26221H119.556L119.61 4.33105C119.631 4.74642 119.642 5.1027 119.642 5.3999V9.396H118.251V2.06445H120.518L121.828 5.45898C121.918 5.69531 122.036 6.03369 122.183 6.47412L122.344 6.98438L122.51 7.48926H122.559L122.714 6.98438L122.875 6.47949C123.008 6.05339 123.123 5.7168 123.219 5.46973L124.508 2.06445H126.823Z"
                fill="#96AECB"
            />
            <path
                d="M134.187 2.06445L131.469 6.59766V9.396H130.078V6.59766L127.436 2.06445H129.047L130.153 3.99805C130.214 4.10547 130.318 4.3042 130.465 4.59424L130.621 4.88965L130.771 5.18506H130.809L131.12 4.59424C131.253 4.32926 131.358 4.13053 131.437 3.99805L132.554 2.06445H134.187Z"
                fill="#96AECB"
            />
            <path
                d="M47.7417 15.2021H46.3828C46.3757 15.1341 46.3721 15.084 46.3721 15.0518C46.3398 14.64 46.2485 14.3822 46.0981 14.2783C45.9478 14.1709 45.5933 14.1172 45.0347 14.1172C44.3758 14.1172 43.9443 14.1781 43.7402 14.2998C43.5397 14.4215 43.4395 14.6776 43.4395 15.0679C43.4395 15.5298 43.5218 15.8073 43.6865 15.9004C43.8512 15.9935 44.3955 16.0651 45.3193 16.1152C46.4115 16.1761 47.1169 16.3319 47.4355 16.5825C47.7578 16.8332 47.9189 17.3542 47.9189 18.1455C47.9189 19.1195 47.731 19.7497 47.355 20.0361C46.979 20.319 46.1501 20.4604 44.8682 20.4604C43.7152 20.4604 42.9489 20.3208 42.5693 20.0415C42.1934 19.7622 42.0054 19.1965 42.0054 18.3442L42 18.0757H43.3535L43.3589 18.2314C43.3589 18.7435 43.4484 19.0568 43.6274 19.1714C43.8065 19.286 44.2952 19.3433 45.0938 19.3433C45.7168 19.3433 46.1143 19.277 46.2861 19.1445C46.458 19.012 46.5439 18.7059 46.5439 18.2261C46.5439 17.8716 46.4777 17.637 46.3452 17.5225C46.2163 17.4043 45.9352 17.3327 45.502 17.3076L44.7339 17.2593C43.5737 17.1912 42.8325 17.0301 42.5103 16.7759C42.188 16.5216 42.0269 15.9756 42.0269 15.1377C42.0269 14.2819 42.2202 13.7126 42.6069 13.4297C42.9972 13.1432 43.7743 13 44.938 13C46.0409 13 46.7839 13.1307 47.167 13.3921C47.5501 13.6535 47.7417 14.1637 47.7417 14.9229V15.2021Z"
                fill="#96AECB"
            />
            <path
                d="M54.9604 13.0645L52.2427 17.5977V20.396H50.8516V17.5977L48.209 13.0645H49.8203L50.9268 14.998C50.9876 15.1055 51.0915 15.3042 51.2383 15.5942L51.394 15.8896L51.5444 16.1851H51.582L51.8936 15.5942C52.026 15.3293 52.1317 15.1305 52.2104 14.998L53.3276 13.0645H54.9604Z"
                fill="#96AECB"
            />
            <path
                d="M61.019 15.2021H59.6602C59.653 15.1341 59.6494 15.084 59.6494 15.0518C59.6172 14.64 59.5259 14.3822 59.3755 14.2783C59.2251 14.1709 58.8706 14.1172 58.312 14.1172C57.6532 14.1172 57.2217 14.1781 57.0176 14.2998C56.8171 14.4215 56.7168 14.6776 56.7168 15.0679C56.7168 15.5298 56.7992 15.8073 56.9639 15.9004C57.1286 15.9935 57.6729 16.0651 58.5967 16.1152C59.6888 16.1761 60.3942 16.3319 60.7129 16.5825C61.0352 16.8332 61.1963 17.3542 61.1963 18.1455C61.1963 19.1195 61.0083 19.7497 60.6323 20.0361C60.2563 20.319 59.4274 20.4604 58.1455 20.4604C56.9925 20.4604 56.2262 20.3208 55.8467 20.0415C55.4707 19.7622 55.2827 19.1965 55.2827 18.3442L55.2773 18.0757H56.6309L56.6362 18.2314C56.6362 18.7435 56.7257 19.0568 56.9048 19.1714C57.0838 19.286 57.5726 19.3433 58.3711 19.3433C58.9941 19.3433 59.3916 19.277 59.5635 19.1445C59.7354 19.012 59.8213 18.7059 59.8213 18.2261C59.8213 17.8716 59.755 17.637 59.6226 17.5225C59.4937 17.4043 59.2126 17.3327 58.7793 17.3076L58.0112 17.2593C56.8511 17.1912 56.1099 17.0301 55.7876 16.7759C55.4653 16.5216 55.3042 15.9756 55.3042 15.1377C55.3042 14.2819 55.4976 13.7126 55.8843 13.4297C56.2746 13.1432 57.0516 13 58.2153 13C59.3182 13 60.0612 13.1307 60.4443 13.3921C60.8275 13.6535 61.019 14.1637 61.019 14.9229V15.2021Z"
                fill="#96AECB"
            />
            <path
                d="M65.085 14.3105V20.396H63.6938V14.3105H61.5776V13.0645H67.2764V14.3105H65.085Z"
                fill="#96AECB"
            />
            <path
                d="M69.2314 14.2354V16.126H72.6689V17.1519H69.2314V19.2251H72.8892V20.396H67.8403V13.0645H72.8569V14.2354H69.2314Z"
                fill="#96AECB"
            />
            <path
                d="M82.498 13.0645V20.396H81.1069V16.3999C81.1069 16.0812 81.1159 15.7196 81.1338 15.3149L81.1875 14.2246H81.1445L80.978 14.7349L80.8169 15.2451C80.6665 15.7035 80.5501 16.0418 80.4678 16.2603L78.8564 20.396H77.5889L75.9614 16.2925C75.8719 16.0669 75.7537 15.7285 75.6069 15.2773L75.4404 14.7671L75.2739 14.2622H75.231L75.2847 15.3311C75.3062 15.7464 75.3169 16.1027 75.3169 16.3999V20.396H73.9258V13.0645H76.1924L77.5029 16.459C77.5924 16.6953 77.7106 17.0337 77.8574 17.4741L78.0186 17.9844L78.1851 18.4893H78.2334L78.3892 17.9844L78.5503 17.4795C78.6828 17.0534 78.7974 16.7168 78.894 16.4697L80.1831 13.0645H82.498Z"
                fill="#96AECB"
            />
        </svg>
    );
});

export default LogoFullIcon;
