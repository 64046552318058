import { useEffect } from "react";
import { theme } from "../config/theme";

export const useTitle = (title?: string) => {
    useEffect(() => {
        window.document.title = !!title
            ? `${title} - ${theme.projectName}`
            : theme.projectName;
    }, [title]);
};
