import styled from "styled-components/macro";

export const GoogleMapsLoading = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -1;

    svg {
        animation-duration: 0.4s;
    }
`;

export const GoogleMapsContainer = styled.div`
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
    height: 550px;

    iframe,
    ${GoogleMapsLoading}, .map {
        width: 100%;
        height: 100%;
        border: none;
        color: #000;
    }
`;
