import { dictionary } from "config/dictionary";
import { WeekDays } from "utils/enums/WeekDays";

export const convertWeekDaysToString = (
    days: string,
    dictionaryObj: any = dictionary
) => {
    return days
        ?.split(";")
        ?.filter(Boolean)
        ?.map((day: any) => (dictionaryObj as any)[WeekDays[day]])
        ?.filter(Boolean)
        ?.join(", ");
};
