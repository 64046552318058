import React, { memo } from "react";
import { IIconProps } from "../../utils/interfaces/IIconProps";

const LogoLettersIcon = memo(({ ...props }: IIconProps) => {
    return (
        <svg
            viewBox="0 0 286 58"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M18.5652 6.9884H14.3651C14.3429 6.77812 14.3318 6.62317 14.3318 6.52356C14.2322 5.25077 13.95 4.4539 13.4852 4.13293C13.0203 3.8009 11.9246 3.63489 10.1981 3.63489C8.1616 3.63489 6.82794 3.82304 6.19708 4.19934C5.57729 4.57564 5.2674 5.36698 5.2674 6.57336C5.2674 8.0011 5.52195 8.85885 6.03107 9.14661C6.54018 9.43437 8.22247 9.65572 11.0779 9.81067C14.4536 9.99882 16.6339 10.4803 17.619 11.255C18.6151 12.0297 19.1131 13.6401 19.1131 16.0861C19.1131 19.0965 18.532 21.0444 17.3699 21.9298C16.2078 22.8042 13.6457 23.2413 9.68341 23.2413C6.11961 23.2413 3.75112 22.8097 2.57794 21.9464C1.41583 21.0831 0.834778 19.3344 0.834778 16.7003L0.818176 15.8702H5.00177L5.01837 16.3517C5.01837 17.9344 5.29506 18.9028 5.84845 19.257C6.40184 19.6111 7.91258 19.7882 10.3807 19.7882C12.3065 19.7882 13.535 19.5835 14.0662 19.174C14.5975 18.7644 14.8631 17.8182 14.8631 16.3351C14.8631 15.2394 14.6583 14.5144 14.2488 14.1603C13.8504 13.795 12.9816 13.5737 11.6424 13.4962L9.26837 13.3468C5.68243 13.1365 3.39142 12.6385 2.39532 11.8527C1.39923 11.0669 0.901184 9.37903 0.901184 6.78918C0.901184 4.144 1.49884 2.38424 2.69415 1.50989C3.90053 0.624471 6.30223 0.181763 9.89923 0.181763C13.3081 0.181763 15.6046 0.585734 16.7889 1.39368C17.9731 2.20162 18.5652 3.77877 18.5652 6.12512V6.9884Z"
                fill="#96AECB"
            />
            <path
                d="M22.1014 23.0421V0.380981H32.3611C35.4269 0.380981 37.491 0.917765 38.5535 1.99133C39.6271 3.05383 40.1639 5.11243 40.1639 8.16711C40.1639 11.1997 39.6548 13.214 38.6365 14.2101C37.6183 15.2062 35.5597 15.7042 32.4608 15.7042L31.4647 15.7208H26.4012V23.0421H22.1014ZM26.4012 12.1017H31.2986C33.2466 12.1017 34.4806 11.8803 35.0008 11.4376C35.532 10.9838 35.7977 9.92135 35.7977 8.25012C35.7977 6.33541 35.5763 5.15116 35.1336 4.69739C34.702 4.23254 33.5675 4.00012 31.7303 4.00012H26.4012V12.1017Z"
                fill="#96AECB"
            />
            <path
                d="M52.3328 0.181763C56.45 0.181763 59.1007 0.867961 60.285 2.24036C61.4692 3.60168 62.0613 6.65637 62.0613 11.4044C62.0613 16.5952 61.4637 19.8657 60.2684 21.2159C59.0841 22.5662 56.2065 23.2413 51.6356 23.2413C47.5184 23.2413 44.8566 22.5773 43.6502 21.2491C42.4438 19.91 41.8406 16.9715 41.8406 12.4337C41.8406 7.03267 42.4328 3.64042 43.617 2.25696C44.8013 0.873494 47.7065 0.181763 52.3328 0.181763ZM52.0008 3.86731C49.1121 3.86731 47.4243 4.23808 46.9373 4.97961C46.4503 5.72115 46.2069 8.28886 46.2069 12.6827C46.2069 15.9366 46.4891 17.8956 47.0535 18.5597C47.6291 19.2238 49.3113 19.5558 52.1004 19.5558C54.7677 19.5558 56.367 19.185 56.8983 18.4435C57.4295 17.6909 57.6951 15.422 57.6951 11.6368C57.6951 7.84062 57.4406 5.59941 56.9315 4.91321C56.4334 4.21594 54.7899 3.86731 52.0008 3.86731Z"
                fill="#96AECB"
            />
            <path
                d="M64.9666 23.0421V0.380981H76.1395C78.9175 0.380981 80.8322 0.862427 81.8836 1.82532C82.935 2.78821 83.4608 4.53137 83.4608 7.05481C83.4608 9.34583 83.1951 10.9119 82.6639 11.7531C82.1437 12.5942 81.0701 13.1753 79.4432 13.4962V13.6456C81.9556 13.8006 83.2117 15.267 83.2117 18.045V23.0421H78.9119V18.9083C78.9119 16.573 77.7609 15.4054 75.4588 15.4054H69.2664V23.0421H64.9666ZM69.2664 11.7863H75.4422C76.9142 11.7863 77.8882 11.5262 78.3641 11.006C78.8511 10.4747 79.0946 9.41776 79.0946 7.83508C79.0946 6.2192 78.8843 5.17883 78.4637 4.71399C78.0542 4.23808 77.1466 4.00012 75.741 4.00012H69.2664V11.7863Z"
                fill="#96AECB"
            />
            <path
                d="M95.9119 4.23254V23.0421H91.6121V4.23254H85.0711V0.380981H102.685V4.23254H95.9119Z"
                fill="#96AECB"
            />
            <path
                d="M121.694 6.9884H117.494C117.472 6.77812 117.461 6.62317 117.461 6.52356C117.361 5.25077 117.079 4.4539 116.614 4.13293C116.149 3.8009 115.054 3.63489 113.327 3.63489C111.291 3.63489 109.957 3.82304 109.326 4.19934C108.706 4.57564 108.396 5.36698 108.396 6.57336C108.396 8.0011 108.651 8.85885 109.16 9.14661C109.669 9.43437 111.351 9.65572 114.207 9.81067C117.583 9.99882 119.763 10.4803 120.748 11.255C121.744 12.0297 122.242 13.6401 122.242 16.0861C122.242 19.0965 121.661 21.0444 120.499 21.9298C119.337 22.8042 116.775 23.2413 112.812 23.2413C109.249 23.2413 106.88 22.8097 105.707 21.9464C104.545 21.0831 103.964 19.3344 103.964 16.7003L103.947 15.8702H108.131L108.147 16.3517C108.147 17.9344 108.424 18.9028 108.977 19.257C109.531 19.6111 111.041 19.7882 113.51 19.7882C115.435 19.7882 116.664 19.5835 117.195 19.174C117.726 18.7644 117.992 17.8182 117.992 16.3351C117.992 15.2394 117.787 14.5144 117.378 14.1603C116.979 13.795 116.11 13.5737 114.771 13.4962L112.397 13.3468C108.811 13.1365 106.52 12.6385 105.524 11.8527C104.528 11.0669 104.03 9.37903 104.03 6.78918C104.03 4.144 104.628 2.38424 105.823 1.50989C107.029 0.624471 109.431 0.181763 113.028 0.181763C116.437 0.181763 118.734 0.585734 119.918 1.39368C121.102 2.20162 121.694 3.77877 121.694 6.12512V6.9884Z"
                fill="#96AECB"
            />
            <path
                d="M144.829 18.6925H135.051L133.64 23.0421H129.091L136.628 0.380981H143.086L150.739 23.0421H146.274L144.829 18.6925ZM143.833 15.5216L139.915 3.7179L136.064 15.5216H143.833Z"
                fill="#96AECB"
            />
            <path
                d="M166.693 14.9572H171.01V15.7374C171.01 18.8917 170.434 20.9337 169.283 21.8634C168.132 22.782 165.587 23.2413 161.647 23.2413C157.175 23.2413 154.425 22.5109 153.396 21.0499C152.366 19.589 151.852 15.6821 151.852 9.32922C151.852 5.58834 152.549 3.13131 153.943 1.95813C155.338 0.773885 158.254 0.181763 162.692 0.181763C165.924 0.181763 168.082 0.668742 169.167 1.6427C170.252 2.60559 170.794 4.52584 170.794 7.40344L170.811 7.91809H166.494V7.33704C166.494 5.85396 166.212 4.90767 165.648 4.49817C165.094 4.0776 163.821 3.86731 161.829 3.86731C159.162 3.86731 157.557 4.19381 157.015 4.8468C156.483 5.4998 156.218 7.43665 156.218 10.6573C156.218 14.9959 156.456 17.5636 156.932 18.3605C157.419 19.1573 158.979 19.5558 161.613 19.5558C163.749 19.5558 165.133 19.3344 165.764 18.8917C166.406 18.449 166.727 17.4751 166.727 15.9698L166.693 14.9572Z"
                fill="#96AECB"
            />
            <path
                d="M187.429 18.6925H177.65L176.239 23.0421H171.691L179.228 0.380981H185.686L193.339 23.0421H188.873L187.429 18.6925ZM186.433 15.5216L182.515 3.7179L178.663 15.5216H186.433Z"
                fill="#96AECB"
            />
            <path
                d="M195.298 23.0421V0.380981H206.438C209.603 0.380981 211.816 1.07271 213.078 2.45618C214.351 3.83964 214.987 6.26347 214.987 9.72766C214.987 15.3833 214.478 19.019 213.46 20.6349C212.442 22.2397 210.145 23.0421 206.57 23.0421H195.298ZM199.598 19.423H205.724C207.782 19.423 209.11 18.9526 209.708 18.0118C210.317 17.06 210.621 14.9682 210.621 11.7365C210.621 8.394 210.35 6.269 209.808 5.36145C209.265 4.4539 207.993 4.00012 205.989 4.00012H199.598V19.423Z"
                fill="#96AECB"
            />
            <path
                d="M221.993 4.00012V9.84387H232.618V13.0148H221.993V19.423H233.299V23.0421H217.693V0.380981H233.199V4.00012H221.993Z"
                fill="#96AECB"
            />
            <path
                d="M262.999 0.380981V23.0421H258.699V10.6906C258.699 9.70553 258.727 8.58769 258.782 7.33704L258.948 3.96692H258.816L258.301 5.54407L257.803 7.12122C257.338 8.53788 256.978 9.58378 256.724 10.2589L251.743 23.0421H247.825L242.795 10.3585C242.518 9.66125 242.153 8.61536 241.699 7.22083L241.185 5.64368L240.67 4.08313H240.537L240.703 7.38684C240.77 8.67069 240.803 9.77193 240.803 10.6906V23.0421H236.503V0.380981H243.509L247.56 10.8732C247.836 11.6036 248.202 12.6495 248.655 14.0109L249.153 15.588L249.668 17.1486H249.817L250.299 15.588L250.797 14.0275C251.206 12.7104 251.561 11.67 251.859 10.9064L255.844 0.380981H262.999Z"
                fill="#96AECB"
            />
            <path
                d="M285.76 0.380981L277.359 14.3927V23.0421H273.06V14.3927L264.892 0.380981H269.872L273.292 6.35754C273.48 6.68958 273.801 7.30383 274.255 8.20032L274.736 9.1134L275.201 10.0265H275.317L276.28 8.20032C276.69 7.38131 277.016 6.76705 277.26 6.35754L280.713 0.380981H285.76Z"
                fill="#96AECB"
            />
            <path
                d="M18.5652 40.9884H14.3651C14.3429 40.7781 14.3318 40.6232 14.3318 40.5236C14.2322 39.2508 13.95 38.4539 13.4852 38.1329C13.0203 37.8009 11.9246 37.6349 10.1981 37.6349C8.1616 37.6349 6.82794 37.823 6.19708 38.1993C5.57729 38.5756 5.2674 39.367 5.2674 40.5734C5.2674 42.0011 5.52195 42.8588 6.03107 43.1466C6.54018 43.4344 8.22247 43.6557 11.0779 43.8107C14.4536 43.9988 16.6339 44.4803 17.619 45.255C18.6151 46.0297 19.1131 47.6401 19.1131 50.0861C19.1131 53.0965 18.532 55.0444 17.3699 55.9298C16.2078 56.8042 13.6457 57.2413 9.68341 57.2413C6.11961 57.2413 3.75112 56.8097 2.57794 55.9464C1.41583 55.0831 0.834778 53.3344 0.834778 50.7003L0.818176 49.8702H5.00177L5.01837 50.3517C5.01837 51.9344 5.29506 52.9028 5.84845 53.257C6.40184 53.6111 7.91258 53.7882 10.3807 53.7882C12.3065 53.7882 13.535 53.5835 14.0662 53.174C14.5975 52.7644 14.8631 51.8182 14.8631 50.3351C14.8631 49.2394 14.6583 48.5144 14.2488 48.1603C13.8504 47.795 12.9816 47.5737 11.6424 47.4962L9.26837 47.3468C5.68243 47.1365 3.39142 46.6385 2.39532 45.8527C1.39923 45.0669 0.901184 43.379 0.901184 40.7892C0.901184 38.144 1.49884 36.3842 2.69415 35.5099C3.90053 34.6245 6.30223 34.1818 9.89923 34.1818C13.3081 34.1818 15.6046 34.5857 16.7889 35.3937C17.9731 36.2016 18.5652 37.7788 18.5652 40.1251V40.9884Z"
                fill="#96AECB"
            />
            <path
                d="M40.8778 34.381L32.4774 48.3927V57.0421H28.1776V48.3927L20.0096 34.381H24.9901L28.41 40.3575C28.5981 40.6896 28.9191 41.3038 29.3729 42.2003L29.8543 43.1134L30.3192 44.0265H30.4354L31.3983 42.2003C31.8078 41.3813 32.1343 40.767 32.3777 40.3575L35.8309 34.381H40.8778Z"
                fill="#96AECB"
            />
            <path
                d="M59.6043 40.9884H55.4041C55.382 40.7781 55.3709 40.6232 55.3709 40.5236C55.2713 39.2508 54.9891 38.4539 54.5242 38.1329C54.0594 37.8009 52.9637 37.6349 51.2371 37.6349C49.2007 37.6349 47.867 37.823 47.2361 38.1993C46.6164 38.5756 46.3065 39.367 46.3065 40.5734C46.3065 42.0011 46.561 42.8588 47.0701 43.1466C47.5792 43.4344 49.2615 43.6557 52.117 43.8107C55.4927 43.9988 57.673 44.4803 58.658 45.255C59.6541 46.0297 60.1522 47.6401 60.1522 50.0861C60.1522 53.0965 59.5711 55.0444 58.409 55.9298C57.2469 56.8042 54.6847 57.2413 50.7225 57.2413C47.1587 57.2413 44.7902 56.8097 43.617 55.9464C42.4549 55.0831 41.8738 53.3344 41.8738 50.7003L41.8572 49.8702H46.0408L46.0574 50.3517C46.0574 51.9344 46.3341 52.9028 46.8875 53.257C47.4409 53.6111 48.9516 53.7882 51.4197 53.7882C53.3455 53.7882 54.574 53.5835 55.1053 53.174C55.6365 52.7644 55.9022 51.8182 55.9022 50.3351C55.9022 49.2394 55.6974 48.5144 55.2879 48.1603C54.8895 47.795 54.0207 47.5737 52.6815 47.4962L50.3074 47.3468C46.7215 47.1365 44.4305 46.6385 43.4344 45.8527C42.4383 45.0669 41.9403 43.379 41.9403 40.7892C41.9403 38.144 42.5379 36.3842 43.7332 35.5099C44.9396 34.6245 47.3413 34.1818 50.9383 34.1818C54.3471 34.1818 56.6437 34.5857 57.8279 35.3937C59.0122 36.2016 59.6043 37.7788 59.6043 40.1251V40.9884Z"
                fill="#96AECB"
            />
            <path
                d="M72.1717 38.2325V57.0421H67.8719V38.2325H61.3309V34.381H78.9451V38.2325H72.1717Z"
                fill="#96AECB"
            />
            <path
                d="M84.9881 38.0001V43.8439H95.6131V47.0148H84.9881V53.423H96.2938V57.0421H80.6883V34.381H96.1942V38.0001H84.9881Z"
                fill="#96AECB"
            />
            <path
                d="M125.994 34.381V57.0421H121.694V44.6906C121.694 43.7055 121.722 42.5877 121.777 41.337L121.943 37.9669H121.81L121.296 39.5441L120.798 41.1212C120.333 42.5379 119.973 43.5838 119.719 44.2589L114.738 57.0421H110.82L105.79 44.3585C105.513 43.6613 105.148 42.6154 104.694 41.2208L104.18 39.6437L103.665 38.0831H103.532L103.698 41.3868C103.764 42.6707 103.798 43.7719 103.798 44.6906V57.0421H99.4979V34.381H106.504L110.555 44.8732C110.831 45.6036 111.196 46.6495 111.65 48.0109L112.148 49.588L112.663 51.1486H112.812L113.294 49.588L113.792 48.0275C114.201 46.7104 114.555 45.67 114.854 44.9064L118.839 34.381H125.994Z"
                fill="#96AECB"
            />
        </svg>
    );
});

export default LogoLettersIcon;
