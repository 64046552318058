import axios, { AxiosRequestConfig } from "axios";
import { store } from "store";
import { Creators } from "store/auth";

export const apiConfig: AxiosRequestConfig = {
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        "Content-Type": "application/json",
    },
};

const api = axios.create(apiConfig);

api.interceptors.response.use(
    (response) => response,
    async (error: any) => {
        if (error?.response?.status === 401) {
            store.dispatch(Creators.logout());
        }

        return Promise.reject(error);
    }
);

export default api;
