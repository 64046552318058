import { IOnChangeWatchers } from "components/Form/FormFactory";
import { useCallback, useRef, useState } from "react";
import { IRequestCEPResponse, requestCEP } from "utils/requestCEP";

const ZIP_CODE_LENGTH = 8;

export function useFormZipCode(addressPath = "person.address") {
    const [isZipCodeLoading, setIsZipCodeLoading] = useState(false);

    const lastRequestedZipCode = useRef<string>();

    const handleChangeZipCode = useCallback(
        (items?: IOnChangeWatchers[]) => {
            const data = items?.find(
                ({ name }) => name === `${addressPath}.zip`
            );

            if (!data?.value || lastRequestedZipCode.current === data?.value) {
                return;
            }

            lastRequestedZipCode.current = data.value;

            const { value, setValue } = data || {};

            const cleanValue = value.replace(/\D/g, "");

            if (cleanValue?.length === ZIP_CODE_LENGTH) {
                setIsZipCodeLoading(true);

                requestCEP(cleanValue)
                    .then((address: IRequestCEPResponse) => {
                        if (!address) {
                            throw new Error();
                        }

                        setValue(`${addressPath}.zip`, address.cep);
                        setValue(`${addressPath}.city`, address.city);
                        setValue(
                            `${addressPath}.neighbourhood`,
                            address.neighborhood
                        );
                        setValue(`${addressPath}.state`, address.state);
                        setValue(`${addressPath}.street`, address.street);

                        setIsZipCodeLoading(false);
                    })
                    .catch((err: any) => {
                        console.error(err);
                        setIsZipCodeLoading(false);
                    });
            }
        },
        [addressPath]
    );

    return { isZipCodeLoading, handleChangeZipCode };
}
