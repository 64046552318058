// sampleReducer.js
import { createReducer, createActions } from "reduxsauce";
import { ModalElements } from "../../utils/enums/ModalElements";
import { ModalTypes, IModalState, IModalActionsCreators } from "./types";

export const { Creators, Types } = createActions<
    Record<ModalTypes, ModalTypes>,
    IModalActionsCreators
>({
    openModal: ["payload"],
    closeModal: [],

    clearModal: [],
});

const INITIAL_STATE: IModalState = {
    element: ModalElements.Default,
    isOpen: false,
    title: "",
    action: undefined,
    width: undefined,
    height: undefined,
    contentBackground: undefined,
};

const open = (state = INITIAL_STATE, action: any) => {
    return { ...state, ...INITIAL_STATE, isOpen: true, ...action.payload };
};

const clear = () => INITIAL_STATE;

const HANDLERS = {
    [Types.OPEN_MODAL]: open,
    [Types.CLOSE_MODAL]: clear,

    [Types.CLEAR_MODAL]: clear,
};

export default createReducer<IModalState>(INITIAL_STATE, HANDLERS);
