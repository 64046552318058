import React, { memo, useCallback } from "react";
// import UsersIcon from "../Icons/users";
// import MusicIcon from "../Icons/music";
import NavbarDesktop from "./desktop";
import useIsMobile from "../../hooks/useIsMobile";
import { NavbarContainer } from "./styles";
import NavbarMobile from "./mobile";
import history from "../../services/history";

import DashboardIcon from "../Icons/dashboard";

import UsersIcon from "../Icons/users";
import BuildingIcon from "components/Icons/building";
import TeacherIcon from "components/Icons/teacher";
import ProductIcon from "components/Icons/product";
import FileIcon from "components/Icons/file";
import BallIcon from "components/Icons/Ball";
import UserIcon from "components/Icons/user";
import ReportIcon from "components/Icons/report";
import ProviderIcon from "components/Icons/provider";

export interface INavbarItem {
    label: string;
    Icon: any;
    path: string;
    items?: { label: string; path: string }[];
}

const items: INavbarItem[] = [
    {
        label: "Dashboard",
        Icon: DashboardIcon,
        path: "/app",
    },
    {
        label: "Núcleos",
        Icon: BuildingIcon,
        path: "/app/nucleos",
    },
    {
        label: "Turmas",
        Icon: UsersIcon,
        path: "/app/turmas",
    },
    {
        label: "Profissionais",
        Icon: TeacherIcon,
        path: "/app/profissionais",
    },
    {
        label: "Alunos",
        Icon: UserIcon,
        path: "/app/alunos",
    },

    {
        label: "Modalidades",
        Icon: BallIcon,
        path: "/app/modalidades",
    },
    {
        label: "Produtos",
        Icon: ProductIcon,
        path: "/app/produtos",
    },
    {
        label: "Pedidos",
        Icon: FileIcon,
        path: "/app/pedidos",
    },
    {
        label: "Fornecedores",
        Icon: ProviderIcon,
        path: "/app/fornecedores",
    },
    {
        label: "Relatórios",
        Icon: ReportIcon,
        path: "/app/relatorios",
        items: [
            {
                label: "Aulas",
                path: "/app/relatorios/aulas",
            },
            {
                label: "Produtos",
                path: "/app/relatorios/produtos",
            },
            {
                label: "Núcleos",
                path: "/app/relatorios/nucleos",
            },
            {
                label: "Profissionais",
                path: "/app/relatorios/profissionais",
            },
            {
                label: "Pedidos",
                path: "/app/relatorios/pedidos",
            },
        ],
    },
];

const Navbar = () => {
    const isMobile = useIsMobile();

    const Component = isMobile ? NavbarMobile : NavbarDesktop;

    const handleClickHome = useCallback(() => {
        history.push("/app");
    }, []);

    return (
        <NavbarContainer>
            <Component items={items} onClickHome={handleClickHome} />
        </NavbarContainer>
    );
};

export default memo(Navbar);
