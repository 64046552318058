import styled, { createGlobalStyle } from "styled-components/macro";
import { theme } from "../config/theme";
import { generateSpacingStyles } from "../helpers/generateSpacingStyles";
import { ISpacing } from "../utils/interfaces/ISpacing";

export const VerticalDiv = styled.div<ISpacing>`
    display: flex;
    flex-direction: column;

    ${(props) => generateSpacingStyles(props)};
`;

export const HorizontalDiv = styled.div<ISpacing>`
    display: flex;
    align-items: center;

    ${(props) => generateSpacingStyles(props)};
`;

export const GlobalStyles = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-smoothing: antialiased;
        -webkit-font-smoothing: antialiased;
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
    }

    button, a {
        outline: none;
        cursor: pointer;

        ::-moz-focus-inner {
            border: 0;
        }
    }

    body {
        background: ${theme.colors.background};
        color: ${theme.colors.mainLight};
    }

    html, body, #root {
        height: 100%;
        width: 100%;
    } 


    // MODAL BACKGROUND TRANSITIONS STYLES
    .modalcontainer-enter {
        background: transparent;
    }
    .modalcontainer-enter-active {
        background: rgba(0, 0, 0, 0.3);
        transition: background 200ms ease-in-out;
    }
    .modalcontainer-exit {
        background: rgba(0, 0, 0, 0.3);
    }
    .modalcontainer-exit-active {
        background: transparent;
        transition: background 100ms ease-in-out;

    }


    /// GOOGLE MAPS INPUT

    


    .pac-container {
        border: 1px solid;
        background: #FFF;
        box-shadow: unset;
        border-top: unset;
        top: -5px;
        margin-top: 3px;
        border-top: unset;
        margin-left: 3px;
        /* box-shadow: 0 2px 8px 1px rgba(16, 19, 35, 0.2); */
        box-shadow: 0 0 0 1px hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.1);
        border-radius: 4px;

        border-top: 1px solid;
        border-color: #EFF4FA;

        * {
            font-family: 'Open Sans', sans-serif !important;
        }

        :after {
            display: none;
        }
    }

    .pac-item {
        border-top: unset;
        padding: 6px 8px;
        cursor: pointer;
    
        :hover {
            background-color: #EFF4FA;
        }


        span {
            ::last-child {
                color: ${theme.colors.gray};
            }
        }
    }

    .pac-item-query  {
        color: ${theme.colors.mainDark}; 
    }



`;

export const Content = styled.main`
    display: block;
    width: 100%;
    height: 100%;
    max-width: 100%;
`;
