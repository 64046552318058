export enum ModalElements {
    Default = 1,
    GenericForm,
    ConfirmAction,
    List,
    Actions,
    CreateProjectClassDay,
    Custom,
    SelectCompany,
}
