import styled from "styled-components";
import { Card } from "../../components/Card";
import { TextElement } from "../../components/Text";
import { theme } from "../../config/theme";

export const ProjectClassDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;

    ${Card} {
        ${TextElement} {
            display: flex;
        }
    }
`;

export const ProjectClassDetailsProfessionalEditButton = styled.button`
    background: transparent;
    border: none;
    margin-left: 10px;

    :hover {
        svg {
            fill: ${theme.colors.black};

            path {
                fill: ${theme.colors.black};
            }
        }
    }
`;
