import styled from "styled-components/macro";
import { IInputProps } from ".";
import { BaseFormElementStyle } from "../styles";
import { theme } from "../../../config/theme";
import { generateSizeStyles } from "../../../helpers/generateSizeStyles";
import { generateSpacingStyles } from "../../../helpers/generateSpacingStyles";

interface IElementProps extends Omit<IInputProps, "error"> {
    error?: boolean;
}

export const InputGoogleMapsElement = styled.input<IElementProps>`
    padding: 12px;
    font-size: 13px;

    ${(props) => generateSpacingStyles(props)};
    ${(props) => generateSizeStyles(props)};

    ${BaseFormElementStyle};
`;

export const InputGoogleMapsContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;

    /* > input {
    width: 100%;
} */

    > svg {
        fill: ${theme.colors.placeholder};
        right: 8px;
        position: absolute;
    }

    input:focus + svg {
        display: none;
    }

    input[type="search"]:hover + svg {
        display: none;
    }
`;
