export function generateSpacingStyles(
    props: any,
    attrName?: "margin" | "padding"
) {
    let spacingStyle = "";
    let attr = attrName || "margin";

    if (!!props?.all) {
        spacingStyle = `${attr}: ${props.all};\n`;
    }

    if (!!props?.top) {
        spacingStyle = `${attr}-top: ${props.top};\n`;
    }

    if (!!props?.bottom) {
        spacingStyle += `${attr}-bottom: ${props.bottom};\n`;
    }

    if (!!props?.left) {
        spacingStyle += `${attr}-left: ${props.left};\n`;
    }

    if (!!props?.right) {
        spacingStyle += `${attr}-right: ${props.right};\n`;
    }

    return spacingStyle;
}
