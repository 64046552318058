import React from "react";
import { dictionary } from "../../../config/dictionary";
import convertEnumToOptions from "../../../helpers/convertEnumToOptions";
import { InputType } from "../../../utils/enums/InputType";
import { IInputField } from "../../../utils/interfaces/IInputField";
import Input from "../Input";
import Select from "../Select";
import AsyncSelect from "../AsyncSelect";
import SelectAvailation from "../SelectAvailation";
import { WeekDays } from "utils/enums/WeekDays";
import { Controller } from "react-hook-form";
import Editor from "../Editor";
import InputMask from "../InputMask";
import InputSwitch from "../InputSwitch";

const InputFactory = ({
    name,
    type,
    label,
    required = false,
    options = [],
    enumValue,
    isClearable = true,
    graphQL,
    isMulti = false,
    disabled = false,
    onChange,
    register,
    control,
    errorMessage,
}: IInputField) => {
    const currentLabel = label ?? (dictionary as any)[name] ?? name;

    switch (type) {
        case InputType.Email:
        case InputType.Text:
        case InputType.Number:
        case InputType.Hidden:
        case InputType.Date:
        case InputType.DateWithHour:
        case InputType.Password:
            return (
                <Input
                    name={name}
                    type={type}
                    label={currentLabel}
                    required={required}
                    disabled={disabled}
                    onChange={onChange}
                    placeholder={currentLabel}
                    error={errorMessage}
                    {...register(name, { required })}
                />
            );

        case InputType.Time:
            return (
                <Input
                    name={name}
                    type={InputType.Time}
                    label={currentLabel}
                    required={required}
                    disabled={disabled}
                    onChange={onChange}
                    min="00:00"
                    max="23:59"
                    error={errorMessage}
                    {...register(name, { required })}
                />
            );

        case InputType.Enum:
            const selectOptions = !!enumValue
                ? convertEnumToOptions(enumValue)
                : [];

            return (
                <Controller
                    name={name}
                    control={control}
                    rules={{ required }}
                    render={({ field }: any) => (
                        <Select
                            options={selectOptions}
                            label={currentLabel}
                            required={required}
                            isClearable={isClearable}
                            isMulti={isMulti}
                            disabled={disabled}
                            onChange={onChange}
                            error={errorMessage}
                            {...field}
                        />
                    )}
                />
            );
        case InputType.Select:
            return (
                <Controller
                    name={name}
                    control={control}
                    rules={{ required }}
                    render={({ field }: any) => (
                        <Select
                            options={options}
                            label={currentLabel}
                            required={required}
                            isClearable={isClearable}
                            isMulti={isMulti}
                            disabled={disabled}
                            error={errorMessage}
                            {...field}
                        />
                    )}
                />
            );

        case InputType.AsyncSelect:
            return (
                <Controller
                    name={name}
                    control={control}
                    rules={{ required }}
                    render={({ field }: any) => (
                        <AsyncSelect
                            name={name}
                            label={currentLabel}
                            required={required}
                            isClearable={isClearable}
                            query={graphQL?.searchQuery}
                            defaultSearch={graphQL?.defaultSearch}
                            isMulti={isMulti}
                            isDisabled={disabled}
                            onChange={onChange}
                            options={options}
                            error={errorMessage}
                            {...field}
                        />
                    )}
                />
            );

        case InputType.SelectAvailation:
            return (
                <Controller
                    name={name}
                    control={control}
                    rules={{ required }}
                    render={({ field }: any) => (
                        <SelectAvailation
                            label={currentLabel}
                            required={required}
                            isClearable={isClearable}
                            isMulti={isMulti}
                            disabled={disabled}
                            onChange={onChange}
                            error={errorMessage}
                            {...field}
                        />
                    )}
                />
            );

        case InputType.WeekDays:
            return (
                <Controller
                    name={name}
                    control={control}
                    rules={{ required }}
                    render={({ field }: any) => (
                        <Select
                            options={convertEnumToOptions(WeekDays)}
                            label={currentLabel}
                            required={required}
                            isClearable={isClearable}
                            isMulti={isMulti}
                            disabled={disabled}
                            onChange={onChange}
                            error={errorMessage}
                            {...field}
                        />
                    )}
                />
            );

        case InputType.Editor:
            return (
                <Controller
                    name={name}
                    control={control}
                    rules={{ required }}
                    render={({ field }: any) => {
                        const { onChange, name, value } = field;

                        return (
                            <Editor
                                value={value}
                                label={currentLabel}
                                required={required}
                                onChange={onChange}
                                error={errorMessage}
                                name={name}
                            />
                        );
                    }}
                />
            );

        case InputType.Document:
        case InputType.Phone:
        case InputType.CEP:
        case InputType.CPNJ:
            const maskByType = {
                [InputType.Document]: "999.999.999-99",
                [InputType.Phone]: "(99) 99999-9999",
                [InputType.CEP]: "99999-999",
                [InputType.CPNJ]: "99.999.999/9999-99",
            };

            return (
                <Controller
                    name={name}
                    control={control}
                    rules={{ required }}
                    render={({ field }: any) => {
                        const { name, onChange, value } = field;
                        return (
                            <InputMask
                                name={name}
                                type={type}
                                label={currentLabel}
                                required={required}
                                disabled={disabled}
                                onChange={onChange}
                                placeholder={currentLabel}
                                error={errorMessage}
                                value={value}
                                mask={maskByType[type]}
                            />
                        );
                    }}
                />
            );

        case InputType.Switch:
            return (
                <Controller
                    name={name}
                    control={control}
                    rules={{ required }}
                    render={({ field }: any) => {
                        return (
                            <InputSwitch
                                {...field}
                                ref={field.ref}
                                label={currentLabel}
                                error={errorMessage}
                            />
                        );
                    }}
                />
            );

        default:
            return null;
    }
};

export default InputFactory;
