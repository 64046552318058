export const createProjectClassProfessionalQuery = `mutation ($body: project_class_professional_insert_input!) {
    item: insert_project_class_professional_one(object: $body) {
      id_professional
      professional {
        id_professional
        person {
            name
        }
        professional_position {
            name
        }
      }
      project_class {
        name
        id_project_class
      }
    }
  }
  `;
