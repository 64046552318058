import styled from "styled-components";
import { VerticalDiv } from "../../../../assets/globalStyles";
import { PersonContainer } from "../../../../components/Person/styles";
import { theme } from "../../../../config/theme";

export const StudentResponsibleList = styled(VerticalDiv)`
    margin-bottom: 35px;

    ${PersonContainer} {
        :not(:last-child) {
            padding-bottom: 10px;
            margin-bottom: 10px;
            border-bottom: 1px solid ${theme.colors.mainLight};
        }
    }
`;
