import styled from "styled-components";
import { MediaQueries } from "../../utils/mediaQueries";

export const NavbarContainer = styled.div`
    display: flex;

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        height: 100%;
    }

    .navbarBig-enter {
        opacity: 0;
        transform: translateX(-100%);
    }
    .navbarBig-enter-active {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 300ms, transform 300ms;
    }
    .navbarBig-exit {
        opacity: 1;
    }
    .navbarBig-exit-active {
        opacity: 0;
        transform: translateX(-100%);
        transition: opacity 300ms, transform 300ms;
    }
`;
