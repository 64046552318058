import DateService from "../services/date";

export function isValidDate(date?: Date | string | null) {
    if (!date) {
        return false;
    }

    const isDateString = typeof date === "string";

    let currentDate = isDateString
        ? DateService.fromISO(date as string)
        : DateService.fromJSDate(date as Date);

    return currentDate.isValid;
}
