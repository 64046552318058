import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import { theme } from "../../../config/theme";
import { MainContainer } from "../../../pages/Main/styles";
import { FontWeight } from "../../../utils/enums/FontWeight";

const colors = theme.colors.components.navbar;

export const NavbarMobileBar = styled.div`
    display: flex;
    background: ${colors.small.background};
    width: 100%;
    height: 63px;
    padding: 0 20px;
    align-items: center;
    border-bottom: 1px solid ${theme.colors.components.navbar.big.borderColor};

    > img,
    > svg {
        margin-right: auto;
    }

    > button {
        background: transparent;
        border: none;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${colors.small.iconColor};
        text-transform: uppercase;

        > svg {
            margin-left: 10px;
        }
    }
`;

export const NavbarMobileModal = styled.div`
    display: flex;
    background: ${colors.big.background};
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 3;
    top: 0;
    left: 0;
    flex-direction: column;
`;

export const NavbarMobileList = styled(MainContainer)`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
`;

const itemStyles = css`
    color: ${colors.big.color};
    font-size: 18px;
    margin-bottom: 30px;
    font-weight: ${FontWeight.Regular};
    text-decoration: none;
    text-transform: none;
    display: flex;
    align-items: center;

    > span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &.active {
        :after {
            position: absolute;
            display: block;
            width: 2px;
            height: calc(100% + 4px);
            right: -15px;
            background: ${colors.big.colorActive};
            content: " ";
        }
    }

    &.active {
        color: ${colors.big.colorActive};
        position: relative;

        > svg {
            fill: ${colors.big.colorActive};

            > path {
                fill: ${colors.big.colorActive};
            }
        }
    }

    :hover {
        color: ${colors.big.colorHover};
        position: relative;

        > svg {
            fill: ${colors.big.colorHover};

            > path {
                fill: ${colors.big.colorHover};
            }
        }
    }

    > svg {
        margin-right: 10px;
        fill: ${colors.big.iconColor};

        > path {
            fill: ${colors.big.iconColor};
        }
    }
`;

export const NavbarMobileListItem = styled.span`
    ${itemStyles}
`;

export const NavbarMobileListItemLink = styled(NavLink)`
    ${itemStyles}
`;
