export function getProfessionalByPositon(searchText?: string, positionId = 1) {
    return `query ($searchText: String, $positionId: Int) {
        items: professional(
            limit: 10, 
            order_by: {person: {name: asc}}, 
            where: {
                person: {
                    _or: [
                        {name: {_ilike: ${searchText}}}, {user: {email: {_ilike: ${searchText}}}}
                    ]
                },
                professional_position_id: {_eq: ${positionId}}
            }
        ) {
          value: id
          person {
            name
            user {
              email
            }
          }
        }
        quantity: professional_aggregate(where: {person: {_or: [{name: {_ilike: $searchText}}, {user: {email: {_ilike: $searchText}}}]}}) {
          aggregate {
            count
          }
        }
    }`;
}

const professionalQueryByPosition = (
    positionId: number
) => `query ($searchText: String) {
    items: professional(limit: 10, order_by: {person: {name: asc}}, where: {person: {_or: [{name: {_ilike: $searchText}}, {user: {email: {_ilike: $searchText}}}]}, professional_position_id: {_eq: ${positionId}}}) {
      value: id
      person {
        name
        user {
          email
        }
      }
    }
    quantity: professional_aggregate(where: {person: {_or: [{name: {_ilike: $searchText}}, {user: {email: {_ilike: $searchText}}}]}, professional_position_id: {_eq: ${positionId}}}) {
      aggregate {
        count
      }
    }
  }
  
`;

export const searchProfessionalsQuery = professionalQueryByPosition(1);

export const searchAssistantQuery = professionalQueryByPosition(2);
