import styled from "styled-components/macro";
import { Content } from "../../assets/globalStyles";

export const MainWrap = styled(Content)`
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: 1024px) {
        flex-direction: row;
    }
`;

export const MainContainer = styled.div`
    margin: 0 auto;
    padding: 0 20px;
    width: 1300px;
    max-width: 100%;
`;

export const MainContent = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    flex-direction: column;

    padding-top: 0;
`;

export const MainContentScroll = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;
