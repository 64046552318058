import React from "react";

import { AvailationContainer as Container } from "./styles";
import StarIcon from "../Icons/star";
import { theme } from "../../config/theme";

interface IProps {
    vote: 1 | 2 | 3 | 4 | 5;
}

const Availation: React.FC<IProps> = ({ vote }) => {
    return (
        <Container>
            {[1, 2, 3, 4, 5].map((index) => {
                const isActive = index <= vote;

                return (
                    <StarIcon
                        key={`start_${index}`}
                        width={16}
                        height={16}
                        fill={isActive ? theme.colors.main : "transparent"}
                        {...(!isActive && {
                            stroke: theme.colors.main,
                            strokeWidth: 45,
                        })}
                    />
                );
            })}
        </Container>
    );
};

export default Availation;
