import React from "react";
import { Content, GlobalStyles } from "./assets/globalStyles";

import { Provider } from "react-redux";
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import Spinner from "./components/Spinner";
import Modal from "./components/Modal";
import Routes from "./routes";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import ErrorBoundary from "./components/ErrorBoundary";

function App() {
    return (
        <>
            <GlobalStyles />
            <Content id="app-content">
                <ErrorBoundary>
                    <Provider store={store}>
                        <PersistGate
                            loading={<Spinner fullScreen={true} />}
                            persistor={persistor}
                        >
                            <Routes />

                            <ToastContainer closeOnClick />
                            <Modal />
                        </PersistGate>
                    </Provider>
                </ErrorBoundary>
            </Content>
        </>
    );
}

export default App;
