export enum InputType {
    Text = "text",
    Email = "email",
    Number = "number",
    Select = "select",
    Enum = "enum",
    Hidden = "hidden",
    AsyncSelect = "asyncSelect",
    Date = "date",
    DateWithHour = "datetime-local",
    Search = "search",
    Time = "time",
    SelectAvailation = "selectAvailation",
    WeekDays = "weekDays",
    Editor = "editor",
    Password = "password",
    Document = "document",
    Phone = "phone",
    CEP = "cep",
    CPNJ = "cnpj",
    Space = "space",
    Switch = "switch",
}
