import { IRoute } from "utils/interfaces/IRoute";
import { professionalColumns } from "./columns";
import CrudList from "pages/CRUD/List";
import ProfessionalCreateOrEdit from "pages/Professional/createOrEdit";
import Professional from "pages/Professional";

export const professionalsRoutes: IRoute[] = [
    {
        title: "Profissionais",
        path: "/profissionais",
        graphql: {
            companyPath: "company_id",
            tableName: "professional",
            orderBy: {
                person: {
                    name: "asc",
                },
            },
        },
        mainColumn: "id",
        component: CrudList,
        exact: true,
        canEdit: true,
        canSee: true,
        canDelete: true,
        canCreate: true,
        columns: professionalColumns,
        showTotal: true,
    },
    {
        title: "Novo profissional",
        path: "/profissionais/novo",
        exact: true,
        component: ProfessionalCreateOrEdit,
    },
    {
        title: "Editar profissional",
        path: "/profissionais/editar/:id",
        exact: true,
        component: ProfessionalCreateOrEdit,
        mainColumn: "id",
    },

    {
        title: "Visualizar profissional",
        hideTitle: true,
        path: "/profissionais/ver/:id",
        exact: true,
        component: Professional,
    },
];
