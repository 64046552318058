import _get from "lodash/get";
import _set from "lodash/set";
import _isArray from "lodash/isArray";
import { IInputField } from "../utils/interfaces/IInputField";
import { InputType } from "../utils/enums/InputType";
import { dictionary } from "../config/dictionary";
import { WeekDays } from "utils/enums/WeekDays";
import { formatDate } from "./formatDate";

export default function formatInitialDataFormValues(
    data: object,
    fields: IInputField[]
) {
    const tempData: any = { ...data };

    fields.forEach((field) => {
        const type = field.type;

        if (type === InputType.AsyncSelect) {
            const fieldName = field.name.replace("id_", "");
            const dataValue = _get(tempData, fieldName);

            if (!!field?.options?.length) {
                const optionValue = field?.options?.find((item) => {
                    return new RegExp(dataValue?.toLocaleLowerCase()).test(
                        item?.label?.toLocaleLowerCase()
                    );
                });

                return _set(tempData, field.name, optionValue || null);
            }

            const label =
                dataValue?.name ??
                dataValue?.title ??
                dataValue?.person?.name ??
                null;

            const finalValue = _isArray(dataValue)
                ? dataValue.map((item) => ({
                      value: item?.id ?? item?.value ?? null,
                      label:
                          item?.name ??
                          item?.title ??
                          item?.person?.name ??
                          null,
                  }))
                : {
                      value: dataValue?.id ?? null,
                      label,
                  };

            _set(tempData, field.name, !!dataValue ? finalValue : null);
        }

        if (
            type === InputType.Enum ||
            type === InputType.Select ||
            type === InputType.WeekDays
        ) {
            const dataValue = _get(tempData, field.name);

            const isEnum =
                field.type === InputType.WeekDays ||
                (field.type === InputType.Enum && !!field?.enumValue);

            if (isEnum) {
                const enumValues =
                    field.type === InputType.WeekDays
                        ? WeekDays
                        : field.enumValue;

                if (field.isMulti || field.type === InputType.WeekDays) {
                    const formatMultiValue = dataValue
                        .split(";")
                        .map((value: any) => ({
                            value: Number(value),
                            label:
                                (dictionary as any)[enumValues[value]] ??
                                enumValues[value] ??
                                value,
                        }));

                    return _set(tempData, field.name, formatMultiValue);
                }

                const enumKey = enumValues[dataValue];

                if (!enumKey) {
                    return _set(tempData, field.name, null);
                }

                const optionValue = {
                    value: dataValue,
                    label: (dictionary as any)[enumKey] ?? enumKey,
                };

                _set(tempData, field.name, optionValue);
            }
        }

        if (type === InputType.Date) {
            const dataValue = _get(tempData, field.name);

            _set(tempData, field.name, formatDate(dataValue, "yyyy-LL-dd"));
        }

        if (type === InputType.Time) {
            const dataValue = _get(tempData, field.name);

            _set(tempData, field.name, formatDate(dataValue, "HH:mm"));
        }
    });

    return tempData;
}
