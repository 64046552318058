export default function scrollToTop() {
    if (!window?.scroll) {
        return;
    }

    window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
    });
}
