import React, { useCallback } from "react";
import { InputType } from "../../utils/enums/InputType";
import Input from "../Form/Input";
import InputGoogleMaps from "../Form/InputGoogleMaps";
import { Col, Row } from "../Grid";

import GoogleMaps from "../GoogleMaps";

interface IProps {
    address?: any;
    onChange(address?: any): void;
    onChangeMap(address?: any): void;
    onChangeInputName(address?: any): void;
    onSelectAddress(address?: any): void;
    errors: any;
}

const AddressForm = ({
    address,
    onChange,
    onChangeInputName,
    onChangeMap,
    onSelectAddress,
    errors,
}: IProps) => {
    const handleChangeInputValue = useCallback(
        (inputName: string, value: any) => {
            onChange({
                [inputName]: value,
            });
        },
        [onChange]
    );

    return (
        <>
            <Row>
                <Col md={12}>
                    <InputGoogleMaps
                        name="name"
                        label="Endereço"
                        onChangeAddress={onSelectAddress}
                        placeholder="Busque por um local..."
                        autoComplete="none"
                        value={address?.name}
                        onChange={(e) =>
                            onChangeInputName({ name: e.target.value })
                        }
                        error={errors?.name}
                    />
                </Col>
            </Row>

            <div id="form-address" style={{ display: "none" }}>
                <Row>
                    <Input
                        name="place_id"
                        type={InputType.Hidden}
                        disabled
                        value={address?.place_id}
                    />

                    <Input
                        name="latitude"
                        type={InputType.Hidden}
                        disabled
                        value={address?.latitude}
                    />

                    <Input
                        name="longitude"
                        type={InputType.Hidden}
                        disabled
                        value={address?.longitude}
                    />

                    <Col md={3}>
                        <Input
                            label="CEP"
                            name="zip"
                            type={InputType.Text}
                            value={address?.zip}
                            error={errors?.zip}
                            onChange={(e) =>
                                handleChangeInputValue("zip", e.target.value)
                            }
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={8}>
                        <Input
                            error={errors?.street}
                            label="Rua"
                            name="street"
                            type={InputType.Text}
                            value={address?.street}
                            onChange={(e) =>
                                handleChangeInputValue("street", e.target.value)
                            }
                        />
                    </Col>

                    <Col md={4}>
                        <Input
                            label="Número"
                            name="number"
                            required={false}
                            type={InputType.Text}
                            value={address?.number}
                            error={errors?.number}
                            onChange={(e) =>
                                handleChangeInputValue("number", e.target.value)
                            }
                        />
                    </Col>

                    <Col md={4}>
                        <Input
                            error={errors?.neighbourhood}
                            label="Bairro"
                            name="neighbourhood"
                            type={InputType.Text}
                            value={address?.neighbourhood}
                            onChange={(e) =>
                                handleChangeInputValue(
                                    "neighbourhood",
                                    e.target.value
                                )
                            }
                        />
                    </Col>

                    <Col md={4}>
                        <Input
                            error={errors?.city}
                            label="Cidade"
                            name="city"
                            type={InputType.Text}
                            value={address?.city}
                            onChange={(e) => {
                                handleChangeInputValue("city", e.target.value);
                            }}
                        />
                    </Col>
                    <Col md={4}>
                        <Input
                            error={errors?.state}
                            label="Estado"
                            name="state"
                            type={InputType.Text}
                            value={address?.state}
                            onChange={(e) =>
                                handleChangeInputValue("state", e.target.value)
                            }
                        />
                    </Col>

                    <Col md={6}>
                        <Input
                            label="Complemento"
                            name="complement"
                            type={InputType.Text}
                            value={address?.complement}
                            error={errors?.complement}
                            onChange={(e) =>
                                handleChangeInputValue(
                                    "complement",
                                    e.target.value
                                )
                            }
                        />
                    </Col>

                    {address && (
                        <Col md={12} bottom="30px" id="google-maps-preview">
                            <GoogleMaps
                                latitude={address?.latitude}
                                longitude={address?.longitude}
                                place={address?.name}
                                onChange={onChangeMap}
                            />
                        </Col>
                    )}
                </Row>
            </div>
        </>
    );
};

export default AddressForm;
