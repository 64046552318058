import getGoogleMapFieldByType from "./getGoogleMapFieldByType";

export default function formatAddressComponents(address_components: any[]) {
    const country = getGoogleMapFieldByType(address_components, "country");
    const city = getGoogleMapFieldByType(
        address_components,
        "administrative_area_level_2"
    );
    const state = getGoogleMapFieldByType(
        address_components,
        "administrative_area_level_1"
    );

    const neighbourhood = getGoogleMapFieldByType(
        address_components,
        "sublocality_level_1"
    );

    const street = getGoogleMapFieldByType(address_components, "route");

    const number = getGoogleMapFieldByType(address_components, "street_number");

    const zip = getGoogleMapFieldByType(address_components, "postal_code");

    return {
        country,
        city,
        state,
        neighbourhood,
        street,
        number,
        zip,
    };
}
