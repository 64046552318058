import React, { useMemo } from "react";
import { theme } from "../../config/theme";
import { InputType } from "../../utils/enums/InputType";
import { TableColumnType } from "../../utils/enums/TableColumnType";
import { ITableColumn } from "../../utils/interfaces/ITable";
import Form from "../Form";
import Input from "../Form/Input";
import SearchIcon from "../Icons/search";
import Select from "../Form/Select";
import { Status } from "../../utils/enums/Status";
import convertEnumToOptions from "../../helpers/convertEnumToOptions";
import SelectAvailation from "../Form/SelectAvailation";
import { WeekDays } from "utils/enums/WeekDays";
import { ISelectOption } from "utils/interfaces/ISelectOption";
import AsyncSelect from "components/Form/AsyncSelect";
import generateSearchQuery from "helpers/generateSearchQuery";

interface IProps {
    column: ITableColumn;
    onChange(value: any, column: ITableColumn): void;
}

const TableHeaderSearchItem = ({ column, onChange }: IProps) => {
    const input = useMemo(() => {
        switch (column.type) {
            case TableColumnType.Text:
            case TableColumnType.Number:
            case TableColumnType.Person:
            case TableColumnType.Link:
            case TableColumnType.Date:
            case TableColumnType.DateWithTime:
                const name =
                    column.type === TableColumnType.Link
                        ? `${column.name}.title`
                        : column.name;

                // WTF!? WHAT'S THAT SHIT?
                const typeName = `${column.type[0].toUpperCase()}${column.type?.substr(
                    1,
                    column.type.length
                )}`;

                console.log("typeName", typeName);
                const inputType =
                    column.type === TableColumnType.DateWithTime
                        ? InputType.Date
                        : (InputType as any)[typeName] || InputType.Search;

                console.log(column.name, column.type, { inputType });

                return (
                    <Input
                        placeholder="Buscar..."
                        type={inputType}
                        name={`search.${name}`}
                        showError={false}
                        width="100%"
                        icon={
                            [
                                InputType.Date,
                                InputType.Time,
                                InputType.DateWithHour,
                            ].includes(inputType) ? null : (
                                <SearchIcon
                                    width={15}
                                    height={15}
                                    fill={theme.colors.placeholder}
                                />
                            )
                        }
                        onChange={(e) =>
                            onChange(e.target.value, { ...column, name })
                        }
                        {...(column.type === TableColumnType.Number && {
                            min: 0,
                        })}
                    />
                );

            case TableColumnType.Status:
                return (
                    <Select
                        name={`search.${column.name}`}
                        placeholder="Selecione..."
                        options={convertEnumToOptions(Status)}
                        isClearable
                        onChange={(e: any) => onChange(e?.value, column)}
                    />
                );

            case TableColumnType.WeekDays:
                return (
                    <Select
                        name={`search.${column.name}`}
                        placeholder="Selecione..."
                        options={convertEnumToOptions(WeekDays)}
                        isClearable
                        onChange={(e: any) => onChange(e?.value, column)}
                    />
                );

            case TableColumnType.Enum:
                return (
                    <Select
                        name={`search.${column.name}`}
                        placeholder="Selecione..."
                        options={convertEnumToOptions(column?.enumValue)}
                        isClearable
                        onChange={(e: any) => onChange(e?.value, column)}
                    />
                );

            case TableColumnType.Availation:
                return (
                    <SelectAvailation
                        name={`search.${column.name}`}
                        placeholder="Selecione..."
                        isClearable
                        onChange={(e: any) => onChange(e?.value, column)}
                    />
                );

            case TableColumnType.Boolean:
                const options: ISelectOption<boolean>[] = [
                    {
                        label: "Sim",
                        value: true,
                    },
                    {
                        label: "Não",
                        value: false,
                    },
                ];

                return (
                    <Select
                        name={`search.${column.name}`}
                        placeholder="Selecione..."
                        options={options}
                        isClearable
                        onChange={(e: any) => onChange(e?.value, column)}
                    />
                );

            case TableColumnType.AsyncSelect:
                return (
                    <AsyncSelect
                        name={`search.${column.name}`}
                        isClearable
                        isMulti={false}
                        query={generateSearchQuery({ tableName: column.name })}
                        onChange={(e: any) => onChange(e?.value, column)}
                    />
                );

            default:
                return null;
        }
    }, [column, onChange]);

    if (!input) {
        return null;
    }

    return <Form onSubmit={(e) => e.preventDefault()}>{input}</Form>;
};

export default TableHeaderSearchItem;
