import _set from "lodash/set";

type ExampleErr = {
    inner?: {
        message: string;
        path: string;
    }[];
};

export default function getYupErrors(err: ExampleErr) {
    let tmpErr: any = {};

    err?.inner?.forEach(({ path, message }) => {
        _set(tmpErr, path, message);
    });

    return tmpErr;
}
