import React, { memo } from "react";
import { theme } from "../../config/theme";
import { IIconProps } from "../../utils/interfaces/IIconProps";

const ArrowLeftIcon = memo(
    ({ fill = theme.colors.black, ...props }: IIconProps) => {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                {...props}
                viewBox="0 0 16 14"
            >
                <defs>
                    <clipPath id="clip-path">
                        <path
                            d="M15,6H3.135L6.768,1.64A1,1,0,0,0,5.232.36l-5,6a.942.942,0,0,0-.088.154.947.947,0,0,0-.071.124A.985.985,0,0,0,0,7V7S0,7,0,7a.985.985,0,0,0,.072.358.947.947,0,0,0,.071.124.942.942,0,0,0,.088.154l5,6a1,1,0,0,0,1.536-1.28L3.135,8H15a1,1,0,0,0,0-2"
                            transform="translate(4 5)"
                            fill={fill}
                        />
                    </clipPath>
                </defs>
                <g transform="translate(-4 -5)">
                    <path
                        d="M15,6H3.135L6.768,1.64A1,1,0,0,0,5.232.36l-5,6a.942.942,0,0,0-.088.154.947.947,0,0,0-.071.124A.985.985,0,0,0,0,7V7S0,7,0,7a.985.985,0,0,0,.072.358.947.947,0,0,0,.071.124.942.942,0,0,0,.088.154l5,6a1,1,0,0,0,1.536-1.28L3.135,8H15a1,1,0,0,0,0-2"
                        transform="translate(4 5)"
                        fill={fill}
                    />
                </g>
            </svg>
        );
    }
);

export default ArrowLeftIcon;
