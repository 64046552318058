import React, { useCallback } from "react";
import { theme } from "../../config/theme";
import useIsMobile from "../../hooks/useIsMobile";
import Dropdown from "../Dropdown";
import DropdownLink from "../Dropdown/link";
import UserIcon from "../Icons/user";
import CustomText from "../Text";
import { useAppDispatch } from "../../hooks/store";
import { Creators } from "../../store/auth";
import { version } from "../../../package.json";

const HeaderDropdown: React.FC = () => {
    const isMobile = useIsMobile();

    const dispatch = useAppDispatch();

    const handleClickLogout = useCallback(
        () => dispatch(Creators.logout()),
        [dispatch]
    );

    return (
        <Dropdown
            minContentWidth="150px"
            button={{
                label: "",
                variant: "light",
                icon: (
                    <UserIcon
                        width={18}
                        height={18}
                        fill={theme.colors[isMobile ? "white" : "base"]}
                    />
                ),
            }}
        >
            <DropdownLink onClick={() => null}>
                <CustomText color="base" size={16}>
                    Minha conta
                </CustomText>
            </DropdownLink>
            <DropdownLink onClick={handleClickLogout}>
                <CustomText color="danger" size={16}>
                    Sair
                </CustomText>
            </DropdownLink>

            <CustomText
                color="placeholder"
                size={10}
                italic
                style={{ marginLeft: "auto" }}
            >
                versão {version}
            </CustomText>
        </Dropdown>
    );
};

export default HeaderDropdown;
