import React, { memo, useRef, useState } from "react";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { ColorsVariants } from "../../utils/types/Variants";
import Button from "../Button";

import * as S from "./styles";

interface IProps {
    button: {
        label?: string | React.ReactNode;
        variant: ColorsVariants;
        icon?: React.ReactNode;
    };
    showIndicator?: boolean;
    minContentWidth?: string;
}

const Dropdown: React.FC<IProps> = memo(
    ({
        button,
        showIndicator = false,
        children,
        minContentWidth = "200px",
    }) => {
        const [active, setActive] = useState(false);
        const refContainer = useRef<HTMLDivElement>(null);

        useOnClickOutside(refContainer, () => active && setActive(false));

        return (
            <S.DropDownContainer ref={refContainer}>
                <S.DropDownButtonContainer>
                    <Button
                        variant={button.variant || "main"}
                        icon={button.icon}
                        onClick={() => setActive((old) => !old)}
                    >
                        {button?.label}
                    </Button>

                    {showIndicator && active && <S.DropDownIndicator />}
                </S.DropDownButtonContainer>

                {active && (
                    <S.DropDownContent minWidth={minContentWidth}>
                        {children}
                    </S.DropDownContent>
                )}
            </S.DropDownContainer>
        );
    }
);

export default Dropdown;
