import React, { useCallback } from "react";
import { theme } from "../../config/theme";
import { ISpacing } from "../../utils/interfaces/ISpacing";
import EditIcon from "../Icons/edit";
import PlusIcon from "../Icons/plus";

import * as S from "./styles";

interface IProps extends ISpacing {
    size?: string;
    image?: string;
    onEdit?(): void;
    name?: string;
    style?: any;
}

const Avatar = ({ size = "100%", image, onEdit, name, style }: IProps) => {
    const handleEditImage = useCallback(() => {}, []);

    const letter = name?.charAt(0)?.toUpperCase();

    const showLetter = !!letter && !image;

    return (
        <S.AvatarContainer size={size} image={image} style={style}>
            {showLetter && <span>{letter}</span>}
            {!!onEdit && (
                <S.AvatarButton onClick={handleEditImage}>
                    {!!image ? (
                        <EditIcon
                            fill={theme.colors.white}
                            width={20}
                            height={20}
                        />
                    ) : (
                        <PlusIcon
                            fill={theme.colors.white}
                            width={20}
                            height={20}
                        />
                    )}
                </S.AvatarButton>
            )}
        </S.AvatarContainer>
    );
};

export default Avatar;
