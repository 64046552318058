export default function pluralize(
    quantity: number | undefined,
    singularText: string,
    pluralText: string
) {
    if (!quantity || quantity <= 1) {
        return singularText;
    }

    return pluralText;
}
