import { IInputField } from "../utils/interfaces/IInputField";

export default function generateEditQuery(
    tableName: string,
    fields: IInputField[],
    mainColumn = "id"
) {
    const generateFields = fields
        ?.map(({ name }) => name)
        ?.filter(Boolean)
        ?.join("\n");

    return `mutation ($body: ${tableName}_set_input, $id: Int!) {
        item: update_${tableName}_by_pk(pk_columns: {${mainColumn}: $id}, _set: $body) {
          ${mainColumn}
          ${generateFields}
        }
      }`;
}
