export enum TableColumnType {
    Text = "text",
    Number = "number",
    Badge = "badge",
    Date = "date",
    Person = "person",
    Hidden = "hidden",
    Status = "status",
    Enum = "enum",
    Availation = "availation",
    Link = "link",
    Avatar = "avatar",
    DateWithTime = "dateWithTime",
    WeekDays = "weekDays",
    Time = "time",
    Custom = "custom",
    Boolean = "boolean",
    AsyncSelect = "asyncSelect",
}
