import { number, object, string } from "yup";
import { dictionary } from "../../config/dictionary";
import CrudCreateOrEdit from "../../pages/CRUD/CreateOrEdit";

import CrudList from "../../pages/CRUD/List";
import { InputType } from "../../utils/enums/InputType";
import { TableColumnType } from "../../utils/enums/TableColumnType";
import { IInputField } from "../../utils/interfaces/IInputField";
import { IRoute } from "../../utils/interfaces/IRoute";
import generateCreateQuery from "helpers/generateCreateQuery";
import generateEditQuery from "helpers/generateEditQuery";
import generateGetByIdQuery from "helpers/generateGetByIdQuery";
import { Status } from "utils/enums/Status";

const schema = object().shape({
    title: string()
        .typeError(dictionary.validations.required)
        .required(dictionary.validations.required),
    status: number()
        .transform((_, v) => v?.value)
        .typeError(dictionary.validations.required)
        .required(dictionary.validations.required),
});

const fields: IInputField[] = [
    {
        name: "title",
        type: InputType.Text,
        required: true,
        grid: {
            col: 6,
        },
    },
    {
        name: "status",
        type: InputType.Enum,
        enumValue: Status,
        required: true,
        grid: {
            col: 6,
        },
    },
];

export const productsRoutes: IRoute[] = [
    {
        title: "Produtos",
        path: "/produtos",
        exact: true,
        graphql: {
            tableName: "product",
            orderBy: {
                title: "asc",
            },
        },
        mainColumn: "id",
        component: CrudList,
        canEdit: true,
        canDelete: true,
        canCreate: true,
        showTotal: true,
        columns: [
            {
                col: "auto",
                name: "title",
                type: TableColumnType.Text,
            },
            {
                col: 3,
                name: "status",
                type: TableColumnType.Status,
            },
        ],
    },
    {
        title: "Novo produto",
        path: "/produtos/novo",
        graphql: {
            tableName: "modality",
            create: generateCreateQuery("product", fields),
        },
        component: CrudCreateOrEdit,
        exact: true,
        schema,
        fields,
    },
    {
        title: "Editar produto",
        path: "/produtos/editar/:id",
        graphql: {
            edit: generateEditQuery("product", fields),
            getById: generateGetByIdQuery("product", fields),
        },
        component: CrudCreateOrEdit,
        mainColumn: "id",
        exact: true,
        schema,
        fields,
    },
];
