import React, {
    memo,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { dictionary } from "../../config/dictionary";
import useDebounce from "../../hooks/useDebounce";
import { ITable, ITableColumn } from "../../utils/interfaces/ITable";
import Button from "../Button";

import Pagination from "../Pagination";
import Spinner from "../Spinner";
import CustomText from "../Text";
import TableItem from "./item";
import _get from "lodash/get";
import _set from "lodash/set";

import * as S from "./styles";
import { TableColumnType } from "../../utils/enums/TableColumnType";
import convertToGraphQLSearch from "../../helpers/convertToGraphQLSearch";
import TableHeaderSearchItem from "./headerSearchItem";
import TableEmpty from "./empty";

const Table = ({
    columns,
    actions = [],
    data,
    pagination,
    isLoading,
    handleChangePagination = () => null,
    mainColumn,
    handleChangeSearch = () => null,
    isEmpty = false,
    error = false,
}: ITable) => {
    const withActions = useMemo(() => !!actions?.length, [actions]);

    const [search, setSearch] = useState<any>();

    // temporary table id to prevent unnecessary renders
    const tmpTableId = useRef(new Date().getTime());

    const debounceSearch = useDebounce(search, 600);

    useEffect(() => {
        if (!columns) {
            //
        }

        // clear search state every time columns change
        return () => {
            tmpTableId.current = new Date().getTime();
        };
    }, [columns]);

    useEffect(() => {
        return () => {
            setSearch(undefined);
        };
    }, []);

    const callbackSearch = useCallback(() => {
        // callback father only if current table id has state
        if (!debounceSearch) {
            return;
        }

        handleChangeSearch(
            convertToGraphQLSearch(debounceSearch[tmpTableId.current])
        );
    }, [debounceSearch, handleChangeSearch]);

    useEffect(() => {
        callbackSearch();
    }, [callbackSearch]);

    const handleChangeSearchText = useCallback(
        (value: any, column: ITableColumn) => {
            const tmpSearch = { ...search };

            _set(tmpSearch, `${tmpTableId.current}['${column.name}']`, {
                value:
                    value === ""
                        ? undefined
                        : (column.type === TableColumnType.Number
                              ? Number(value)
                              : value) ?? undefined,
                type: column.type,
            });

            setSearch(tmpSearch);
        },
        [search]
    );

    const hasFilters = useMemo(
        () => columns.some((item) => !item.removeSearch),
        [columns]
    );

    return (
        <>
            <S.TableScroll>
                <S.TableContainer
                    id="table-container"
                    cellPadding={0}
                    cellSpacing={0}
                >
                    <S.TableHeader>
                        <tr>
                            {columns.map((column, index) => {
                                return (
                                    <S.TableItem
                                        key={`column_${column.name}_${index}_${tmpTableId.current}`}
                                        as="th"
                                        style={column?.style}
                                    >
                                        <CustomText
                                            color="components.table.header.color"
                                            size={15}
                                            weight="SemiBold"
                                        >
                                            {column?.label ||
                                                (dictionary as any)[
                                                    column.name
                                                ] ||
                                                column.name}
                                        </CustomText>
                                    </S.TableItem>
                                );
                            })}
                            {withActions && (
                                <S.TableItem as="th">
                                    <CustomText
                                        color="components.table.header.color"
                                        size={15}
                                        weight="SemiBold"
                                    >
                                        Ação
                                    </CustomText>
                                </S.TableItem>
                            )}
                        </tr>
                    </S.TableHeader>
                    <S.TableBody>
                        {hasFilters && (
                            <tr>
                                {columns.map((column, index) => {
                                    if (column?.removeSearch) {
                                        return <S.TableItem key={index} />;
                                    }

                                    return (
                                        <S.TableItem
                                            key={index}
                                            style={{
                                                overflow: "unset",
                                            }}
                                        >
                                            <TableHeaderSearchItem
                                                column={column}
                                                onChange={
                                                    handleChangeSearchText
                                                }
                                            />
                                        </S.TableItem>
                                    );
                                })}
                                {withActions && <S.TableItem />}
                            </tr>
                        )}
                        {data.map((item, index) => {
                            const id =
                                !!mainColumn && !Array.isArray(mainColumn)
                                    ? item[mainColumn]
                                    : item?.id;

                            return (
                                <tr key={id ?? `table_${index}`}>
                                    {columns.map((column, ix) => {
                                        const columnValue = _get(
                                            item,
                                            column.name
                                        );

                                        const hasComponent =
                                            !!column?.component;

                                        if (column.name === "start_time") {
                                            console.log(
                                                column.name,
                                                hasComponent
                                            );
                                        }

                                        return (
                                            <S.TableItem
                                                key={`column_${index}_${ix}`}
                                                style={column?.style}
                                            >
                                                <TableItem
                                                    type={column.type}
                                                    value={columnValue}
                                                    enumValue={
                                                        column?.enumValue
                                                    }
                                                    path={column?.path}
                                                    component={
                                                        hasComponent
                                                            ? column?.component!(
                                                                  item
                                                              )
                                                            : null
                                                    }
                                                />
                                            </S.TableItem>
                                        );
                                    })}
                                    {withActions && (
                                        <S.TableActions>
                                            <div>
                                                {actions.map(
                                                    (
                                                        { icon, onClick },
                                                        index
                                                    ) => (
                                                        <Button
                                                            key={`action_${index}_${id}`}
                                                            variant="light"
                                                            onClick={() =>
                                                                onClick({
                                                                    id,
                                                                    ...item,
                                                                })
                                                            }
                                                        >
                                                            {icon(item)}
                                                        </Button>
                                                    )
                                                )}
                                            </div>
                                        </S.TableActions>
                                    )}
                                </tr>
                            );
                        })}

                        {error && (
                            <div>
                                <CustomText
                                    color="gray"
                                    size={15}
                                    weight="SemiBold"
                                    center
                                    top="25px"
                                    bottom="25px"
                                >
                                    Ops, aconteceu algo de errado.
                                </CustomText>
                            </div>
                        )}
                    </S.TableBody>
                </S.TableContainer>
                {isEmpty && <TableEmpty />}
                {isLoading && (
                    <Spinner
                        fullScreen={true}
                        backgroundColor={
                            !pagination?.totalItems ? "transparent" : undefined
                        }
                    />
                )}
            </S.TableScroll>
            {pagination && pagination.totalPages > 1 && (
                <Pagination
                    page={pagination?.page ?? 1}
                    totalPages={pagination?.totalPages ?? 1}
                    totalItems={pagination?.totalItems ?? 0}
                    onChangePage={handleChangePagination}
                    totalItemsPerPage={data?.length}
                />
            )}
        </>
    );
};

export default memo(Table);
