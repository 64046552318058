import { generateFileURL } from "helpers/generateFileURL";
import React from "react";
import { IFile } from "utils/interfaces/IFile";
import { theme } from "../../config/theme";
import Avatar from "../Avatar";
import Button from "../Button";
import DeleteIcon from "../Icons/delete";
import CustomText from "../Text";

import * as S from "./styles";

interface IProps {
    onClickRemove?(): void;
    name?: string;
    email?: string;
    image?: string;
    file?: IFile;
}

const Person: React.FC<IProps> = ({
    onClickRemove,
    image,
    name,
    email,
    file,
}) => {
    const imageURL = image || generateFileURL(file);

    return (
        <S.PersonContainer>
            <Avatar size="45px" image={imageURL} name={name} />

            <div
                style={{
                    flex: 1,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
            >
                <S.PersonName>
                    <CustomText color="black" size={15} right="10px">
                        {name}
                    </CustomText>

                    {!!onClickRemove && (
                        <Button
                            variant="light"
                            type="button"
                            onClick={onClickRemove}
                            padding={{ all: "0px" }}
                        >
                            <DeleteIcon
                                fill={theme.colors.danger}
                                width={12}
                                height={12}
                            />
                        </Button>
                    )}
                </S.PersonName>

                <CustomText color="gray" size={12} italic>
                    {email}
                </CustomText>
            </div>
        </S.PersonContainer>
    );
};

export default Person;
