import React from "react";
import { ISpacing } from "../../utils/interfaces/ISpacing";
import Button from "../Button";
import CustomText from "../Text";
import { EmptyContainer } from "./styles";

interface IProps {
    text: string;
    onClick?(): void;
    padding?: ISpacing;
}

const Empty: React.FC<IProps> = ({ text, onClick, padding }) => {
    return (
        <EmptyContainer padding={padding}>
            <CustomText color="black" size={16} bottom="15px">
                {text}
            </CustomText>

            {onClick && (
                <div>
                    <Button variant="main" type="button" onClick={onClick}>
                        ADICIONAR
                    </Button>
                </div>
            )}
        </EmptyContainer>
    );
};

export default Empty;
