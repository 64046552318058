import React from "react";

import { Route, Router, Switch } from "react-router";
import history from "../services/history";
import Login from "../pages/Login";
import Main from "../pages/Main";
import Error from "../components/Error";

const Routes = () => {
    return (
        <Router history={history as any}>
            <Switch>
                <Route path="/" component={Login} exact />
                <Route path="/app" component={Main} />
                <Route path="*" component={Error} />
            </Switch>
        </Router>
    );
};

export default Routes;
