import React, { memo } from "react";
import { IIconProps } from "../../utils/interfaces/IIconProps";

const LogoSmallIcon = memo(({ ...props }: IIconProps) => {
    return (
        <svg
            id="sas"
            viewBox="0 0 118 68"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M97.5303 0H19.564C8.69512 0 0 8.64407 0 19.4492V48.5508C0 59.2119 8.69512 68 19.564 68H97.5303C108.254 68 117.094 59.3559 117.094 48.5508V19.4492C116.949 8.64407 108.254 0 97.5303 0Z"
                fill="#96AECB"
            />
            <path
                d="M105.646 23.3389H96.8059C96.8059 22.9067 96.8059 22.6185 96.8059 22.3304C96.661 19.7372 95.9364 18.0084 95.0669 17.288C94.0524 16.5677 91.7337 16.2796 88.1108 16.2796C83.9081 16.2796 81.0098 16.7118 79.7055 17.4321C78.4012 18.1524 77.6766 19.8813 77.6766 22.3304C77.6766 25.3558 78.2563 27.0846 79.2707 27.6609C80.2852 28.2372 83.9081 28.6694 89.8498 29.1016C96.9508 29.5338 101.443 30.5423 103.617 32.127C105.646 33.7118 106.805 37.1694 106.805 42.2118C106.805 48.5508 105.646 52.5846 103.182 54.4575C100.719 56.3304 95.3567 57.1948 87.0963 57.1948C79.5606 57.1948 74.6333 56.3304 72.1697 54.4575C69.7061 52.5846 68.5468 48.983 68.5468 43.5084V41.7796H77.3868V42.788C77.3868 46.1016 77.9665 48.1185 79.1258 48.8389C80.2852 49.5592 83.4734 49.9914 88.6905 49.9914C92.7482 49.9914 95.3567 49.5592 96.3711 48.6948C97.5305 47.8304 98.1102 45.8135 98.1102 42.788C98.1102 40.483 97.6754 39.0423 96.8059 38.1779C95.9364 37.4575 94.1974 37.0253 91.299 36.8813L86.3718 36.5931C78.836 36.1609 74.0537 35.1524 71.8799 33.4236C69.851 31.8389 68.6917 28.2372 68.6917 22.9067C68.6917 17.4321 69.9959 13.6863 72.4596 11.8135C74.9232 9.94058 79.9953 9.07617 87.5311 9.07617C94.6321 9.07617 99.5594 9.94058 102.023 11.6694C104.487 13.3982 105.791 16.7118 105.791 21.6101V23.3389H105.646Z"
                fill="#1A173F"
            />
            <path
                d="M47.0986 23.339H38.2586C38.2586 22.9068 38.2586 22.6186 38.2586 22.3305C38.1137 19.7373 37.3891 18.0085 36.5196 17.2881C35.5051 16.5678 33.1864 16.2797 29.5635 16.2797C25.3608 16.2797 22.4625 16.7119 21.1582 17.4322C19.8539 18.1525 19.1293 19.8814 19.1293 22.3305C19.1293 25.3559 19.709 27.0847 20.7234 27.661C21.7379 28.2373 25.3608 28.6695 31.3025 29.1017C38.4035 29.5339 42.896 30.5424 45.0698 32.1271C47.0986 33.7119 48.258 37.1695 48.258 42.2119C48.258 48.5508 47.0986 52.5847 44.635 54.4576C42.1714 56.3305 36.8094 57.1949 28.549 57.1949C21.0133 57.1949 16.086 56.3305 13.6224 54.4576C11.1588 52.5847 9.99945 48.9831 9.99945 43.5085V41.7797H18.8395V42.7881C18.8395 46.1017 19.4192 48.1186 20.5785 48.839C21.7379 49.5593 24.9261 49.9915 30.1432 49.9915C34.2009 49.9915 36.8094 49.5593 37.8238 48.6949C38.9832 47.8305 39.5629 45.8136 39.5629 42.7881C39.5629 40.4831 39.1281 39.0424 38.2586 38.178C37.3891 37.4576 35.6501 37.0254 32.7517 36.8814L27.8245 36.5932C20.2887 36.161 15.5064 35.1525 13.3326 33.4237C11.1588 31.839 10.1444 28.3814 10.1444 22.9068C10.1444 17.4322 11.4486 13.6864 13.9123 11.8136C16.3759 10.0847 21.448 9.22034 28.9838 9.22034C36.0848 9.22034 41.0121 10.0847 43.4757 11.8136C45.9393 13.5424 47.2436 16.8559 47.2436 21.7542V23.339H47.0986Z"
                fill="#1A173F"
            />
            <path
                d="M72.0247 58.3473L68.9814 49.271H50.5767L47.6784 58.3473H35.0704L51.7361 8.06763H67.3873L84.4877 58.2032H72.0247V58.3473ZM65.9381 39.7625L59.7066 21.1778L53.62 39.7625H65.9381Z"
                fill="#1A173F"
            />
            <path
                d="M66.3729 9.50843L82.4589 56.7627H73.0392L69.9959 47.6864H49.5624L46.664 56.7627H37.0994L52.7506 9.50843H66.3729ZM51.5912 41.2034H67.9671L59.7067 16.5678L51.5912 41.2034ZM68.4018 6.62708H66.3729H52.7506H50.7217L49.9971 8.64402L34.201 55.8983L32.8967 59.644H36.9545H46.5191H48.6929L49.2725 57.6271L51.5912 50.5678H67.9671L70.2858 57.6271L71.0103 59.644H73.0392H82.4589H86.5166L85.2124 55.8983L69.1264 8.64402L68.4018 6.62708ZM55.649 38.322L59.7067 25.7881L63.9093 38.322H55.649Z"
                fill="#96AECB"
            />
        </svg>
    );
});

export default LogoSmallIcon;
