export const dictionary = {
    phrases: {
        confirmAction: "Confirme a ação",
        confirmDelete: "Você realmente deseja apagar esse registro?",
        loadingSelect: "Carregando...",
        selectEmpty: "Nenhum registro encontrado",
        selectTypeSomething: "Digite algo para buscar...",
    },
    validations: {
        email: "E-mail inválido",
        required: "Campo obrigatório",
        requiredAddress: "Campo obrigatório. Digite um endereço válido.",
        invalid: "Valor inválido.",
        document: "CPF inválido",
        documentCPNJ: "CPNJ inválido",
    },
    name: "Nome",
    created_at: "Data de criação",
    action: "Ação",
    id: "ID",
    email: "E-mail",
    Monday: "Segunda-feira",
    Tuesday: "Terça-feira",
    Wednesday: "Quarta-feira",
    Thursday: "Quinta-feira",
    Friday: "Sexta-feira",
    Saturday: "Sábado",
    Sunday: "Domingo",
    city: "Cidade",
    status: "Status",
    Active: "Ativo",
    Inactive: "Inativo",
    Deleted: "Deletado",
    title: "Título",
    type: "Tipo",
    Published: "Publicado",
    role: "Permissão",
    id_project: "#",
    id_professional: "#",
    "person.name": "Nome",
    professional_position_id: "Cargo",
    "person.gender": "Gênero",
    Teacher: "Professor",
    Assistant: "Assistente",
    Administrator: "Administrador",
    Male: "Masculino",
    Feminine: "Feminino",
    id_student: "#",
    "project.name": "Núcleo",
    "person.document": "CPF",
    "person.email": "E-mail",
    "person.birthday": "Data de nascimento",
    "modality.name": "Modalidade",
    week_days: "Dias da semana",
    "professional.person.name": "Profissional",
    "professional.professional_position.name": "Cargo",
    state: "Estado",
    neighbourhood: "Bairro",
    street: "Rua",
    number: "Número",
    complement: "Complemento",
    zip: "CEP",
    teachers: "Profissionais",
    assistants: "Assistentes",
    administrators: "Administradores",
    classDay: "Aulas",
    start_time: "Horário de Início",
    end_time: "Horário Final",
    day: "Data",
    person: "Pessoa",
    weekDays: "Dias da semana",
    project: "Núcleo",
    modality: "Modalidade",
    startTimeString: "Horário de Início",
    endTimeString: "Horário Final",
    startTime: "Horário de Início",
    endTime: "Horário Final",
    startDate: "Data de Início",
    endDate: "Data Final",
    "classes_aggregate.aggregate.count": "Turmas",
    "students_aggregate.aggregate.count": "Alunos",
    "project_class.name": "Turma",
    "professional.professional.person": "Profissional",
    shortWeekDays: {
        Monday: "Segunda",
        Tuesday: "Terça",
        Wednesday: "Quarta",
        Thursday: "Quinta",
        Friday: "Sexta",
        Saturday: "Sábado",
        Sunday: "Domingo",
    },
    is_present: "Presente",
    start_date: "Data de Início",
    end_date: "Data Final",
    "person.phone": "Telefone",
    "person.user.email": "Email",
    "person.address.street": "Rua",
    "person.address.number": "Número",
    "person.address.zip": "Cep",
    "person.address.city": "Cidade",
    "person.address.state": "Estado",
    "person.address.neighbourhood": "Bairro",
    "person.address.complement": "Complemento",
    "person.user.username": "Nome de usuário",
    documentProfessional: "CREF",
    Admin: "Administrador",
    profile_id: "Perfil",
    password: "Senha",
    Requested: "Solicitado",
    Sent: "Enviado",
    Denied: "Negado",
    Concluded: "Concluído",
    Received: "Recebido",
    Canceled: "Cancelado",
    "product.title": "Produto",
    quantity: "Quantidade",
    product_order_status_id: "Status",
    observation: "Observação",
    professionals: "Profissionais",
    region: "Região",
    technical_guidance: "Informações Técnicas Gerais",
    main_goals: "Principais Objetivos",
    observation_points: "Pontos importantes a observar durante a execução",
    regions: "Regiões",
    projects: "Núcleos",
    "region.name": "Região",
    "schoolClass.name": "Turma",
    "status.name": "Status",
    presence: "Presença",
    numberOfClasses: "Aulas",
    numberOfStudents: "Alunos",
    numberOfStudentsPresent: "Alunos presentes",
    "professional_position.name": "Cargo",
    provider: "Fornecedor",
    position: "Cargo",
    juridic_document: "CNPJ",
    juridic_name: "Razão Social",
    juridicName: "Razão Social",
    juridicDocument: "CNPJ",
    "responsible.name": "Nome do responsável",
    "responsible.gender": "Gênero do responsável",
    "responsible.document": "CPF do responsável",
    "responsible.birthday": "Data de nascimento do responsável",
    "responsible.phone": "Telefone do responsável",
    "responsible.user.email": "E-mail do responsável",
    "responsible.address.zip": "Cep",
    "responsible.address.city": "Cidade",
    "responsible.address.state": "Estado",
    "responsible.address.neighbourhood": "Bairro",
    "responsible.address.complement": "Complemento",
    "responsible.address.street": "Rua",
    "responsible.address.number": "Número",
    product: "Produto",
    products: "Produtos",
    numberOfProducts: "Produtos",
    modalities: "Modalidades",
    numberOfProjects: "Projetos",
    numberOfSchoolClasses: "Turmas",
    presencePercentage: "Presença (%)",
    averageByProject: "Média por projeto",
    averageGradeByProject: "Média por projeto",
    "professional.name": "Profissional",
    "presence.percentage": "Presença",
    averageGrade: "Nota média",
    numberOfPossibleClasses: "Aulas",
    numberOfClosedClasses: "Aulas finalizadas",
    numberOfLostClasses: "Aulas perdidas",
    numberOfNextClassesByProject: "Próximas aulas",
    numberOfNextClasses: "Próximas aulas",
    providers: "Fornecedores",
    dateCreatedStart: "Data de criação",
    dateCreatedEnd: "Data de finalização",
    dateSentStart: "Envio de",
    dateSentEnd: "Envio até",
    dateReceivedStart: "Recebimento de",
    dateReceivedEnd: "Recebimento até",
    dateConfirmationStart: "Confirmado de",
    dateConfirmationEnd: "Confirmado até",
    "provider.name": "Fornecedor",
    numberOfItems: "Quantidade",
    created: "Solicitado em",
    sentDate: "Enviado em",
    receiptDate: "Recebido em",
    confirmationDate: "Confirmado em",
    document: "CPF",
    "project_class_day_status.name": "Status da Aula",
    grade: "Nota",
    Coordinator: "Coordenador",
};
