import React, { useCallback, useMemo, useState } from "react";
import generateSearchQuery from "helpers/generateSearchQuery";
import { InputType } from "utils/enums/InputType";
import { TableColumnType } from "utils/enums/TableColumnType";
import { IInputField } from "utils/interfaces/IInputField";
import { ITableColumn } from "utils/interfaces/ITable";
import { dateSchema } from "utils/schemas/date";
import { multipleSelectSchema } from "utils/schemas/select";
import { object } from "yup";
import Reports from ".";
import useDownload from "./utils/useDownload";
import useApi, { IRequestPayload } from "hooks/useApi";
import { formatReportBody } from "./utils/formatBody";

const ReportProducts: React.FC = () => {
    const [isDownload, setIsDownload] = useState(false);

    const {
        loading: isLoadingDownload,
        pdf,
        request: startDownload,
    } = useDownload();

    const {
        request,
        response,
        loading: isLoadingData,
        error,
    } = useApi<{
        featuredNumbers: any;
        report: any[];
    }>();

    const requestItems = useCallback(
        (data: any) => {
            const body = formatReportBody(data);

            const payload: IRequestPayload = {
                endpoint: "/admin/reports/products",
                method: "post",
                body,
            };

            if (isDownload) {
                startDownload({
                    ...payload,
                    endpoint: `${payload.endpoint}/pdf`,
                });
                return;
            }

            request(payload);
        },
        [startDownload, isDownload, request]
    );

    const columns: ITableColumn[] = [
        {
            name: "product",
            type: TableColumnType.Text,
        },
        {
            name: "region.name",
            type: TableColumnType.Text,
        },
        {
            name: "quantity",
            style: { textAlign: "center" },
            type: TableColumnType.Text,
        },
        {
            name: "status.name",
            style: { textAlign: "center" },
            type: TableColumnType.Text,
        },
    ].map((i: any) => ({ ...i, removeSearch: true, col: i?.col || "auto" }));

    const fields: IInputField[] = [
        {
            name: "regions",
            graphQL: {
                searchQuery: generateSearchQuery({
                    tableName: "region",
                }),
            },
            type: InputType.AsyncSelect,
            isMulti: true,
            grid: {
                md: 4,
            },
        },
        {
            name: "products",
            graphQL: {
                searchQuery: generateSearchQuery({
                    tableName: "product",
                    columnName: "title",
                    companyPath: "company_id",
                }),
            },
            type: InputType.AsyncSelect,
            isMulti: true,
            grid: {
                md: 4,
            },
        },
        {
            name: "status",
            graphQL: {
                searchQuery: generateSearchQuery({
                    tableName: "product_order_status",
                    columnName: "title",
                }),
            },
            type: InputType.AsyncSelect,
            isMulti: true,
            grid: {
                md: 4,
            },
        },
        {
            name: "startDate",
            type: InputType.Date,
            required: true,
            grid: {
                md: 3,
            },
        },
        {
            name: "endDate",
            type: InputType.Date,
            required: true,
            grid: {
                md: 3,
            },
        },
    ];
    const schema: any = object().shape({
        regions: multipleSelectSchema(),
        products: multipleSelectSchema(),
        status: multipleSelectSchema(),
        startDate: dateSchema(),
        endDate: dateSchema(),
    });

    const featuredNumbers = useMemo(() => {
        if (!response) {
            return [];
        }

        const items = [
            {
                title: "Produtos",
                values: [response.featuredNumbers.numberOfProducts],
            },
        ];

        const statuses =
            response.featuredNumbers.status.map((item: any) => ({
                title: item.name,
                values: [
                    item.value,
                    ...(Boolean(item.percentage)
                        ? [`${item.percentage}%`]
                        : []),
                ],
            })) || [];

        return [...items, ...statuses];
    }, [response]);

    return (
        <Reports
            {...{
                columns,
                fields,
                schema,
                error,
                pdf,
                isLoadingData,
                isLoadingDownload,
                featuredNumbers,
            }}
            report={response?.report}
            onSubmit={requestItems}
            onClickDownload={() => setIsDownload(true)}
        />
    );
};

export default ReportProducts;
