import { createContext, useContext, useEffect } from "react";

export const HeaderTitleContext = createContext({
    title: "",
    setTitle: (_: string) => {},
});

export function useHeaderTitle(newTitle?: string) {
    const { title: headerTitle, setTitle } = useContext(HeaderTitleContext);

    useEffect(() => {
        if (!!newTitle) {
            setTitle(newTitle);
        }
    }, [newTitle, setTitle]);

    return { headerTitle, setTitle };
}
