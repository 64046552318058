import generateSearchQuery from "helpers/generateSearchQuery";
import { InputType } from "utils/enums/InputType";
import { ProfessionalPosition } from "utils/enums/ProfessionalPosition";
import { createPersonFields } from "utils/fields/person";
import { IInputField } from "utils/interfaces/IInputField";

export const professionalFields = (position?: ProfessionalPosition) =>
    [
        {
            name: "position",
            type: InputType.AsyncSelect,
            graphQL: {
                searchQuery: generateSearchQuery({
                    tableName: "professional_position",
                }),
            },
            required: true,
            grid: {
                col: 4,
            },
        },
        ...((position === ProfessionalPosition.Teacher
            ? [
                  {
                      name: "documentProfessional",
                      type: InputType.Text,
                      required: true,
                      grid: {
                          col: 4,
                      },
                  },
              ]
            : []) as IInputField[]),
        {
            type: InputType.Space,
            grid: {
                col: position === ProfessionalPosition.Teacher ? 4 : 8,
            },
        },

        ...((position === ProfessionalPosition.Coordinator
            ? [
                  {
                      name: "projects",
                      type: InputType.AsyncSelect,
                      isMulti: true,
                      graphQL: {
                          searchQuery: generateSearchQuery({
                              tableName: "project",
                              companyPath: "company_id",
                          }),
                      },
                      required: true,
                      grid: {
                          col: 12,
                      },
                  },
              ]
            : []) as IInputField[]),

        ...createPersonFields({ withAdress: true, withUser: true }),
    ] as IInputField[];
