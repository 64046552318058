import React, { memo } from "react";
import { theme } from "../../config/theme";
import { IIconProps } from "../../utils/interfaces/IIconProps";

const SpinnerIcon = memo(
    ({ fill = theme.colors.black, ...props }: IIconProps) => {
        return (
            <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
                viewBox="0 0 18 18"
            >
                <path
                    fill={fill}
                    stroke={fill}
                    strokeWidth={1}
                    d="M15 8c0 3.9-3.1 7-7 7s-7-3-7-7h-1c0 4 3.6 8 8 8s8-3.6 8-8h-1z"
                    style={{ transform: "translate(1px, 1px)" }}
                ></path>
            </svg>
        );
    }
);

export default SpinnerIcon;
