import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Creators as CrudActions } from "../../../store/crud";
import { ICrudActions } from "../../../store/crud/types";
import { IPagination } from "../../../utils/interfaces/IPagination";
import { IBaseRouteProps } from "../../../utils/interfaces/IBaseRouteProps";
import ListData from "../../../components/ListData";
import { IReduxStore } from "../../../store/ducks";

interface IProps extends IBaseRouteProps {
    crudActions: ICrudActions;
    items: any[];
    loading: boolean;
    pagination: IPagination;
    empty: boolean;
}

const CrudList = ({
    columns = [],
    crudActions,
    items,
    loading,
    pagination,
    canEdit,
    canDelete,
    canCreate,
    path,
    canSee,
    graphql,
    mainColumn,
    empty,
    tableTitle,
    defaultSearch,
}: IProps) => {
    return (
        <ListData
            title={tableTitle}
            {...{
                columns,
                crudActions,
                items,
                loading,
                pagination,
                canEdit,
                canDelete,
                canCreate,
                path,
                canSee,
                graphql,
                mainColumn,
                empty,
                defaultSearch,
            }}
        />
    );
};

const mapStateToProps = ({
    crud: { items, loading, pagination, empty },
}: IReduxStore) => ({
    items,
    loading,
    pagination,
    empty,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    crudActions: bindActionCreators(CrudActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CrudList);
