import GoogleMaps from "components/GoogleMaps";
import useGraphQL from "hooks/useGraphQL";
import React, { useCallback, useEffect, useMemo } from "react";
import _sumBy from "lodash/sumBy";
import { useCompanyId } from "hooks/useCompanyId";
import { Card } from "components/Card";

const DashboardMap: React.FC = () => {
    const { request, response, loading, error } = useGraphQL<any>();

    const company = useCompanyId();

    const getAdresses = useCallback(() => {
        request(
            `query ($company: Int!) {
            items: project_address(where: {project: {status: {_neq: -1}, company_id: {_eq: $company}}}) {
              project {
                name
                classes {
                  subscriptions_aggregate(where: {is_active: {_eq: true}, student: {status: {_eq: 1}}}, distinct_on: [student_id]) {
                    aggregate {
                      count
                    }
                  }
                }
                classes_aggregate(distinct_on: [project_id]) {
                  aggregate {
                    count
                  }
                }
              }
              address {
                latitude
                longitude
              }
            }
          }`,
            { company }
        );
    }, [company, request]);

    useEffect(() => {
        getAdresses();
    }, [getAdresses]);

    const locations = useMemo(() => {
        return response?.items?.map(({ project, address }: any) => {
            const totalStudents =
                _sumBy(
                    project?.classes || [],
                    "subscriptions_aggregate.aggregate.count"
                ) || 0;
            return {
                name: project.name,
                totalStudents,
                totalClasses: ~~project?.classes_aggregate?.aggregate?.count,
                ...address,
            };
        });
    }, [response]);

    if (loading || error || !locations?.length) {
        return null;
    }

    return (
        <Card title="Núcleos" bottom="30px">
            <GoogleMaps
                locations={locations}
                center={{ longitude: "-43.372869", latitude: "-22.9231052" }}
            />
        </Card>
    );
};

export default DashboardMap;
