import CustomText from "components/Text";
import React from "react";
import { TableEmptyContainer } from "./styles";

const TableEmpty: React.FC = () => {
    return (
        <TableEmptyContainer>
            <svg
                height="80"
                viewBox="-80 0 400 400.254"
                width="80"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginBottom: "20px" }}
            >
                <path
                    d="m60.078125 6.667969h120v186.664062h-120zm0 0"
                    fill="#fdbd40"
                />
                <path
                    d="m80.078125 220 46.664063-46.667969-26.664063-26.664062-40 33.332031v-46.667969l-40 40v180h66.664063v-13.332031l13.335937-13.332031v-13.335938l-20-26.664062zm0 0"
                    fill="#f6bf9b"
                />
                <g fill="#fdbd40">
                    <path d="m33.410156 80h-26.667968c-3.679688 0-6.664063-2.984375-6.664063-6.667969 0-3.679687 2.984375-6.664062 6.664063-6.664062h26.667968c3.683594 0 6.667969 2.984375 6.667969 6.664062 0 3.683594-2.988281 6.667969-6.667969 6.667969zm0 0" />
                    <path d="m233.410156 80h-26.667968c-3.679688 0-6.664063-2.984375-6.664063-6.667969 0-3.679687 2.984375-6.664062 6.664063-6.664062h26.667968c3.679688 0 6.667969 2.984375 6.667969 6.664062 0 3.683594-2.988281 6.667969-6.667969 6.667969zm0 0" />
                    <path d="m33.410156 40c-1.769531 0-3.464844-.699219-4.714844-1.953125l-13.332031-13.332031c-2.578125-2.628906-2.542969-6.847656.085938-9.429688 2.59375-2.546875 6.746093-2.546875 9.339843 0l13.335938 13.335938c2.601562 2.601562 2.601562 6.824218-.003906 9.425781-1.25 1.25-2.941406 1.953125-4.710938 1.953125zm0 0" />
                    <path d="m206.742188 40c-3.679688 0-6.664063-2.988281-6.664063-6.667969 0-1.765625.703125-3.460937 1.953125-4.710937l13.332031-13.335938c2.582031-2.625 6.800781-2.664062 9.429688-.082031 2.625 2.578125 2.664062 6.796875.082031 9.425781-.027344.027344-.054688.054688-.082031.085938l-13.335938 13.332031c-1.25 1.253906-2.945312 1.953125-4.714843 1.953125zm0 0" />
                    <path d="m220.078125 133.332031c-1.769531.003907-3.464844-.699219-4.714844-1.953125l-13.332031-13.332031c-2.582031-2.628906-2.542969-6.847656.082031-9.429687 2.59375-2.546876 6.75-2.546876 9.34375 0l13.332031 13.335937c2.605469 2.605469 2.601563 6.824219 0 9.425781-1.25 1.25-2.945312 1.953125-4.710937 1.953125zm0 0" />
                    <path d="m20.078125 133.332031c-3.683594 0-6.667969-2.984375-6.667969-6.667969 0-1.765624.703125-3.460937 1.953125-4.710937l13.332031-13.332031c2.628907-2.582032 6.847657-2.542969 9.429688.082031 2.546875 2.59375 2.546875 6.75 0 9.34375l-13.335938 13.332031c-1.246093 1.253906-2.945312 1.957032-4.710937 1.953125zm0 0" />
                </g>
                <path
                    d="m126.742188 193.332031v40h-46.664063v-13.332031l26.664063-26.667969zm0 0"
                    fill="#ed917e"
                />
                <path
                    d="m80.078125 233.332031h46.664063v40h-46.664063zm0 0"
                    fill="#f9a698"
                />
                <path
                    d="m80.078125 273.332031h46.664063v40h-26.664063l-20-26.664062zm0 0"
                    fill="#ed917e"
                />
                <path
                    d="m6.742188 353.332031h93.335937v40h-93.335937zm0 0"
                    fill="#5a6470"
                />
                <path d="m.078125 73.332031c0 3.683594 2.984375 6.667969 6.664063 6.667969h26.667968c3.683594 0 6.667969-2.984375 6.667969-6.667969 0-3.679687-2.984375-6.664062-6.667969-6.664062h-26.667968c-3.679688 0-6.664063 2.984375-6.664063 6.664062zm0 0" />
                <path d="m206.742188 80h26.667968c3.683594 0 6.667969-2.984375 6.667969-6.667969 0-3.679687-2.984375-6.664062-6.667969-6.664062h-26.667968c-3.679688 0-6.664063 2.984375-6.664063 6.664062 0 3.683594 2.984375 6.667969 6.664063 6.667969zm0 0" />
                <path d="m33.410156 40c3.679688 0 6.664063-2.988281 6.664063-6.667969 0-1.765625-.703125-3.460937-1.953125-4.710937l-13.332032-13.335938c-2.625-2.578125-6.84375-2.539062-9.425781.085938-2.546875 2.59375-2.546875 6.75 0 9.34375l13.332031 13.332031c1.25 1.253906 2.945313 1.953125 4.714844 1.953125zm0 0" />
                <path d="m206.742188 40c1.769531 0 3.464843-.699219 4.714843-1.953125l13.332031-13.335937c2.628907-2.578126 2.664063-6.796876.085938-9.425782s-6.800781-2.664062-9.425781-.085937c-.03125.027343-.058594.058593-.085938.085937l-13.332031 13.332032c-2.605469 2.605468-2.605469 6.828124 0 9.429687 1.25 1.25 2.945312 1.953125 4.710938 1.953125zm0 0" />
                <path d="m202.03125 118.046875 13.332031 13.332031c2.578125 2.628906 6.800781 2.664063 9.425781.085938 2.628907-2.578125 2.664063-6.800782.085938-9.425782-.027344-.03125-.054688-.058593-.085938-.085937l-13.332031-13.332031c-2.625-2.582032-6.847656-2.542969-9.425781.082031-2.546875 2.59375-2.546875 6.75 0 9.34375zm0 0" />
                <path d="m28.695312 108.621094-13.332031 13.332031c-2.625 2.578125-2.664062 6.800781-.082031 9.425781 2.578125 2.628906 6.800781 2.664063 9.425781.085938.027344-.027344.054688-.054688.082031-.085938l13.335938-13.332031c2.578125-2.625 2.542969-6.847656-.085938-9.425781-2.59375-2.546875-6.75-2.546875-9.34375 0zm0 0" />
                <path d="m.078125 393.332031c0 3.683594 2.984375 6.667969 6.664063 6.667969h93.335937c3.679687 0 6.667969-2.984375 6.667969-6.667969v-40c0-3.679687-2.988282-6.664062-6.667969-6.664062h-6.667969v-3.90625l11.378906-11.382813c1.25-1.25 1.953126-2.945312 1.957032-4.710937v-6.667969h20c3.679687 0 6.664062-2.984375 6.664062-6.667969v-113.332031h46.667969c3.679687 0 6.667969-2.984375 6.667969-6.667969v-186.664062c0-3.683594-2.988282-6.667969-6.667969-6.667969h-120c-3.683594 0-6.667969 2.984375-6.667969 6.667969v123.90625l-38.046875 38.046875c-1.25 1.25-1.953125 2.945312-1.953125 4.710937v173.335938h-6.667968c-3.679688 0-6.664063 2.984375-6.664063 6.664062zm120-86.664062h-16.667969l-16.664062-22.222657v-4.445312h33.332031zm0-40h-33.332031v-26.667969h33.332031zm0-40h-33.332031v-3.90625l22.757812-22.761719h10.574219zm-53.335937-213.335938h106.667968v173.335938h-50.574218l8.621093-8.621094c2.601563-2.601563 2.605469-6.820313.003907-9.425781-.003907 0-.003907 0-.003907 0l-26.667969-26.667969c-2.4375-2.4375-6.332031-2.613281-8.980468-.40625l-29.066406 24.21875zm-40 162.761719 26.667968-26.667969v30.574219c0 3.679688 2.980469 6.664062 6.660156 6.667969 1.5625 0 3.074219-.546875 4.273438-1.546875l35.324219-29.433594 17.648437 17.644531-41.953125 41.953125c-1.25 1.25-1.953125 2.945313-1.953125 4.714844v66.664062c0 1.445313.46875 2.847657 1.335938 4l18.664062 24.890626v8.351562l-11.378906 11.378906c-1.25 1.25-1.953125 2.945313-1.953125 4.714844v6.664062h-53.332031zm-13.332032 183.90625h80v26.667969h-80zm0 0" />
                <path d="m120.078125 100c3.125 0 5.828125-2.171875 6.503906-5.222656l13.335938-60c.800781-3.59375-1.464844-7.152344-5.058594-7.953125-.476563-.105469-.960937-.15625-1.449219-.15625h-26.667968c-3.679688-.003907-6.664063 2.980469-6.664063 6.660156 0 .488281.050781.972656.160156 1.449219l13.332031 60c.675782 3.050781 3.382813 5.222656 6.507813 5.222656zm5.023437-60-5.023437 22.601562-5.023437-22.601562zm0 0" />
                <path d="m133.410156 126.667969c0 7.363281-5.96875 13.332031-13.332031 13.332031s-13.335937-5.96875-13.335937-13.332031c0-7.367188 5.972656-13.335938 13.335937-13.335938s13.332031 5.96875 13.332031 13.335938zm0 0" />
            </svg>

            <CustomText
                color="base"
                size={15}
                weight="SemiBold"
                center
                top="25px"
                bottom="25px"
                style={{ display: "block" }}
            >
                Nenhum registro foi encontrado.
            </CustomText>
        </TableEmptyContainer>
    );
};

export default TableEmpty;
