import { IInputField } from "../utils/interfaces/IInputField";

export default function generateCreateQuery(
    tableName: string,
    fields: IInputField[]
) {
    const generateFields = fields
        ?.map(({ name, type }) => {
            switch (type) {
                //     case InputType.AsyncSelect:
                //         return `${name} {
                //        id
                //        name
                //    }`;

                default:
                    return name;
            }
        })
        ?.filter(Boolean)
        ?.join("\n");

    return `mutation create${tableName}($body: ${tableName}_insert_input!) {
        item: insert_${tableName}_one(object: $body) {
          id
          ${generateFields}
        }
      }`;
}
