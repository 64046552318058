import Avatar from "components/Avatar";
import CustomText from "components/Text";
import { generateFileURL } from "helpers/generateFileURL";
import React from "react";
import { IPerson } from "utils/interfaces/IPerson";

import { CenteredProfileDetailsContainer } from "./styles";

interface IProps {
    person?: IPerson;
}

const CenteredProfileDetails = ({ person }: IProps) => {
    const imageURL =
        (person as any)?.profileImage?.url || generateFileURL(person?.file);

    return (
        <CenteredProfileDetailsContainer>
            <Avatar size="150px" image={imageURL} name={person?.name} />

            <CustomText color="base" size={25}>
                {person?.name}
            </CustomText>
        </CenteredProfileDetailsContainer>
    );
};

export default CenteredProfileDetails;
