import { combineReducers } from "redux";
import { PersistPartial } from "redux-persist/es/persistReducer";

import crud from "./crud";
import modal from "./modal";
import auth from "./auth";
import projectClass from "./projectClass";
import projectClassDay from "./projectClassDay";
import student from "./student";
import project from "./project";
import professional from "./professional";
import user from "./user";
import provider from "./provider";

const ducks = combineReducers({
    auth,
    crud,
    modal,
    projectClass,
    projectClassDay,
    student,
    project,
    professional,
    user,
    provider,
});

export type IReduxStore = ReturnType<typeof ducks> & PersistPartial;

export default ducks;
