import { dictionary } from "config/dictionary";
import { isValidCNPJ } from "helpers/isValidCNPJ";
import { isValidCPF } from "helpers/isValidCPF";
import { string } from "yup";

export const documentSchema = string()
    .test("is-valid-cpf", dictionary.validations.document, (value = "") =>
        isValidCPF(value?.replace(/[^\d]+/g, ""))
    )
    .required(dictionary.validations.required)
    .typeError(dictionary.validations.document);

export const cnjSchema = string()
    .test("is-valid-cpnj", dictionary.validations.documentCPNJ, (value = "") =>
        isValidCNPJ(value?.replace(/[^\d]+/g, ""))
    )
    .required(dictionary.validations.required)
    .typeError(dictionary.validations.documentCPNJ);
