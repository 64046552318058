import React, { useMemo } from "react";
import { Card } from "components/Card";
import Error from "components/Error";
import FormFactory from "components/Form/FormFactory";
import { Col, Row } from "components/Grid";
import Table from "components/Table";
import { IInputField } from "utils/interfaces/IInputField";
import { ITableColumn } from "utils/interfaces/ITable";
import CustomText from "components/Text";

import { theme } from "config/theme";
import DownloadButton from "./downloadButton";
import { HorizontalDiv } from "assets/globalStyles";

interface IProps {
    error: boolean;
    isLoadingData: boolean;
    isLoadingDownload: boolean;
    fields: IInputField[];
    schema: any;
    columns: ITableColumn[];
    featuredNumbers: any[];
    pdf: string;
    onClickDownload: () => void;
    onSubmit: (data: any) => void;
    report?: any[];
    initialData?: any;
}

const Reports = ({
    error,
    isLoadingData,
    isLoadingDownload,
    fields = [],
    schema,
    columns = [],
    report,
    featuredNumbers,
    pdf,
    initialData,
    onClickDownload,
    onSubmit,
}: IProps) => {
    const content = useMemo(() => {
        if (!report) {
            return;
        }

        if (!Boolean(report?.length)) {
            return (
                <CustomText color="#000" size={20} style={{ margin: 20 }}>
                    Nenhum resultado foi encontrado.
                </CustomText>
            );
        }

        return (
            <>
                <Row bottom="15px">
                    {featuredNumbers?.map(({ values, title }) => (
                        <Col sm={6} md={2} key={`card_${title}`} bottom="15px">
                            <Card
                                style={{
                                    textAlign: "center",
                                }}
                            >
                                <CustomText
                                    color={
                                        theme.colors.components.listCards.color
                                    }
                                    size={16}
                                    bottom="10px"
                                >
                                    {title}
                                </CustomText>
                                <HorizontalDiv
                                    style={{
                                        justifyContent:
                                            values.length > 1
                                                ? "space-between"
                                                : "center",
                                    }}
                                >
                                    {values.map((value: any, index: number) => {
                                        return (
                                            <CustomText
                                                key={`${title}_${value}`}
                                                weight={
                                                    index ? "Regular" : "Bold"
                                                }
                                                color={
                                                    theme.colors.components
                                                        .listCards.color
                                                }
                                                size={index ? 20 : 33}
                                                ellipsis
                                                // style={{
                                                //     alignItems: "center",
                                                //     ...(!index && {
                                                //         flex: 1,
                                                //     }),
                                                // }}
                                            >
                                                {value || 0}
                                            </CustomText>
                                        );
                                    })}
                                </HorizontalDiv>
                            </Card>
                        </Col>
                    ))}
                </Row>
                <Card style={{ padding: 0 }} bottom="40px">
                    <Table
                        columns={columns}
                        data={report || []}
                        isLoading={isLoadingData}
                    />
                </Card>
            </>
        );
    }, [columns, isLoadingData, report, featuredNumbers]);

    return (
        <>
            <Card title="Filtrar" bottom="30px">
                <FormFactory
                    initialData={initialData}
                    fields={fields}
                    onSubmit={onSubmit}
                    schema={schema}
                    submitButtonLabel="Buscar"
                    isLoading={isLoadingData}
                >
                    <DownloadButton
                        onClick={onClickDownload}
                        isLoading={isLoadingDownload}
                        pdf={pdf}
                    />
                </FormFactory>
            </Card>
            {error && <Error hideButton />}
            {content}
        </>
    );
};

export default Reports;
