import React, { forwardRef } from "react";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FormElementContainer, FormError, FormLabel } from "../styles";
import "./styles.css";

interface IEditorProps {
    name: string;
    label?: string;
    required?: boolean;
    error?: string;
    value?: string;
    onChange(value: string): void;
}

const editorModules = {
    toolbar: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
        ],
        ["clean"],
        [{ color: [] }, { background: [] }],
    ],
};

const Editor = forwardRef(
    (
        { name, label, required, error, value = "", onChange }: IEditorProps,
        ref: any
    ) => {
        // const [editorValue, setEditorValue] = useState(value);

        // const handleChange = useCallback(() => {
        //     onChange(editorValue);
        // }, [editorValue, onChange]);

        // useEffect(() => {
        //     handleChange();
        // }, [handleChange]);

        console.log("editor umberto", { value });
        return (
            <FormElementContainer>
                {!!label && (
                    <FormLabel htmlFor={name}>
                        {label}
                        {required && ` *`}
                    </FormLabel>
                )}
                <ReactQuill
                    ref={ref}
                    modules={editorModules}
                    theme="snow"
                    value={value}
                    onChange={onChange}
                    {...(!!error && { className: "error" })}
                />

                {!!error && <FormError>{error}</FormError>}
            </FormElementContainer>
        );
    }
);

export default Editor;
