import { dictionary } from "../config/dictionary";
import { ISelectOption } from "../utils/interfaces/ISelectOption";

const convertEnumToOptions = (enumValue: any): ISelectOption[] => {
    if (!enumValue) {
        return [];
    }

    const tempA: ISelectOption[] = [];

    Object.keys(enumValue)
        .filter((key) => isNaN(+key))
        .forEach((key) => {
            tempA.push({
                label: (dictionary as any)[key] ?? key,
                value: enumValue[key],
            });
        });

    return tempA;
};

export default convertEnumToOptions;
