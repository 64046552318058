import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Creators } from "../store/crud";
import {
    ICRudAddListItemPayload,
    ICrudCreatePayload,
    ICrudState,
} from "../store/crud/types";
import { IReduxStore } from "../store/ducks";

export function useCrud() {
    const dispatch = useDispatch();

    const getAll = useCallback(
        (payload: { query: string; page: number; search?: any }) =>
            dispatch(Creators.getAllRequest(payload)),
        [dispatch]
    );

    const getById = useCallback(
        (payload: { id: number | string; query: string }) =>
            dispatch(Creators.getByIdRequest(payload)),
        [dispatch]
    );

    const createOrEdit = useCallback(
        (payload: ICrudCreatePayload) =>
            dispatch(Creators.createOrEditRequest(payload)),
        [dispatch]
    );

    const clear = useCallback(() => dispatch(Creators.clearCrud()), [dispatch]);

    const state = useSelector<IReduxStore, ICrudState>((state) => state.crud);

    const addListItem = useCallback(
        (payload: ICRudAddListItemPayload) =>
            dispatch(Creators.crudAddListItem(payload)),
        [dispatch]
    );

    return { getAll, createOrEdit, getById, state, clear, addListItem };
}
