import styled from "styled-components/macro";
import { MediaQueries } from "utils/mediaQueries";
import { theme } from "../../config/theme";
import { MainContainer } from "../../pages/Main/styles";

export const HeaderContainer = styled.div`
    display: flex;
    top: 0;
    background: ${theme.colors.background};
    z-index: 1;
    border-bottom: 1px solid ${theme.colors.mainLight};
    margin-bottom: 25px;
    padding: 20px 0;
    width: 100%;
`;

export const SubHeaderContainer = styled(MainContainer)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
`;

export const HeaderBreadcrumbContainer = styled(MainContainer)`
    display: flex;
    flex-direction: column;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        flex-direction: row;
        align-items: center;
    }
`;
