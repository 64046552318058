import React, { useMemo } from "react";
import styled from "styled-components";
import { generateSizeStyles } from "../../helpers/generateSizeStyles";
import { generateSpacingStyles } from "../../helpers/generateSpacingStyles";
import { ISpacing } from "../../utils/interfaces/ISpacing";

interface IGridDefaultProps extends ISpacing {
    children: React.ReactNode;
    column?: boolean;
    style?: React.CSSProperties;
    id?: any;
}

export type AvailableColValues =
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | "auto";

export interface IGridProps {
    col?: AvailableColValues;
    sm?: AvailableColValues;
    md?: AvailableColValues;
    lg?: AvailableColValues;
    xl?: AvailableColValues;
}
export interface IColProps extends IGridDefaultProps, IGridProps {
    hidden?: boolean;
    style?: React.CSSProperties;
}

const BaseElement = styled.div<any>`
    ${({ hidden }) => !!hidden && `display: "none";`};

    ${({ column }) => !!column && "flex-direction: column;"};

    ${(props) => generateSizeStyles(props)};
    ${(props) => generateSpacingStyles(props)};

    /* max-width: 100%; */

    &.row {
        height: auto;
    }
`;

export const Row = ({ children, ...props }: IGridDefaultProps) => {
    return (
        <BaseElement className="row" {...props}>
            {children}
        </BaseElement>
    );
};

export const Container = ({ children, ...props }: any) => {
    return (
        <BaseElement className="container" {...props}>
            {children}
        </BaseElement>
    );
};

export const Col = ({
    children,
    col,
    sm,
    md,
    lg,
    xl,
    hidden,
    ...props
}: IColProps) => {
    const generateClassName = useMemo(() => {
        let className = "";

        if (!!col) {
            className = col === "auto" ? "col" : `col-${col}`;
        }

        if (!!sm) {
            className = `${className} col-sm-${sm}`;
        }

        if (!!md) {
            className = `${className} col-md-${md}`;
        }

        if (!!lg) {
            className = `${className} col-lg-${lg}`;
        }

        if (!!xl) {
            className = `${className} col-xl-${xl}`;
        }

        return className;
    }, [col, lg, md, sm, xl]);

    return (
        <BaseElement className={generateClassName} hidden={!!hidden} {...props}>
            {children}
        </BaseElement>
    );
};

const Grid = {
    Row,
    Col,
    Container,
};

export default Grid;
