import DateService from "../services/date";

export function formatDate(
    date?: string | Date,
    format: any = "dd/MM/yyyy"
): string | undefined {
    if (!date) {
        return undefined;
    }

    const isDateString = typeof date === "string";

    let currentDate = isDateString
        ? DateService.fromISO(date as string)
        : DateService.fromJSDate(date as Date);

    if (!currentDate.isValid) {
        return undefined;
    }

    return currentDate.toFormat(format);
}
