import { useAppSelector } from "./store";
import history from "../services/history";

export default function useAuthenticatedRoute() {
    const token = useAppSelector(({ auth }) => auth?.token);

    if (!token) {
        history.push("/");
    }
}
