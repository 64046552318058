export const getProjectDetailsByIdQuery = `query ($id: Int!) {
    item: project_by_pk(id_project: $id) {
      name
      id_project
      students: students_aggregate {
        aggregate {
          count
        }
      }
      project_classes: project_classes_aggregate {
        aggregate {
          count
        }
      }
    }
    professional: project_class_professional_aggregate(where: {project_class: {project: {id_project: {_eq: $id}}}}) {
      aggregate {
        count
      }
    }
  }
  `;
