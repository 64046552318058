import { useAppDispatch, useAppSelector } from "hooks/store";
import React, { useCallback, useEffect, useMemo } from "react";
import { IBaseRouteProps } from "utils/interfaces/IBaseRouteProps";
import { Creators } from "store/student";
import ListTabData, { IListTabDataConfig } from "components/ListTabData";
import StudentTabDetails from "./tabs/details";
import StudentHeader from "./header";
import Spinner from "components/Spinner";
import StudentSchoolClasses from "./tabs/schoolClasses";
import { useHeaderTitle } from "hooks/useHeaderTitle";

const StudentDetails = ({ match: { params } }: IBaseRouteProps) => {
    const { id } = params || {};

    const {
        id: studentId,
        person,
        loading,
        stats,
    } = useAppSelector(({ student }) => student!);
    const dispatch = useAppDispatch();

    useHeaderTitle(person?.name);

    const getStudentById = useCallback(() => {
        dispatch(Creators.getStudentByIdRequest({ id, isDetailScreen: true }));
    }, [dispatch, id]);

    useEffect(() => {
        getStudentById();
    }, [getStudentById]);

    const listDataConfig = useMemo<IListTabDataConfig[]>(
        () => [
            {
                title: "Detalhes",
                columns: [],
                component: <StudentTabDetails />,
            },

            {
                title: "Turmas",
                columns: [],
                component: <StudentSchoolClasses />,
            },
        ],
        []
    );

    return (
        <>
            {loading && <Spinner fullScreen />}

            <StudentHeader person={person} id={studentId} stats={stats} />

            {!!studentId && <ListTabData configs={listDataConfig} />}
        </>
    );
};

export default StudentDetails;
