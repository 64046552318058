import { useLayoutEffect, useState } from "react";
import { BreakPoints } from "../utils/enums/BreakPoints";

const isClient = typeof window === "object";
const isAPISupported = (api: string) => api in window;

const errorMessage =
    "matchMedia is not supported, this could happen both because window.matchMedia is not supported by your current browser or you're using the useMediaQuery hook whilst server side rendering.";

const useIsMobile = (breakpoint = BreakPoints.medium) => {
    const [isMobile, setIsMobile] = useState(
        !!window.matchMedia(`(max-width: ${breakpoint - 1}px)`).matches
    );

    useLayoutEffect(() => {
        if (!isClient || !isAPISupported("matchMedia")) {
            console.warn(errorMessage);

            return;
        }

        const mediaQueryList = window.matchMedia(
            `(max-width: ${breakpoint - 1}px)`
        );
        const documentChangeHandler = () =>
            setIsMobile(!!mediaQueryList.matches);

        mediaQueryList.addListener(documentChangeHandler);

        documentChangeHandler();

        return () => {
            mediaQueryList.removeListener(documentChangeHandler);
        };
    }, [breakpoint]);

    return isMobile;
};

export default useIsMobile;
