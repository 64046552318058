import React, { useCallback, useState, forwardRef, useRef } from "react";
import { components } from "react-select";
import { ISizing } from "../../../utils/interfaces/ISizing";
import { ISelectOption } from "../../../utils/interfaces/ISelectOption";
import { theme } from "../../../config/theme";
import CrossIcon from "../../Icons/cross";
import { FormElementContainer, FormLabel, FormError } from "../styles";
import { AsyncSelectElement } from "./styles";
import graphql from "../../../services/graphql";
import { dictionary } from "../../../config/dictionary";
import _get from "lodash/get";

export interface IAsyncSelectProps<T = any> extends ISizing {
    name: string;
    showError?: boolean;
    options?: ISelectOption<T>[];
    label?: string;
    required?: boolean;
    isClearable?: boolean;
    query?: string;
    isMulti?: boolean;
    placeholder?: string;
    isDisabled?: boolean;
    onChange?(data: ISelectOption): void;
    value?: ISelectOption;
    error?: string;
    defaultSearch?: object;
}

const DropdownIndicator = (props: any) => {
    return (
        <components.DropdownIndicator {...props}>
            <CrossIcon width={12} height={12} fill={theme.colors.black} />
        </components.DropdownIndicator>
    );
};

const AsyncSelect = forwardRef(
    (
        {
            name,
            showError = true,
            required = false,
            label,
            isClearable = true,
            query,
            defaultSearch,
            placeholder = "Digite para buscar...",
            options: mockOptions,
            error,
            ...rest
        }: IAsyncSelectProps,
        ref
    ) => {
        const [defaultOptions, setDefaultOptions] = useState<ISelectOption[]>();

        const handleLoadOptions = useCallback<{
            (searchText?: string): Promise<ISelectOption[]>;
        }>(
            async (searchText?: string) => {
                if (!!mockOptions?.length) {
                    const searchTerm = (searchText || "")?.toLocaleLowerCase();

                    let filterItems = mockOptions?.filter((item) => {
                        return new RegExp(searchTerm).test(
                            item?.label?.toLocaleLowerCase()
                        );
                    });

                    if (filterItems?.length > 10) {
                        filterItems = filterItems?.filter(
                            (_, index) => index <= 10
                        );

                        filterItems.push({
                            value: null,
                            label: "Continue escrevendo para buscar...",
                        });
                    }

                    return new Promise((resolve) => resolve(filterItems));
                }

                if (!query) {
                    return new Promise((resolve) => resolve([]));
                }

                const { data } = await graphql(query, {
                    searchText: `%${searchText || ""}%`,
                    ...defaultSearch,
                });

                const quantity = _get<number>(
                    data,
                    "quantity.aggregate.count",
                    0
                );

                const items =
                    data?.items?.map((item: any) => {
                        const label = [
                            item.person?.name,
                            item.person?.user?.email,
                        ]
                            ?.filter(Boolean)
                            ?.join(" - ");

                        return !!item?.person?.name
                            ? {
                                  value: item.value,
                                  label,
                              }
                            : item;
                    }) || [];

                if (quantity > ~~data?.items?.length) {
                    items.push({
                        value: null,
                        label: "Continue escrevendo para buscar...",
                    });
                }

                return items;
            },
            [defaultSearch, mockOptions, query]
        );

        const loadInitialOptions = useCallback(async () => {
            const options = !!mockOptions?.length
                ? mockOptions?.filter((_, index) => index <= 10)
                : await handleLoadOptions();

            if ((mockOptions?.length || 0) > 10) {
                options.push({
                    value: null,
                    label: "Continue escrevendo para buscar...",
                });
            }

            setDefaultOptions(options || []);
        }, [handleLoadOptions, mockOptions]);

        // useEffect(() => {
        //     if (!shouldRequestInitialOptions.current) {
        //         return;
        //     }

        //     shouldRequestInitialOptions.current = false;

        //     // NEED TO BE INSIDE A TIMEOUT BECAUSE OF REACT-SELECT BUG
        //     setTimeout(() => {
        //         loadInitialOptions();
        //     }, 1000);
        // }, [loadInitialOptions]);

        const errorMessage = _get(
            error,
            "[0].value",
            _get(error, "label", error)
        );

        const isFirstFocus = useRef(true);

        const handleFocus = useCallback(() => {
            if (!isFirstFocus.current) {
                return;
            }

            isFirstFocus.current = false;

            loadInitialOptions();
        }, [loadInitialOptions]);

        return (
            <FormElementContainer>
                {!!label && (
                    <FormLabel htmlFor={name}>
                        {label}
                        {required && ` *`}
                    </FormLabel>
                )}
                <AsyncSelectElement
                    cacheOptions
                    defaultOptions={defaultOptions}
                    // defaultValue={defaultValue}
                    ref={ref}
                    classNamePrefix="react-select"
                    components={{ DropdownIndicator }}
                    error={!!error}
                    onFocus={handleFocus}
                    isClearable={isClearable}
                    options={[]}
                    loadOptions={handleLoadOptions}
                    loadingMessage={() => dictionary.phrases.loadingSelect}
                    noOptionsMessage={({ inputValue }: any) =>
                        !!inputValue
                            ? dictionary.phrases.selectEmpty
                            : dictionary.phrases.selectTypeSomething
                    }
                    placeholder={placeholder}
                    isOptionDisabled={(option: ISelectOption) =>
                        option.value === null
                    }
                    {...rest}
                />
                {!!errorMessage && showError && (
                    <FormError>{errorMessage}</FormError>
                )}
            </FormElementContainer>
        );
    }
);
export default AsyncSelect;
