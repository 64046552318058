import styled from "styled-components/macro";

export const DropDownContainer = styled.div`
    position: relative;
    z-index: 2;
`;

export const DropDownButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
`;

export const DropDownContent = styled.div<{ minWidth?: string }>`
    position: absolute;
    background: #ffffff;
    border-radius: 8px;
    padding: 15px;
    right: 0;
    top: calc(100% + 6px);
    box-shadow: 0 2px 10px rgba(16, 19, 35, 0.08);
    z-index: -1;
    display: flex;
    flex-direction: column;
    min-width: ${({ minWidth }) => minWidth ?? "200px"};
    max-width: 100%;
`;

export const DropDownIndicator = styled.span`
    background: #ffffff;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 100%;
    transform: rotate(45deg);
    box-shadow: -10px -9px 10px rgba(16, 19, 35, 0.06);
`;
