export function generateSizeStyles(props: any) {
    let sizeStyle = "";

    if (!!props.width) {
        sizeStyle = `width: ${props.width} !important;\n`;
    }

    if (!!props.height) {
        sizeStyle = `height: ${props.height} !important;\n`;
    }

    return sizeStyle;
}
