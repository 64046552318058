import React from "react";
import { IBaseModalElement } from "../../../utils/interfaces/IBaseModalElement";
import ListData, { IListDataProps } from "../../ListData";
import { ModalListContainer } from "./styles";

interface IProps extends IBaseModalElement {
    action?: IListDataProps;
}

const ModalList: React.FC<IProps> = ({ action }) => {
    if (!action) {
        return null;
    }

    return (
        <ModalListContainer>
            <ListData {...action} />
        </ModalListContainer>
    );
};

export default ModalList;
