// sampleReducer.js
import { createReducer, createActions } from "reduxsauce";
import { ProfessionalPosition } from "../../utils/enums/ProfessionalPosition";
import {
    ProjectClassTypes,
    IProjectClassState,
    IProjectClassActionsCreators,
} from "./types";

export const { Creators, Types } = createActions<
    Record<ProjectClassTypes, ProjectClassTypes>,
    IProjectClassActionsCreators
>({
    createOrEditProjectClassRequest: ["payload"],
    createOrEditProjectClassSuccess: ["payload"],
    createOrEditProjectClassFailure: [],

    getProjectClassByIdRequest: ["payload"],
    getProjectClassByIdSuccess: ["payload"],
    getProjectClassByIdFailure: [],

    createProjectClassStudentRequest: ["payload"],
    createProjectClassStudentSuccess: ["payload"],
    createProjectClassStudentFailure: [],

    addProjectClassStudent: ["payload"],

    createProjectClassProfessionalRequest: ["payload"],
    createProjectClassProfessionalSuccess: ["payload"],
    createProjectClassProfessionalFailure: [],

    clearProjectClass: [],

    openProjectClassStudentModal: [],
    closeProjectClassStudentModal: [],

    deleteProjectClassProfessionalRequest: ["payload"],
    deleteProjectClassProfessionalSuccess: ["payload"],
    deleteProjectClassProfessionalFailure: [],
});

const INITIAL_STATE: IProjectClassState = {
    error: false,
    loading: false,
    name: "",
    loadingStudent: false,
    studentModalActive: false,
    loadingProfessional: false,
    loadingProjectClassSetup: false,
    bePresentBeginningClass: false,
    mustHaveGeolocationEndClass: false,
    mustHaveGeolocationStartClass: false,
    sendFileToFinalizeClass: false,
};

const chore = (state = INITIAL_STATE) => state;

const request = (state = INITIAL_STATE, _: any) => {
    return { ...state, error: false, loading: true };
};

const failure = (state = INITIAL_STATE) => {
    return { ...state, error: true, loading: false };
};

const setState = (state = INITIAL_STATE, action: any) => ({
    ...state,
    loading: false,
    error: false,
    ...action.payload,
});

const clear = () => INITIAL_STATE;

const createStudentRequest = (state = INITIAL_STATE, _: any) => {
    return { ...state, loadingStudent: true };
};

const createStudentSuccess = (state = INITIAL_STATE, action: any) => {
    return { ...state, loadingStudent: false };
};

const createStudentFailure = (state = INITIAL_STATE, _: any) => {
    return { ...state, loadingStudent: false };
};

const openCreateStudent = (state = INITIAL_STATE) => {
    return { ...state, studentModalActive: true };
};

const closeCreateStudent = (state = INITIAL_STATE) => {
    return { ...state, studentModalActive: false };
};

const createProfessionalRequest = (state = INITIAL_STATE, _: any) => {
    return { ...state, loadingProfessional: true };
};

const createProfessionalSuccess = (state = INITIAL_STATE, action: any) => {
    const { professional } = action.payload;

    const professionalsPositions: any = {
        [ProfessionalPosition.Teacher]: "teachers",
        // [ProfessionalPosition.Assistant]: "assistants",
        // [ProfessionalPosition.Administrator]: "administrators",
    };

    if (!professionalsPositions[professional?.professional_position_id]) {
        return { ...state, loadingProfessional: false };
    }

    return {
        ...state,
        loadingProfessional: false,
        [professionalsPositions[professional.professional_position_id]]: [
            { professional },
        ],
    };
};

const createProfessionalFailure = (state = INITIAL_STATE, _: any) => {
    return { ...state, loadingProfessional: false };
};

const deleteProfessional = (state = INITIAL_STATE, action: any) => {
    const { position } = action.payload;

    const professionalsKeys = {
        [ProfessionalPosition.Teacher]: "teachers",
        // [ProfessionalPosition.Administrator]: "administrators",
        // [ProfessionalPosition.Assistant]: "assistants",
    };

    const key = (professionalsKeys as any)[position];

    if (!key) {
        return { ...state, loadingProfessional: false };
    }

    return { ...state, loadingProfessional: false, [key]: [] };
};

const HANDLERS = {
    [Types.CREATE_OR_EDIT_PROJECT_CLASS_REQUEST]: request,
    [Types.CREATE_OR_EDIT_PROJECT_CLASS_SUCCESS]: setState,
    [Types.CREATE_OR_EDIT_PROJECT_CLASS_FAILURE]: failure,

    [Types.GET_PROJECT_CLASS_BY_ID_REQUEST]: request,
    [Types.GET_PROJECT_CLASS_BY_ID_SUCCESS]: setState,
    [Types.GET_PROJECT_CLASS_BY_ID_FAILURE]: failure,

    [Types.CREATE_PROJECT_CLASS_STUDENT_REQUEST]: createStudentRequest,
    [Types.CREATE_PROJECT_CLASS_STUDENT_SUCCESS]: createStudentSuccess,
    [Types.CREATE_PROJECT_CLASS_STUDENT_FAILURE]: createStudentFailure,

    [Types.ADD_PROJECT_CLASS_STUDENT]: chore,

    [Types.CLEAR_PROJECT_CLASS]: clear,

    [Types.OPEN_PROJECT_CLASS_STUDENT_MODAL]: openCreateStudent,
    [Types.CLOSE_PROJECT_CLASS_STUDENT_MODAL]: closeCreateStudent,

    [Types.CREATE_PROJECT_CLASS_PROFESSIONAL_REQUEST]:
        createProfessionalRequest,
    [Types.CREATE_PROJECT_CLASS_PROFESSIONAL_SUCCESS]:
        createProfessionalSuccess,
    [Types.CREATE_PROJECT_CLASS_PROFESSIONAL_FAILURE]:
        createProfessionalFailure,

    [Types.DELETE_PROJECT_CLASS_PROFESSIONAL_REQUEST]:
        createProfessionalRequest,
    [Types.DELETE_PROJECT_CLASS_PROFESSIONAL_SUCCESS]: deleteProfessional,
    [Types.DELETE_PROJECT_CLASS_PROFESSIONAL_FAILURE]:
        createProfessionalFailure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
