import ClockIcon from "components/Icons/clock";
import CustomText from "components/Text";
import React from "react";

interface IProps {
    start_time?: string;
    end_time?: string;
    days?: string;
}

const TableProjectClassDay = ({ start_time, end_time, days }: IProps) => {
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <CustomText color="components.table.color" size={15} bottom="4px">
                {days}
            </CustomText>

            <CustomText
                color="components.table.color"
                size={14}
                style={{ display: "flex", alignItems: "center" }}
            >
                <ClockIcon width={14} height={14} style={{ marginRight: 3 }} />{" "}
                {start_time} às {end_time}
            </CustomText>
        </div>
    );
};

export default TableProjectClassDay;
