import React, { memo } from "react";
import { theme } from "../../config/theme";
import { IIconProps } from "../../utils/interfaces/IIconProps";

const ArrowRightIcon = memo(
    ({ fill = theme.colors.black, ...props }: IIconProps) => {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                {...props}
                viewBox="0 0 16 14"
            >
                <defs>
                    <clipPath id="clip-path">
                        <path
                            d="M1,8H12.865L9.232,12.36a1,1,0,0,0,1.537,1.28l5-6a.936.936,0,0,0,.087-.154.947.947,0,0,0,.071-.124A.985.985,0,0,0,16,7s0,0,0,0,0,0,0,0a.985.985,0,0,0-.072-.358.947.947,0,0,0-.071-.124.936.936,0,0,0-.087-.154l-5-6A1,1,0,0,0,9.232,1.64L12.865,6H1A1,1,0,0,0,1,8"
                            transform="translate(4 5)"
                            fill={fill}
                        />
                    </clipPath>
                </defs>
                <g transform="translate(-4 -5)">
                    <path
                        d="M1,8H12.865L9.232,12.36a1,1,0,0,0,1.537,1.28l5-6a.936.936,0,0,0,.087-.154.947.947,0,0,0,.071-.124A.985.985,0,0,0,16,7s0,0,0,0,0,0,0,0a.985.985,0,0,0-.072-.358.947.947,0,0,0-.071-.124.936.936,0,0,0-.087-.154l-5-6A1,1,0,0,0,9.232,1.64L12.865,6H1A1,1,0,0,0,1,8"
                        transform="translate(4 5)"
                        fill={fill}
                    />
                </g>
            </svg>
        );
    }
);

export default ArrowRightIcon;
