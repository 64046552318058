import {
    TypedUseSelectorHook,
    useDispatch,
    useSelector as useReduxSelector,
} from "react-redux";
import { store } from "../store";
import { IReduxStore } from "../store/ducks";

export const useAppSelector: TypedUseSelectorHook<IReduxStore> =
    useReduxSelector;
export const useAppDispatch = () => useDispatch<typeof store.dispatch>();
