export default function removeNestedObjects(data: object) {
    return Object.keys(data)
        .filter((item) => typeof (data as any)[item] !== "object")
        .reduce((accumulator: any, currentValue) => {
            accumulator = {
                ...accumulator,
                [currentValue]: (data as any)[currentValue],
            };

            return accumulator;
        }, {});
}
