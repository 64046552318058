import React, { useCallback, useEffect } from "react";
import { Row, Col } from "../Grid";
import { Card } from "../Card";
import CustomText from "../Text";
import useGraphQL from "../../hooks/useGraphQL";
import generateGetByIdQuery from "../../helpers/generateGetByIdQuery";
import { IInputField } from "../../utils/interfaces/IInputField";
import { dictionary } from "../../config/dictionary";
import _get from "lodash/get";
import { Skeleton } from "../Skeleton";
import { theme } from "../../config/theme";
import ListCardsItem from "./item";
import ClockIcon from "components/Icons/clock";
import { InputType } from "utils/enums/InputType";

type IInputFieldWithValueCallback = IInputField & {
    valueCallback?: (value: any) => any;
    customKey?: string;
};

export interface IListCardsConfig {
    cards: IInputFieldWithValueCallback[];
    defaultValue?: any;
    graphQL?: {
        mainColumn?: string;
        tableName?: string;
        query?: string;
        primaryKey?: string | number;
        returnPath?: string;
        errorMessage?: string;
        variables?: any;
    };
}

const ListCards: React.FC<IListCardsConfig> = ({
    cards,
    graphQL,
    defaultValue,
}) => {
    const { response, request, loading } = useGraphQL();

    const requestData = useCallback(() => {
        if (!graphQL) {
            return;
        }

        const mainColumn = graphQL?.mainColumn ?? "id";
        const query =
            graphQL?.query ??
            generateGetByIdQuery(graphQL?.tableName ?? "", cards);

        request(
            query,
            {
                [mainColumn]: graphQL?.primaryKey,
                ...graphQL?.variables,
            },
            graphQL?.returnPath ?? "item",
            graphQL?.errorMessage
        );
    }, [cards, graphQL, request]);

    useEffect(() => {
        requestData();
    }, [requestData]);

    const color = theme.colors.components.listCards.color;

    return (
        <Row>
            {cards?.map(
                ({
                    name,
                    label,
                    grid = { col: "auto" },
                    type,
                    enumValue,
                    customKey,
                    valueCallback,
                }) => {
                    const newValue = valueCallback?.(response);

                    const value = _get(
                        !!graphQL ? response : defaultValue,
                        name
                    );

                    return (
                        <Col {...grid} key={customKey || name} bottom="30px">
                            <Card style={{ textAlign: "center" }}>
                                <CustomText
                                    weight="Bold"
                                    color={color}
                                    size={28}
                                    bottom="10px"
                                    ellipsis
                                    style={{ alignItems: "center" }}
                                >
                                    {loading ? (
                                        <Skeleton width="100%" height="38px" />
                                    ) : (
                                        <>
                                            {type === InputType.Time && (
                                                <ClockIcon
                                                    width={20}
                                                    height={20}
                                                    style={{ marginRight: 5 }}
                                                    fill={theme.colors.gray}
                                                />
                                            )}
                                            <ListCardsItem
                                                type={type}
                                                value={
                                                    !!newValue
                                                        ? newValue
                                                        : value
                                                }
                                                enumValue={enumValue}
                                            />
                                        </>
                                    )}
                                </CustomText>

                                <CustomText color={color} size={16}>
                                    {label ?? (dictionary as any)[name] ?? name}
                                </CustomText>
                            </Card>
                        </Col>
                    );
                }
            )}
        </Row>
    );
};

export default ListCards;
