import cep from "cep-promise";

export interface IRequestCEPResponse {
    cep: string;
    city: string;
    neighborhood: string;
    state: string;
    street: string;
}

export const requestCEP = cep;
