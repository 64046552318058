export default function generateDeleteQuery(
    mainColumn?: string | Array<string>,
    tableName?: string,
    alternativeQuery?: any
) {
    if (!tableName) {
        return alternativeQuery;
    }

    const body = Array.isArray(mainColumn)
        ? mainColumn?.join("\n")
        : mainColumn || "id";

    const variables = Array.isArray(mainColumn)
        ? mainColumn?.map((column) => `$${column}: Int!`).join(", ")
        : `$${mainColumn || "id"}: Int!`;

    const pks = Array.isArray(mainColumn)
        ? mainColumn?.map((column) => `${column}: $${column}`).join(", ")
        : `${mainColumn || "id"}: $${mainColumn || "id"}`;

    return `mutation (${variables}) {
        item: update_${tableName}_by_pk(pk_columns: {${pks}}, _set: {status: -1}) {
          ${body}
        }
      }`;
}
