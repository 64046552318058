import React, { useCallback, useEffect, useState } from "react";
import FormFactory, { IOnChangeWatchers } from "components/Form/FormFactory";
import {
    projectClassesFields,
    projectClassSchema,
} from "routes/entities/projectClasses";
import { useProjectClass } from "hooks/useProjectClass";
import { ISelectOption } from "utils/interfaces/ISelectOption";
import Spinner from "components/Spinner";

interface IProps {
    id?: string | number;
    onCreate?(data: any): void;
    project?: ISelectOption;
}

const ProjectClassForm = ({ id, onCreate, project }: IProps) => {
    const { createOrEdit, getById, state, clear } = useProjectClass();

    const [projectId, setProjectId] = useState<number>();

    const { loading, ...formState } = state || {};

    useEffect(() => {
        return () => {
            clear();
        };
    }, [clear]);

    useEffect(() => {
        if (!id) {
            return;
        }

        getById({ id });
    }, [getById, id]);

    const handleSubmit = useCallback(
        (data: any) => {
            console.log({ data });

            createOrEdit({
                id,
                body: {
                    ...data,
                },
                onCreate,
            });
        },
        [createOrEdit, id, onCreate]
    );

    const showLoading = !!id ? !state?.id : false;

    useEffect(() => {
        if (project?.value) {
            setProjectId(project?.value);
        }
    }, [project]);

    const onChangeWatchers = useCallback((data: IOnChangeWatchers[]) => {
        const projectField = data?.find(({ name }) => name === "project");

        if (!projectField?.value?.value) {
            return;
        }

        setProjectId(projectField?.value?.value);
    }, []);

    if (showLoading) {
        return <Spinner />;
    }

    const initialData = !!project
        ? { project }
        : !!state?.id
        ? formState
        : undefined;

    return (
        <FormFactory
            fields={projectClassesFields(!!project?.value, projectId)}
            onSubmit={handleSubmit}
            schema={projectClassSchema({ isEdit: !!id })}
            isLoading={!!loading}
            initialData={initialData}
            watchers={["project"]}
            onChangeWatchers={onChangeWatchers}
        />
    );
};

export default ProjectClassForm;
