import Button from "components/Button";
import { dictionary } from "config/dictionary";
import { useModal } from "hooks/useModal";
import React, { useCallback, useEffect } from "react";
import { InputType } from "utils/enums/InputType";
import { ModalElements } from "utils/enums/ModalElements";
import { IInputField } from "utils/interfaces/IInputField";
import { object, string } from "yup";
import { toast } from "react-toastify";
import useApi, { IRequestPayload } from "hooks/useApi";
import Spinner from "components/Spinner";
import generateSearchQuery from "helpers/generateSearchQuery";
import { selectSchema } from "utils/schemas/select";

interface IProps {
    id: number;
    onSubmit(): void;
}

const DispatchButton = ({ id, onSubmit }: IProps) => {
    const { close: closeModal, open: openModal } = useModal();

    const { request, loading, response } = useApi<any>();

    useEffect(() => {
        if (!!response?.created) {
            toast.success("Pedido enviado!");

            closeModal();
            onSubmit();
        }
    }, [closeModal, onSubmit, response]);

    const handleSubmit = useCallback(
        (body: any) => {
            console.log({ body });

            if (loading) {
                return;
            }

            const payload: IRequestPayload = {
                endpoint: `/admin/orders/${id}/dispatch`,
                method: "post",
                body: {
                    ...body,
                    provider: body?.provider?.value,
                },
            };

            request(payload);
        },
        [id, loading, request]
    );

    const handleClick = useCallback(() => {
        const schema = object().shape({
            observation: string()
                .default(null)
                .typeError(dictionary.validations.required),
            provider: selectSchema().required(dictionary.validations.required),
        });

        const fields: IInputField[] = [
            {
                name: "observation",
                type: InputType.Text,
            },
            {
                name: "provider",
                type: InputType.AsyncSelect,
                graphQL: {
                    searchQuery: generateSearchQuery({ tableName: "provider" }),
                },
            },
        ];

        openModal({
            element: ModalElements.GenericForm,
            title: "Enviar pedido",
            width: undefined,
            action: {
                fields,
                onSubmit: handleSubmit,
                schema,
            },
        });
    }, [handleSubmit, openModal]);

    return (
        <>
            {loading && <Spinner fullScreen style={{ zIndex: 10 }} />}
            <Button variant="main" onClick={handleClick}>
                Enviar
            </Button>
        </>
    );
};

export default DispatchButton;
