const defaultColors = {
    white: "#fff",
    base: "#707590",
    primary: "#0d6efd",
    grayLight: "#4d5061",
    main: "#473F97",
    mainDark: "#1A173F",
    mainLight: "#EFF4FA",
    secondary: "#94CBFF",
    success: "#28a745",
    warning: "#FFB45E",
    danger: "#FF2D55",
    info: "#0095FF",
    black: "#222B45",
    gray: "#8F9BB3",
    placeholder: "#C5CEE0",
    background: "#F9FBFD",
};

const {
    white,
    base,
    grayLight,
    main,
    mainDark,
    mainLight,

    black,
    gray,
    placeholder,
    background,
} = defaultColors;

export const theme = {
    projectName: "SAS Admin",
    projectSlug: "admin-sas",
    colors: {
        ...defaultColors,
        components: {
            navbar: {
                small: {
                    background: mainDark,
                    borderColor: mainDark,
                    iconColor: white,
                    buttonHoverBackground: "rgba(255, 255, 255, 0.05)",
                },
                big: {
                    background: white,
                    borderColor: mainLight,
                    iconColor: placeholder,
                    color: gray,
                    colorHover: mainDark,
                    colorActive: main,
                    activeLineBackground: main,
                },
            },
            table: {
                borderColor: mainLight,
                color: gray,
                header: {
                    background: mainLight,
                    color: gray,
                },
            },
            card: {
                borderColor: mainLight,
                background: white,
            },
            form: {
                label: {
                    color: base,
                },
                input: {
                    backgroundColor: white,
                    borderColor: mainLight,
                    color: black,
                },
                select: {
                    backgroundColor: white,
                    borderColor: grayLight,
                    color: black,
                    colorSelected: black,
                    backgroundFocused: mainLight,
                    backgroundSelected: "rgb(13 110 253 / 20%)",
                },
            },
            spinner: {
                background: background,
                iconColor: main,
            },
            pagination: {
                background: white,
                buttonColor: gray,
                buttonColorHover: base,
                numberColor: gray,
                numberColorHover: base,
                numberColorActive: main,
            },
            tab: {
                color: gray,
                colorHover: base,
                colorActive: main,
            },
            listCards: {
                color: base,
            },
            breadcrumb: {
                color: gray,
                hoverColor: base,
                iconColor: gray,
            },
        },
    },
};
