import React, { memo } from "react";
import { theme } from "../../config/theme";
import { IIconProps } from "../../utils/interfaces/IIconProps";

const DashboardIcon = memo(
    ({ fill = theme.colors.black, ...props }: IIconProps) => {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                {...props}
                viewBox="0 0 12 12"
            >
                <path
                    d="M10,12H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0h8a2,2,0,0,1,2,2v8A2,2,0,0,1,10,12ZM1.333,5.333V10A.668.668,0,0,0,2,10.667h8A.668.668,0,0,0,10.667,10V5.333Zm.667-4A.668.668,0,0,0,1.333,2V4h9.334V2A.668.668,0,0,0,10,1.333Zm4,2a.667.667,0,1,1,.666-.667A.668.668,0,0,1,6,3.334Zm-2.667,0A.667.667,0,1,1,4,2.667.668.668,0,0,1,3.334,3.334Z"
                    transform="translate(0 0)"
                    fill={fill}
                />
            </svg>
        );
    }
);

export default DashboardIcon;
