import React, { memo } from "react";
import { theme } from "../../config/theme";
import { IIconProps } from "../../utils/interfaces/IIconProps";

const EyeIcon = memo(({ fill = theme.colors.black, ...props }: IIconProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16.666 11.667"
        >
            <defs>
                <clipPath id="clip-path">
                    <path
                        d="M8.354,11.667C3.461,11.667.629,7.154.111,6.249a.833.833,0,0,1,0-.829C.822,4.179,3.5.121,8.108,0c.081,0,.164,0,.245,0A8.44,8.44,0,0,1,14.07,2.368,13.145,13.145,0,0,1,16.556,5.42a.837.837,0,0,1,0,.829c-.712,1.241-3.392,5.3-8,5.416Zm-.049-10-.155,0a6.7,6.7,0,0,0-4.1,1.611A10.889,10.889,0,0,0,1.81,5.831C2.633,7.084,4.921,10,8.373,10l.143,0a6.7,6.7,0,0,0,4.1-1.611,10.857,10.857,0,0,0,2.241-2.55C14.032,4.585,11.747,1.667,8.305,1.668Zm.028,7.083A2.917,2.917,0,1,1,11.25,5.834,2.92,2.92,0,0,1,8.333,8.751Zm0-4.167a1.25,1.25,0,1,0,1.25,1.25A1.252,1.252,0,0,0,8.333,4.584Z"
                        transform="translate(0 0)"
                        fill={fill}
                    />
                </clipPath>
            </defs>
            <g transform="translate(0)">
                <path
                    d="M8.354,11.667C3.461,11.667.629,7.154.111,6.249a.833.833,0,0,1,0-.829C.822,4.179,3.5.121,8.108,0c.081,0,.164,0,.245,0A8.44,8.44,0,0,1,14.07,2.368,13.145,13.145,0,0,1,16.556,5.42a.837.837,0,0,1,0,.829c-.712,1.241-3.392,5.3-8,5.416Zm-.049-10-.155,0a6.7,6.7,0,0,0-4.1,1.611A10.889,10.889,0,0,0,1.81,5.831C2.633,7.084,4.921,10,8.373,10l.143,0a6.7,6.7,0,0,0,4.1-1.611,10.857,10.857,0,0,0,2.241-2.55C14.032,4.585,11.747,1.667,8.305,1.668Zm.028,7.083A2.917,2.917,0,1,1,11.25,5.834,2.92,2.92,0,0,1,8.333,8.751Zm0-4.167a1.25,1.25,0,1,0,1.25,1.25A1.252,1.252,0,0,0,8.333,4.584Z"
                    transform="translate(0 0)"
                    fill={fill}
                />
            </g>
        </svg>
    );
});

export default EyeIcon;
