import Button from "components/Button";
import CustomText from "components/Text";
import React from "react";
import { DownloadButtonContainer } from "./styles";

interface IProps {
    onClick(): void;
    isLoading: boolean;
    pdf?: string;
}

const DownloadButton = ({ onClick, isLoading = false, pdf }: IProps) => {
    return (
        <DownloadButtonContainer>
            <Button
                type="submit"
                variant="success"
                left="20px"
                onClick={onClick}
                loading={isLoading}
            >
                Exportar PDF
            </Button>

            {!isLoading && !!pdf && (
                <CustomText color="base" size={16}>
                    Link para download:{" "}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={pdf}
                        download
                    >
                        {pdf}
                    </a>
                </CustomText>
            )}
        </DownloadButtonContainer>
    );
};

export default DownloadButton;
