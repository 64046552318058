import React, { memo } from "react";
import { IBaseModalElement } from "../../../utils/interfaces/IBaseModalElement";
import Button from "../../Button";
import { ButtonGroup } from "../../Button/styles";
import CustomText from "../../Text";

import * as S from "./styles";

const ModalConfirmAction = memo(
    ({
        action,
        onClose,
    }: IBaseModalElement<{ message: string; onConfirm(): void }>) => {
        return (
            <S.ModalConfirmActionContainer>
                {!!action?.message && (
                    <CustomText
                        color="base"
                        size={16}
                        bottom="35px"
                        weight="SemiBold"
                    >
                        {action.message}
                    </CustomText>
                )}

                <ButtonGroup>
                    <Button
                        variant="success"
                        width="120px"
                        onClick={() => action?.onConfirm()}
                    >
                        SIM
                    </Button>

                    <Button variant="danger" width="120px" onClick={onClose}>
                        NÃO
                    </Button>
                </ButtonGroup>
            </S.ModalConfirmActionContainer>
        );
    }
);

export default ModalConfirmAction;
