import React, { memo } from "react";
import { theme } from "../../config/theme";
import { IIconProps } from "../../utils/interfaces/IIconProps";

const CrossIcon = memo(
    ({ fill = theme.colors.black, ...props }: IIconProps) => {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                {...props}
                viewBox="0 0 7.024 12"
            >
                <defs>
                    <clipPath>
                        <path
                            d="M2.736,11.641.27,8.657A1.222,1.222,0,0,1,.119,7.364a1.031,1.031,0,0,1,.928-.614H5.978a1.031,1.031,0,0,1,.928.614,1.221,1.221,0,0,1-.152,1.294L4.288,11.641a1.019,1.019,0,0,1-1.552,0ZM1.046,5.25a1.031,1.031,0,0,1-.928-.614A1.222,1.222,0,0,1,.27,3.342L2.736.359a1.019,1.019,0,0,1,1.552,0L6.754,3.342a1.221,1.221,0,0,1,.152,1.294,1.031,1.031,0,0,1-.928.614Z"
                            transform="translate(0 0)"
                            fill={fill}
                        />
                    </clipPath>
                </defs>
                <g transform="translate(0 0)">
                    <path
                        d="M2.736,11.641.27,8.657A1.222,1.222,0,0,1,.119,7.364a1.031,1.031,0,0,1,.928-.614H5.978a1.031,1.031,0,0,1,.928.614,1.221,1.221,0,0,1-.152,1.294L4.288,11.641a1.019,1.019,0,0,1-1.552,0ZM1.046,5.25a1.031,1.031,0,0,1-.928-.614A1.222,1.222,0,0,1,.27,3.342L2.736.359a1.019,1.019,0,0,1,1.552,0L6.754,3.342a1.221,1.221,0,0,1,.152,1.294,1.031,1.031,0,0,1-.928.614Z"
                        transform="translate(0 0)"
                        fill={fill}
                    />
                </g>
            </svg>
        );
    }
);

export default CrossIcon;
