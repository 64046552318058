import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import { theme } from "../../../config/theme";
import { ButtonElement } from "../../Button/styles";

export const NavbarSmall = styled.div`
    display: flex;
    flex-direction: column;
    width: 50px;
    background: ${theme.colors.components.navbar.small.background};
    padding: 25px 8px;
    align-items: center;
    z-index: 1;
    border-right: 1px solid ${theme.colors.components.navbar.small.borderColor};
`;

export const NavbarSmallItems = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
`;

export const NavbarSmallItemButton = styled.button.attrs({
    type: "button",
})`
    width: 34px;
    height: 34px;
    border-radius: 8px;
    background: transparent;
    border: none;
    align-items: center;
    justify-content: center;
    display: flex;

    > * {
        fill: ${theme.colors.components.navbar.small.iconColor};
        opacity: 0.6;
    }

    :hover {
        background: ${theme.colors.components.navbar.small
            .buttonHoverBackground};

        > * {
            opacity: 1;
        }
    }

    :not(:last-child) {
        margin-bottom: 15px;
    }
`;

export const NavbarSmallLogo = styled.button.attrs({
    type: "button",
})`
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    cursor: pointer;
    background: transparent;
    width: 100%;
    padding: 0;
    margin: 0;
    margin-bottom: 29px;

    > img {
        margin: 0 auto;
    }
`;

export const NavbarBig = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    background: ${theme.colors.components.navbar.big.background};
    padding: 25px 15px;
    border-right: 1px solid ${theme.colors.components.navbar.big.borderColor};
    width: 150px;

    @media only screen and (min-width: 1200px) {
        width: 186px;
    }

    > ${ButtonElement} {
        width: 100%;
        justify-content: flex-start;
        padding-left: 0;
    }
`;

export const NavbarBigProjectName = styled.button.attrs({
    type: "button",
})`
    width: 100%;

    display: flex;
    margin-bottom: 35px;
    border: none;
    outline: none;
    cursor: pointer;
    background: transparent;

    > svg {
        margin-top: 2px;
    }
`;

export const NavbarListItems = styled.div`
    display: flex;
    flex-direction: column;
`;

const itemStyles = css`
    display: flex;
    align-items: center;
    color: ${theme.colors.components.navbar.big.color};
    font-size: 15px;
    margin-bottom: 20px;
    text-decoration: none;
    text-transform: none;

    > span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &.active {
        :after {
            position: absolute;
            display: block;
            width: 2px;
            height: calc(100% + 4px);
            right: -15px;
            background: ${theme.colors.components.navbar.big
                .activeLineBackground};
            content: " ";
        }
    }

    &.active {
        color: ${theme.colors.components.navbar.big.colorActive};
        position: relative;

        > svg {
            fill: ${theme.colors.components.navbar.big.colorActive};

            > path {
                fill: ${theme.colors.components.navbar.big.colorActive};
            }
        }
    }

    :hover {
        color: ${theme.colors.components.navbar.big.colorHover};
        position: relative;

        > svg {
            fill: ${theme.colors.components.navbar.big.colorHover};

            > path {
                fill: ${theme.colors.components.navbar.big.colorHover};
            }
        }
    }

    > svg {
        margin-right: 10px;
        fill: ${theme.colors.components.navbar.big.iconColor};

        > path {
            fill: ${theme.colors.components.navbar.big.iconColor};
        }
    }
`;

export const NavbarItem = styled.span`
    ${itemStyles};
`;

export const NavbarItemLink = styled(NavLink)`
    ${itemStyles};
`;

export const NavbarSubItemContainer = styled.div`
    display: flex;
    position: relative;

    > .line {
        width: 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: calc(100% + 10px);

        :before {
            content: "";
            width: 2px;
            height: 100%;
            background: rgba(143, 155, 179, 0.3);
            margin-top: -10px;
        }
    }

    > .wrap {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
`;

export const NavbarSubItem = styled(NavbarItemLink)`
    position: relative;

    :last-child {
        margin-bottom: 0;
    }

    :before {
        content: "";
        width: 13px;
        background: rgba(143, 155, 179, 0.3);
        height: 2px;
        margin-left: -6px;
        margin-right: 3px;
    }
`;
