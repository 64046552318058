import { put, takeLatest, call, select } from "redux-saga/effects";
import graphql from "../../services/graphql";
import { ISagaAction } from "../../utils/interfaces/ISagaAction";

import { Creators, Types } from ".";
import { Creators as ModalActions } from "../modal";
import { Creators as CrudActions } from "../crud";

import { toast } from "react-toastify";
import history from "../../services/history";

import _pick from "lodash/pick";
import { createStudentSubscriptionQuery } from "../../queries/students/createSubscription";
import { IReduxStore } from "../ducks";
import { IStudent } from "../student/types";
import {
    ICreateProjectClassPayload,
    ICreateProjectClassSetupPayload,
    IDeleteProfessionalPayload,
} from "../projectClass/types";
import api from "services/api";
import { formatDate } from "helpers/formatDate";
import formatInitialDataFormValues from "helpers/formatFormValues";
import { projectClassesFields } from "routes/entities/projectClasses";
import { ISelectOption } from "utils/interfaces/ISelectOption";
import { IYield } from "utils/interfaces/IYield";
import { ProjectClassSetupType } from "utils/enums/ProjectClass";

function* getProjectClassId() {
    return yield* select((state: IReduxStore) => state.projectClass?.id) as any;
}

function* createOrEdit(
    action: ISagaAction<
        ICreateProjectClassPayload & ICreateProjectClassSetupPayload
    >
) {
    try {
        const { id, body, onCreate } = action.payload;

        const formatBody = {
            ..._pick(body, [
                "project",
                "professionals",
                "weekDays",
                "name",
                "modality",
                "endDate",
                "startDate",
                "startTimeString",
                "endTimeString",
            ]),
            startDate: formatDate(body?.startDate, "dd/LL/yyyy"),
            endDate: formatDate(body?.endDate, "dd/LL/yyyy"),
            weekDays: (body?.weekDays as ISelectOption[])
                ?.map(({ value }) => value)
                ?.filter(Boolean)
                ?.join(";"),
            modality: body?.modality?.value,
            project: body?.project?.value,
            setups: [
                {
                    enabled: body?.setups[0].enabled || false,
                    type: ProjectClassSetupType.MandatoryPresence,
                },
                {
                    enabled: body?.setups[1].enabled || false,
                    type: ProjectClassSetupType.Media,
                },
                {
                    enabled: body?.setups[2].enabled || false,
                    type: ProjectClassSetupType.StartClassGeolocaton,
                },
                {
                    enabled: body?.setups[3].enabled || false,
                    type: ProjectClassSetupType.EndClassGeolocation,
                },
            ],
            // professionals: [body?.professionals?.value],
        };

        const method = !!id ? api.patch : api.post;
        const endpoint = !!id ? `/admin/classes/${id}` : "/admin/classes";

        const { data } = yield call(method, endpoint, formatBody);

        if (!data) {
            throw new Error();
        }

        // const addressData = {
        //     ...formatBody?.address,
        //     ...formatBody?.body?.address?.data,
        //     id_project_class: data?.item.id_project_class,
        // };

        // yield call(createOrEditAddress, addressData);

        yield put(Creators.createOrEditProjectClassSuccess(data));

        yield call(toast.success, "Registro salvo!");

        if (onCreate) {
            return onCreate(data);
        }
        yield call(history.push, { pathname: "/app/turmas" });
    } catch (error) {
        console.log("error", error);

        yield call(toast.error, "Desculpe, tivemos um problema.");
        yield put(Creators.createOrEditProjectClassFailure());
    }
}

function* getById(
    action: ISagaAction<{
        id: number | string;
    }>
): IYield {
    try {
        if (!action?.payload?.id) {
            throw new Error();
        }

        const { id } = action.payload;

        const endpoint = `/admin/classes/${id}`;

        const { data } = yield call(api.get, endpoint);

        if (!data?.id) {
            throw new Error();
        }

        const formatBody = {
            ...formatInitialDataFormValues(
                {
                    ...data,
                    days: undefined,
                    // weekDays: data?.weekDays?.split(";")?.map((i: any) => ({
                    //     value: i,
                    //     label: (dictionary as any)[WeekDays[i]],
                    // })),
                    startTimeString: data?.startTime,
                    endTimeString: data?.endTime,
                },
                projectClassesFields()
            ),
            professionals: [],
        };

        yield put(Creators.getProjectClassByIdSuccess(formatBody));
    } catch (error) {
        console.log("error", error);

        yield call(toast.error, "Desculpe, tivemos um problema.");

        yield put(Creators.getProjectClassByIdFailure());

        yield call(history.push, { pathname: "/app/turmas" });
    }
}

function* createStudent(action: ISagaAction<IStudent>) {
    try {
        console.log("action", action);

        if (!action?.payload?.id) {
            throw new Error();
        }

        const id_project_class = yield* getProjectClassId();

        if (!id_project_class) {
            throw new Error();
        }

        const body = {
            id_project_class,
            id_student: action?.payload?.id,
            is_active: true,
        };

        const { data } = yield call(graphql, createStudentSubscriptionQuery, {
            body,
        });

        if (!data?.item) {
            throw new Error();
        }

        const { created_at, id_subscription } = data.item;

        const student = {
            created_at,
            id_subscription,
            student: {
                person: action?.payload.person,
            },
        };

        yield put(Creators.createProjectClassStudentSuccess(student));

        yield put(
            CrudActions.crudAddListItem({
                item: student,
                contentId: "students",
            })
        );

        yield call(toast.success, "Aluno adicionado com sucesso.");
        yield put(ModalActions.closeModal());
        yield put(Creators.closeProjectClassStudentModal());
    } catch (error) {
        console.log("error", error);

        yield call(toast.error, "Desculpe, tivemos um problema.");

        yield put(Creators.createProjectClassStudentFailure());

        // yield call(history.push, { pathname: "/app/turmas" });
    }
}

function* createProfessional(action: ISagaAction<any>) {
    try {
        if (
            !action?.payload?.professional_position_id ||
            !action?.payload?.id_professional
        ) {
            throw new Error();
        }

        const projectClassId = yield* select(
            (state) => state.projectClass.id_project_class
        ) as any;

        if (!projectClassId) {
            throw new Error();
        }

        const { professional_position_id, id_professional } = action.payload;

        const { data } = yield call(
            graphql,
            `mutation ($projectClassId: Int!, $professionalPosition: Int!, $professionalId: Int!) {
                updateProfessionals: delete_project_class_professional(where: {id_project_class: {_eq: $projectClassId}, professional: {professional_position_id: {_eq: $professionalPosition}}}) {
                  affected_rows
                }
                addProfessional: insert_project_class_professional_one(object: {id_professional: $professionalId, id_project_class: $projectClassId}) {
                  professional {
                    id_professional
                    professional_position_id
                    person {
                      name
                    }
                  }
                }
              }
              `,
            {
                projectClassId: projectClassId,
                professionalId: id_professional,
                professionalPosition: professional_position_id,
            }
        );

        yield put(ModalActions.closeModal());

        yield put(
            Creators.createProjectClassProfessionalSuccess(data.addProfessional)
        );

        yield call(toast.success, "Profissional atualizado com sucesso.");
    } catch (error) {
        console.log("error", error);

        yield call(toast.error, "Desculpe, tivemos um problema.");

        yield put(Creators.createProjectClassProfessionalFailure());
    }
}

function* addStudentToCrud(action: ISagaAction<IStudent>) {
    try {
        if (!action?.payload?.id) {
            throw new Error();
        }

        const id_project_class = yield* getProjectClassId();

        if (!id_project_class) {
            throw new Error();
        }

        const id_subscription = action?.payload?.subscriptions?.find(
            (item) => item?.project_class?.id_project_class === id_project_class
        )?.id_subscription;

        const student = {
            created_at: new Date(),
            id_subscription,
            student: {
                person: action?.payload?.person,
            },
        };

        yield put(
            CrudActions.crudAddListItem({
                item: student,
                contentId: "students",
            })
        );

        yield call(toast.success, "Aluno adicionado com sucesso.");
        yield put(ModalActions.closeModal());
        yield put(Creators.closeProjectClassStudentModal());
    } catch (error) {
        yield call(toast.error, "Desculpe, tivemos um problema.");
    }
}

function* deleteProfessional(action: ISagaAction<IDeleteProfessionalPayload>) {
    try {
        if (!action?.payload?.id) {
            throw new Error();
        }

        const idProjectClass = yield* getProjectClassId();

        const { data } = yield call(
            graphql,
            `mutation ($idProjectClass: Int!, $idProfessional: Int!) {
            item: delete_project_class_professional(where: {id_professional: {_eq: $idProfessional}, id_project_class: {_eq: $idProjectClass}}) {
              affected_rows
            }
          }`,
            {
                idProfessional: action?.payload?.id,
                idProjectClass,
            }
        );

        if (!data?.item) {
            throw new Error();
        }

        yield put(
            Creators.deleteProjectClassProfessionalSuccess(action?.payload)
        );
        yield put(ModalActions.closeModal());

        yield call(toast.success, "Profissional removido com sucesso.");
    } catch (error) {
        yield put(Creators.deleteProjectClassProfessionalFailure());
        yield call(toast.error, "Desculpe, tivemos um problema.");
    }
}

export default [
    takeLatest(Types.CREATE_OR_EDIT_PROJECT_CLASS_REQUEST, createOrEdit),
    takeLatest(Types.GET_PROJECT_CLASS_BY_ID_REQUEST, getById),
    takeLatest(Types.CREATE_PROJECT_CLASS_STUDENT_REQUEST, createStudent),
    takeLatest(
        Types.CREATE_PROJECT_CLASS_PROFESSIONAL_REQUEST,
        createProfessional
    ),
    takeLatest(Types.ADD_PROJECT_CLASS_STUDENT, addStudentToCrud),
    takeLatest(
        Types.DELETE_PROJECT_CLASS_PROFESSIONAL_REQUEST,
        deleteProfessional
    ),
];
