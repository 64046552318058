import styled from "styled-components";
import { theme } from "../../config/theme";
import { ISizing } from "../../utils/interfaces/ISizing";

interface IProps extends ISizing {
    size: string;
    image?: string;
}

export const AvatarContainer = styled.div<IProps>`
    display: flex;
    width: ${({ size }) => size};
    padding: 0;
    background: ${({ image }) =>
        !!image
            ? `url(${image}) ${theme.colors.placeholder}`
            : theme.colors.placeholder};
    border: 1px solid #ffffff;
    border-radius: 100%;
    position: relative;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    align-items: center;
    justify-content: center;

    :before {
        padding-top: 100%;
        display: block;
        content: "";
    }

    :after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }

    > span {
        color: ${theme.colors.base};
        font-size: 16px;
        line-height: 16px;
        position: absolute;
    }
`;

export const AvatarButton = styled.button.attrs({ type: "button" })`
    background: transparent;
    border: none;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;

    :hover {
        background: rgba(0, 0, 0, 0.2);
        opacity: 1;
    }
`;
