import React, { memo } from "react";
import { theme } from "../../config/theme";
import { IIconProps } from "../../utils/interfaces/IIconProps";

const EditIcon = memo(({ fill = theme.colors.black, ...props }: IIconProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            viewBox="0 0 13.334 16.648"
        >
            <path
                d="M12.5,16.648H.833a.833.833,0,0,1,0-1.667H12.5a.833.833,0,0,1,0,1.667ZM.833,13.315A.833.833,0,0,1,0,12.406L.32,8.932A1.644,1.644,0,0,1,.794,7.92l7.5-7.5A1.5,1.5,0,0,1,9.365,0a1.69,1.69,0,0,1,1.189.481l2.281,2.282a1.6,1.6,0,0,1,.058,2.263l-7.5,7.5A1.647,1.647,0,0,1,4.385,13l-3.475.316Zm5.82-8.9h0L1.973,9.1l-.22,2.463,2.48-.226L8.9,6.664,6.653,4.417Zm2.74-2.741h0L7.77,3.3l2.245,2.245,1.623-1.625L9.393,1.677Z"
                fill={fill}
            />
        </svg>
    );
});

export default EditIcon;
