import styled, { css } from "styled-components";
import { Card } from "../../../components/Card";
import { theme } from "../../../config/theme";

export const StudentWizardContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
`;

interface IBullProps {
    done: boolean;
    active: boolean;
    error: boolean;
}

export const StudentWizardTimeLine = styled.div<IBullProps>`
    width: 2px;
    position: absolute;
    left: 15px;

    height: 100%;
    top: 20px;

    background: ${({ done, active, error }) => {
        if (error) {
            return theme.colors.danger;
        }

        if (done) {
            return theme.colors.success;
        }

        return active ? theme.colors.main : theme.colors.placeholder;
    }};
`;

export const StudentWizardBull = styled.span<IBullProps>`
    width: 23px;
    height: 23px;
    position: absolute;
    top: 20px;
    left: 4px;

    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #fff;

    background: ${({ done, active, error }) => {
        if (error) {
            return theme.colors.danger;
        }

        if (done) {
            return theme.colors.success;
        }

        return active ? theme.colors.main : theme.colors.placeholder;
    }};

    ${({ active }) =>
        active &&
        css`
            width: 30px;
            height: 30px;
            color: #fff;
            left: 1px;
            font-size: 15px;
        `};
`;

export const StudentWizardContent = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;

    :last-child {
        ${StudentWizardTimeLine} {
            height: 0;
        }
    }
`;

export const StudentWizardContentHeader = styled.button.attrs({
    type: "button",
})`
    border: none;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${theme.colors.mainLight};
    padding: 18px 20px;
    background: transparent;
    outline: none;
`;

export const StudentWizardCard = styled(Card)<{ active: boolean }>`
    padding: 0;
    margin-left: 42px;
    margin-bottom: 30px;
`;

export const StudentWizardCardContent = styled.div`
    padding: 18px 20px;
`;
