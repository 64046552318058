import { IGridProps } from "components/Grid";
import CustomText from "components/Text";
import useApi, { IRequestPayload } from "hooks/useApi";
import { useCompanyId } from "hooks/useCompanyId";
import React, { useCallback, useEffect, useMemo } from "react";
import ListCards, { IListCardsConfig } from "../../components/ListCards";
import { InputType } from "../../utils/enums/InputType";
import DashboardMap from "./map";

const Dashboard = () => {
    const companyId = useCompanyId();
    const { request, response } = useApi<any>();

    const requestStats = useCallback(() => {
        console.log({ companyId });
        const payload: IRequestPayload = {
            endpoint: "/admin/dashboard/stats",
            method: "get",
        };

        request(payload);
    }, [companyId, request]);

    useEffect(() => {
        requestStats();
    }, [requestStats]);

    const listCardsConfig = useMemo<IListCardsConfig>(() => {
        const grid: IGridProps = { sm: 12, md: 3 };

        return {
            defaultValue: {
                ...response,
                schoolClasses: {
                    label: !!response?.schoolClasses ? (
                        <div
                            style={{
                                display: "flex",
                                width: "50%",
                                margin: "auto",
                                alignItems: "center",
                            }}
                        >
                            <CustomText
                                weight="Bold"
                                color="base"
                                size={28}
                                ellipsis
                            >
                                {response?.schoolClasses}
                            </CustomText>
                            <CustomText size={11} color="base" left="auto">
                                {Math.round(
                                    response?.schoolClasses / response?.projects
                                )}
                                /N
                            </CustomText>
                        </div>
                    ) : (
                        ""
                    ),
                },
                classes: {
                    label: !!response?.classes ? (
                        <div
                            style={{
                                display: "flex",
                                width: "70%",
                                margin: "auto",
                                alignItems: "center",
                            }}
                        >
                            <CustomText
                                weight="Bold"
                                color="base"
                                size={28}
                                ellipsis
                            >
                                {response?.classes?.average}%
                            </CustomText>
                            <CustomText size={11} color="base" left="auto">
                                {response?.classes?.closed}/
                                {response?.classes?.possible}
                            </CustomText>
                        </div>
                    ) : (
                        ""
                    ),
                },
                presencePercentage: {
                    label: !!response?.presencePercentage ? (
                        <div>
                            <CustomText
                                weight="Bold"
                                color="base"
                                size={28}
                                ellipsis
                            >
                                {response?.presencePercentage}%
                            </CustomText>
                        </div>
                    ) : (
                        ""
                    ),
                },
            },
            cards: [
                {
                    name: "projects",
                    type: InputType.Number,
                    grid,
                    label: "Núcleos",
                },
                {
                    name: "schoolClasses.label",
                    type: InputType.Text,
                    grid,
                    label: "Turmas",
                },
                {
                    name: "students",
                    type: InputType.Number,
                    grid,

                    label: "Alunos",
                },
                {
                    name: "classes.label",
                    type: InputType.Text,
                    grid,
                    label: "Aulas",
                },
                {
                    name: "modalities",
                    type: InputType.Text,
                    grid,
                    label: "Modalidades",
                },
                {
                    name: "professionals.teachers",
                    type: InputType.Text,
                    grid,
                    label: "Professores",
                },
                {
                    name: "professionals.assistants",
                    type: InputType.Text,
                    grid,
                    label: "Assistentes",
                },
                {
                    name: "presencePercentage.label",
                    type: InputType.Text,
                    grid,
                    label: "Presença",
                },
            ],
        };
    }, [response]);

    return (
        <div>
            <ListCards {...listCardsConfig} />
            <DashboardMap />
        </div>
    );
};

export default Dashboard;
