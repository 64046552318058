import React, { useCallback, useEffect, useState } from "react";
import CSSTransition from "react-transition-group/CSSTransition";
import Button from "../../components/Button";
import { Card } from "../../components/Card";
import Form from "../../components/Form";
import Input from "../../components/Form/Input";
import LogoFullIcon from "../../components/Icons/logoFull";
import Spinner from "../../components/Spinner";
import history from "../../services/history";
import { InputType } from "../../utils/enums/InputType";
import { Creators } from "../../store/auth";
import * as S from "./styles";
import { useAppDispatch, useAppSelector } from "../../hooks/store";
import { ILoginRequestPayload } from "../../store/auth/types";
import { theme } from "../../config/theme";

const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const dispatch = useAppDispatch();
    const { loading, token, currentCompany } = useAppSelector(
        ({ auth }) => auth
    );

    useEffect(() => {
        if (!!token && !!currentCompany?.id) {
            history.push("/app");
        }
    }, [token, currentCompany]);

    const handleSubmit = useCallback(
        (data: any) => {
            data.preventDefault();

            dispatch(
                Creators.loginRequest({
                    username,
                    password,
                } as ILoginRequestPayload)
            );
        },
        [dispatch, password, username]
    );

    return (
        <S.LoginContainer>
            {loading ? (
                <Spinner color={theme.colors.white} />
            ) : (
                <CSSTransition
                    in={!token}
                    timeout={400}
                    classNames="cardexit"
                    unmountOnExit
                >
                    <S.LoginContent>
                        <LogoFullIcon
                            width={140}
                            height={22}
                            className="logo-full"
                        />

                        <Card column>
                            <Form onSubmit={handleSubmit}>
                                <Input
                                    name="username"
                                    type={InputType.Text}
                                    required
                                    bottom="15px"
                                    placeholder="E-mail..."
                                    value={username}
                                    onChange={(e) =>
                                        setUsername(e?.target?.value)
                                    }
                                />
                                <Input
                                    name="password"
                                    type="password"
                                    required
                                    placeholder="Senha..."
                                    bottom="15px"
                                    value={password}
                                    onChange={(e) =>
                                        setPassword(e?.target?.value)
                                    }
                                />

                                <Button variant="main" type="submit">
                                    Entrar
                                </Button>
                            </Form>
                        </Card>
                    </S.LoginContent>
                </CSSTransition>
            )}
        </S.LoginContainer>
    );
};

export default Login;
