import { Card } from "components/Card";
import CustomText from "components/Text";
import { formatDate } from "helpers/formatDate";
import React from "react";
import { ISelectOption } from "utils/interfaces/ISelectOption";
import { IProductOrderResponse } from "..";

interface IProps {
    details?: IProductOrderResponse;
}

const formatNameWithEmail = (person?: {
    name: string;
    user?: {
        email?: string;
    };
}) => {
    return [person?.name, person?.user?.email].filter(Boolean).join(" - ");
};

const ProductOrderTabDetails = ({ details }: IProps) => {
    const {
        number,
        professional,
        project,
        created_at,
        sent_date,
        receipt_date,
        confirmation_date,
        person_received,
        person_confirmed,
    } = details || {};

    const items: ISelectOption[] = [
        {
            label: "Número",
            value: number,
        },
        {
            label: "Professor",
            value: formatNameWithEmail(professional?.person),
        },
        {
            label: "Núcleo",
            value: project?.name,
        },
        {
            label: "Data de solicitação",
            value: formatDate(created_at),
        },
        {
            label: "Data de envio",
            value: formatDate(sent_date),
        },
        {
            label: "Data de recebimento",
            value: formatDate(receipt_date),
        },
        {
            label: "Data de confirmação",
            value: formatDate(confirmation_date),
        },
        {
            label: "Recebido por",
            value: formatNameWithEmail(person_received),
        },
        {
            label: "Confirmador por",
            value: formatNameWithEmail(person_confirmed),
        },
    ];

    return (
        <Card column>
            {items.map(({ label, value }, index) => (
                <CustomText
                    key={`order_item_${index}`}
                    color="base"
                    size={18}
                    bottom="12px"
                >
                    <strong>{label}</strong>: {value || "-"}
                </CustomText>
            ))}
        </Card>
    );
};

export default ProductOrderTabDetails;
