import FormFactory, { IOnChangeWatchers } from "components/Form/FormFactory";
import Spinner from "components/Spinner";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { useFormZipCode } from "hooks/useFormZipCode";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { professionalFields } from "routes/entities/professionals/fields";
import { professionalSchema } from "routes/entities/professionals/schema";
import { Creators } from "store/professional";
import { ProfessionalPosition } from "utils/enums/ProfessionalPosition";

interface IProps {
    id?: number;
}

const ProfessionalForm = ({ id }: IProps) => {
    const [position, setPosition] = useState<ProfessionalPosition>();

    const state = useAppSelector(({ professional }) => professional);
    const { loading, ...formState } = state || {};
    const dispatch = useAppDispatch();

    const { handleChangeZipCode, isZipCodeLoading } = useFormZipCode();

    const getProfessional = useCallback(() => {
        if (!id) {
            return;
        }

        dispatch(Creators.getProfessionalByIdRequest({ id }));
    }, [dispatch, id]);

    useEffect(() => {
        getProfessional();
    }, [getProfessional]);

    useEffect(() => {
        return () => {
            dispatch(Creators.clearProfessional());
        };
    }, [dispatch]);

    const handleSubmit = useCallback(
        (body: any) => {
            dispatch(Creators.createOrEditProfessionalRequest({ id, body }));
        },
        [dispatch, id]
    );

    const initialData: any = {
        ...(!!state?.id && formState),
    };

    const initialPositionWasSetted = useRef(false);

    useEffect(() => {
        if (initialPositionWasSetted.current) {
            return;
        }

        initialPositionWasSetted.current = true;

        setPosition(initialData?.position?.value);
    }, [initialData]);

    const onChangeWatchers = useCallback(
        (data: IOnChangeWatchers[]) => {
            handleChangeZipCode(data);

            const positionValue = data?.find(
                ({ name }) => name === "position"
            )?.value;

            setPosition(positionValue?.value ?? positionValue);
        },
        [handleChangeZipCode]
    );

    const showLoading = !!id && loading && !state?.id;

    if (showLoading) {
        return <Spinner />;
    }

    return (
        <FormFactory
            fields={professionalFields(position)}
            onSubmit={handleSubmit}
            schema={professionalSchema(position)}
            isLoading={!!loading || isZipCodeLoading}
            initialData={initialData}
            watchers={["person.address.zip", "position"]}
            onChangeWatchers={onChangeWatchers}
        />
    );
};

export default ProfessionalForm;
