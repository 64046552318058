export const editResponsibleQuery = `mutation ($body: responsible_set_input, $id: Int!, $person: person_insert_input!) {
    item: update_responsible_by_pk(pk_columns: {id: $id}, _set: $body) {
      id
      project_id
    }
    person: insert_person_one(object: $person, on_conflict: {constraint: person_pkey, update_columns: [name, phone, document, birthday, gender, updated_at]}) {
      id
      document
      name
      birthday
      gender
    }
  }
  `;
