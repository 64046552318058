import ListCards, { IListCardsConfig } from "components/ListCards";
import ListTabData, { IListTabDataConfig } from "components/ListTabData";
import useGraphQL from "hooks/useGraphQL";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { InputType } from "utils/enums/InputType";
import { ProductOrderStatus } from "utils/enums/ProductOrderStatus";
import { TableColumnType } from "utils/enums/TableColumnType";
import { IBaseRouteProps } from "utils/interfaces/IBaseRouteProps";
import { ProductOrderHeader } from "./styles";
import DispatchButton from "./buttons/dispatch";
import { useHeaderTitle } from "hooks/useHeaderTitle";
import CustomText from "components/Text";
import Badge from "components/Badge";
import { dictionary } from "config/dictionary";
import ProductOrderTabDetails from "./tabs/details";

export interface IProductOrderResponse {
    id: number;
    product_order_status_id: ProductOrderStatus;
    number: string;
    professional?: {
        person: {
            name: string;
            user?: {
                email?: string;
            };
        };
    };
    project?: {
        name: string;
    };
    created_at: Date;
    sent_date?: any;
    receipt_date?: Date;
    confirmation_date?: Date;
    person_confirmed?: {
        name: string;
        user?: {
            email?: string;
        };
    };
    person_received?: {
        name: string;
        user?: {
            email?: string;
        };
    };
}

const ProductOrder = ({ match }: IBaseRouteProps<{ id: number }>) => {
    const { id } = match?.params || {};

    const { request, response } = useGraphQL<IProductOrderResponse>();
    const [status, setStatus] = useState<ProductOrderStatus>();

    useHeaderTitle(
        !!response?.number ? `Pedido ${response?.number}` : undefined
    );

    useEffect(() => {
        if (response?.product_order_status_id !== undefined) {
            setStatus(response?.product_order_status_id);
        }
    }, [response]);

    const requestOrder = useCallback(() => {
        request(
            `query ($id: Int!) {
                item: product_order_by_pk(id: $id) {
                  id
                  product_order_status_id
                  number
                  professional {
                    person {
                      name
                      user {
                        email
                      }
                    }
                  }
                  project {
                    name
                  }
                  created_at
                  sent_date
                  receipt_date
                  confirmation_date
                  person_confirmed: person {
                    name
                    user {
                      email
                    }
                  }
                  person_received: personByPersonReceivedId {
                    name
                    user {
                      email
                    }
                  }
                }
              }
              
          `,
            {
                id,
            },
            "item"
        );
    }, [id, request]);

    useEffect(() => {
        requestOrder();
    }, [requestOrder]);

    const listCardsConfig = useMemo<IListCardsConfig>(
        () => ({
            cards: [
                {
                    name: "professional.person.name",
                    type: InputType.Text,
                    grid: {
                        col: "auto",
                    },
                    label: "Profissional",
                },
                {
                    name: "project.name",
                    type: InputType.Text,
                    grid: {
                        col: "auto",
                    },
                    label: "Núcelo",
                },
            ],
            graphQL: {
                tableName: "product_order",
                returnPath: "item",
                primaryKey: id,
            },
        }),
        [id]
    );

    const listDataConfig = useMemo<IListTabDataConfig[]>(() => {
        const defaultSearch = {
            product_order_id: {
                _eq: id,
            },
        };

        return [
            {
                title: "Detalhes",
                component: <ProductOrderTabDetails details={response} />,
                columns: [],
            },
            {
                title: "Itens",
                canCreate: false,
                canDelete: false,
                canEdit: false,
                canSee: false,
                contentId: "product_order_item",
                columns: [
                    {
                        col: "auto",
                        name: "product.title",
                        type: TableColumnType.Text,
                    },
                    {
                        col: 3,
                        name: "quantity",
                        type: TableColumnType.Number,
                    },
                ],
                graphql: {
                    tableName: "product_order_item",
                },
                mainColumn: "id",
                defaultSearch,
            },
            {
                title: "Movimentações",
                canCreate: false,
                canDelete: false,
                canEdit: false,
                canSee: false,
                contentId: "product_order_movement",
                columns: [
                    {
                        col: "auto",
                        name: "person",
                        type: TableColumnType.Person,
                    },
                    {
                        col: "auto",
                        name: "observation",
                        type: TableColumnType.Text,
                    },
                    {
                        col: 3,
                        name: "product_order_status_id",
                        type: TableColumnType.Enum,
                        enumValue: ProductOrderStatus,
                    },
                ],
                graphql: {
                    tableName: "product_order_movement",
                },
                mainColumn: "id",
                defaultSearch,
            },
        ];
    }, [id, response]);

    const statusVariant = {
        [ProductOrderStatus.Requested]: "info",
        [ProductOrderStatus.Sent]: "main",
        [ProductOrderStatus.Denied]: "warning",
        [ProductOrderStatus.Concluded]: "success",
        [ProductOrderStatus.Received]: "primary",
        [ProductOrderStatus.Canceled]: "danger",
    };

    return (
        <div>
            <ProductOrderHeader>
                <div className="title">
                    <CustomText color="black" size={22} right="10px">
                        Pedido {response?.number}
                    </CustomText>
                    <Badge
                        variant={
                            (statusVariant[response?.product_order_status_id] ||
                                statusVariant["1"]) as any
                        }
                    >
                        {
                            (dictionary as any)[
                                ProductOrderStatus[
                                    response?.product_order_status_id
                                ]
                            ]
                        }
                    </Badge>
                </div>

                <div>
                    {status === ProductOrderStatus.Requested && (
                        <DispatchButton
                            id={id}
                            onSubmit={() => setStatus(ProductOrderStatus.Sent)}
                        />
                    )}
                    {/* {status === ProductOrderStatus.Received && (
                        <FinalizeButton
                            id={id}
                            onSubmit={() =>
                                setStatus(ProductOrderStatus.Concluded)
                            }
                        />
                    )} */}
                </div>
            </ProductOrderHeader>
            <ListCards {...listCardsConfig} />
            {!!response?.id && <ListTabData configs={listDataConfig} />}
        </div>
    );
};

export default ProductOrder;
