import { theme } from "config/theme";
import styled from "styled-components";
import { MediaQueries } from "utils/mediaQueries";

export const NavbarCompanyContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid #eff4fa;

    p {
        font-size: 13px;
        color: ${theme.colors.base};
        margin: 15px 0 10px 15px;

        ${MediaQueries.BIGGER_THAN_MEDIUM} {
            margin: 0 0 5px 0;
        }
    }

    button {
        display: flex;
        align-items: center;
        background: transparent;
        border: none;
        text-align: left;
        background: #eff4fa;
        padding: 15px;

        ${MediaQueries.BIGGER_THAN_MEDIUM} {
            margin: 0 -15px;
            padding: 10px 15px;
        }

        svg {
            fill: ${theme.colors.main};
        }

        strong {
            font-size: 1.05rem;
            color: ${theme.colors.main};
            flex: 1;
        }
    }
`;
