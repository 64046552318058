import React, { useMemo } from "react";
import FormFactory from "../../../../components/Form/FormFactory";
import { createPersonFields } from "../../../../utils/fields/person";

import _pick from "lodash/pick";
import _uniq from "lodash/uniq";
import formatInitialDataFormValues from "../../../../helpers/formatFormValues";
import { personSchema } from "../../../../utils/schemas/person";
import { number, object } from "yup";
import { InputType } from "../../../../utils/enums/InputType";
import { searchProjectsQuery } from "../../../../queries/projects/selectSearch";
import { IInputField } from "../../../../utils/interfaces/IInputField";
import { dictionary } from "../../../../config/dictionary";
import { ISelectOption } from "../../../../utils/interfaces/ISelectOption";

interface IProps {
    onSubmit(data: any): void;
    initialData?: any;
    isEdit: boolean;
    projectId?: ISelectOption;
}

const schema = object().shape({
    person: personSchema(),
    id_project: number()
        .typeError(dictionary.validations.required)
        .required(dictionary.validations.required),
});

const StudentBasicInfos: React.FC<IProps> = ({
    onSubmit,
    initialData,
    isEdit,
    projectId,
}) => {
    console.log("projectId", projectId);

    const fields: IInputField[] = useMemo<IInputField[]>(
        () => [
            ...createPersonFields(),

            {
                name: "id_project",
                type: InputType.AsyncSelect,
                label: "Núcleo",
                required: true,
                grid: {
                    col: 4,
                },
                graphQL: {
                    searchQuery: searchProjectsQuery,
                },
                disabled: !!projectId?.value,
                isClearable: !projectId?.value,
            },
        ],
        [projectId]
    );

    const formatedInitialData = useMemo(() => {
        if (!initialData) {
            return undefined;
        }

        const tempInitialData: any = _pick(
            initialData,
            _uniq([
                ...fields.map(({ name }) => name),
                ...fields.map(({ name }) => name.replace("id_", "")),
            ])
        );

        return formatInitialDataFormValues(tempInitialData, fields);
    }, [fields, initialData]);

    return (
        <FormFactory
            initialData={formatedInitialData}
            submitButtonLabel={isEdit ? "Salvar" : "Próximo"}
            onSubmit={onSubmit}
            fields={fields}
            schema={schema}
        />
    );
};

export default StudentBasicInfos;
