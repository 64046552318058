import React from "react";
import { useAppSelector } from "hooks/store";
import { Card } from "components/Card";
import CustomText from "components/Text";
import { formatDate } from "helpers/formatDate";

const StudentTabDetails = () => {
    const { person, loading, project, responsible } = useAppSelector(
        ({ student }) => student!
    );

    if (loading) {
        return null;
    }

    return (
        <Card column>
            <CustomText color="base" size={18} bottom="10px">
                <strong>Núcleo</strong>: {project?.name}
            </CustomText>
            <CustomText color="base" size={18} bottom="10px">
                <strong>Data de nascimento</strong>:{" "}
                {formatDate(person?.birthday)}
            </CustomText>

            <CustomText color="base" size={18} bottom="20px">
                <strong>CPF</strong>: {person?.document || "-"}
            </CustomText>

            {!!responsible?.responsible && (
                <>
                    <CustomText color="base" size={20} bottom="10px">
                        <strong>Responsável</strong>
                    </CustomText>

                    <CustomText color="base" size={18} bottom="10px">
                        <strong>Nome</strong>:{" "}
                        {responsible?.responsible?.person?.name}
                    </CustomText>
                    <CustomText color="base" size={18} bottom="10px">
                        <strong>CPF</strong>:{" "}
                        {responsible?.responsible?.person?.document || "-"}
                    </CustomText>

                    <CustomText color="base" size={18} bottom="10px">
                        <strong>Telefone</strong>:{" "}
                        {responsible?.responsible?.person?.phone || "-"}
                    </CustomText>
                </>
            )}
        </Card>
    );
};

export default StudentTabDetails;
