import styled from "styled-components";

export const AvailationContainer = styled.div`
    display: flex;
    align-items: center;

    > svg {
        :not(:last-child) {
            margin-right: 5px;
        }
    }
`;
