import { number, object, string } from "yup";
import { dictionary } from "../../config/dictionary";
import { editProjectQuery } from "../../queries/projects/edit";
import { getProjectByIdQuery } from "../../queries/projects/getById";

import CrudList from "../../pages/CRUD/List";
import { InputType } from "../../utils/enums/InputType";
import { TableColumnType } from "../../utils/enums/TableColumnType";
import { IRoute } from "../../utils/interfaces/IRoute";
import Projects from "../../pages/Projects";

import { IInputField } from "utils/interfaces/IInputField";
import CreateProject from "pages/Projects/create";
import { multipleSelectSchema, selectSchema } from "utils/schemas/select";

export const projectSchema = object().shape({
    name: string()
        .typeError(dictionary.validations.required)
        .required(dictionary.validations.required),
    region: selectSchema().required(dictionary.validations.required),
    professional: selectSchema().required(dictionary.validations.required),
    professionals: multipleSelectSchema()
        .max(3, "Você só pode adicionar até 3 assistentes.")
        .required(dictionary.validations.required),
    modalities: multipleSelectSchema()
        .max(2, "Você só pode adicionar até 2 modalidades.")
        .required(dictionary.validations.required),
    address: object().shape({
        name: string()
            .typeError(dictionary.validations.requiredAddress)
            .required(dictionary.validations.requiredAddress),
        city: string()
            .typeError(dictionary.validations.required)
            .required(dictionary.validations.required),
        state: string()
            .typeError(dictionary.validations.required)
            .required(dictionary.validations.required),
        neighbourhood: string()
            .typeError(dictionary.validations.required)
            .required(dictionary.validations.required),
        street: string()
            .typeError(dictionary.validations.required)
            .required(dictionary.validations.required),
        zip: string()
            .typeError(dictionary.validations.required)
            .required(dictionary.validations.required),
        number: string()
            .typeError(dictionary.validations.required)
            .default(undefined),
        complement: string()
            .typeError(dictionary.validations.required)
            .notRequired()
            .nullable(),
        latitude: number()
            .typeError(dictionary.validations.requiredAddress)
            .required(dictionary.validations.requiredAddress),
        longitude: number()
            .typeError(dictionary.validations.requiredAddress)
            .required(dictionary.validations.requiredAddress),
    }),
});

export const projectFields: IInputField[] = [
    {
        name: "name",
        type: InputType.Text,
        required: true,
        grid: {
            col: 4,
        },
    },

    {
        name: "address.name",
        type: InputType.Text,
        required: true,
        grid: {
            col: 4,
        },
    },
    {
        name: "address.city",
        type: InputType.Text,
        required: true,
        grid: {
            col: 4,
        },
    },
    {
        name: "address.state",
        type: InputType.Text,
        required: true,
        grid: {
            col: 4,
        },
    },
    {
        name: "address.neighbourhood",
        type: InputType.Text,
        required: true,
        grid: {
            col: 4,
        },
    },
    {
        name: "address.street",
        type: InputType.Text,
        required: true,
        grid: {
            col: 4,
        },
    },
    {
        name: "address.zip",
        type: InputType.Text,
        required: true,
        grid: {
            col: 4,
        },
    },
    {
        name: "address.number",
        type: InputType.Text,
        required: false,
        grid: {
            col: 4,
        },
    },
    {
        name: "address.complement",
        type: InputType.Text,
        required: false,
        grid: {
            col: 4,
        },
    },
    {
        name: "address.place_id",
        type: InputType.Text,
        required: true,
        grid: {
            col: 4,
        },
    },
    {
        name: "address.latitude",
        type: InputType.Number,
        required: true,
        grid: {
            col: 4,
        },
    },
    {
        name: "address.longitude",
        type: InputType.Number,
        required: true,
        grid: {
            col: 4,
        },
    },
    {
        name: "region",
        type: InputType.AsyncSelect,
        required: true,
        grid: {
            col: 4,
        },
    },
    {
        name: "professional",
        type: InputType.AsyncSelect,
        required: true,
        grid: {
            col: 4,
        },
    },
    {
        name: "professionals",
        type: InputType.AsyncSelect,
        required: true,
        isMulti: true,
        grid: {
            col: 4,
        },
    },
    {
        name: "modalities",
        type: InputType.AsyncSelect,
        required: true,
        isMulti: true,
        grid: {
            col: 4,
        },
    },
];

export const projectsRoutes: IRoute[] = [
    {
        title: "Núcleos",
        path: "/nucleos",
        graphql: {
            getAll: `query ($orderBy: [project_order_by!], $where: project_bool_exp, $limit: Int, $offset: Int) {
                items: project(where: $where, order_by: $orderBy, limit: $limit, offset: $offset) {
                  id
                  name
                  region {
                    id
                    name
                  }
                  professional {
                    person {
                      name
                    }
                  }
                  classes_aggregate(where: {status: {_eq: 1}}) {
                    aggregate {
                      count
                    }
                  }
                  students_aggregate(where: {status: {_eq: 1}}) {
                    aggregate {
                      count
                    }
                  }
                }
                quantity: project_aggregate(where: $where) {
                  aggregate {
                    count
                  }
                }
              }
              `,
            tableName: "project",
            orderBy: {
                name: "asc",
            },
            companyPath: "company_id",
        },
        mainColumn: "id",
        component: CrudList,
        exact: true,
        canEdit: true,
        canDelete: true,
        canCreate: true,
        canSee: true,
        columns: [
            {
                col: 3,
                name: "name",
                type: TableColumnType.Text,
            },
            {
                col: 3,
                name: "region",
                type: TableColumnType.AsyncSelect,
            },
            {
                col: 3,
                name: "professional.person.name",
                type: TableColumnType.Text,
            },
            {
                col: "auto",
                name: "classes_aggregate.aggregate.count",
                type: TableColumnType.Number,
                removeSearch: true,
            },
            {
                col: "auto",
                name: "students_aggregate.aggregate.count",
                type: TableColumnType.Number,
                removeSearch: true,
            },
        ],
        showTotal: true,
    },
    {
        title: "Novo Núcleo",
        path: "/nucleos/novo",
        component: CreateProject,
        exact: true,
    },
    {
        title: "Editar Núcleo",
        path: "/nucleos/editar/:id",
        component: CreateProject,
        exact: true,
    },
    {
        title: "Visualizar Núcleo",
        path: "/nucleos/ver/:id",
        graphql: {
            getById: getProjectByIdQuery,
            edit: editProjectQuery,
        },
        mainColumn: "id_project",
        component: Projects,
        exact: true,
        schema: projectSchema,
        fields: projectFields,
        showEditButton: true,
    },
];
