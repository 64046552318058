import { put, takeLatest, call } from "redux-saga/effects";
import graphql from "../../services/graphql";
import { ISagaAction } from "../../utils/interfaces/ISagaAction";

import { Creators, Types } from ".";

import { toast } from "react-toastify";
import history from "../../services/history";
import { getProjectDetailsByIdQuery } from "../../queries/projects/getDetailsById";
import {
    IProject,
    ICreateProjectPayload,
    IGetProjectById,
} from "../project/types";
import _get from "lodash/get";
import api from "services/api";
import { IYield } from "utils/interfaces/IYield";
import _pick from "lodash/pick";

function* createOrEdit(action: ISagaAction<ICreateProjectPayload>) {
    try {
        const { id, body, onCreate } = action.payload || {};

        const endpoint = !!id ? `/admin/projects/${id}` : "/admin/projects";

        const method = !!id ? api.patch : api.post;

        const payload = {
            ..._pick(body, [
                "name",
                "address.complement",
                "address.city",
                "address.state",
                "address.latitude",
                "address.longitude",
                "address.neighbourhood",
                "address.number",
                "address.street",
                "address.zip",
                "region",
            ]),
            company: 1,
            region: body?.region?.value,
            professional: body?.professional?.value,
            professionals: body?.professionals?.map((item: any) => item.value),
            modalities: body?.modalities?.map((item: any) => item.value),
        };

        const { data } = yield call(method, endpoint, payload);

        if (!data?.id) {
            throw new Error();
        }

        if (onCreate) {
            onCreate(data);
        } else {
            yield call(history.push, {
                pathname: !!id
                    ? "/app/nucleos"
                    : `/app/nucleos/ver/${data?.id}`,
            });
        }

        yield put(Creators.createOrEditProjectSuccess(data));
        yield call(toast.success, "Registro salvo!");
    } catch (error) {
        console.log("error", error);

        yield call(toast.error, "Desculpe, tivemos um problema.");
        yield put(Creators.createOrEditProjectFailure());
    }
}

function* getById(action: ISagaAction<IGetProjectById>): IYield {
    try {
        console.log("getById");
        const { id, isDetails = false } = action.payload || {};

        if (!id) {
            throw new Error();
        }

        let request: any;

        if (isDetails) {
            request = yield call(
                graphql,
                getProjectDetailsByIdQuery,
                {
                    id,
                },
                "projectdetails"
            ) as any;
        } else {
            request = yield call(api.get, `/admin/projects/${id}`) as any;
        }

        const { data } = request;

        if (!data?.id) {
            throw new Error();
        }

        const formatBody: IProject = isDetails
            ? {
                  id_project: data.item.id_project,
                  name: data.item.name,
                  totalProfessionals: _get(
                      data,
                      "professional.aggregate.count",
                      0
                  ),
                  totalProjectClasses: _get(
                      data.item,
                      "project_classes.aggregate.count",
                      0
                  ),
                  totalResponsibles: _get(
                      data.item,
                      "responsibles.aggregate.count",
                      0
                  ),
                  totalStudents: _get(data.item, "students.aggregate.count", 0),
              }
            : data;

        console.log("formatBody", formatBody);

        yield put(Creators.getProjectByIdSuccess(formatBody));
    } catch (error) {
        console.log("error", error);

        yield call(toast.error, "Desculpe, tivemos um problema.");

        yield put(Creators.getProjectByIdFailure());

        yield call(history.push, { pathname: "/app/nucleos" });
    }
}

export default [
    takeLatest(Types.CREATE_OR_EDIT_PROJECT_REQUEST, createOrEdit),
    takeLatest(Types.GET_PROJECT_BY_ID_REQUEST, getById),
];
