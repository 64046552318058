import React, { useCallback, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import PlusIcon from "../../components/Icons/plus";
import SearchIcon from "../../components/Icons/search";
import { IListDataProps } from "../../components/ListData";
import ListTabData, { IListTabDataConfig } from "../../components/ListTabData";
import { theme } from "../../config/theme";
import { useModal } from "../../hooks/useModal";
import { studentColumns } from "../../routes/entities/students";
import { ModalElements } from "../../utils/enums/ModalElements";
import { TableColumnType } from "../../utils/enums/TableColumnType";
import { IBaseRouteProps } from "../../utils/interfaces/IBaseRouteProps";
import { IReduxStore } from "../../store/ducks";
import { Creators as ProjectClassActions } from "../../store/projectClass";
import { ProjectClassDetailsContainer } from "./styles";
import {
    IProjectClassActions,
    IProjectClassState,
} from "../../store/projectClass/types";
import ListCards, { IListCardsConfig } from "components/ListCards";
import ProjectClassDetailsTab from "./tabs/details";
import { useHeaderTitle } from "hooks/useHeaderTitle";
import history from "services/history";
import { listCardsConfigProjectClass } from "./utils";

export enum ProjectClassEntities {
    ClassDay = "classDay",
    ClassDayWithProblems = "classDayWithProblems",
    Teachers = "teachers",
    Assistants = "assistants",
    Administrators = "administrators",
    Student = "students",
}

interface IProps extends IBaseRouteProps {
    projectClass?: IProjectClassState;
    projectClassActions: IProjectClassActions;
}

const ProjectClassDetails = ({
    match: { params },
    projectClass,
    projectClassActions,
}: IProps) => {
    const { id } = params;

    const { id: id_project_class, name } = projectClass || {};

    useHeaderTitle(name);

    const { open: openModal, close: closeModal } = useModal();

    useEffect(() => {
        return () => {
            projectClassActions.clearProjectClass();
        };
    }, [projectClassActions]);

    const request = useCallback(async () => {
        if (!id) {
            return;
        }

        projectClassActions.getProjectClassByIdRequest({
            id,
        });
    }, [id, projectClassActions]);

    useEffect(() => {
        request();
    }, [request]);

    const handleSelectStudent = useCallback(
        (student: object) => {
            if (!student) {
                return;
            }

            projectClassActions.createProjectClassStudentRequest(student);
        },
        [projectClassActions]
    );

    const handleClickSearchStudent = useCallback(() => {
        const action: IListDataProps = {
            contentId: `student_search`,
            columns: studentColumns,
            graphql: {
                tableName: "student",
            },
            mainColumn: "id",
            customActions: [
                {
                    icon: () => (
                        <PlusIcon
                            width={12}
                            height={12}
                            fill={theme.colors.success}
                        />
                    ),
                    onClick: handleSelectStudent,
                },
            ],
            defaultSearch: {
                _not: { subscriptions: { id_project_class: { _eq: +id } } },
            },
        };

        openModal({
            element: ModalElements.List,
            action,
            width: undefined,
            title: `Buscar aluno`,
        });
    }, [handleSelectStudent, id, openModal]);

    const handleClickCreateStudent = useCallback(() => {
        closeModal();
        projectClassActions.openProjectClassStudentModal();
    }, [closeModal, projectClassActions]);

    const handleClickAddStudent = useCallback(() => {
        openModal({
            title: "Adicionar aluno",
            element: ModalElements.Actions,
            action: [
                {
                    text: "Buscar",
                    icon: <SearchIcon width={12} height={12} fill="#FFF" />,
                    onClick: handleClickSearchStudent,
                },
                {
                    text: "Criar",
                    icon: <PlusIcon width={12} height={12} fill="#FFF" />,
                    onClick: handleClickCreateStudent,
                    variant: "success",
                },
            ],
            width: "450px",
        });
    }, [handleClickCreateStudent, handleClickSearchStudent, openModal]);

    const handleClickCreateProjectClassDay = useCallback(() => {
        openModal({
            element: ModalElements.CreateProjectClassDay,
            title: `Criar Aula`,
            width: undefined,
            action: {
                id_project_class,
                onSubmit: console.log,
            },
        });
    }, [id_project_class, openModal]);

    const handleClickClassDayDetails = useCallback(
        (data: { id: number }) => {
            const { id } = data;

            history.push({ pathname: `${id_project_class}/aula/${id}` });
        },
        [id_project_class]
    );

    const listConfig = useMemo<Array<IListTabDataConfig>>(() => {
        if (!id_project_class) {
            return [];
        }

        const projectClassDayColumns: any = [
            {
                col: 4,
                name: "day",
                type: TableColumnType.Date,
            },
            {
                col: 3,
                name: "start_time",
                type: TableColumnType.Time,
            },
            {
                col: 3,
                name: "end_time",
                type: TableColumnType.Time,
            },
            {
                col: 4,
                name: "project_class_day_status.name",
                type: TableColumnType.Text,
            },
        ];

        return [
            {
                title: "Detalhes",
                component: <ProjectClassDetailsTab />,
                columns: [],
            },
            {
                contentId: ProjectClassEntities.Student,
                createButtonLabel: "Adicionar",
                title: "Alunos",
                canCreate: false,
                canDelete: false,
                canSee: true,
                path: "/alunos",
                columns: [
                    {
                        col: 8,
                        name: "student.person.name",
                        type: TableColumnType.Text,
                        label: "Nome",
                    },
                    {
                        col: "auto",
                        name: "created_at",
                        type: TableColumnType.Date,
                        label: "Adicionado em",
                    },
                ],
                graphql: {
                    tableName: "subscription",
                    orderBy: {
                        student: {
                            person: {
                                name: "asc",
                            },
                        },
                    },
                },
                mainColumn: "id",
                defaultSearch: {
                    project_class_id: {
                        _eq: id_project_class,
                    },
                },
                onClickCreate: handleClickAddStudent,
            },
            {
                contentId: ProjectClassEntities.ClassDay,
                createButtonLabel: "Adicionar",
                title: "Aulas",
                canCreate: false,
                canDelete: false,
                canSee: true,
                columns: projectClassDayColumns,
                graphql: {
                    tableName: "project_class_day",
                    orderBy: {
                        day: "asc",
                    },
                },
                mainColumn: "id",
                defaultSearch: {
                    project_class_id: {
                        _eq: id_project_class,
                    },
                },
                onClickCreate: handleClickCreateProjectClassDay,
                onClickSeeDetails: ({ id }) => {
                    handleClickClassDayDetails({ id });
                },
            },
        ];
    }, [
        handleClickAddStudent,
        handleClickClassDayDetails,
        handleClickCreateProjectClassDay,
        id_project_class,
    ]);

    const listCardsConfig = useMemo<IListCardsConfig>(
        () => listCardsConfigProjectClass(id),
        [id]
    );

    return (
        <>
            <ProjectClassDetailsContainer>
                <ListCards {...listCardsConfig} />

                {!!projectClass?.id && <ListTabData configs={listConfig} />}
            </ProjectClassDetailsContainer>
        </>
    );
};

const mapStateToProps = ({ projectClass }: IReduxStore) => ({
    projectClass,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    projectClassActions: bindActionCreators(ProjectClassActions, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectClassDetails);
