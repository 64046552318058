import styled from "styled-components";
import { generateSpacingStyles } from "../../helpers/generateSpacingStyles";
import { ISpacing } from "../../utils/interfaces/ISpacing";
import { ButtonElement } from "../Button/styles";

export const EmptyContainer = styled.div<{ padding?: ISpacing }>`
    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    ${({ padding }) => generateSpacingStyles(padding, "padding")};

    div {
        display: flex;
    }

    ${ButtonElement} {
        margin-right: 15px;
    }
`;
