import React from "react";
import LogoLettersIcon from "../Icons/logoLetters";

interface IProps {
    width: number;
    height: number;
    onClick?(): void;
}

const Logo: React.FC<IProps> = (props) => {
    return <LogoLettersIcon {...props} />;
};

export default Logo;
