// sampleReducer.js
import { createReducer, createActions } from "reduxsauce";
import { Profile } from "utils/enums/Profile";
import { UserTypes, IUserState, IUserActionsCreators } from "./types";

export const { Creators, Types } = createActions<
    Record<UserTypes, UserTypes>,
    IUserActionsCreators
>({
    createOrEditUserRequest: ["payload"],
    createOrEditUserSuccess: ["payload"],
    createOrEditUserFailure: [],

    getUserByIdRequest: ["payload"],
    getUserByIdSuccess: ["payload"],
    getUserByIdFailure: [],

    clearUser: [],
});

const INITIAL_STATE: IUserState = {
    error: false,
    loading: false,
    id: 0,
    email: "",
    profile_id: Profile.Teacher,
    username: "",
};

const request = (state = INITIAL_STATE, _: any) => {
    return { ...state, error: false, loading: true };
};

const failure = (state = INITIAL_STATE) => {
    return { ...state, error: true, loading: false };
};

const setState = (state = INITIAL_STATE, action: any) => ({
    ...state,
    loading: false,
    error: false,
    ...action.payload,
});

const clear = () => INITIAL_STATE;

const HANDLERS = {
    [Types.CREATE_OR_EDIT_USER_REQUEST]: request,
    [Types.CREATE_OR_EDIT_USER_SUCCESS]: setState,
    [Types.CREATE_OR_EDIT_USER_FAILURE]: failure,

    [Types.GET_USER_BY_ID_REQUEST]: request,
    [Types.GET_USER_BY_ID_SUCCESS]: setState,
    [Types.GET_USER_BY_ID_FAILURE]: failure,

    [Types.CLEAR_USER]: clear,
};

export default createReducer(INITIAL_STATE, HANDLERS);
