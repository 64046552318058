import React, { memo, useCallback, useEffect, useRef } from "react";
import { ISpacing } from "../../../utils/interfaces/ISpacing";
import { ISizing } from "../../../utils/interfaces/ISizing";
import { InputGoogleMapsContainer, InputGoogleMapsElement } from "./styles";
import { FormElementContainer, FormError, FormLabel } from "../styles";

import Script from "react-load-script";
import { IGoogleMapsAddress } from "../../../utils/interfaces/IGoogleMapsAddress";
import formatAddressComponents from "../../../helpers/formatAddressComponents";

export interface IInputProps extends ISpacing, ISizing {
    type?: "search" | "text" | "number" | "password" | "hidden" | "email";
    placeholder?: string;
    name: string;
    required?: boolean;
    showError?: boolean;
    icon?: React.ReactNode;
    label?: string;
    onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
    value?: any;
    onChangeAddress?(data: IGoogleMapsAddress): void;
    autoComplete?: string;
    error?: string;
}

const InputGoogleMaps = memo(
    ({
        type = "text",
        name,
        required = false,
        showError = true,
        icon,
        label,
        onChange,
        // value,
        onChangeAddress = () => null,
        autoComplete = "none",
        error,
        ...props
    }: IInputProps) => {
        const inputRef = useRef(null);
        // const {
        //     fieldName,
        //     defaultValue,
        //     registerField,
        //     error,
        //     clearError,
        // } = useField(name);

        // useEffect(() => {
        //     registerField({
        //         name: fieldName,
        //         ref: inputRef.current,
        //         path: "value",
        //     });
        // }, [fieldName, registerField]);

        const autocomplete = useRef<any>();

        const removeInputAutoComplete = useCallback(() => {
            const input = inputRef.current as HTMLInputElement | null;

            if (input) {
                input.autocomplete = "none";
            }
        }, []);

        const geocodeByAddress = async (address: any) => {
            const geocoder = new (window as any).google.maps.Geocoder();
            const OK = (window as any).google.maps.GeocoderStatus.OK;

            return new Promise((resolve, reject) => {
                geocoder.geocode({ address }, (results: any, status: any) => {
                    if (status !== OK) {
                        reject(status);
                    }
                    resolve(results);
                });
            });
        };

        const getGeocode = useCallback((result: any) => {
            return new Promise((resolve, reject) => {
                try {
                    const center = {
                        latitude: result.geometry.location.lat(),
                        longitude: result.geometry.location.lng(),
                        name: result.formatted_address,
                        place_id: result.place_id,
                    };
                    resolve(center);
                } catch (e) {
                    reject(e);
                }
            });
        }, []);

        const handlePlaceSelect = useCallback(async () => {
            const addressObject = autocomplete.current.getPlace();

            const address = addressObject?.address_components ?? [];

            if (!addressObject) {
                throw new Error();
            }

            const geocode: any = await geocodeByAddress(
                addressObject.formatted_address
            ).then((results: any) => getGeocode(results[0]));

            onChangeAddress({
                ...formatAddressComponents(address),
                ...geocode,
            });
        }, [getGeocode, onChangeAddress]);

        const handleScriptLoad = useCallback(() => {
            const options = {
                types: ["geocode"],
            };

            autocomplete.current = new (
                window as any
            ).google.maps.places.Autocomplete(
                document.getElementById("autocomplete"),
                options
            );

            // Avoid paying for data that you don't need by restricting the set of
            // place fields that are returned to just the address components and formatted
            // address.
            autocomplete.current.setFields([
                "address_components",
                "formatted_address",
            ]);

            // Fire Event when a suggested name is selected
            autocomplete.current.addListener(
                "place_changed",
                handlePlaceSelect
            );

            removeInputAutoComplete();
        }, [handlePlaceSelect, removeInputAutoComplete]);

        useEffect(() => {
            setTimeout(() => {
                removeInputAutoComplete();
            }, 500);
        }, [removeInputAutoComplete]);

        return (
            <>
                <Script
                    url={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&libraries=places&region=BR&language=pt-br`}
                    onLoad={handleScriptLoad}
                />
                <FormElementContainer>
                    {!!label && (
                        <FormLabel htmlFor={name}>
                            {label}
                            {required && ` *`}
                        </FormLabel>
                    )}
                    <InputGoogleMapsContainer>
                        <InputGoogleMapsElement
                            ref={inputRef}
                            // defaultValue={defaultValue}
                            type="search"
                            name={name}
                            error={!!error}
                            required={required}
                            onChange={onChange}
                            id="autocomplete"
                            autoComplete="none"
                            // {...(!!error && { onFocus: clearError })}
                            {...props}
                        />
                        {icon}
                    </InputGoogleMapsContainer>
                    {!!error && showError && <FormError>{error}</FormError>}
                </FormElementContainer>
            </>
        );
    }
);

export default InputGoogleMaps;
