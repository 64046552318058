import { BreakPoints } from "./enums/BreakPoints";

export const MediaQueries = {
    BIGGER_THAN_SMALLX: `@media only screen and (min-width: ${BreakPoints.smallX}px)`,
    BIGGER_THAN_SMALL: `@media only screen and (min-width: ${BreakPoints.small}px)`,
    BIGGER_THAN_TABLET: `@media only screen and (min-width: ${
        BreakPoints.tablet + 1
    }px)`,
    BIGGER_THAN_MEDIUM: `@media only screen and (min-width: ${BreakPoints.medium}px)`,
    BIGGER_THAN_WIDE: `@media only screen and (min-width: ${BreakPoints.wide}px)`,
    BIGGER_THAN_HUGE: `@media only screen and (min-width: ${BreakPoints.huge}px)`,
};
