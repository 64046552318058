import { createStudentQuery } from "../../queries/students/create";
import CrudList from "../../pages/CRUD/List";
import { TableColumnType } from "../../utils/enums/TableColumnType";

import { IRoute } from "../../utils/interfaces/IRoute";
import CreateStudent from "../../pages/Student/create";
import { ITableColumn } from "../../utils/interfaces/ITable";
import StudentDetails from "pages/Student/details";

export const studentColumns: ITableColumn[] = [
    {
        col: "auto",
        name: "person",
        type: TableColumnType.Person,
        label: "Aluno",
    },
    {
        col: "auto",
        name: "person.birthday",
        type: TableColumnType.Date,
    },
    {
        col: "auto",
        name: "responsible.responsible.person",
        type: TableColumnType.Person,
        label: "Responsável",
    },
    {
        col: "auto",
        name: "project.name",
        type: TableColumnType.Text,
    },
    {
        col: 1,
        name: "subscriptions_aggregate.aggregate.count",
        type: TableColumnType.Number,
        removeSearch: true,
        label: "Turmas",
    },
];

export const studentsRoutes: IRoute[] = [
    {
        title: "Alunos",
        path: "/alunos",
        graphql: {
            companyPath: "project.company_id",
            tableName: "student",
            orderBy: {
                person: {
                    name: "asc",
                },
            },
        },
        mainColumn: "id",
        component: CrudList,
        exact: true,
        canEdit: false,
        canDelete: true,
        canCreate: false,
        canSee: true,
        columns: studentColumns,
        showTotal: true,
    },
    {
        title: "Novo Aluno",
        path: "/alunos/novo",
        graphql: {
            create: createStudentQuery,
        },
        component: CreateStudent,
        exact: true,
    },

    {
        title: "Editar Aluno",
        path: "/alunos/editar/:id",
        component: CreateStudent,
        exact: true,
    },
    {
        title: "Visualizar aluno",
        path: "/alunos/ver/:id",
        component: StudentDetails,
        exact: true,
        hideTitle: true,
    },
];
