import styled, { css } from "styled-components";
import { theme } from "../../config/theme";
import { generateSizeStyles } from "../../helpers/generateSizeStyles";
import { generateSpacingStyles } from "../../helpers/generateSpacingStyles";
import { ISizing } from "../../utils/interfaces/ISizing";
import { ISpacing } from "../../utils/interfaces/ISpacing";
import { ColorsVariants } from "../../utils/types/Variants";

export interface IButtonElementProps extends ISpacing, ISizing {
    variant: ColorsVariants;
    iconDirection?: "left" | "right";
    textAlign?: "left" | "right" | "center";
    padding?: ISpacing;
    disabled?: boolean;
    rounded?: boolean;
}

const generateColors = (variant: ColorsVariants) => {
    let color = variant === "light" ? theme.colors.base : theme.colors.white;
    let background =
        variant === "light"
            ? "transparent"
            : (theme as any).colors[variant] || "transparent";

    return css`
        color: ${color};
        background: ${background};
        border: none;

        ${variant === "light" &&
        `:hover {
            color: ${theme.colors.black};
        }`};
    `;
};

export const ButtonElement = styled.button<IButtonElementProps>`
    ${({ variant }) => generateColors(variant)};
    border-radius: 8px;
    padding: 8px 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s, transform 0.3s;
    text-align: ${({ textAlign }) => textAlign};
    position: relative;

    ${({ rounded }) =>
        !!rounded &&
        css`
            border-radius: 100%;
            padding: 8px;
        `};

    ${({ padding }) => generateSpacingStyles(padding, "padding")}
    ${(props) => generateSpacingStyles(props)};
    ${(props) => generateSizeStyles(props)};

    ${({ iconDirection }) =>
        iconDirection === "left" &&
        css`
            flex-direction: row-reverse;
        `};

    :active {
        opacity: 1 !important;
    }

    :hover {
        opacity: 0.8;
    }

    > .button-icon {
        ${({ iconDirection }) =>
            iconDirection === "left"
                ? css`
                      margin-right: 10px;
                  `
                : css`
                      margin-left: 10px;
                  `};

        display: flex;
        align-items: center;
    }

    :disabled {
        opacity: 0.8;
        cursor: no-drop;
    }

    .spinner {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 8px;

        ${({ variant }) => generateColors(variant)};
    }
`;

export const ButtonGroup = styled.div<ISizing & { column?: boolean }>`
    display: flex;

    ${({ column }) =>
        column
            ? css`
                  flex-direction: column;

                  ${ButtonElement} {
                      :not(:last-child) {
                          margin-bottom: 10px;
                      }
                  }
              `
            : css`
                  ${ButtonElement} {
                      :not(:last-child) {
                          margin-right: 10px;
                      }
                  }
              `};

    ${(props) => generateSizeStyles(props)};
`;
