import React, { forwardRef } from "react";
import { ISpacing } from "../../../utils/interfaces/ISpacing";
import { ISizing } from "../../../utils/interfaces/ISizing";
import { InputContainer, InputElement } from "./styles";
import { FormElementContainer, FormError, FormLabel } from "../styles";
import { InputType } from "../../../utils/enums/InputType";

export interface IInputProps extends ISpacing, ISizing {
    type?: InputType | string;
    placeholder?: string;
    name: string;
    required?: boolean;
    showError?: boolean;
    icon?: React.ReactNode;
    autoComplete?: string;
    label?: string;
    onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
    value?: any;
    disabled?: boolean;
    min?: number | string;
    max?: number | string;
    error?: string;
}

const Input = forwardRef(
    (
        {
            type = InputType.Text,
            name,
            required = false,
            showError = true,
            icon,
            label,
            onChange,
            value,
            autoComplete = "none",
            error,
            ...props
        }: IInputProps,
        ref: any
    ) => {
        return (
            <FormElementContainer hidden={type === "hidden"}>
                {!!label && (
                    <FormLabel htmlFor={name}>
                        {label}
                        {required && ` *`}
                    </FormLabel>
                )}
                <InputContainer>
                    <InputElement
                        ref={ref}
                        type={type}
                        name={name}
                        error={!!error}
                        required={required}
                        onChange={onChange}
                        autoComplete={autoComplete}
                        value={value}
                        {...props}
                    />
                    {icon}
                </InputContainer>
                {!!error && showError && <FormError>{error}</FormError>}
            </FormElementContainer>
        );
    }
);

export default Input;
