import React, { memo } from "react";
import { theme } from "../../config/theme";
import { IIconProps } from "../../utils/interfaces/IIconProps";

const CloseIcon = memo(
    ({ fill = theme.colors.black, ...props }: IIconProps) => {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                {...props}
                viewBox="0 0 12 12"
            >
                <g transform="translate(-6 -6)">
                    <path
                        id="_Icon_Сolor-2"
                        data-name="🎨 Icon Сolor"
                        d="M7.414,6l4.293-4.293A1,1,0,1,0,10.293.293L6,4.586,1.707.293A1,1,0,0,0,.293,1.707L4.586,6,.293,10.293a1,1,0,1,0,1.414,1.414L6,7.414l4.293,4.293a1,1,0,0,0,1.414-1.414Z"
                        transform="translate(6 6)"
                        fill={fill}
                    />
                </g>
            </svg>
        );
    }
);

export default CloseIcon;
