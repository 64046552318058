import React, { useCallback, useEffect, useState } from "react";
import { Card } from "../../../components/Card";
import FormFactory from "../../../components/Form/FormFactory";
import { IBaseRouteProps } from "../../../utils/interfaces/IBaseRouteProps";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Creators as CrudActions } from "../../../store/crud";
import { IReduxStore } from "../../../store/ducks";

import { ICrudActions, ICrudState } from "../../../store/crud/types";

import _pick from "lodash/pick";
import _uniq from "lodash/uniq";
import formatInitialDataFormValues from "../../../helpers/formatFormValues";
import Spinner from "components/Spinner";

interface IProps extends IBaseRouteProps<{ id: string }> {
    crud: ICrudState;
    crudActions: ICrudActions;
}

const CrudCreateOrEdit = ({
    fields = [],
    schema,
    crud,
    crudActions,
    graphql,
    match,
    mainColumn = "id",
}: IProps) => {
    const [initialData, setInitialData] = useState();

    const { loading = false } = crud;

    const { id = "" } = match.params;

    useEffect(() => {
        return () => {
            crudActions.clearCrud();
        };
    }, [crudActions]);

    const getById = useCallback(() => {
        if (!id) {
            return;
        }

        crudActions.getByIdRequest({ id, query: graphql?.getById });
    }, [crudActions, graphql, id]);

    useEffect(() => {
        getById();
    }, [getById]);

    const handleSubmit = useCallback(
        (data: any) => {
            console.log(data);

            const query = !!id ? graphql?.edit : graphql?.create;

            const body = {
                ...data,
                ...(!!id &&
                    (!!mainColumn ? { [mainColumn]: +id } : { id: +id })),
            };

            console.log("handleSubmit", body);

            crudActions.createOrEditRequest({
                ...(!!id && { id }),
                body,
                query,
                formatFunction: graphql?.formatFunction,
            });
        },
        [crudActions, graphql, id, mainColumn]
    );

    const formatInitialData = useCallback(() => {
        if (!crud?.id && !(crud as any)[mainColumn!]) {
            return;
        }

        const tempInitialData: any = _pick(
            crud,
            _uniq([
                ...fields.map(({ name }) => name),
                ...fields.map(({ name }) => name.replace("id_", "")),
                ...fields.map(({ name }) => name.replace("_id", "")),
            ])
        );

        setInitialData(formatInitialDataFormValues(tempInitialData, fields));
    }, [crud, fields, mainColumn]);

    useEffect(() => formatInitialData(), [formatInitialData]);

    const showLoading = !!id ? !crud?.id || !initialData : false;

    console.log("initialData", initialData);

    console.log("showLoading", { showLoading, initialData });

    return (
        <Card style={{ position: "relative" }} bottom="30px">
            {showLoading ? (
                <Spinner />
            ) : (
                <FormFactory
                    fields={fields}
                    schema={schema}
                    onSubmit={handleSubmit}
                    initialData={initialData}
                    isLoading={loading}
                />
            )}
        </Card>
    );
};

const mapStateToProps = ({ crud }: IReduxStore) => ({
    crud,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    crudActions: bindActionCreators(CrudActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CrudCreateOrEdit);
