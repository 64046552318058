import { dictionary } from "config/dictionary";
import { isValidDate } from "helpers/isValidDate";
import DateService from "services/date";
import { date } from "yup";

export const transformSchemaValueToDate = (_: any, value: string | Date) => {
    const isValid = isValidDate(value);

    if (!isValid) {
        return undefined;
    }

    const isString = typeof value === "string";

    if (isString) {
        return DateService.fromISO(value as string).toJSDate();
    }

    return value;
};

export const dateSchema = (min?: {
    value?: any;
    message?: string;
    required?: boolean;
}) => {
    let schema = date()
        .transform(transformSchemaValueToDate)
        .typeError(dictionary.validations.required);

    if (min?.required) {
        schema = schema.required(dictionary.validations.required);
    }

    if (min?.value) {
        schema = schema.min(
            min?.value,
            min?.message || dictionary.validations.required
        );
    }

    return schema;
};
