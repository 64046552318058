import { Card } from "components/Card";
import React from "react";
import { IBaseRouteProps } from "utils/interfaces/IBaseRouteProps";
import UserForm from "./form";

const UserCreateOrEdit = ({ match: { params } }: IBaseRouteProps) => {
    const { id } = params || {};

    return (
        <Card column bottom="50px">
            <UserForm id={~~id} />
        </Card>
    );
};

export default UserCreateOrEdit;
