import styled, { css } from "styled-components/macro";
import { theme } from "../../config/theme";
import { FontWeight } from "../../utils/enums/FontWeight";

const colors = theme.colors.components.pagination;

export const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 0px 5px;
`;

export const PaginationButton = styled.button<{ direction: "left" | "right" }>`
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    outline: none;
    padding: 20px 25px;

    :disabled {
        cursor: not-allowed;
        opacity: 0.7;
    }

    ${({ direction }) =>
        direction === "left"
            ? css`
                  border-right: 1px solid ${theme.colors.mainLight};
              `
            : css`
                  border-left: 1px solid ${theme.colors.mainLight};
              `};

    > svg {
        fill: ${colors.buttonColor};

        path {
            fill: ${colors.buttonColor};
        }

        ${({ direction }) =>
            direction === "left" &&
            css`
                margin-right: 12px;
            `};
    }

    > span {
        color: ${colors.buttonColor};
        font-weight: ${FontWeight.SemiBold};
        font-size: 12px;

        ${({ direction }) =>
            direction === "right" &&
            css`
                margin-right: 12px;
            `};
    }

    :not(:disabled) {
        :hover {
            > span {
                color: ${colors.buttonColorHover};
            }

            > svg {
                fill: ${colors.buttonColorHover};

                path {
                    fill: ${colors.buttonColorHover};
                }
            }
        }
    }
`;

export const PaginationNumbersContainer = styled.div`
    display: flex;
    margin: 0 auto;
    height: 100%;
    align-self: flex-end;
`;

export const PaginationNumberButton = styled.button.attrs({ type: "button" })<{
    active: boolean;
}>`
    padding: 15px 8px;
    border: none;
    border-bottom: 2px solid
        ${({ active }) => (active ? theme.colors.main : "transparent")};
    background: transparent;
    outline: none;

    color: ${({ active }) =>
        active ? colors.numberColorActive : colors.numberColor};

    :hover {
        color: ${colors.numberColorHover};
    }
`;
