import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistReducer, persistStore } from "redux-persist";
// @ts-ignore
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";

import rootReducer from "./ducks";
import authMiddleware from "./middlewares/auth";
import rootSaga from "./sagas";
import { theme } from "../config/theme";

export type ReduxAction<T> = {
    type: string;
    payload: T;
};

// Reducers
const middlewares: any[] = [];
const sagaMiddleware = createSagaMiddleware();

middlewares.push(...[authMiddleware, sagaMiddleware]);

const persistConfig = {
    key: theme.projectSlug,
    storage,
    whitelist: ["auth"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(
    persistedReducer,
    composeWithDevTools(applyMiddleware(...middlewares))
);
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor };
