import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import ListCards, { IListCardsConfig } from "components/ListCards";

import { IBaseRouteProps } from "utils/interfaces/IBaseRouteProps";
import {
    IProjectClassDayActions,
    IProjectClassDayState,
} from "store/projectClassDay/types";
import { Creators as ProjectClassDayActions } from "../../store/projectClassDay";
import { Creators as ProjectClassActions } from "../../store/projectClass";

import ListTabData, { IListTabDataConfig } from "components/ListTabData";
import { IReduxStore } from "store/ducks";
import { ProjectClassDetailsContainer } from "pages/ProjectClass/styles";
import { useHeaderTitle } from "hooks/useHeaderTitle";
import { formatDate } from "helpers/formatDate";
import { IProjectClassActions } from "store/projectClass/types";
import {
    listCardsConfigProjectClassDay,
    listConfigProjectClassDay,
} from "./utils";

export enum ProjectClassEntities {
    ClassDay = "classDay",
    ClassDayWithProblems = "classDayWithProblems",
    Teachers = "teachers",
    Assistants = "assistants",
    Administrators = "administrators",
    Student = "students",
}

interface IProps extends IBaseRouteProps {
    projectClassDay?: IProjectClassDayState;
    projectClassDayActions: IProjectClassDayActions;
    projectClassActions: IProjectClassActions;
}

const ProjectClassDayDetails = ({
    match: { params },
    projectClassDay,
    projectClassDayActions,
    projectClassActions,
}: IProps) => {
    const { id: projectClassId, classDayId } = params;

    useHeaderTitle(`Aula - ${formatDate(projectClassDay?.day)}`);

    const request = useCallback(async () => {
        if (!classDayId) {
            return;
        }

        projectClassDayActions.getProjectClassDayByIdRequest({
            classDayId,
        });

        projectClassActions.getProjectClassByIdRequest({ id: projectClassId });
    }, [
        classDayId,
        projectClassId,
        projectClassDayActions,
        projectClassActions,
    ]);

    useEffect(() => {
        request();
    }, [request]);

    const listConfig: IListTabDataConfig[] =
        listConfigProjectClassDay(classDayId);

    const listCardsConfig: IListCardsConfig = listCardsConfigProjectClassDay(
        projectClassDay!,
        classDayId
    );

    useEffect(() => {
        return () => {
            projectClassActions.clearProjectClass();
            projectClassDayActions.clearProjectClassDay();
        };
    }, [projectClassActions, projectClassDayActions]);

    return (
        <>
            <ProjectClassDetailsContainer>
                <ListCards {...listCardsConfig} />

                {!!projectClassDay?.id && <ListTabData configs={listConfig} />}
            </ProjectClassDetailsContainer>
        </>
    );
};

const mapStateToProps = ({ projectClassDay }: IReduxStore) => ({
    projectClassDay,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    projectClassDayActions: bindActionCreators(
        ProjectClassDayActions,
        dispatch
    ),
    projectClassActions: bindActionCreators(ProjectClassActions, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectClassDayDetails);
