import { dictionary } from "config/dictionary";
import { object, string, number, array } from "yup";

export const selectSchema = (valueType?: "string" | "number") =>
    object({
        value: (valueType === "string" ? string : number)()
            .typeError(dictionary.validations.required)
            .required(dictionary.validations.required),
        label: string()
            .typeError(dictionary.validations.required)
            .required(dictionary.validations.required),
    }).typeError(dictionary.validations.required);

export const multipleSelectSchema = (valueType?: "string" | "number") =>
    array()
        .of(
            object({
                value: (valueType === "string" ? string : number)()
                    .typeError(dictionary.validations.required)
                    .required(dictionary.validations.required),
                label: string()
                    .typeError(dictionary.validations.required)
                    .required(dictionary.validations.required),
            })
        )
        .typeError(dictionary.validations.required);
