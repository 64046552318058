import { IListCardsConfig } from "components/ListCards";
import { dictionary } from "config/dictionary";
import { InputType } from "utils/enums/InputType";

export const listCardsConfigProjectClass = (
    projectClassId: number
): IListCardsConfig => ({
    cards: [
        {
            name: "project.professional.person.name",
            type: InputType.Text,
            grid: {
                md: 4,
            },
            label: dictionary["professional.name"],
        },
        {
            name: "number_of_students",
            type: InputType.Number,
            grid: {
                md: 4,
            },
            label: "Alunos",
        },
        {
            name: "days_aggregate.aggregate.count",
            type: InputType.Number,
            grid: {
                md: 4,
            },
            label: "Aulas",
        },
    ],
    graphQL: {
        tableName: "project_class",
        returnPath: "item",
        primaryKey: projectClassId,
    },
});
