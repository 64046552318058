import React, { memo, useEffect, useMemo, useState } from "react";
import ListData, { IListDataProps } from "../ListData";
import Tab from "../Tab";
import { ListTabDataContainer } from "./styles";

export interface IListTabDataConfig extends IListDataProps {
    component?: React.ReactNode;
}
interface IProps {
    configs: Array<IListTabDataConfig>;
}

const ListTabData = ({ configs }: IProps) => {
    const tabItems = configs.map(({ title }, index) => ({
        value: index,
        label: title ?? "-",
    }));

    const [activeTab, setActiveTab] = useState<any>();

    const activeEntity = useMemo(
        () => (activeTab !== undefined ? configs[activeTab] : undefined),
        [activeTab, configs]
    );

    useEffect(() => {
        if (!configs?.length) {
            return;
        }

        setActiveTab(0);
    }, [configs]);

    if (!configs?.length) {
        return null;
    }

    return (
        <ListTabDataContainer>
            <Tab
                onClick={setActiveTab}
                activeItem={activeTab}
                items={tabItems}
                bottom="20px"
            />

            {!!activeEntity &&
                (activeEntity?.component ? (
                    activeEntity?.component
                ) : (
                    <ListData {...activeEntity} />
                ))}
        </ListTabDataContainer>
    );
};

export default memo(ListTabData);
