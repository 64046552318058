import UserCreateOrEdit from "pages/User/createOrEdit";
import { Profile } from "utils/enums/Profile";
import { createPersonFields } from "utils/fields/person";
import { IInputField } from "utils/interfaces/IInputField";
import { personSchema } from "utils/schemas/person";
import { object, string } from "yup";
import { dictionary } from "../../config/dictionary";
import CrudList from "../../pages/CRUD/List";
import { InputType } from "../../utils/enums/InputType";
import { TableColumnType } from "../../utils/enums/TableColumnType";
import { IRoute } from "../../utils/interfaces/IRoute";

export const userFields: IInputField[] = [
    // {
    //     name: "profile_id",
    //     type: InputType.Enum,
    //     enumValue: Profile,
    //     required: true,
    //     grid: {
    //         col: 4,
    //     },
    // },
    {
        name: "email",
        type: InputType.Email,
        grid: {
            col: 4,
        },
    },
    {
        name: "username",
        type: InputType.Text,
        grid: {
            col: 4,
        },
    },
    ...createPersonFields(),
];

export const userSchema = object().shape({
    person: personSchema(),
    email: string()
        .email(dictionary.validations.email)
        .typeError(dictionary.validations.required)
        .required(dictionary.validations.required),
    username: string()
        .min(2, dictionary.validations.invalid)
        .typeError(dictionary.validations.required)
        .required(dictionary.validations.required),
});

export const usersRoutes: IRoute[] = [
    {
        title: "Usuários",
        path: "/usuarios",
        component: CrudList,
        exact: true,
        canEdit: true,
        canDelete: true,
        canCreate: true,
        columns: [
            {
                col: 6,
                name: "person",
                type: TableColumnType.Person,
            },

            {
                col: 3,
                name: "profile_id",
                type: TableColumnType.Enum,
                enumValue: Profile,
                label: "Perfil",
            },
        ],
        showTotal: true,
        graphql: {
            tableName: "users",
        },
        mainColumn: "id",
    },
    {
        title: "Novo Usuário",
        path: "/usuarios/novo",
        component: UserCreateOrEdit,
        exact: true,
    },
    {
        title: "Editar Usuário",
        path: "/usuarios/editar/:id",
        component: UserCreateOrEdit,
        exact: true,
    },
];
