// sampleReducer.js
import { createReducer, createActions } from "reduxsauce";
import { CrudTypes, ICrudState, ICrudActionsCreators } from "./types";

export const { Creators, Types } = createActions<
    Record<CrudTypes, CrudTypes>,
    ICrudActionsCreators
>({
    getAllRequest: ["payload"],
    getAllSuccess: ["payload"],
    getAllFailure: ["payload"],

    createOrEditRequest: ["payload"],
    createOrEditSuccess: ["payload"],
    createOrEditFailure: [],

    deleteByIdRequest: ["payload"],
    deleteByIdSuccess: ["payload"],
    deleteByIdFailure: ["payload"],

    getByIdRequest: ["payload"],
    getByIdSuccess: ["payload"],
    getByIdFailure: [],

    crudAddListItem: ["payload"],

    clearCrud: ["payload"],
});

const INITIAL_STATE: ICrudState = {
    error: false,
    loading: false,
    empty: false,
    items: [],
    pagination: {
        page: 0,
        totalPages: 0,
        totalItems: 0,
    },
    id: 0,
};

const request = (state = INITIAL_STATE, action: any) => {
    const contentId = action?.payload?.contentId;

    const updateState = {
        ...(state as any)[contentId],
        error: false,
        loading: true,
        empty: false,
    };

    return {
        ...state,
        ...(!!contentId ? { [contentId]: updateState } : updateState),
    };
};

const success = (state = INITIAL_STATE, action: any) => {
    const { items, pagination } = action.payload;

    const contentId = action?.payload?.contentId;

    const updateState = {
        error: false,
        loading: false,
        items,
        pagination,
        empty: !items?.length,
    };

    return {
        ...state,
        ...(!!contentId ? { [contentId]: updateState } : updateState),
    };
};

const deleteSuccess = (state = INITIAL_STATE, action: any) => {
    const { item, mainColumn, contentId } = action.payload;

    const items = !!contentId ? (state as any)[contentId]?.items : state.items;

    const updateItems =
        items?.filter((updatedItem: any) => {
            if (!!Array.isArray(mainColumn)) {
                return !mainColumn.every(
                    (column: string) => item[column] === updatedItem[column]
                );
            }

            return !!mainColumn
                ? updatedItem[mainColumn] !== item[mainColumn]
                : updatedItem.id !== item.id;
        }) ?? [];

    const updateState = {
        error: false,
        loading: false,
        items: updateItems,
        empty: !updateItems?.length,
    };

    return {
        ...state,
        ...(!!contentId ? { [contentId]: updateState } : updateState),
    };
};

const failure = (state = INITIAL_STATE, action: any) => {
    const contentId = action?.payload?.contentId;

    const updateState = {
        error: true,
        loading: false,
        empty: false,
    };

    return {
        ...state,

        ...(!!contentId
            ? {
                  [contentId]: {
                      ...(state as any)[contentId],
                      ...updateState,
                  },
              }
            : updateState),
    };
};

const setState = (state = INITIAL_STATE, action: any) => ({
    ...state,
    loading: false,
    ...action.payload,
});

const clear = (state = INITIAL_STATE, action: any) => {
    return !!action?.payload
        ? { ...state, [action.payload]: undefined }
        : INITIAL_STATE;
};

const addListItem = (state = INITIAL_STATE, action: any) => {
    const { item, contentId } = action.payload;

    const items = !!contentId ? (state as any)[contentId]?.items : state.items;

    const updateItems = [item, ...(items || [])];

    const updateState = {
        error: false,
        loading: false,
        items: updateItems,
        empty: !updateItems?.length,
    };

    return {
        ...state,
        ...(!!contentId ? { [contentId]: updateState } : updateState),
        loading: false,
        empty: !updateItems?.length,
    };
};

const HANDLERS = {
    [Types.GET_ALL_REQUEST]: request,
    [Types.GET_ALL_SUCCESS]: success,
    [Types.GET_ALL_FAILURE]: failure,

    [Types.DELETE_BY_ID_REQUEST]: request,
    [Types.DELETE_BY_ID_SUCCESS]: deleteSuccess,
    [Types.DELETE_BY_ID_FAILURE]: failure,

    [Types.CREATE_OR_EDIT_REQUEST]: request,
    [Types.CREATE_OR_EDIT_SUCCESS]: setState,
    [Types.CREATE_OR_EDIT_FAILURE]: failure,

    [Types.GET_BY_ID_REQUEST]: request,
    [Types.GET_BY_ID_SUCCESS]: setState,
    [Types.GET_BY_ID_FAILURE]: failure,

    [Types.CRUD_ADD_LIST_ITEM]: addListItem,

    [Types.CLEAR_CRUD]: clear,
};

export default createReducer<ICrudState>(INITIAL_STATE, HANDLERS);
