import styled from "styled-components";
import { MediaQueries } from "utils/mediaQueries";

export const ProductOrderHeader = styled.header`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    div {
        display: flex;
        align-items: center;

        button {
            flex: 1;

            :first-child {
                margin-right: 10px;
            }
        }
    }

    .title {
        margin-bottom: 30px;
    }

    ${MediaQueries.BIGGER_THAN_TABLET} {
        flex-direction: row;
        align-items: center;

        .title {
            flex: 1;
            margin-bottom: unset;
        }
    }
`;
