import { useCallback, useState } from "react";
import _get from "lodash/get";
import graphql from "../services/graphql";
import { toast } from "react-toastify";

interface IReturn<T> {
    loading: boolean;
    error: boolean;
    response: T;
    request(
        query: string,
        variables?: object,
        returnPath?: string,
        errorMessage?: string
    ): void;
}

const useGraphQL: <T>() => IReturn<T> = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [response, setResponse] = useState<any>();

    const request = useCallback(
        async (
            query: string,
            variables?: object,
            returnPath?: string,
            errorMessage?: string
        ) => {
            try {
                if (!query) {
                    console.log("QUERY NOT FOUND");

                    throw new Error();
                }

                setError(false);
                setLoading(true);

                const { data } = await graphql(query, variables);

                const responseData = !!returnPath
                    ? _get(data, returnPath)
                    : data;

                if (!responseData) {
                    throw new Error();
                }

                setResponse(responseData);

                setError(false);
            } catch (error) {
                console.log(error);

                setResponse(undefined);

                setError(true);

                toast.error(errorMessage || "Desculpe, tivemos um problema.");
            } finally {
                setLoading(false);
            }
        },
        []
    );

    return { loading, error, response, request };
};

export default useGraphQL;
