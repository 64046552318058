import React from "react";
import { useSelector } from "react-redux";
import { IBaseModalElement } from "../../../utils/interfaces/IBaseModalElement";
import { IInputField } from "../../../utils/interfaces/IInputField";
import { IReduxStore } from "../../../store/ducks";

import FormFactory from "../../Form/FormFactory";

interface IProps extends IBaseModalElement {
    action?: {
        onSubmit?(data: any): void;
        fields?: IInputField[];
        schema?: any;
        initialData?: any;
    };
}

const ModalGenericForm = ({ action }: IProps) => {
    const {
        onSubmit = () => null,
        fields = [],
        initialData,
        schema,
    } = action || {};

    const isLoading = useSelector(
        (state: IReduxStore) => !!state?.crud?.loading
    );

    console.log("action", action);

    return (
        <FormFactory
            fields={fields}
            onSubmit={onSubmit}
            initialData={initialData}
            schema={schema}
            isLoading={isLoading}
        />
    );
};

export default ModalGenericForm;
