import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { Creators } from "../store/modal";
import { IModalPayload } from "../store/modal/types";

export function useModal() {
    const dispatch = useDispatch();

    const open = useCallback(
        (payload: IModalPayload) => dispatch(Creators.openModal(payload)),
        [dispatch]
    );

    const close = useCallback(
        () => dispatch(Creators.closeModal()),
        [dispatch]
    );

    return {
        open,
        close,
    };
}
