import React, { memo } from "react";
import { theme } from "../../config/theme";
import { IIconProps } from "../../utils/interfaces/IIconProps";

const PlusIcon = memo(({ fill = theme.colors.black, ...props }: IIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 16 16">
            <path
                d="M15,7H9V1A1,1,0,0,0,7,1V7H1A1,1,0,0,0,1,9H7v6a1,1,0,0,0,2,0V9h6a1,1,0,0,0,0-2"
                fill={fill}
            />
        </svg>
    );
});

export default PlusIcon;
