import styled from "styled-components";
import { AvatarContainer } from "../Avatar/styles";

export const Container = styled.div``;

export const PersonContainer = styled.div`
    display: flex;
    align-items: center;

    ${AvatarContainer} {
        margin-right: 10px;
    }
`;

export const PersonName = styled.div`
    display: flex;
    align-items: center;
`;
