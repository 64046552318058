import generateSearchQuery from "helpers/generateSearchQuery";
import useApi, { IRequestPayload } from "hooks/useApi";
import { useCompanyId } from "hooks/useCompanyId";
import React, { useCallback, useMemo, useState } from "react";
import { InputType } from "utils/enums/InputType";
import { TableColumnType } from "utils/enums/TableColumnType";
import { IInputField } from "utils/interfaces/IInputField";
import { ITableColumn } from "utils/interfaces/ITable";
import { multipleSelectSchema } from "utils/schemas/select";
import { object } from "yup";
import Reports from ".";
import { formatReportBody } from "./utils/formatBody";
import useDownload from "./utils/useDownload";

const ReportProjects: React.FC = () => {
    const [isDownload, setIsDownload] = useState(false);
    const companyId = useCompanyId();

    const {
        loading: isLoadingDownload,
        pdf,
        request: startDownload,
    } = useDownload();

    const {
        request,
        response,
        loading: isLoadingData,
        error,
    } = useApi<{
        featuredNumbers: any;
        report: any[];
    }>();

    const requestItems = useCallback(
        (data: any) => {
            const body = formatReportBody(data);

            const payload: IRequestPayload = {
                endpoint: "/admin/reports/projects",
                method: "post",
                body,
            };

            if (isDownload) {
                startDownload({
                    ...payload,
                    endpoint: `${payload.endpoint}/pdf`,
                });

                return setIsDownload(false);
            }

            request(payload);
            setIsDownload(false);
        },
        [startDownload, isDownload, request]
    );

    const columns: ITableColumn[] = [
        {
            name: "project.name",
            type: TableColumnType.Text,
        },
        {
            name: "region.name",
            type: TableColumnType.Text,
        },
        {
            name: "professional.name",
            type: TableColumnType.Text,
        },
        {
            name: "modalities",
            type: TableColumnType.Custom,
            component: (row: any) => {
                if (!Boolean(row.modalities?.length)) {
                    return <span>-</span>;
                }

                return (
                    <>
                        {row.modalities.map((item: any, index: number) => (
                            <span
                                key={`${item.name}_${index}`}
                                style={{ display: "block" }}
                            >
                                {item.name}
                            </span>
                        ))}
                    </>
                );
            },
        },
        {
            name: "numberOfSchoolClasses",
            style: { textAlign: "center" },
            type: TableColumnType.Text,
        },
        {
            name: "numberOfStudents",
            style: { textAlign: "center" },
            type: TableColumnType.Text,
        },
        {
            name: "presence.percentage",
            style: { textAlign: "center" },
            type: TableColumnType.Custom,
            component: (row: any) => (
                <span>{row.presence.percentage || 0}%</span>
            ),
        },
        {
            name: "averageGrade",
            style: { textAlign: "center" },
            type: TableColumnType.Text,
        },
        {
            name: "numberOfPossibleClasses",
            style: { textAlign: "center" },
            type: TableColumnType.Text,
        },
        {
            name: "numberOfClosedClasses",
            style: { textAlign: "center" },
            type: TableColumnType.Custom,
            component: (row: any) => (
                <span>
                    {row.numberOfClosedClasses.value || 0} (
                    {row.numberOfClosedClasses.percentage}%)
                </span>
            ),
        },
        {
            name: "numberOfLostClasses",
            style: { textAlign: "center" },
            type: TableColumnType.Custom,
            component: (row: any) => (
                <span>
                    {row.numberOfLostClasses.value || 0} (
                    {row.numberOfLostClasses.percentage}%)
                </span>
            ),
        },
        {
            name: "numberOfNextClassesByProject",
            style: { textAlign: "center" },
            type: TableColumnType.Text,
        },
    ].map((i: any) => ({ ...i, removeSearch: true, col: i?.col || "auto" }));

    const fields: IInputField[] = [
        {
            name: "regions",
            graphQL: {
                searchQuery: generateSearchQuery({ tableName: "region" }),
            },
            type: InputType.AsyncSelect,
            isMulti: true,
            grid: {
                md: 4,
            },
        },
        {
            name: "professionals",
            graphQL: {
                searchQuery: `query ($searchText: String) {
                    items: professional(limit: 10, where: {company_id: {_eq: ${companyId}}, person: {name: {_ilike: $searchText}}}) {
                      value: id
                      company_id
                      person {
                        name
                      }
                    }
                    quantity: professional_aggregate(where: {company_id: {_eq: ${companyId}}, person: {name: {_ilike: $searchText}}}) {
                      aggregate {
                        count
                      }
                    }
                  }
                  `,
            },
            type: InputType.AsyncSelect,
            isMulti: true,
            grid: {
                md: 4,
            },
        },
        {
            name: "modalities",
            graphQL: {
                searchQuery: generateSearchQuery({
                    tableName: "modality",
                }),
            },
            type: InputType.AsyncSelect,
            isMulti: true,
            grid: {
                md: 4,
            },
        },
    ];

    const schema: any = object().shape({
        regions: multipleSelectSchema(),
        professionals: multipleSelectSchema(),
        modalities: multipleSelectSchema(),
    });

    const featuredNumbers = useMemo(() => {
        if (!response) {
            return [];
        }

        const items = [
            {
                title: "Núcleos",
                values: [response.featuredNumbers.numberOfProjects],
            },
            {
                title: "Turmas",
                values: [response.featuredNumbers.numberOfSchoolClasses],
            },
            {
                title: "Alunos",
                values: [
                    response.featuredNumbers.numberOfStudents,
                    `(${response.featuredNumbers.averageByProject || 0}/N)`,
                ],
            },
            {
                title: "Presença",
                values: [
                    `${response.featuredNumbers.presencePercentage || 0}%`,
                ],
            },
            {
                title: "Nota média",
                values: [response.featuredNumbers.averageGradeByProject],
            },
        ];

        const statuses =
            response.featuredNumbers.status.map((item: any) => ({
                title: item.name,
                values: [
                    item.value,
                    ...(Boolean(item.percentage)
                        ? [`${item.percentage}%`]
                        : []),
                ],
            })) || [];

        return [...items, ...statuses];
    }, [response]);

    return (
        <Reports
            {...{
                columns,
                fields,
                schema,
                error,
                pdf,
                isLoadingData,
                isLoadingDownload,
                featuredNumbers,
            }}
            report={response?.report}
            onSubmit={requestItems}
            onClickDownload={() => setIsDownload(true)}
        />
    );
};

export default ReportProjects;
