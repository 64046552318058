import { put, takeLatest, call } from "redux-saga/effects";
import graphql from "../../services/graphql";
import { ISagaAction } from "../../utils/interfaces/ISagaAction";
import { Creators, Types } from ".";
import { toast } from "react-toastify";
import history from "../../services/history";
import api from "services/api";
import { IGetUserByIdSuccessPayload } from "./types";
import convertEnumToOptions from "helpers/convertEnumToOptions";
import { Genre } from "utils/enums/Genre";
import { formatDate } from "helpers/formatDate";
import _pick from "lodash/pick";

function* createOrEdit(
    action: ISagaAction<{
        id?: number | string;
        body: any;
    }>
) {
    try {
        const { id, body } = action.payload;

        const formatBody = {
            ...body,
            profile_id: body?.profile_id?.value,
            person: {
                ...body.person,
                gender: body?.person?.gender?.value,
                birthday: formatDate(body?.person?.birthday, "dd/LL/yyyy"),
            },
        };

        const endpoint = !!id ? `/admin/users/${id}` : "/admin/users";
        const method = !!id ? api.patch : api.post;

        const keys = [
            "email",
            "username",
            "person.name",
            "person.gender",
            "person.phone",
            "person.document",
            "person.birthday",
        ];

        const { data } = yield call(method, endpoint, _pick(formatBody, keys));

        if (!data) {
            throw new Error();
        }

        yield put(Creators.createOrEditUserSuccess(data.item));

        yield call(toast.success, "Registro salvo!");
        yield call(history.push, { pathname: "/app/usuarios" });
    } catch (error) {
        console.log("error", error);

        yield call(toast.error, "Desculpe, tivemos um problema.");
        yield put(Creators.createOrEditUserFailure());
    }
}

function* getById(
    action: ISagaAction<{
        id: number | string;
    }>
) {
    try {
        if (!action?.payload?.id) {
            throw new Error();
        }

        const { id } = action.payload;

        const { data } = yield call(
            graphql,
            `query ($id: Int!) {
                item: users_by_pk(id: $id) {
                  id
                  email
                  profile_id
                  username
                  person {
                    name
                    gender
                    document
                    birthday
                    phone
                  }
                }
              }
            `,
            { id }
        );

        if (!data?.item?.id) {
            throw new Error();
        }

        const { person, email, username } = data.item || {};

        const body: IGetUserByIdSuccessPayload = {
            id: data.item?.id,
            email,
            username,
            // profile_id:
            //     convertEnumToOptions(Profile).find(
            //         ({ value }) => value === profile_id
            //     ) || convertEnumToOptions(Profile)[0],
            person: {
                ...person,
                gender: convertEnumToOptions(Genre).find(
                    ({ value }) => value === person.gender
                ),
            },
        };

        yield put(Creators.getUserByIdSuccess(body));
    } catch (error) {
        console.log("error", error);

        yield call(toast.error, "Desculpe, tivemos um problema.");

        yield put(Creators.getUserByIdFailure());

        yield call(history.push, { pathname: "/app/usuarios" });
    }
}

export default [
    takeLatest(Types.CREATE_OR_EDIT_USER_REQUEST, createOrEdit),
    takeLatest(Types.GET_USER_BY_ID_REQUEST, getById),
];
