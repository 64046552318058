import React, { memo } from "react";
import { theme } from "../../config/theme";
import { IIconProps } from "../../utils/interfaces/IIconProps";

const FileIcon = memo(({ fill = theme.colors.black, ...props }: IIconProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            viewBox="0 0 13.333 16.667"
        >
            <path
                d="M11.2,16.667H2.129A2.109,2.109,0,0,1,0,14.583V2.083A2.109,2.109,0,0,1,2.129,0H7.963A.834.834,0,0,1,8.58.273l4.537,5a.836.836,0,0,1,.217.56v8.749A2.11,2.11,0,0,1,11.2,16.667Zm-9.074-15a.442.442,0,0,0-.463.417v12.5A.442.442,0,0,0,2.129,15H11.2a.443.443,0,0,0,.464-.417V7.5H8.095A2.323,2.323,0,0,1,5.833,5.125V1.666Zm5.371,0V5.125a.66.66,0,0,0,.595.709h3.28L7.593,1.666Z"
                transform="translate(0 0)"
                fill={fill}
            />
        </svg>
    );
});

export default FileIcon;
