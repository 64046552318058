import CrudList from "../../pages/CRUD/List";
import { TableColumnType } from "../../utils/enums/TableColumnType";
import { IRoute } from "../../utils/interfaces/IRoute";

import { ProductOrderStatus } from "utils/enums/ProductOrderStatus";
import ProductOrder from "pages/ProductOrder";

export const productOrdersRoutes: IRoute[] = [
    {
        title: "Pedidos",
        path: "/pedidos",
        exact: true,
        graphql: {
            companyPath: "project.company_id",
            tableName: "product_order",
            orderBy: {
                number: "asc",
            },
        },
        mainColumn: "id",
        component: CrudList,
        canEdit: false,
        canDelete: false,
        canCreate: false,
        canSee: true,
        showTotal: true,
        columns: [
            {
                col: "auto",
                name: "number",
                type: TableColumnType.Text,
                label: "Código",
            },
            {
                col: "auto",
                name: "professional.person",
                type: TableColumnType.Person,
                label: "Profissional",
            },
            {
                col: "auto",
                name: "project.name",
                type: TableColumnType.Text,
            },
            {
                col: "auto",
                name: "product_order_status_id",
                type: TableColumnType.Enum,
                enumValue: ProductOrderStatus,
                label: "Status",
            },
            {
                col: "auto",
                name: "created_at",
                type: TableColumnType.Date,
            },
        ],
        defaultSearch: {
            product_order_status_id: {
                _neq: ProductOrderStatus.Canceled,
            },
        },
    },
    {
        title: "Visualizar pedido",
        path: "/pedidos/ver/:id",
        component: ProductOrder,
        exact: true,
        hideTitle: true,
    },
];
