import React, { useCallback } from "react";
import { IReduxStore } from "../../store/ducks";

import { bindActionCreators, Dispatch } from "redux";
import { Creators as ModalActions } from "../../store/modal";

import { connect } from "react-redux";
import { IModalActions, IModalState } from "../../store/modal/types";

import ModalComponent from "./component";

interface IProps extends IModalState {
    modalActions: IModalActions;
}

const Modal = ({
    title,
    element,
    action,
    isOpen,
    modalActions,
    width,
    height,
    center = false,
    removeCloseButton = false,
    contentBackground,
}: IProps) => {
    const handleCloseModal = useCallback(() => {
        if (removeCloseButton) {
            return;
        }

        modalActions.closeModal();
    }, [modalActions, removeCloseButton]);

    return (
        <ModalComponent
            action={action}
            element={element}
            isOpen={isOpen}
            onClose={handleCloseModal}
            title={title}
            center={center}
            height={height}
            removeCloseButton={removeCloseButton}
            width={width}
            contentBackground={contentBackground}
        />
    );
};

const mapStateToProps = ({
    modal: {
        title,
        element,
        action,
        isOpen,
        width,
        height,
        center,
        removeCloseButton,
    },
}: IReduxStore) => ({
    title,
    element,
    action,
    isOpen,
    width,
    height,
    center,
    removeCloseButton,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    modalActions: bindActionCreators(ModalActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
