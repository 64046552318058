import { createReducer, createActions } from "reduxsauce";
import {
    ProjectClassDayTypes,
    IProjectClassDayState,
    IProjectClassDayActionsCreators,
} from "./types";

export const { Creators, Types } = createActions<
    Record<ProjectClassDayTypes, ProjectClassDayTypes>,
    IProjectClassDayActionsCreators
>({
    getProjectClassDayByIdRequest: ["payload"],
    getProjectClassDayByIdSuccess: ["payload"],
    getProjectClassDayByIdFailure: [],
    clearProjectClassDay: [],
});

const INITIAL_STATE: IProjectClassDayState = {
    movements: [],
    error: false,
    loading: false,
};

const request = (state = INITIAL_STATE, _: any) => {
    return { ...state, error: false, loading: true };
};

const failure = (state = INITIAL_STATE) => {
    return { ...state, error: true, loading: false };
};

const clear = () => INITIAL_STATE;

const setState = (state = INITIAL_STATE, action: any) => ({
    ...state,
    loading: false,
    error: false,
    ...action.payload,
});

const HANDLERS = {
    [Types.GET_PROJECT_CLASS_DAY_BY_ID_REQUEST]: request,
    [Types.GET_PROJECT_CLASS_DAY_BY_ID_SUCCESS]: setState,
    [Types.GET_PROJECT_CLASS_DAY_BY_ID_FAILURE]: failure,
    [Types.CLEAR_PROJECT_CLASS_DAY]: clear,
};

export default createReducer(INITIAL_STATE, HANDLERS);
