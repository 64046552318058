import TableProjectClassDay from "components/TableProjectClassDay";
import { formatDate } from "helpers/formatDate";
import generateSearchQuery from "helpers/generateSearchQuery";
import useApi, { IRequestPayload } from "hooks/useApi";
import React, { useCallback, useMemo, useState } from "react";
import { InputType } from "utils/enums/InputType";
import { TableColumnType } from "utils/enums/TableColumnType";
import { IInputField } from "utils/interfaces/IInputField";
import { ITableColumn } from "utils/interfaces/ITable";
import { dateSchema } from "utils/schemas/date";
import { multipleSelectSchema } from "utils/schemas/select";
import { object } from "yup";
import Reports from ".";
import { formatReportBody } from "./utils/formatBody";
import useDownload from "./utils/useDownload";

const ReportClasses: React.FC = () => {
    const [isDownload, setIsDownload] = useState(false);

    const {
        loading: isLoadingDownload,
        pdf,
        request: startDownload,
    } = useDownload();

    const {
        request,
        response,
        loading: isLoadingData,
        error,
    } = useApi<{
        featuredNumbers: any;
        report: any[];
    }>();

    const requestItems = useCallback(
        (data: any) => {
            const body = formatReportBody(data);

            const payload: IRequestPayload = {
                endpoint: "/admin/reports/classes",
                method: "post",
                body,
            };

            if (isDownload) {
                startDownload({
                    ...payload,
                    endpoint: `${payload.endpoint}/pdf`,
                });
                return setIsDownload(false);
            }

            request(payload);
            setIsDownload(false);
        },
        [startDownload, isDownload, request]
    );

    const columns: ITableColumn[] = [
        {
            name: "",
            type: TableColumnType.Custom,
            label: "Data",
            component: (item: any) => {
                const { startTime, endTime, day } = item || {};

                const formattedDay = formatDate(day);

                return (
                    <TableProjectClassDay
                        days={formattedDay}
                        start_time={startTime?.slice(11, 16)}
                        end_time={endTime?.slice(11, 16)}
                    />
                );
            },
        },

        {
            name: "region.name",
            type: TableColumnType.Text,
        },
        {
            name: "project.name",
            type: TableColumnType.Text,
        },
        {
            name: "schoolClass.name",
            type: TableColumnType.Text,
        },
        {
            name: "status.name",
            type: TableColumnType.Text,
        },
        {
            name: "presence",
            type: TableColumnType.Custom,
            component: (item: any) => {
                const { total, totalPresent, percentage } =
                    item?.presence || {};

                if (!item?.presence) {
                    return null;
                }

                return (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <span>
                            <strong>{totalPresent}</strong>/{total}
                        </span>
                        <span>{percentage}%</span>
                    </div>
                );
            },
        },
    ].map((i: any) => ({ ...i, removeSearch: true, col: i?.col || "auto" }));

    const fields: IInputField[] = [
        {
            name: "regions",
            graphQL: {
                searchQuery: generateSearchQuery({ tableName: "region" }),
            },
            type: InputType.AsyncSelect,
            isMulti: true,
            grid: {
                md: 4,
            },
        },
        {
            name: "projects",
            graphQL: {
                searchQuery: generateSearchQuery({
                    tableName: "project",
                    companyPath: "company_id",
                }),
            },
            type: InputType.AsyncSelect,
            isMulti: true,
            grid: {
                md: 4,
            },
        },
        {
            name: "status",
            graphQL: {
                searchQuery: generateSearchQuery({
                    tableName: "project_class_day_status",
                }),
            },
            type: InputType.AsyncSelect,
            isMulti: true,
            grid: {
                md: 4,
            },
        },
        {
            name: "startDate",
            type: InputType.Date,
            required: false,
            grid: {
                md: 3,
            },
        },
        {
            name: "endDate",
            type: InputType.Date,
            required: false,
            grid: {
                md: 3,
            },
        },
    ];

    const schema: any = object().shape({
        regions: multipleSelectSchema(),
        projects: multipleSelectSchema(),
        status: multipleSelectSchema(),
        startDate: dateSchema(),
        endDate: dateSchema(),
    });

    const featuredNumbers = useMemo(() => {
        if (!response) {
            return [];
        }

        const items = [
            {
                title: "Presença",
                values: [
                    `${response.featuredNumbers.presencePercentage || 0}%`,
                    response.featuredNumbers.numberOfStudentsPresent,
                ],
            },
            {
                title: "Aulas",
                values: [response.featuredNumbers.numberOfClasses || 0],
            },
        ];

        const statuses =
            response.featuredNumbers.status.map((item: any) => ({
                title: item.name,
                values: [
                    item.value,
                    ...(Boolean(item.percentage)
                        ? [`${item.percentage}%`]
                        : []),
                ],
            })) || [];

        return [...items, ...statuses];
    }, [response]);

    return (
        <Reports
            {...{
                columns,
                fields,
                schema,
                error,
                pdf,
                isLoadingData,
                isLoadingDownload,
                featuredNumbers,
            }}
            report={response?.report}
            onSubmit={requestItems}
            onClickDownload={() => setIsDownload(true)}
        />
    );
};

export default ReportClasses;
