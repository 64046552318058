import React from "react";
import { object, string, date } from "yup";
import { dictionary } from "../../config/dictionary";
import CrudList from "../../pages/CRUD/List";
import ProjectClass from "../../pages/ProjectClass";
import ProjectClassDetails from "../../pages/ProjectClass/details";
import ProjectClassDayDetails from "../../pages/ProjectClassDay/details";
import { InputType } from "../../utils/enums/InputType";
import { TableColumnType } from "../../utils/enums/TableColumnType";
import { IRoute } from "../../utils/interfaces/IRoute";
import { ITableColumn } from "../../utils/interfaces/ITable";
import generateSearchQuery from "helpers/generateSearchQuery";
import DateService from "services/date";
import { multipleSelectSchema, selectSchema } from "utils/schemas/select";
import { dateSchema } from "utils/schemas/date";
import { convertWeekDaysToString } from "helpers/convertWeekDaysToString";
import { IInputField } from "utils/interfaces/IInputField";
import TableProjectClassDay from "components/TableProjectClassDay";

const addOneDay = (startDate: any) => {
    const date = DateService.fromJSDate(startDate as Date);

    if (!date.isValid) {
        console.log("date invalid");
        return new Date();
    }

    return date.plus({ day: 1 }).toJSDate();
};

export const projectClassSchema = ({ isEdit = false }) =>
    object().shape({
        name: string()
            .typeError(dictionary.validations.required)
            .required(dictionary.validations.required),
        project: selectSchema(),
        modality: selectSchema(),
        startDate: isEdit
            ? dateSchema()
            : dateSchema({
                  value: DateService.fromJSDate(new Date())
                      .minus({ day: 1 })
                      .toJSDate(),
                  message: "Data precisa ser maior ou igual a hoje.",
              }),
        endDate: dateSchema().when("startDate", (startDate, schema) => {
            return date().min(addOneDay(startDate));
        }),
        startTimeString: string()
            .typeError(dictionary.validations.required)
            .required(dictionary.validations.required),
        endTimeString: string()
            .typeError(dictionary.validations.required)
            .required(dictionary.validations.required),
        weekDays: multipleSelectSchema().required(
            dictionary.validations.required
        ),
    });

export const projectClassesFields = (
    hasProject = false,
    projectId?: number
) => {
    return [
        {
            name: "name",
            type: InputType.Text,
            required: true,
            grid: {
                col: 6,
            },
        },
        {
            name: "project",
            type: InputType.AsyncSelect,
            required: true,
            isClearable: false,
            grid: {
                col: 6,
            },
            graphQL: {
                searchQuery: generateSearchQuery({
                    tableName: "project",
                    companyPath: "company_id",
                }),
            },
            disabled: !!hasProject,
        },
        {
            name: "weekDays",
            type: InputType.WeekDays,
            required: true,
            grid: {
                col: 6,
            },
            isMulti: true,
        },
        {
            name: "modality",
            type: InputType.AsyncSelect,
            required: true,
            isClearable: false,
            grid: {
                col: 6,
            },
            graphQL: {
                searchQuery: !!projectId
                    ? `query ($searchText: String, $projectId: Int!) {
                        items: modality(limit: 10, where: {name: {_ilike: $searchText}, project_modalities: {project_id: {_eq: $projectId}}}, order_by: {name: asc}) {
                          value: id
                          label: name
                        }
                        quantity: modality_aggregate(where: {name: {_ilike: $searchText}, project_modalities: {project_id: {_eq: $projectId}}}) {
                          aggregate {
                            count
                          }
                        }
                      }
                    `
                    : generateSearchQuery({
                          tableName: "modality",
                      }),

                ...(!!projectId && {
                    defaultSearch: {
                        projectId,
                    },
                }),
            },
        },
        {
            name: "startDate",
            type: InputType.Date,
            required: true,
            grid: {
                col: 6,
            },
        },
        {
            name: "endDate",
            type: InputType.Date,
            required: true,
            grid: {
                col: 6,
            },
        },
        {
            name: "startTimeString",
            type: InputType.Time,
            required: true,
            grid: {
                col: 6,
            },
        },
        {
            name: "endTimeString",
            type: InputType.Time,
            required: true,
            grid: {
                col: 6,
            },
        },
        {
            name: "setups.[0].enabled",
            type: InputType.Switch,
            grid: {
                col: 6,
            },
            label: "Realizar presença no início da aula",
        },
        {
            name: "setups.[1].enabled",
            type: InputType.Switch,
            grid: {
                col: 6,
            },
            label: "Enviar arquivos para finalizar a aula",
        },
        {
            name: "setups.[2].enabled",
            type: InputType.Switch,
            grid: {
                col: 6,
            },
            label: "Obrigar geolocalização para iniciar a aula",
        },
        {
            name: "setups.[3].enabled",
            type: InputType.Switch,
            grid: {
                col: 6,
            },
            label: "Obrigar geolocalização para finalizar a aula",
        },
    ] as IInputField[];
};

export const projectClassColumns: ITableColumn[] = [
    {
        col: "auto",
        name: "name",
        type: TableColumnType.Text,
    },
    {
        col: "auto",
        name: "project.name",
        type: TableColumnType.Text,
    },
    {
        col: "auto",
        name: "project.professional.person.name",
        type: TableColumnType.Text,
        label: "Profissional",
    },

    {
        col: "auto",
        name: "",
        type: TableColumnType.Custom,
        label: dictionary.weekDays,
        component: ({ week_days, start_time, end_time }: any) => {
            const days = convertWeekDaysToString(
                week_days,
                dictionary.shortWeekDays
            );

            return (
                <TableProjectClassDay
                    days={days}
                    start_time={start_time?.slice(0, 5)}
                    end_time={end_time?.slice(0, 5)}
                />
            );
        },
    },
    {
        col: 1,
        name: "number_of_students",
        type: TableColumnType.Number,
        label: "Alunos",
        removeSearch: true,
    },
    {
        col: 1,
        name: "days_aggregate.aggregate.count",
        type: TableColumnType.Number,
        removeSearch: true,
        label: "Aulas",
    },
];

export const projectClassesRoutes: IRoute[] = [
    {
        title: "Turmas",
        path: "/turmas",
        exact: true,
        graphql: {
            companyPath: "project.company_id",
            tableName: "project_class",
            getAll: `query ($orderBy: [project_class_order_by!], $where: project_class_bool_exp, $limit: Int, $offset: Int) {
                items: project_class(where: $where, order_by: $orderBy, limit: $limit, offset: $offset) {
                  id
                  name
                  project {
                    name
                    professional {
                        person {
                            name
                            file {
                                filename
                                path
                            }
                            user {
                                email
                            }
                        }
                    }
                  }
                  week_days
                  number_of_students
                  days_aggregate {
                    aggregate {
                      count
                    }
                  }
                  start_time
                  end_time
                }
                quantity: project_class_aggregate(where: $where) {
                  aggregate {
                    count
                  }
                }
              }
              `,
        },
        mainColumn: "id",
        component: CrudList,
        canEdit: true,
        canSee: true,
        canDelete: true,
        canCreate: true,
        columns: projectClassColumns,
        showTotal: true,
    },
    {
        title: "Nova Turma",
        path: "/turmas/novo",

        component: ProjectClass,
        mainColumn: "id",
        exact: true,
    },
    {
        title: "Editar Turma",
        path: "/turmas/editar/:id",
        component: ProjectClass,
        exact: true,
        mainColumn: "id",
    },
    {
        title: "Visualizar Turma",
        path: "/turmas/ver/:id",
        component: ProjectClassDetails,
        exact: true,
        mainColumn: "id",
    },
    {
        title: "Aula",
        path: "/turmas/ver/:id/aula/:classDayId",
        component: ProjectClassDayDetails,
        exact: true,
        mainColumn: "id",
    },
];
