import { ProfessionalPosition } from "utils/enums/ProfessionalPosition";
import { TableColumnType } from "utils/enums/TableColumnType";
import { ITableColumn } from "utils/interfaces/ITable";

export const professionalColumns: ITableColumn[] = [
    {
        col: 4,
        name: "person",
        type: TableColumnType.Person,
    },
    {
        col: 4,
        name: "person.document",
        type: TableColumnType.Text,
    },
    {
        col: 3,
        name: "professional_position_id",
        type: TableColumnType.Enum,
        enumValue: ProfessionalPosition,
    },
];
