import styled from "styled-components";
import { fadeInUp } from "../../assets/animations";
import { Card } from "../../components/Card";
import { theme } from "../../config/theme";

export const LoginContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    background: ${theme.colors.main};
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .cardexit-enter {
        opacity: 1;
        transform: scale(0.9);
    }
    .cardexit-enter-active {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 400ms, transform 400ms;
    }

    .cardexit-exit {
        opacity: 1;
    }

    .cardexit-exit-active {
        opacity: 0;
        transform: scale(0.9);
        transition: opacity 400ms, transform 400ms;
    }
`;

export const LoginContent = styled.section`
    animation: ${fadeInUp} 0.8s ease;

    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

    input {
        width: 100%;
    }

    .logo-full {
        margin-bottom: 40px;
        height: auto;
        width: 250px;
        z-index: 1;
    }

    ${Card} {
        z-index: 1;
        padding: 25px;

        width: 350px;
    }
`;
