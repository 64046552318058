const removeEmptyStrings = (data: any) => {
    return Object.keys(data).reduce((accumulator: any, currentValue) => {
        accumulator = {
            ...accumulator,
            [currentValue]:
                (data as any)[currentValue] === ""
                    ? null
                    : (data as any)[currentValue],
        };

        return accumulator;
    }, {});
};

export default function removeObjectEmptyValues(data: any) {
    if (Array.isArray(data)) {
        return data.map((item) => removeEmptyStrings(item));
    }

    return removeEmptyStrings(data);
}
