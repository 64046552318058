import { TableColumnType } from "../utils/enums/TableColumnType";

import _get from "lodash/get";
import _set from "lodash/set";
import DateService from "services/date";

export default function convertToGraphQLSearch(data?: object) {
    const formatSearch = {};

    if (!data) {
        return;
    }

    Object.keys(data).forEach((itemKey) => {
        let key = itemKey.replace("[0]", ""); // resolve multiple column bug

        const search = _get(data, itemKey);

        if (search?.value === null || search?.value === undefined) {
            return;
        }

        switch (search.type) {
            case TableColumnType.Number:
            case TableColumnType.Status:
            case TableColumnType.Enum:
            case TableColumnType.Availation:
            case TableColumnType.Boolean:
                return _set(formatSearch, key, {
                    _eq: search.value,
                });

            case TableColumnType.AsyncSelect:
                return _set(formatSearch, key, {
                    id: {
                        _eq: search.value,
                    },
                });

            case TableColumnType.Person:
                return _set(formatSearch, key, {
                    _or: [
                        {
                            name: {
                                _ilike: `%${search.value}%`,
                            },
                        },
                        {
                            user: {
                                email: {
                                    _ilike: `%${search.value}%`,
                                },
                            },
                        },
                    ],
                });

            case TableColumnType.Date:
            case TableColumnType.DateWithTime:
                // Transforms '2022-05-11' into '20220511'
                const formatValue = search.value?.replace(/[^\w\s]/gi, "");

                return _set(formatSearch, key, {
                    _gte: formatValue,
                    _lte: DateService.fromISO(search.value)
                        .plus({
                            day: 1,
                        })
                        .toFormat("yyyyMMdd"),
                });

            default:
                return _set(formatSearch, key, {
                    _ilike: `%${search.value}%`,
                });
        }
    });

    // return {
    //     _or: formatSearch,
    // };

    return formatSearch;
}
