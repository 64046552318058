import { dictionary } from "config/dictionary";
import { number, object, string } from "yup";

export const addressSchema = object().shape({
    street: string()
        .typeError(dictionary.validations.required)
        .required(dictionary.validations.required),
    number: number()
        .typeError(dictionary.validations.required)
        .required(dictionary.validations.required),
    complement: string()
        .transform((value) => value ?? "")
        .typeError(dictionary.validations.required)
        .default(""),
    neighbourhood: string()
        .typeError(dictionary.validations.required)
        .required(dictionary.validations.required),
    state: string()
        .typeError(dictionary.validations.required)
        .required(dictionary.validations.required),
    city: string()
        .typeError(dictionary.validations.required)
        .required(dictionary.validations.required),
    zip: string()
        .typeError(dictionary.validations.required)
        .required(dictionary.validations.required),
});
