import { AvatarContainer } from "components/Avatar/styles";
import styled from "styled-components";

export const CenteredProfileDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;

    ${AvatarContainer} {
        margin-bottom: 15px;
    }
`;
