import styled from "styled-components";
import { ToggleSwitchElement } from "../../../../components/Form/ToggleSwitch/styles";
import { theme } from "../../../../config/theme";

export const StudentSubscriptionItem = styled.div`
    display: flex;
    align-items: center;
    padding: 20px 0;

    ${ToggleSwitchElement} {
        margin-right: 15px;
    }
`;

export const StudentSubscriptionListContainer = styled.div`
    display: flex;
    flex-direction: column;

    ${StudentSubscriptionItem} {
        :not(:last-child) {
            border-bottom: 1px solid ${theme.colors.mainLight};
        }
    }
`;
