import React, { memo, useMemo } from "react";
import { theme } from "../../config/theme";
import Button from "../Button";
import { Card } from "../Card";
import { Container } from "../Grid";
import CloseIcon from "../Icons/close";
import CustomText from "../Text";

import * as S from "./styles";
import ModalElement from "./elements";
import { ModalElements } from "../../utils/enums/ModalElements";

import useKeyDown from "../../hooks/useKeyDown";
import { IBaseModalElement } from "../../utils/interfaces/IBaseModalElement";
import { ISizing } from "../../utils/interfaces/ISizing";

interface IProps extends IBaseModalElement, ISizing {
    title: string;
    element?: ModalElements;
    center?: boolean;
    removeCloseButton?: boolean;
    isOpen: boolean;
    children?: React.ReactChild;
    contentBackground?: string;
}

const ModalComponent = ({
    title,
    element,
    action,
    width,
    height,
    center = false,
    removeCloseButton = false,
    isOpen,
    onClose,
    children,
    contentBackground,
}: IProps) => {
    const isModalOpen = useMemo(() => !!isOpen, [isOpen]);

    useKeyDown(() => isModalOpen && onClose());

    if (!isModalOpen) {
        return null;
    }

    return (
        <>
            <S.ModalBackground onClick={onClose}>
                <S.ModalScrolling center={center}>
                    <Container
                        width={width}
                        height={height}
                        onClick={(e: any) => e.stopPropagation()}
                    >
                        <Card>
                            <S.ModalHeader withTitle={!!title}>
                                {!!title && (
                                    <CustomText
                                        color={theme.colors.black}
                                        size={17}
                                        weight="SemiBold"
                                    >
                                        {title}
                                    </CustomText>
                                )}

                                {!removeCloseButton && (
                                    <Button variant="light" onClick={onClose}>
                                        <CloseIcon
                                            width={12}
                                            height={12}
                                            fill={theme.colors.placeholder}
                                        />
                                    </Button>
                                )}
                            </S.ModalHeader>

                            <S.ModalBody background={contentBackground}>
                                {!!children ? (
                                    children
                                ) : element ? (
                                    <ModalElement
                                        element={element}
                                        action={action}
                                        onClose={onClose}
                                    />
                                ) : null}
                            </S.ModalBody>
                        </Card>
                    </Container>
                </S.ModalScrolling>
            </S.ModalBackground>
        </>
    );
};

export default memo(ModalComponent);
