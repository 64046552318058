import { object, string } from "yup";
import { dictionary } from "../../config/dictionary";
import { addressSchema } from "./address";
import { documentSchema } from "./document";
import { selectSchema } from "./select";

const initialOptions = {
    withUser: false,
    withAdress: false,
};

export const personSchema = (options = initialOptions) => {
    const { withAdress = false, withUser = false } = options || {};

    return object().shape({
        document: documentSchema,
        name: string()
            .typeError(dictionary.validations.required)
            .required(dictionary.validations.required),
        birthday: string()
            .typeError(dictionary.validations.required)
            .required(dictionary.validations.required),
        phone: string()
            .typeError(dictionary.validations.required)
            .required(dictionary.validations.required),
        gender: selectSchema().required(dictionary.validations.required),

        ...(withUser && {
            user: object().shape({
                email: string()
                    .email(dictionary.validations.email)
                    .typeError(dictionary.validations.required)
                    .required(dictionary.validations.required),
                // username: string()
                //     .typeError(dictionary.validations.required)
                //     .required(dictionary.validations.required),
            }),
        }),

        ...(withAdress && {
            address: addressSchema,
        }),
    });
};
