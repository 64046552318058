import React, { useEffect, useCallback, useMemo } from "react";
import { IBaseRouteProps } from "../../utils/interfaces/IBaseRouteProps";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IReduxStore } from "../../store/ducks";
import { Creators as ProfessionalActions } from "../../store/professional";
import {
    IProfessionalActions,
    IProfessionalState,
} from "../../store/professional/types";

import Spinner from "../../components/Spinner";
import ListTabData, { IListTabDataConfig } from "../../components/ListTabData";
import { TableColumnType } from "../../utils/enums/TableColumnType";
import { useModal } from "../../hooks/useModal";
import { IListDataProps } from "../../components/ListData";
import { projectClassColumns } from "../../routes/entities/projectClasses";
import PlusIcon from "../../components/Icons/plus";
import { theme } from "../../config/theme";
import { ModalElements } from "../../utils/enums/ModalElements";
import { useCrud } from "../../hooks/useCrud";
import { createProjectClassProfessionalQuery } from "../../queries/projectClass/createProjectClassProfessional";

import history from "../../services/history";
import ProfessionalDetails from "./details";

import ProfessionalsHeader from "./header";
import { ProfessionalPosition } from "utils/enums/ProfessionalPosition";
import { useHeaderTitle } from "hooks/useHeaderTitle";

interface IProps extends IBaseRouteProps {
    professionalActions: IProfessionalActions;
    professional?: IProfessionalState;
}

const Professional = ({
    professionalActions,
    match: { params },
    professional,
}: IProps) => {
    const { id } = params;
    const {
        loading,
        id: teacherId,
        person,
        stats,
        position,
    } = professional || {};

    useHeaderTitle(person?.name);

    const { open: openModal, close: closeModal } = useModal();
    const { createOrEdit, addListItem } = useCrud();

    useEffect(() => {
        return () => {
            professionalActions.clearProfessional();
        };
    }, [professionalActions]);

    const request = useCallback(() => {
        professionalActions.getProfessionalByIdRequest({
            id: ~~id,
            isDetailScreen: true,
        });
    }, [id, professionalActions]);

    useEffect(() => {
        request();
    }, [request]);

    const handleClickAddSchoolClass = useCallback(() => {
        const action: IListDataProps = {
            contentId: `project_class_search`,
            columns: projectClassColumns,
            graphql: {
                tableName: "project_class",
            },
            mainColumn: "id_project_class",
            customActions: [
                {
                    icon: () => (
                        <PlusIcon
                            width={12}
                            height={12}
                            fill={theme.colors.success}
                        />
                    ),
                    onClick: ({ id_project_class }) =>
                        createOrEdit({
                            body: {
                                id_professional: ~~id,
                                id_project_class,
                            },
                            query: createProjectClassProfessionalQuery,
                            onCreate: (item) => {
                                addListItem({
                                    item,
                                    contentId: "project_class_professional",
                                });
                                closeModal();
                            },
                        }),
                },
            ],
            defaultSearch: {
                _not: {
                    project_class_professionals: {
                        id_professional: {
                            _eq: ~~id,
                        },
                    },
                },
            },
        };

        openModal({
            element: ModalElements.List,
            action,
            title: `Buscar núcleo`,
        });
    }, [addListItem, closeModal, createOrEdit, id, openModal]);

    const listTabDataConfig = useMemo<IListTabDataConfig[]>(() => {
        const isCoordinator = position?.id === ProfessionalPosition.Coordinator;

        const columns: IListTabDataConfig[] = [
            {
                title: "Detalhes",
                columns: [],
                component: <ProfessionalDetails />,
            },
            {
                title: "Núcleos",
                canCreate: false,
                canDelete: false,
                canEdit: false,
                canSee: true,
                contentId: "project",
                showDeleted: true,
                mainColumn: isCoordinator ? "project_id" : "id",
                columns: [
                    {
                        col: 6,
                        name: isCoordinator ? "project.name" : "name",
                        type: TableColumnType.Text,
                    },
                ],
                graphql: {
                    tableName: isCoordinator
                        ? "project_professional"
                        : "project",
                    orderBy: {
                        ...(isCoordinator
                            ? {
                                  project: {
                                      name: "asc",
                                  },
                              }
                            : {
                                  name: "asc",
                              }),
                    },
                },
                defaultSearch: {
                    professional_id: {
                        _eq: id,
                    },
                },
                onClickCreate: handleClickAddSchoolClass,
                path: "/nucleos",
            },
        ];

        if (!isCoordinator) {
            columns.push(
                {
                    title: "Turmas",
                    canCreate: false,
                    canDelete: false,
                    canEdit: false,
                    canSee: true,
                    contentId: "project_class_professional",
                    showDeleted: true,
                    columns: [
                        {
                            col: 6,
                            name: "name",
                            type: TableColumnType.Text,
                        },
                    ],
                    graphql: {
                        tableName: "project_class",
                        orderBy: {
                            name: "asc",
                        },
                    },
                    mainColumn: "id",
                    defaultSearch: {
                        professional_id: {
                            _eq: ~~id,
                        },
                    },
                    path: "/turmas",
                },
                {
                    title: "Alunos",
                    canCreate: false,
                    canDelete: false,
                    canEdit: false,
                    canSee: true,
                    contentId: "subscription",
                    showDeleted: true,
                    columns: [
                        {
                            col: 7,
                            name: "student.person",
                            type: TableColumnType.Person,
                            label: "Aluno",
                        },
                    ],
                    graphql: {
                        tableName: "subscription",
                        getAll: `query ($orderBy: [subscription_order_by!], $where: subscription_bool_exp, $limit: Int, $offset: Int) {
                            items: subscription(where: $where, order_by: $orderBy, limit: $limit, offset: $offset, distinct_on: [student_id]) {
                              id
                              student {
                                id
                                person {
                                  name
                                  file {
                                    path
                                    filename
                                  }
                                }
                              }
                            }
                            quantity: subscription_aggregate(where: $where) {
                              aggregate {
                                count
                              }
                            }
                          }
                          `,
                    },
                    mainColumn: "id",
                    defaultSearch: {
                        project_class: {
                            professional_id: {
                                _eq: ~~id,
                            },
                        },
                    },
                    path: "/alunos",
                    onClickSeeDetails: ({ student }) => {
                        history.push(`/app/alunos/ver/${student?.id}`);
                    },
                }
            );
        }

        return columns;
    }, [handleClickAddSchoolClass, id, position]);

    return (
        <div>
            {loading && <Spinner fullScreen />}

            <ProfessionalsHeader
                position={position?.id as ProfessionalPosition}
                person={person}
                stats={stats}
            />

            {!!teacherId && <ListTabData configs={listTabDataConfig} />}
        </div>
    );
};

const mapStateToProps = ({ professional }: IReduxStore) => ({
    professional,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    professionalActions: bindActionCreators(ProfessionalActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Professional);
