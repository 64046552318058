import styled from "styled-components";
import { theme } from "../../../config/theme";

export const ToggleSwitchElement = styled.label`
    position: relative;
    display: inline-block;
    width: 50px;
    height: 26px;

    outline: none;

    * {
        outline: none;
    }

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgb(143, 155, 179, 0.35);
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border: 1px solid ${theme.colors.placeholder};
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 3px;
        bottom: 2px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    input:checked + .slider {
        background-color: ${theme.colors.main};
        border-color: ${theme.colors.mainDark};
    }

    input:focus + .slider {
        /* box-shadow: 0 0 1px ${theme.colors.main}; */
    }

    input:checked + .slider:before {
        transform: translateX(23px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
`;
