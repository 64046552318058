import { useCallback, useState } from "react";
import _get from "lodash/get";
import { toast } from "react-toastify";
import api from "services/api";

export interface IRequestPayload {
    endpoint: string;
    method: "post" | "get" | "delete" | "patch";
    body?: any;
    errorMessage?: string;
}

interface IReturn<T> {
    loading: boolean;
    error: boolean;
    response: T;
    request(payload: IRequestPayload): void;
}

const useApi: <T>() => IReturn<T> = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [response, setResponse] = useState<any>();

    const request = useCallback(async (payload: IRequestPayload) => {
        try {
            setError(false);
            setLoading(true);

            const { method, endpoint, body } = payload;

            const { data } = await api[method](endpoint, body);

            if (!data) {
                throw new Error();
            }

            setResponse(data);

            setError(false);
        } catch (error) {
            console.log((error as any).request, (error as any).response);

            const errorMessage = _get(
                error,
                "response.data.title",
                payload?.errorMessage || "Desculpe, tivemos um problema."
            );

            setResponse(undefined);

            setError(true);

            toast.error(errorMessage);
        } finally {
            setLoading(false);
        }
    }, []);

    return { loading, error, response, request };
};

export default useApi;
