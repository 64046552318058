import FormFactory from "components/Form/FormFactory";
import Spinner from "components/Spinner";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { useFormZipCode } from "hooks/useFormZipCode";
import React, { useCallback, useEffect } from "react";
import { providersFields, providersSchema } from "routes/entities/providers";
import { Creators } from "store/provider";

interface IProps {
    id?: number;
}

const ProviderForm = ({ id }: IProps) => {
    const state = useAppSelector(({ provider }) => provider);
    const { loading, ...formState } = state || {};
    const dispatch = useAppDispatch();

    const { handleChangeZipCode, isZipCodeLoading } = useFormZipCode(
        "responsible.address"
    );

    const getProfessional = useCallback(() => {
        if (!id) {
            return;
        }

        dispatch(Creators.getProviderByIdRequest({ id }));
    }, [dispatch, id]);

    useEffect(() => {
        getProfessional();
    }, [getProfessional]);

    useEffect(() => {
        return () => {
            dispatch(Creators.clearProvider());
        };
    }, [dispatch]);

    const handleSubmit = useCallback(
        (data: any) => {
            console.log(data);

            dispatch(Creators.createOrEditProviderRequest({ id, body: data }));
        },
        [dispatch, id]
    );

    const initialData = {
        ...(!!state?.id && formState),
    };

    const showLoading = !!id && loading && !state?.id;

    if (showLoading) {
        return <Spinner />;
    }

    return (
        <FormFactory
            fields={providersFields}
            onSubmit={handleSubmit}
            schema={providersSchema}
            isLoading={!!loading || isZipCodeLoading}
            initialData={initialData}
            watchers={["responsible.address.zip"]}
            onChangeWatchers={handleChangeZipCode}
        />
    );
};

export default ProviderForm;
