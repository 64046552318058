import { put, takeLatest, call } from "redux-saga/effects";
import { ISagaAction } from "../../utils/interfaces/ISagaAction";

import { toast } from "react-toastify";
import history from "../../services/history";

import { IYield } from "utils/interfaces/IYield";
import graphql from "../../services/graphql";
import { getProjectClassDayByIdQuery } from "queries/projectClassDay/getById";
import { Creators, Types } from ".";

function* getById(
    action: ISagaAction<{
        classDayId: number | string;
    }>
): IYield {
    try {
        if (!action?.payload?.classDayId) {
            throw new Error();
        }

        const { data } = yield call(
            graphql,
            getProjectClassDayByIdQuery,
            action?.payload
        );

        if (!data?.item?.id) {
            throw new Error();
        }

        yield put(Creators.getProjectClassDayByIdSuccess(data.item));
    } catch (error) {
        console.log("error", error);

        yield call(toast.error, "Desculpe, tivemos um problema.");

        yield put(Creators.getProjectClassDayByIdFailure());

        yield call(history.push, { pathname: "/app/turmas" });
    }
}

export default [takeLatest(Types.GET_PROJECT_CLASS_DAY_BY_ID_REQUEST, getById)];
