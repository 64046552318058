import React, { memo } from "react";
import { theme } from "../../config/theme";
import { IIconProps } from "../../utils/interfaces/IIconProps";

const SearchIcon = memo(
    ({ fill = theme.colors.black, ...props }: IIconProps) => {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                {...props}
                viewBox="0 0 15 15"
            >
                <path
                    d="M14.167,15a.828.828,0,0,1-.589-.245l-2.83-2.829a6.592,6.592,0,0,1-4.081,1.407,6.667,6.667,0,1,1,6.667-6.667,6.591,6.591,0,0,1-1.407,4.081l2.829,2.83A.834.834,0,0,1,14.167,15ZM6.667,1.666a5,5,0,1,0,5,5A5.006,5.006,0,0,0,6.667,1.666Z"
                    transform="translate(0)"
                    fill={fill}
                />
            </svg>
        );
    }
);

export default SearchIcon;
