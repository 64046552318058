import styled from "styled-components";
import { theme } from "../../config/theme";
import { generateSpacingStyles } from "../../helpers/generateSpacingStyles";
import { FontWeight } from "../../utils/enums/FontWeight";
import { ISpacing } from "../../utils/interfaces/ISpacing";

const colors = theme.colors.components.tab;

export const TabContainer = styled.div<ISpacing>`
    display: flex;
    width: 100%;
    align-items: center;
    overflow-x: auto;

    ${(props) => generateSpacingStyles(props)};
`;

export const TabItem = styled.button.attrs({ type: "button" })<{
    active: boolean;
}>`
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    outline: none;
    color: ${({ active }) => (active ? colors.colorActive : colors.color)};
    font-weight: ${FontWeight.Regular};
    font-size: 15px;
    padding: 18px;
    border-top: 2px solid
        ${({ active }) => (active ? `${theme.colors.main}` : "transparent")};

    white-space: nowrap;

    :hover {
        color: ${colors.colorHover};
    }

    :disabled {
        cursor: not-allowed;
    }
`;
