import styled, { css } from "styled-components";
import { theme } from "../../config/theme";
import { FontWeight } from "../../utils/enums/FontWeight";
import { generateSpacingStyles } from "../../helpers/generateSpacingStyles";
import { IFormProps } from ".";

const colors = theme.colors.components.form;

export const FormLabel = styled.label`
    font-weight: ${FontWeight.Regular};
    color: ${colors.label.color};
    font-size: 16px;
    margin-bottom: 10px;
`;

export const FormElementContainer = styled.div<{ hidden?: boolean }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;

    ${({ hidden }) =>
        hidden &&
        css`
            display: none;
        `};

    > input {
        width: 100%;
    }
`;

export const BaseFormElementStyle = css`
    border: 2px solid ${colors.input.borderColor};
    color: ${colors.input.color};
    background-color: ${colors.input.backgroundColor};
    border-radius: 8px;
    width: 100%;

    ${(props: any) =>
        props.error &&
        css`
            border: 2px solid ${theme.colors.danger};
        `};

    ::placeholder {
        color: #aeb3cd;
    }

    :focus,
    :hover {
        outline: none;
        border: 2px solid ${theme.colors.placeholder};
    }
`;

export const FormElement = styled.form<IFormProps>`
    display: flex;

    ${(props) => generateSpacingStyles(props)};

    ${({ column }) =>
        !!column
            ? css`
                  flex-direction: column;
              `
            : css`
                  flex-direction: row;
                  align-items: center;
              `};
`;

export const FormError = styled.span`
    color: ${theme.colors.danger};
    font-weight: ${FontWeight.Regular};
    font-size: 14px;
    font-style: italic;
    margin-top: 6px;
`;
