import React, { memo } from "react";
import { ISpacing } from "../../utils/interfaces/ISpacing";

import * as S from "./styles";

export interface ITabItem {
    label: string;
    value: any;
    disabled?: boolean;
}

interface IProps extends ISpacing {
    items: ITabItem[];
    onClick(value: any): void;
    activeItem?: any;
}

const Tab = ({ items = [], onClick, activeItem, ...rest }: IProps) => {
    return (
        <S.TabContainer {...rest}>
            {items.map(({ value, label, disabled = false }, index) => (
                <S.TabItem
                    onClick={() => onClick(value)}
                    active={activeItem === value}
                    key={`tab_${index}`}
                    disabled={!!disabled}
                >
                    {label}
                </S.TabItem>
            ))}
        </S.TabContainer>
    );
};

export default memo(Tab);
