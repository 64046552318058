import React, { useMemo } from "react";

import { IPerson } from "utils/interfaces/IPerson";

import { IProfessionalStats } from "store/professional/types";
import { ProfessionalPosition } from "utils/enums/ProfessionalPosition";
import CenteredProfileDetails from "components/CenteredProfileDetails";
import ListCards, { IListCardsConfig } from "components/ListCards";
import { InputType } from "utils/enums/InputType";
import { IInputField } from "utils/interfaces/IInputField";

interface IProps {
    person?: IPerson;
    stats?: IProfessionalStats;
    position?: ProfessionalPosition;
}

const ProfessionalsHeader = ({ person, stats, position }: IProps) => {
    const listCardsConfig = useMemo<IListCardsConfig>(
        () => ({
            cards: [
                {
                    name: "projects",
                    type: InputType.Number,
                    grid: {
                        col: "auto",
                    },
                    label: "Núcleos",
                },
                {
                    name: "schoolClasses",
                    type: InputType.Number,
                    grid: {
                        col: "auto",
                    },
                    label: "Turmas",
                },
                {
                    name: "students",
                    type: InputType.Number,
                    grid: {
                        col: "auto",
                    },
                    label: "Alunos",
                },

                ...(position === ProfessionalPosition.Teacher
                    ? ([
                          {
                              name: "frequency.numberOfPresences",
                              type: InputType.Number,
                              grid: {
                                  col: "auto",
                              },
                              label: "Frequência",
                          },
                          {
                              name: "frequencyPercentage",
                              type: InputType.Text,
                              grid: {
                                  col: "auto",
                              },
                              label: "Aulas realizadas",
                          },
                      ] as IInputField[])
                    : []),
            ],
            defaultValue: {
                ...stats,
                frequencyPercentage: `${stats?.frequency?.average || 0}%`,
            },
        }),
        [position, stats]
    );

    return (
        <>
            <CenteredProfileDetails person={person} />

            {position !== ProfessionalPosition.Coordinator && (
                <ListCards {...listCardsConfig} />
            )}
        </>
    );
};

export default ProfessionalsHeader;
