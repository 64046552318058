// sampleReducer.js
import { createReducer, createActions } from "reduxsauce";
import { ProjectTypes, IProjectState, IProjectActionsCreators } from "./types";

export const { Creators, Types } = createActions<
    Record<ProjectTypes, ProjectTypes>,
    IProjectActionsCreators
>({
    createOrEditProjectRequest: ["payload"],
    createOrEditProjectSuccess: ["payload"],
    createOrEditProjectFailure: [],

    getProjectByIdRequest: ["payload"],
    getProjectByIdSuccess: ["payload"],
    getProjectByIdFailure: [],

    clearProject: [],
});

const INITIAL_STATE: IProjectState = {
    error: false,
    loading: false,
};

const request = (state = INITIAL_STATE, _: any) => {
    return { ...state, error: false, loading: true };
};

const failure = (state = INITIAL_STATE) => {
    return { ...state, error: true, loading: false };
};

const setState = (state = INITIAL_STATE, action: any) => ({
    ...state,
    loading: false,
    error: false,
    ...action.payload,
});

const clear = () => INITIAL_STATE;

const HANDLERS = {
    [Types.CREATE_OR_EDIT_PROJECT_REQUEST]: request,
    [Types.CREATE_OR_EDIT_PROJECT_SUCCESS]: setState,
    [Types.CREATE_OR_EDIT_PROJECT_FAILURE]: failure,

    [Types.GET_PROJECT_BY_ID_REQUEST]: request,
    [Types.GET_PROJECT_BY_ID_SUCCESS]: setState,
    [Types.GET_PROJECT_BY_ID_FAILURE]: failure,

    [Types.CLEAR_PROJECT]: clear,
};

export default createReducer(INITIAL_STATE, HANDLERS);
