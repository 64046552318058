import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Creators } from "../store/projectClass";
import {
    ICreateProjectClassPayload,
    IProjectClassState,
} from "../store/projectClass/types";
import { IReduxStore } from "../store/ducks";

export function useProjectClass() {
    const dispatch = useDispatch();

    const getById = useCallback(
        (payload: { id: number | string }) =>
            dispatch(Creators.getProjectClassByIdRequest(payload)),
        [dispatch]
    );

    const createOrEdit = useCallback(
        (payload: ICreateProjectClassPayload) =>
            dispatch(Creators.createOrEditProjectClassRequest(payload)),
        [dispatch]
    );

    const clear = useCallback(
        () => dispatch(Creators.clearProjectClass()),
        [dispatch]
    );

    const state = useSelector<IReduxStore, IProjectClassState | undefined>(
        (state) => state?.projectClass
    );

    return { createOrEdit, getById, state, clear };
}
