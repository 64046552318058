import styled, { css, keyframes } from "styled-components/macro";
import { theme } from "../../config/theme";
import { Card } from "../Card";

const backgroundAnimation = keyframes`
    from {
        background: transparent;
    }
    to {
        background: rgba(32, 33, 48, 0.7);
    }
`;

export const ModalBackground = styled.div`
    display: flex;

    z-index: 9;
    background: rgba(32, 33, 48, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* animation: ${backgroundAnimation} ease 0.4s; */

    .container {
        > ${Card} {
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
            padding: 0;
            position: relative;
        }
    }

    // TRANSITIONS STYLES
    .modal-enter {
        opacity: 0;
        transform: translateY(100vh);
    }
    .modal-enter-active {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 200ms, transform 200ms;
    }
    .modal-exit {
        opacity: 0;
    }
    .modal-exit-active {
        opacity: 0;
        /* transition: opacity 200ms, transform 200ms;
        transform: translateY(100vh); */
    }
`;

export const ModalHeader = styled.header<{ withTitle: boolean }>`
    display: flex;
    margin-bottom: 0;

    ${({ withTitle }) =>
        !!withTitle
            ? css`
                  padding: 15px 20px;
                  align-items: center;
                  justify-content: space-between;
                  border-bottom: 1px solid ${theme.colors.mainLight};

                  > button {
                      margin-right: -5px;
                  }
              `
            : css`
                  top: 0;
                  right: 0;
                  width: 45px;
                  position: absolute;
              `};

    > button {
        :hover {
            > svg {
                fill: ${theme.colors.mainDark};

                path {
                    fill: ${theme.colors.mainDark};
                }
            }
        }
    }
`;

export const ModalBody = styled.div<{ background?: string }>`
    padding: 20px;

    ${({ background }) =>
        background &&
        css`
            background: ${background};
        `};
`;

export const ModalScrolling = styled.div<{ center: boolean }>`
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: block;

    .container {
        max-width: 100%;
    }

    ${({ center }) =>
        center
            ? css`
                  display: flex;
                  align-items: center;
                  justify-content: center;
              `
            : css`
                  padding: 80px 0;
              `};
`;
