export const getResponsibleByIdQuery = `query ($id: Int!) {
    item: responsible_by_pk(id: $id) {
      id
      project_id: project {
        name
        id
      }
      person {
        id
        document
        name
        birthday
        gender
      }
    }
  }
  `;
