import { keyframes } from "styled-components";

export const fadeInUp = keyframes`
from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  `;

export const rotation = keyframes`
from { transform: rotate(0deg); } 
    to { transform: rotate(360deg); }
`;
