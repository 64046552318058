import React, { useCallback } from "react";
import { ToggleSwitchElement } from "./styles";

// import { Container } from './styles';

interface IProps {
    checked?: boolean;
    onChange?(active: boolean): void;
}

const ToggleSwitch: React.FC<IProps> = ({ onChange, checked }) => {
    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (!!onChange) {
                onChange(!!e?.target?.checked);
            }
        },
        [onChange]
    );
    return (
        <>
            <ToggleSwitchElement>
                <input
                    type="checkbox"
                    checked={checked}
                    onChange={handleChange}
                />
                <span className="slider round"></span>
            </ToggleSwitchElement>
        </>
    );
};

export default ToggleSwitch;
