import React from "react";
import { ColorsVariants } from "../../../utils/types/Variants";
import Button from "../../Button";

import * as S from "./styles";

export interface IModalAction {
    icon?: React.ReactNode;
    onClick(): void;
    text?: string;
    variant?: ColorsVariants;
}

interface IProps {
    actions: IModalAction[];
}

const ModalActions: React.FC<IProps> = ({ actions = [] }) => {
    return (
        <S.ModalActionsButtonGroup>
            {actions.map(({ onClick, icon, text, variant = "info" }, index) => (
                <Button
                    variant={variant}
                    type="button"
                    onClick={onClick}
                    key={`button_${index}`}
                >
                    {icon}
                    {text}
                </Button>
            ))}
        </S.ModalActionsButtonGroup>
    );
};

export default ModalActions;
