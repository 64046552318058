export const createResponsibleQuery = `mutation ($body: responsible_insert_input!) {
    item: insert_responsible_one(object: $body) {
      id
      project_id
      person {
        id
        document
        name
        birthday
        gender
      }
    }
  }`;
