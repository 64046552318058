import styled from "styled-components";

export const DownloadButtonContainer = styled.div`
    display: flex;
    align-items: center;

    button {
        margin-right: 10px;
    }
`;
