import CenteredProfileDetails from "components/CenteredProfileDetails";
import ListCards, { IListCardsConfig } from "components/ListCards";
import React, { useMemo } from "react";
import { IStudentStats } from "store/student/types";
import { InputType } from "utils/enums/InputType";
import { IPerson } from "utils/interfaces/IPerson";

interface IProps {
    person?: IPerson;
    id?: number;
    stats?: IStudentStats;
}

const StudentHeader = ({ person, id, stats }: IProps) => {
    console.log("stats", stats);

    const listCardsConfig = useMemo<IListCardsConfig>(
        () => ({
            cards: [
                {
                    name: "schoolClasses",
                    type: InputType.Number,
                    grid: {
                        col: "auto",
                    },
                    label: "Turmas",
                },
                {
                    name: "frequency.numberOfPresences",
                    type: InputType.Number,
                    grid: {
                        col: "auto",
                    },
                    label: "Aulas assistidas",
                },
                {
                    name: "frequency.average",
                    type: InputType.Text,
                    grid: {
                        col: "auto",
                    },
                    label: "Frequência",
                },
            ],
            defaultValue: {
                ...stats,
                frequency: {
                    ...stats?.frequency,
                    average: `${stats?.frequency?.average || 0}%`,
                },
            },
        }),
        [stats]
    );

    if (!id) {
        return null;
    }

    return (
        <>
            <CenteredProfileDetails person={person} />
            <ListCards {...listCardsConfig} />
        </>
    );
};

export default StudentHeader;
