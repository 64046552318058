import { IInputField } from "utils/interfaces/IInputField";
import { Genre } from "../enums/Genre";
import { InputType } from "../enums/InputType";
import { addressFields } from "./address";

const user = (pathName = "person") => [
    {
        name: `${pathName}.user.email`,
        type: InputType.Email,
        grid: {
            col: 6,
        },
    },
];

interface IOptions {
    withUser?: boolean;
    withAdress?: boolean;
    pathName?: string;
}

export const createPersonFields = (options?: IOptions) => {
    const {
        withAdress = false,
        withUser = false,
        pathName = "person",
    } = options || {};
    return [
        {
            name: `${pathName}.name`,
            type: InputType.Text,
            required: true,
            grid: {
                col: 6,
            },
        },

        ...(withUser ? user(pathName) : []),

        {
            name: `${pathName}.document`,
            type: InputType.Document,
            required: true,
            grid: {
                col: 3,
            },
        },

        {
            name: `${pathName}.birthday`,
            type: InputType.Date,
            grid: {
                col: 3,
            },
        },

        {
            name: `${pathName}.phone`,
            type: InputType.Phone,
            grid: {
                col: 3,
            },
        },

        {
            name: `${pathName}.gender`,
            type: InputType.Enum,
            enumValue: Genre,
            required: true,
            grid: {
                col: 3,
            },
        },

        ...(withAdress ? addressFields(pathName) : []),
    ] as IInputField[];
};

export const editPersonFields = (withUser = false) => [
    {
        name: "person.id",
        type: InputType.Hidden,
    },
    ...createPersonFields(),
    ...(withUser ? user() : []),
];
