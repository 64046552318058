import ReportClasses from "pages/Reports/classes";
import ReportProjects from "pages/Reports/projects";
import ReportProducts from "pages/Reports/products";
import { IRoute } from "utils/interfaces/IRoute";
import ReportProfessionals from "pages/Reports/professionals";
import ReportOrders from "pages/Reports/orders";

export const reportsRoutes: IRoute[] = [
    {
        title: "Relatório de aulas",
        path: "/relatorios/aulas",
        component: ReportClasses,
        exact: true,
        canEdit: false,
        canDelete: false,
        canCreate: false,
        canSee: false,
        columns: [],
    },
    {
        title: "Relatório de Produtos",
        path: "/relatorios/produtos",
        component: ReportProducts,
        exact: true,
        canEdit: false,
        canDelete: false,
        canCreate: false,
        canSee: false,
        columns: [],
    },
    {
        title: "Relatório de Núcleos",
        path: "/relatorios/nucleos",
        component: ReportProjects,
        exact: true,
        canEdit: false,
        canDelete: false,
        canCreate: false,
        canSee: false,
        columns: [],
    },
    {
        title: "Relatório de Profissionais",
        path: "/relatorios/profissionais",
        component: ReportProfessionals,
        exact: true,
        canEdit: false,
        canDelete: false,
        canCreate: false,
        canSee: false,
        columns: [],
    },
    {
        title: "Relatório de Pedidos",
        path: "/relatorios/pedidos",
        component: ReportOrders,
        exact: true,
        canEdit: false,
        canDelete: false,
        canCreate: false,
        canSee: false,
        columns: [],
    },
];
