export const createStudentQuery = `mutation ($body: student_insert_input!) {
    item: insert_student_one(object: $body) {
      id_student
      project {
        id: id_project
        name
      }
      person {
        id_person
        document
        email
        name
        birthday
        gender
        user {
          id: id_user
          email
        }
      }
      subscriptions {
        id_subscription
        is_active
        project_class {
          id_project_class
          name
        }
      }
      responsibles {
        id_responsible_student
        responsible {
          id_responsible
          person {
            id_person
            name
            user {
              id_user
              email
            }
          }
        }
      }
    }
  }
  
  `;
