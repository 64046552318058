import FormFactory from "components/Form/FormFactory";
import Spinner from "components/Spinner";
import { useAppDispatch, useAppSelector } from "hooks/store";
import React, { useCallback, useEffect } from "react";
import { userFields, userSchema } from "routes/entities/users";
import { Creators } from "store/user";

interface IProps {
    id?: number;
}

const UserForm = ({ id }: IProps) => {
    const state = useAppSelector(({ user }) => user);
    const { loading, error, id: userId, ...formState } = state || {};
    const dispatch = useAppDispatch();

    console.log({ userFields });

    const getUser = useCallback(() => {
        if (!id) {
            return;
        }

        dispatch(Creators.getUserByIdRequest({ id }));
    }, [dispatch, id]);

    useEffect(() => {
        getUser();
    }, [getUser]);

    useEffect(() => {
        return () => {
            dispatch(Creators.clearUser());
        };
    }, [dispatch]);

    const handleSubmit = useCallback(
        (data: any) => {
            dispatch(Creators.createOrEditUserRequest({ id, body: data }));
        },
        [dispatch, id]
    );

    const initialData = {
        ...(!!state?.id && formState),
    };

    const showLoading = !!id && loading && !state?.id;

    if (showLoading) {
        return <Spinner />;
    }

    return (
        <FormFactory
            fields={userFields}
            onSubmit={handleSubmit}
            schema={userSchema}
            isLoading={!!loading}
            initialData={initialData}
        />
    );
};

export default UserForm;
