import React from "react";
import { components } from "react-select";
import Availation from "../../Availation";
import Select, { ISelectProps } from "../Select";
import { ISelectOption } from "../../../utils/interfaces/ISelectOption";

interface IProps extends Partial<ISelectProps> {
    options?: ISelectOption[];
    name: string;
}

const SelectAvailation: React.FC<IProps> = (props) => {
    const CustomOption = (props: any) => {
        const { data } = props;

        return (
            <components.Option {...props}>
                <Availation vote={data.value} />
            </components.Option>
        );
    };

    const CustomValueContainer = (props: any) => {
        const { children, getValue } = props;

        const [selectOption] = getValue();

        return (
            <components.ValueContainer {...props}>
                {!!selectOption?.value ? (
                    <Availation vote={selectOption.value} />
                ) : (
                    children
                )}
            </components.ValueContainer>
        );
    };

    return (
        <Select
            {...props}
            options={[
                { value: 1, label: "1" },
                { value: 2, label: "2" },
                { value: 3, label: "3" },
                { value: 4, label: "4" },
                { value: 5, label: "5" },
            ]}
            components={{
                Option: CustomOption,
                ValueContainer: CustomValueContainer,
            }}
        />
    );
};

export default SelectAvailation;
