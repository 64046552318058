import React from "react";
import styled, { css } from "styled-components/macro";
import { rotation } from "../../assets/animations";
import { theme } from "../../config/theme";
import SpinnerIcon from "../Icons/spinner";

interface IProps {
    size?: number;
    color?: string;
    fullScreen?: boolean;
    backgroundColor?: string;
    style?: any;
}

export const SpinnerContainer = styled.span<{
    fullScreen: boolean;
    backgroundColor: string;
}>`
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ fullScreen, backgroundColor }) =>
        fullScreen &&
        css`
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;

            :before {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                background: ${backgroundColor};
                width: 100%;
                height: 100%;
                opacity: 0.5;
            }
        `};

    > svg {
        animation: ${rotation} 0.5s linear infinite;
    }
`;

const Spinner = ({
    size = 40,
    color = theme.colors.components.spinner.iconColor,
    fullScreen = false,
    backgroundColor = theme.colors.components.spinner.background,
    style,
}: IProps) => {
    return (
        <SpinnerContainer
            fullScreen={fullScreen}
            backgroundColor={backgroundColor}
            className="spinner"
            style={style}
        >
            <SpinnerIcon height={size} width={size} fill={color} />
        </SpinnerContainer>
    );
};

export default Spinner;
