import React, { Fragment, useCallback, useMemo, useState } from "react";
import CloseIcon from "../../Icons/close";
import HamburgerIcon from "../../Icons/hamburger";
import CSSTransition from "react-transition-group/CSSTransition";

import * as S from "./styles";
import { INavbarItem } from "..";
import { MainContent } from "../../../pages/Main/styles";
import Logo from "../../Logo";

import HeaderDropdown from "../../Header/dropdown";
import { NavbarSubItem, NavbarSubItemContainer } from "../desktop/styles";
import NavbarCompany from "../company";

interface IProps {
    items: INavbarItem[];
    onClickHome(): void;
}

const NavbarMobile: React.FC<IProps> = ({ items, onClickHome }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggleMenu = useCallback(() => setIsOpen((old) => !old), []);

    const bar = useMemo(() => {
        const Icon = isOpen ? CloseIcon : HamburgerIcon;

        return (
            <S.NavbarMobileBar>
                <Logo width={120} height={22} onClick={onClickHome} />

                <HeaderDropdown />

                <button type="button" onClick={handleToggleMenu}>
                    <Icon width={18} height={18} fill="currentColor" />
                </button>
            </S.NavbarMobileBar>
        );
    }, [handleToggleMenu, isOpen, onClickHome]);

    return (
        <>
            {bar}

            <CSSTransition
                in={isOpen}
                timeout={300}
                classNames="navbarBig"
                unmountOnExit
            >
                <S.NavbarMobileModal>
                    {bar}

                    <NavbarCompany onCloseNav={handleToggleMenu} />

                    <MainContent>
                        <S.NavbarMobileList>
                            {items.map(
                                ({ path, Icon, label, items: subItems }) => {
                                    const Component: any = !!subItems?.length
                                        ? S.NavbarMobileListItem
                                        : S.NavbarMobileListItemLink;

                                    return (
                                        <Fragment key={path}>
                                            <Component
                                                to={path}
                                                activeClassName="active"
                                                exact={path === "/app"}
                                                onClick={
                                                    !!subItems?.length
                                                        ? undefined
                                                        : handleToggleMenu
                                                }
                                            >
                                                <Icon width={14} height={14} />
                                                <span>{label}</span>
                                            </Component>

                                            {!!subItems?.length && (
                                                <NavbarSubItemContainer>
                                                    <span className="line"></span>

                                                    <div className="wrap">
                                                        {subItems?.map(
                                                            (item) => (
                                                                <NavbarSubItem
                                                                    to={
                                                                        item.path
                                                                    }
                                                                    activeClassName="active"
                                                                    onClick={
                                                                        handleToggleMenu
                                                                    }
                                                                >
                                                                    <span>
                                                                        {
                                                                            item.label
                                                                        }
                                                                    </span>
                                                                </NavbarSubItem>
                                                            )
                                                        )}
                                                    </div>
                                                </NavbarSubItemContainer>
                                            )}
                                        </Fragment>
                                    );
                                }
                            )}
                        </S.NavbarMobileList>
                    </MainContent>
                </S.NavbarMobileModal>
            </CSSTransition>
        </>
    );
};

export default NavbarMobile;
