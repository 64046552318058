import React from "react";
import { BadgeElement, IBadgeElementProps } from "./styles";

interface IProps extends IBadgeElementProps {
    children: React.ReactNode;
    icon?: React.ReactNode;
    style?: any;
}

const Badge = ({ children, icon, textAlign = "center", ...rest }: IProps) => {
    return (
        <BadgeElement textAlign={textAlign} {...rest}>
            {children}
            {!!icon && <span className="badge-icon">{icon}</span>}
        </BadgeElement>
    );
};

export default Badge;
