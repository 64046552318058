import { object, string } from "yup";
import { dictionary } from "../../config/dictionary";
import CrudCreateOrEdit from "../../pages/CRUD/CreateOrEdit";

import CrudList from "../../pages/CRUD/List";
import { InputType } from "../../utils/enums/InputType";
import { TableColumnType } from "../../utils/enums/TableColumnType";
import { IInputField } from "../../utils/interfaces/IInputField";
import { IRoute } from "../../utils/interfaces/IRoute";
import generateCreateQuery from "helpers/generateCreateQuery";
import generateEditQuery from "helpers/generateEditQuery";
import generateGetByIdQuery from "helpers/generateGetByIdQuery";

const schema = object().shape({
    name: string()
        .typeError(dictionary.validations.required)
        .required(dictionary.validations.required),
    technical_guidance: string()
        .min(1, dictionary.validations.required)
        .typeError(dictionary.validations.required)
        .required(dictionary.validations.required),
    main_goals: string()
        .min(1, dictionary.validations.required)
        .typeError(dictionary.validations.required)
        .required(dictionary.validations.required),
    observation_points: string()
        .min(1, dictionary.validations.required)
        .typeError(dictionary.validations.required)
        .required(dictionary.validations.required),
});

const fields: IInputField[] = [
    {
        name: "name",
        type: InputType.Text,
        label: "Nome",
        required: true,
        grid: {
            col: 12,
        },
    },
    {
        name: "technical_guidance",
        type: InputType.Editor,
        required: true,
        grid: {
            col: 12,
        },
    },

    {
        name: "main_goals",
        type: InputType.Editor,
        required: true,
        grid: {
            col: 12,
        },
    },

    {
        name: "observation_points",
        type: InputType.Editor,
        required: true,
        grid: {
            col: 12,
        },
    },
];

export const modalitiesRoutes: IRoute[] = [
    {
        title: "Modalidades",
        path: "/modalidades",
        exact: true,
        graphql: {
            companyPath: "project_modalities.project.company_id",
            tableName: "modality",
            orderBy: {
                name: "asc",
            },
        },
        mainColumn: "id",
        component: CrudList,
        canEdit: true,
        canDelete: true,
        canCreate: true,
        showTotal: true,
        columns: [
            {
                col: "auto",
                name: "name",
                type: TableColumnType.Text,
            },
        ],
    },
    {
        title: "Nova Modalidade",
        path: "/modalidades/novo",
        graphql: {
            tableName: "modality",
            create: generateCreateQuery("modality", fields),
        },
        component: CrudCreateOrEdit,
        exact: true,
        schema,
        fields,
    },
    {
        title: "Editar Modalidade",
        path: "/modalidades/editar/:id",
        graphql: {
            edit: generateEditQuery("modality", fields),
            getById: generateGetByIdQuery("modality", fields),
        },
        component: CrudCreateOrEdit,
        mainColumn: "id",
        exact: true,
        schema,
        fields,
    },
];
