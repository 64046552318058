import React, { useCallback } from "react";

import SelectCompany from "components/SelectCompany";
import { useAppDispatch, useAppSelector } from "hooks/store";

import { Creators as AuthActions } from "store/auth";
import { useModal } from "hooks/useModal";

const SelectCompanyModal = () => {
    const { close: closeModal } = useModal();

    const hasCurrentCompany = useAppSelector(
        ({ auth }) => !!auth?.currentCompany?.id
    );

    const dispatch = useAppDispatch();

    const onClickCancel = useCallback(() => {
        if (!hasCurrentCompany) {
            dispatch(AuthActions.logout());
        }

        closeModal();
    }, [closeModal, dispatch, hasCurrentCompany]);

    return <SelectCompany onCancel={onClickCancel} />;
};

export default SelectCompanyModal;
