import { Status } from "utils/enums/Status";

export interface IGenerateSearchQueryOptions {
    tableName: string;
    columnName?: string;
    companyPath?: string;
    filterByStatus?: Status;
}

export const generateSearchQuery = (options: IGenerateSearchQueryOptions) => {
    const {
        tableName,
        columnName = "name",
        companyPath,
        filterByStatus = Status.Active,
    } = options;

    const filters = [`${columnName}: {_ilike: $searchText}`];

    if (!!companyPath) {
        const keys = companyPath.split(".");

        var obj =
            `${keys.join(": {\n")}\n` +
            `: { _eq: ${(window as any)?.company} }` +
            Array.from(new Array(keys.length >= 1 ? keys.length - 1 : 0))
                .map(() => `}`)
                .join("\n");

        filters.push(obj);
    }

    if (!!filterByStatus) {
        filters.push(`status: {\n _eq: ${filterByStatus} }`);
    }

    let where = "{\n" + filters.join(",\n") + "}";

    const query = `query ($searchText: String) {
        items: ${tableName}(limit: 10, where: ${where}, order_by: {${columnName}: asc}) {
          value: id
          label: ${columnName}
        }
        quantity: ${tableName}_aggregate(where: ${where}) {
            aggregate {
              count
            }
          }
      }`;

    // console.group(`generateSearchQuery - ${tableName}`, { options });
    // console.log(query);
    // console.groupEnd();

    return query;
};

export default generateSearchQuery;
