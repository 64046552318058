import { createReducer, createActions } from "reduxsauce";
import {
    AuthTypes,
    IAuthState,
    IAuthActionsCreators,
    ILoginRequestPayload,
    IAuth,
} from "./types";
import { ReduxAction } from "..";
import { ICompany } from "utils/interfaces/ICompany";

export const { Creators, Types } = createActions<
    Record<AuthTypes, AuthTypes>,
    IAuthActionsCreators
>({
    loginRequest: ["payload"],
    loginSuccess: ["payload"],
    loginFailure: [],

    logout: [],

    getAvailableCompaniesRequest: [],
    getAvailableCompaniesSuccess: ["payload"],
    getAvailableCompaniesFailure: [],

    setCurrentCompany: ["payload"],
});

const INITIAL_STATE: IAuthState = {
    error: false,
    loading: false,
    token: undefined,
    credentials: undefined,
    currentCompany: undefined,
    availableCompanies: [],
};

const loginRequest = (
    _state = INITIAL_STATE,
    _action: ReduxAction<ILoginRequestPayload>
): IAuthState => {
    return {
        ...INITIAL_STATE,
        error: false,
        loading: true,
    };
};

const loginSuccess = (
    state = INITIAL_STATE,
    action: ReduxAction<IAuth>
): IAuthState => {
    return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
    };
};

const clear = (_state = INITIAL_STATE): IAuthState => {
    return INITIAL_STATE;
};

const setCurrentCompany = (
    state = INITIAL_STATE,
    action: ReduxAction<ICompany>
): IAuthState => {
    return {
        ...state,
        currentCompany: action.payload,
    };
};

const getAvailableCompaniesSuccess = (
    state = INITIAL_STATE,
    action: ReduxAction<ICompany[]>
) => {
    return {
        ...state,
        availableCompanies: action?.payload ?? [],
    };
};

const HANDLERS = {
    [Types.LOGIN_REQUEST]: loginRequest,
    [Types.LOGIN_SUCCESS]: loginSuccess,
    [Types.LOGIN_FAILURE]: clear,

    [Types.GET_AVAILABLE_COMPANIES_REQUEST]: loginRequest,
    [Types.GET_AVAILABLE_COMPANIES_SUCCESS]: getAvailableCompaniesSuccess,
    [Types.GET_AVAILABLE_COMPANIES_FAILURE]: clear,

    [Types.SET_CURRENT_COMPANY]: setCurrentCompany,

    [Types.LOGOUT]: clear,
};

export default createReducer<IAuthState>(INITIAL_STATE, HANDLERS);
