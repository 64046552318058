import styled, { css } from "styled-components";
import { theme } from "../../config/theme";
import { generateSizeStyles } from "../../helpers/generateSizeStyles";
import { generateSpacingStyles } from "../../helpers/generateSpacingStyles";
import { ISizing } from "../../utils/interfaces/ISizing";
import { ISpacing } from "../../utils/interfaces/ISpacing";

interface IProps extends ISizing, ISpacing {
    column?: boolean;
}

const colors = theme.colors.components.card;

export const CardStyles = css`
    background: ${colors.background};
    border: 1px solid ${colors.borderColor};
    padding: 18px 20px;
    border-radius: 8px;
`;

export const Card = styled.div<IProps>`
    ${CardStyles};

    ${(props) => generateSpacingStyles(props)};
    ${(props) => generateSizeStyles(props)};

    ${({ column }) =>
        !!column &&
        css`
            flex-direction: column;
        `};
`;
