import styled, { keyframes } from "styled-components";
import { ISizing } from "../../utils/interfaces/ISizing";
import { ISpacing } from "../../utils/interfaces/ISpacing";
import { generateSpacingStyles } from "../../helpers/generateSpacingStyles";
import { generateSizeStyles } from "../../helpers/generateSizeStyles";
import { theme } from "../../config/theme";

interface IProps extends ISizing, ISpacing {
    column?: boolean;
}

const loading = keyframes`from {
    left: -150px;
}
to   {
    left: 100%;
}`;

export const Skeleton = styled.span<IProps>`
    ${(props) => generateSpacingStyles(props)};
    ${(props) => generateSizeStyles(props)};

    display: flex;

    box-shadow: 0 4px 10px 0 rgba(33, 33, 33, 0.15);
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    background: ${theme.colors.mainLight};

    :before {
        content: "";
        display: block;
        position: absolute;
        left: -150px;
        top: 0;
        height: 100%;
        width: 150px;
        background: linear-gradient(
            to right,
            transparent 0%,
            ${theme.colors.white} 50%,
            transparent 100%
        );
        animation: ${loading} 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    }
`;
