import _isArray from "lodash/isArray";
import { isValidDate } from "helpers/isValidDate";
import { formatDate } from "helpers/formatDate";

export const formatReportBody = (data: any) => {
    const body: any = {};

    const items = [...Object.keys(data)];

    items.forEach((key) => {
        const value = data[key];

        if (_isArray(value)) {
            body[key] = value?.map(({ value }) => value);
            return;
        }

        if (isValidDate(value)) {
            body[key] = formatDate(value, "yyyy-MM-dd");

            return;
        }

        body[key] = value;
    });

    return body;
};
