export const editStudentSubscriptionQuery = `mutation ($body: subscription_set_input, $id: Int!) {
    item: update_subscription_by_pk(pk_columns: {id_subscription: $id}, _set: $body) {
      id_subscription
      is_active
      project_class {
        id_project_class
        name
      }
    }
  }
  `;
