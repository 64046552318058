import React, { CSSProperties } from "react";
import Spinner from "../Spinner";
import { ButtonElement, IButtonElementProps } from "./styles";

interface IProps extends IButtonElementProps {
    children: React.ReactNode;
    type?: "button" | "submit" | "reset";
    onClick?(): void;
    icon?: React.ReactNode;
    style?: CSSProperties;
    loading?: boolean;
}

const Button = ({
    children,
    type = "button",
    icon,
    textAlign = "center",
    rounded = false,
    loading = false,
    disabled = false,
    ...rest
}: IProps) => {
    return (
        <ButtonElement
            type={type}
            textAlign={textAlign}
            rounded={rounded}
            disabled={loading || disabled}
            {...rest}
        >
            {loading && <Spinner color="#fff" size={17} />}
            {children}
            {!!icon && <span className="button-icon">{icon}</span>}
        </ButtonElement>
    );
};

export default Button;
