import React, { useCallback, useEffect, useRef, memo, useMemo } from "react";
import { IReduxStore } from "../../store/ducks";
import { Card } from "../Card";
import Table from "../Table";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ITableAction, ITableColumn } from "../../utils/interfaces/ITable";
import { ICrudActions, ICrudState } from "../../store/crud/types";
import { IRouteGraphQueries } from "../../utils/interfaces/IRoute";
import { ListDataContainer } from "./styles";
import { ModalElements } from "../../utils/enums/ModalElements";
import { dictionary } from "../../config/dictionary";
import history from "../../services/history";
import { useModal } from "../../hooks/useModal";
import { Creators as CrudActions } from "../../store/crud";
import CSSTransition from "react-transition-group/CSSTransition";
import generateGetAllQuery from "../../helpers/generateGetAllQuery";
import generateDeleteQuery from "../../helpers/generateDeleteQuery";
import EyeIcon from "../Icons/eye";
import { theme } from "../../config/theme";
import EditIcon from "../Icons/edit";
import DeleteIcon from "../Icons/delete";
import { TableColumnType } from "../../utils/enums/TableColumnType";
import scrollToTop from "../../helpers/scrollToTop";
import ListDataHeader from "./header";
import { Status } from "utils/enums/Status";
import { useCompanyId } from "hooks/useCompanyId";
import _set from "lodash/set";

export interface IListDataProps {
    columns: ITableColumn[];
    canEdit?: boolean;
    canCreate?: boolean;
    canDelete?: boolean;
    canSee?: boolean;
    path?: string;
    graphql?: IRouteGraphQueries;
    mainColumn?: string | Array<string>;
    defaultSearch?: object;
    onClickCreate?(): void;
    onClickSeeDetails?(item: any): void;
    onClickEdit?(item: any): void;
    title?: string;
    createButtonLabel?: string;
    contentId?: number | string;
    customActions?: ITableAction[];
    showTotal?: boolean;
    showDeleted?: boolean;
}

interface IProps extends IListDataProps {
    // redux props
    crudActions: ICrudActions;
    crud: ICrudState;
}

const ListData = ({
    columns = [],
    crudActions,
    canEdit,
    canDelete,
    canCreate,
    path,
    canSee,
    graphql,
    mainColumn,
    defaultSearch,
    onClickCreate,
    title,
    createButtonLabel = "Criar novo",
    contentId,
    crud,
    customActions,
    onClickSeeDetails,
    onClickEdit,
    showTotal = true,
    showDeleted = false,
}: IProps) => {
    const company = useCompanyId();

    const modal = useModal();

    const tempQuery = useRef("");

    const currentContent = useMemo(
        () => (!!contentId ? (crud as any)[contentId] : crud),
        [contentId, crud]
    );

    const orderBy = useMemo(() => graphql?.orderBy, [graphql]);

    const getAllQuery = useMemo(() => {
        return generateGetAllQuery(
            columns,
            graphql?.tableName,
            graphql?.getAll,
            mainColumn
        );
    }, [columns, graphql, mainColumn]);

    const deleteQuery = useMemo(
        () =>
            generateDeleteQuery(
                mainColumn,
                graphql?.tableName,
                graphql?.getAll
            ),
        [graphql, mainColumn]
    );

    useEffect(() => {
        tempQuery.current = graphql?.getAll;
    }, [graphql]);

    useEffect(() => {
        return () => {
            crudActions.clearCrud(contentId);
        };
    }, [contentId, crudActions]);

    const request = useCallback(
        (page = 1, search?: any) => {
            scrollToTop();

            const searchObject = {
                ...defaultSearch,
                ...search,
                ...(!showDeleted && {
                    status: { _eq: Status.Active },
                }),
            };

            if (!!graphql?.companyPath) {
                _set(searchObject, `${graphql.companyPath}._eq`, company);
            }

            crudActions.getAllRequest({
                page,
                search: searchObject,
                query: getAllQuery,
                contentId,
                orderBy,
            });
        },
        [
            company,
            contentId,
            crudActions,
            defaultSearch,
            getAllQuery,
            graphql,
            orderBy,
            showDeleted,
        ]
    );

    useEffect(() => {
        request();
    }, [request]);

    const handleClickDelete = useCallback(
        (item: any) => {
            if (!item || !deleteQuery) {
                return;
            }

            modal.open({
                element: ModalElements.ConfirmAction,
                width: "500px",
                action: {
                    message: dictionary.phrases.confirmDelete,
                    onConfirm: () =>
                        crudActions.deleteByIdRequest({
                            item,
                            query: deleteQuery,
                            mainColumn,
                            contentId,
                        }),
                },
                center: true,
            });
        },
        [contentId, crudActions, deleteQuery, mainColumn, modal]
    );

    const handleClickEdit = useCallback(
        (item: any) => {
            if (onClickEdit) {
                onClickEdit(item);
                return;
            }

            if (!item?.id) {
                return;
            }

            const editPath = `/app${path}/editar/${item?.id}`;

            history.push({
                pathname: editPath,
            });
        },
        [onClickEdit, path]
    );

    const handleClickSee = useCallback(
        (item: any) => {
            if (onClickSeeDetails) {
                onClickSeeDetails(item);
                return;
            }

            if (!item?.id) {
                return;
            }

            const editPath = `/app${path}/ver/${item.id}`;

            history.push({
                pathname: editPath,
            });
        },
        [onClickSeeDetails, path]
    );

    const requestSearch = useCallback(
        (search: any) => {
            if (!search) {
                return;
            }

            request(1, search);
        },
        [request]
    );

    const handleClickCreate = useCallback(() => {
        if (onClickCreate) {
            onClickCreate();
            return;
        }

        if (!!path) {
            history.push(`/app${path}/novo`);
        }
    }, [onClickCreate, path]);

    const defaultActions = useMemo(() => {
        const tmpActions = customActions || [];

        if (canEdit) {
            tmpActions.push({
                icon: () => (
                    <EditIcon
                        width={13}
                        height={16}
                        fill={theme.colors.placeholder}
                    />
                ),
                onClick: (item) => handleClickEdit(item),
            });
        }

        if (canSee) {
            tmpActions.push({
                icon: () => (
                    <EyeIcon
                        width={16}
                        height={16}
                        fill={theme.colors.placeholder}
                    />
                ),
                onClick: (item) => handleClickSee(item),
            });
        }

        if (canDelete) {
            tmpActions.push({
                icon: () => (
                    <DeleteIcon
                        width={16}
                        height={16}
                        fill={theme.colors.placeholder}
                    />
                ),
                onClick: (item) => handleClickDelete(item),
            });
        }

        return tmpActions;
    }, [
        canDelete,
        canEdit,
        canSee,
        customActions,
        handleClickDelete,
        handleClickEdit,
        handleClickSee,
    ]);

    const removeHiddenColomuns = useMemo(
        () =>
            columns?.filter(({ type }) => type !== TableColumnType.Hidden) ||
            [],
        [columns]
    );

    return (
        <ListDataContainer>
            {/* {!!canCreate && !title && (
                <Button
                    variant="success"
                    left="auto"
                    bottom="25px"
                    onClick={handleClickCreate}
                >
                    {createButtonLabel}
                </Button>
            )} */}

            <Card column>
                {!!title && (
                    <ListDataHeader
                        totalItems={currentContent?.pagination?.totalItems ?? 0}
                        showTotal={showTotal}
                        title={title}
                        canCreate={canCreate}
                        createButtonLabel={createButtonLabel}
                        handleClickCreate={handleClickCreate}
                    />
                )}

                <CSSTransition
                    in={tempQuery?.current === graphql?.getAll}
                    timeout={300}
                    classNames="table"
                    unmountOnExit
                >
                    <Table
                        isEmpty={!!currentContent?.empty}
                        isLoading={!!currentContent?.loading}
                        columns={removeHiddenColomuns}
                        actions={defaultActions}
                        data={currentContent?.items || []}
                        pagination={{
                            page: currentContent?.pagination?.page ?? 1,
                            totalPages:
                                currentContent?.pagination?.totalPages ?? 1,
                            totalItems:
                                currentContent?.pagination?.totalItems ?? 0,
                        }}
                        handleChangePagination={request}
                        mainColumn={mainColumn}
                        handleChangeSearch={requestSearch}
                        // error={!!currentContent?.error}
                    />
                </CSSTransition>
            </Card>
        </ListDataContainer>
    );
};

const mapStateToProps = ({ crud }: IReduxStore) => ({
    crud,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    crudActions: bindActionCreators(CrudActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(ListData));
