import { IFile } from "utils/interfaces/IFile";

export const generateFileURL = (file?: IFile) => {
    const { filename, path } = file || {};

    if (!filename || !path) {
        return undefined;
    }

    return `${process.env.REACT_APP_ASSETS_URL}/${path}/${filename}`;
};
