import Dashboard from "../pages/Dashboard";
import { IRoute } from "../utils/interfaces/IRoute";

import { modalitiesRoutes } from "./entities/modalities";
import { professionalsRoutes } from "./entities/professionals";
import { projectClassesRoutes } from "./entities/projectClasses";
import { projectsRoutes } from "./entities/projects";
import { responsiblesRoutes } from "./entities/responsibles";
import { studentsRoutes } from "./entities/students";
import { usersRoutes } from "./entities/users";

import Error from "../components/Error";
import { productsRoutes } from "./entities/products";
import { productOrdersRoutes } from "./entities/productOrders";
import { reportsRoutes } from "./entities/reports";
import { providersRoutes } from "./entities/providers";

// all routes start with "/app"
export const privateRoutes: IRoute[] = [
    {
        path: "",
        component: Dashboard,
        exact: true,
        title: "Dashboard",
    },

    ...usersRoutes,
    ...projectsRoutes,
    ...professionalsRoutes,
    ...studentsRoutes,
    ...responsiblesRoutes,
    ...modalitiesRoutes,
    ...productsRoutes,
    ...productOrdersRoutes,
    ...reportsRoutes,
    ...providersRoutes,
    ...projectClassesRoutes,

    {
        path: "*",
        component: Error,
        exact: false,
        title: "Página não encontrada.",
        description: "Como você chegou aqui?",
    },
];
